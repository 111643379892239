import React from "react";
import { InjectedFormikProps } from "formik";
import Input from "../../../../components/form/Input";
import { Row } from "reactstrap";

export interface ILoginFormElementsValues {
	username: string;
	password: string;
}

interface ILoginFormElementsProps {}

const LoginFormElements: React.FC<
	InjectedFormikProps<ILoginFormElementsProps, ILoginFormElementsValues>
> = (props) => {
	const {
		values: { username, password },
		handleChange,
		touched,
		handleBlur,
		errors
	} = props;

	return (
		<div className="login-form-content">
			<Row>
				<Input
					name="username"
					placeholder="Email address"
					value={username}
					handleChange={handleChange}
					errors={errors}
					touched={touched}
					handleOnBlur={handleBlur}
				/>
			</Row>
			<Row>
				<Input
					type="password"
					name="password"
					placeholder="Password"
					value={password}
					handleChange={handleChange}
					errors={errors}
					handleOnBlur={handleBlur}
					touched={touched}
				/>
			</Row>
		</div>
	);
};

export default LoginFormElements;
