import React, { useState } from "react";
import { ArrowRight, Compass, DollarSign, Download, Star, Users, X } from "react-feather";
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Table, Tooltip } from "reactstrap";
import { INotification } from "../../../interfaces/notification";
import { getNotificationIcon } from "../../../utils/notification";
import { generateNotificationDetails } from "./utils/notification";
import SustainabilityScore from "../../../assets/images/sustainability-scale-icon.svg";
import SuitabilityIcon from "../../../assets/images/suitability-score-icon2.svg";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import ReactImageFallback from "react-image-fallback";
import RecommendedIngredientsTable from "../../products/components/recommendIngredientsTable";
import { RecommendationActions } from "../../../components/common/recommendations";
import { routes } from "../../routes";
import { Link } from "react-router-dom";

import RecommendationModal from "../../products/components/recommendationModal";

interface IProps {
	isOpen: boolean;
	notification: INotification;
	toggleModal: () => void;
}

const NotificationModal: React.FC<IProps> = ({ notification, isOpen, toggleModal, ...props }) => {
	const recommendation = notification?.meta_data?.recommended_product_ingredients;

	const [viewActionPlan, setViewActionPlan] = useState<boolean>();

	const renderRecommendationTags = () => {
		return (
			<>
				<h6>Associated Tags:</h6>
				<div className="rec-tags">
					{recommendation?.tags?.map((tag: string) => {
						return <span>{tag}</span>;
					})}
				</div>
			</>
		);
	};

	const handleNavBack = () => {
		setViewActionPlan(false);
	};

	return (
		<>
			{notification?.message_type == "Recommendation" ? (
				<RecommendationModal isOpen={isOpen} recommendation={recommendation} toggle={toggleModal} />
			) : (
				<Modal
					isOpen={isOpen}
					className={
						notification?.message_type === "regulation" ||
						notification?.message_type == "Regulation"
							? "notification-modal regulation-notification"
							: `notification-modal ${notification?.message_type.toLowerCase()}`
					}
				>
					<ModalHeader>
						{notification?.message_type != "Recommendation" &&
							(notification?.icon || getNotificationIcon(notification?.message_type))}
						<span>
							{notification?.message_type
								? notification?.message_type.charAt(0).toUpperCase() +
								  notification?.message_type.slice(1)
								: "Notification"}
							{notification.message_type == "Recommendation" &&
								` for ${recommendation?.product.name}`}
						</span>
						{notification?.message_type == "Recommendation" ? (
							<div className="ai">
								<Compass size={18} />
								<span>Journey AI</span>
							</div>
						) : (
							<></>
						)}
						<X className="float-right" onClick={toggleModal} />
					</ModalHeader>

					<ModalBody>
						{viewActionPlan && recommendation ? (
							<RecommendationActions
								navBackCallback={handleNavBack}
								recommendation={recommendation}
							/>
						) : (
							<Col>
								<Row style={{ height: 125 }} className={"mb-3"}>
									{notification.message_type == "Recommendation" && (
										// <img
										// 	className={"mr-3"}
										// 	src={config.api.endpoints.ingredients.ingredientImage(
										// 		recommendation?.ingredient.jf_display_name as string
										// 	)}
										// />
										<ReactImageFallback
											src={config.api.endpoints.ingredients.ingredientImage(
												recommendation?.ingredient.jf_display_name
											)}
											fallbackImage={getIngredientImagePlaceholder()}
											alt="Ingredient Image"
										/>
									)}
									<div style={{ height: "100%" }} className={"ml-2 summary"}>
										<h6>Summary: </h6>
										<p>{notification?.message}</p>
										{notification.message_type == "Recommendation" && (
											<Link to={routes.PRODUCTS + "/" + recommendation?.product?.id}>
												View Product
											</Link>
										)}
									</div>
								</Row>
							</Col>
						)}

						{recommendation && !viewActionPlan && (
							<>
								{renderRecommendationTags()}
								<RecommendedIngredientsTable recommendation={recommendation} />
							</>
						)}
						{!viewActionPlan && (
							<p>
								{" "}
								Created On: <br />{" "}
								{notification?.created_at ? new Date(notification.created_at).toString() : ""}{" "}
							</p>
						)}

						{recommendation && !viewActionPlan && (
							<div
								className="action-buttons"
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
									alignItems: "center",
									flexWrap: "nowrap"
								}}
							>
								<Button
									disabled={true}
									style={{
										padding: ".375rem",
										height: 32
									}}
									className={"mr-2 d-flex align-items-center justify-content-center"}
									color="success"
									onClick={() => setViewActionPlan(true)}
								>
									<span>Actions</span>
									<ArrowRight size={18} className={"ml-2"} />
								</Button>
								<a
								// href=" https://storage.googleapis.com/journeyfoods-datasheets/CactOLean_Tech_Sheet_v1.pdf"
								// target="_blank"
								>
									<Button
										disabled
										color="primary"
										className="d-flex align-items-center justify-content-center"
										style={{
											padding: ".375rem",
											height: 32,
											width: 32,
											marginLeft: "auto",
											marginRight: 0
										}}
									>
										<Download size={18} color="#fff" />
									</Button>
								</a>
							</div>
						)}
					</ModalBody>
				</Modal>
			)}
		</>
	);
};

export default NotificationModal;
