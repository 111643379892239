import React, { useState } from 'react';
import { Box, Loader, ShoppingCart } from 'react-feather';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import Upgrade from '../../../components/common/upgrade';
import { IUser } from '../../../interfaces/user';
import { IApplicationState } from '../../../redux/reducers';

interface IProps {
    profile?: IUser | undefined;
}

const Unmatched: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState<boolean>();
    return (
        <Card className={`packaging-unmatched`}>
            <CardBody>
                { props.profile?.company?.subscription_type == 'supplier' ?
                    <div style={{height: '100%', marginTop: 0}}>
                        <Upgrade /> 
                    </div> :
                    <div className={'unmatched-num'}>
                        <div>
                            <CardTitle><h1 className="font-weight-semibold mb-0">{30}</h1>{loading ? <Loader className="fa-spin ml-2" /> : <ShoppingCart className="ml-2" />}</CardTitle>
                            <CardSubtitle>Unmatched <br /> Active Products</CardSubtitle>
                        </div>
                        <div>
                            <CardTitle><h1 className="font-weight-semibold mb-0">{15}</h1>{loading ? <Loader className="fa-spin ml-2" /> : <Box className="ml-2" />}</CardTitle>
                            <CardSubtitle>Unmatched <br /> Concept Products</CardSubtitle>
                        </div>
                    </div>
                }

            </CardBody>
        </Card>
    )
}

const mapStateToProps = (state: IApplicationState) => ({
    notifications: state.notification.all,
    profile: state.user.profile,
})

export default Unmatched