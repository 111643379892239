import React from "react";
import { withFormik, InjectedFormikProps } from "formik";
import { connect } from "react-redux";
import ProductFormElements, { IProductFormElements } from "./productFormElements";
import { ProductValidation } from "../../../../validations/productValidation";
import { IProduct } from "../../../../interfaces/products";
import { Form } from "reactstrap";
import Button from "../../../../components/common/button";
import { Save } from "react-feather";
import moment from "moment";
import { formatProductData, formatProductFormData } from "../../../../utils/product";
import productService from "../../../../services/productService";
import { IApplicationState } from "../../../../redux/reducers";
import searchService from "../../../../services/searchService";
import { paginateAction } from "../../../../redux/actions/pagination/pagination";
import { search } from "../../../../redux/actions/actionContants";
import { Dispatch } from "redux";

interface IAddProductFormProps {
	add: (productData: any) => Promise<any>;
	company: any;
	toggleForm: () => void;
}

const AddProductForm: React.FC<InjectedFormikProps<IAddProductFormProps, any>> = (props) => {
	return (
		<Form className="product-form">
			<ProductFormElements {...props} />
			<br />
			<hr />
			<span className="save">
				<Button
					className="color-white mr-2"
					onClick={props.handleSubmit}
					color="primary"
					loading={props.isSubmitting}
					disabled={props.isSubmitting}
				>
					<Save size={18} className="mr-2" />
					Add
				</Button>
			</span>
		</Form>
	);
};

const WrappedForm = withFormik<IAddProductFormProps, IProductFormElements>({
	mapPropsToValues: ({ company }) => {
		return {
			cost: 0,
			markup: 0,
			cost_margin: 0,
			name: "",
			company,
			serving_size: 0,
			fulfillment_date: moment().format("YYYY-MM-DD"),
			created_date: moment().format("YYYY-MM-DD"),
			unit_serving_size: "oz",
			manufacturer: null,
			product_type: null,
			notes: "",
			country: null,
			currency: null,
			product_status: {
				label: "Concept",
				value: false
			},
			ingredients: [],
			flavor: "",
			brand: null,
			code: "",
			packaging: undefined
		};
	},
	handleSubmit: async function (values, { props, resetForm }) {
		values.company = props.company;
		const formattedData = formatProductFormData(values);
		await props.add(formattedData);
		props.toggleForm();
		resetForm();
	},
	validationSchema: ProductValidation
});

const mapStateToProps = (state: IApplicationState) => {
	return {
		company: state.user.profile?.company || {}
	};
};

const mapDispatchToProps = {
	add: (productData: IProduct) => productService.addProduct(productData)
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm(AddProductForm));
