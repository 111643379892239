import React, { useCallback, useEffect, useState } from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { IProduct, IProductIngredient } from "../../../../../interfaces/products";
import { IApplicationState } from "../../../../../redux/reducers";
import config from "../../../../../utils/config";
import { ImageSkeleton } from "../../../../../utils/ghostUI";
import { getIngredientImagePlaceholder } from "../../../../../utils/ingredient";

interface IProductIngredientComparison extends IProductIngredient {
	percent_diff: number | undefined;
}
interface IProps {
	currentIngredients: IProductIngredient[];
	comparisonIngredients: IProductIngredient[];
	currentVersion: IProduct | null;
	isMain?: boolean;
}

const IngredientComparison: React.FC<IProps> = ({
	currentIngredients,
	comparisonIngredients,
	currentVersion,
	isMain,
	...props
}) => {
	const [ingredientDifferences, setIngredientDifferences] = useState<
		IProductIngredientComparison[]
	>([]);

	const computeIngredientDifferences = useCallback(() => {
		const ingredientsWithDifferences: IProductIngredientComparison[] = [];
		currentIngredients.forEach((productIngredient: IProductIngredient) => {
			const match = comparisonIngredients.find(
				(p: IProductIngredient) => p.ingredient.id == productIngredient.ingredient.id
			);
			const diff =
				match == undefined || !(match.percentage && productIngredient.percentage)
					? undefined
					: match.percentage - productIngredient.percentage;
			ingredientsWithDifferences.push({
				...productIngredient,
				percent_diff: diff
			});
		});
		setIngredientDifferences(ingredientsWithDifferences);
	}, [comparisonIngredients, currentIngredients, setIngredientDifferences]);

	useEffect(() => {
		computeIngredientDifferences();
	}, [computeIngredientDifferences]);

	return (
		<Container>
			<Col className={"ingredient-comparison"}>
				<Row className={"header"}>
					<div>
						<span>Ingredients:</span>
						<span>{`${currentIngredients.length}`}</span>
					</div>
					{/* <div>
						<span>Industry Avg:</span>
						<span>{`${
							currentVersion?.ingredients ? currentVersion.ingredients.length - 2 : "-"
						}`}</span>
					</div> */}
				</Row>
				<Row>
					<ul className={"ingredients"}>
						{ingredientDifferences.map((i: IProductIngredientComparison, index: number) => (
							<li key={index} className={`ingredient-item ${i.percent_diff != 0 ? "uniq" : ""}`}>
								<ReactImageFallback
									src={config.api.endpoints.ingredients.ingredientImage(
										i.ingredient?.jf_display_name
									)}
									fallbackImage={getIngredientImagePlaceholder()}
									initialImage={<ImageSkeleton />}
								/>
								<span className={"ingredient-name"}>{i.ingredient?.jf_display_name}</span>
								{i.percent_diff != 0 && i.percent_diff && !isMain && (
									<div
										className={`ingredient-percent-diff ${
											i.percent_diff && i.percent_diff < 0 ? "decrease" : ""
										}`}
									>
										{i.percent_diff && i.percent_diff > 0 ? (
											<ArrowUp size={18} />
										) : (
											<ArrowDown size={18} />
										)}
										<span>{Math.abs(i.percent_diff).toFixed(2) + "%"}</span>
									</div>
								)}
							</li>
						))}
					</ul>
				</Row>
			</Col>
		</Container>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	currentVersion: state.product.selected
});

export default connect(mapStateToProps)(IngredientComparison);
