import { ICompany } from "./company";

export interface IInvoice {
	company: ICompany | null;
	created_at: string;
	id: string;
	invoice_id: string;
	invoice_status: string;
	meta_data: IInvoiceMetaData;
	updated_at: string;
	type?: string;
}

export interface IInvoiceMetaData {
	description: string;
	price: string;
	service: string;
}

export enum StripeSubscriptionModifyAction {
	CANCEL = "CANCEL",
	AUTO_RENEW_OFF = "AUTO_RENEW_OFF",
	AUTO_RENEW_ON = "AUTO_RENEW_ON"
}
