import React from 'react';
import { Row, Button } from 'reactstrap';
import {Share2 } from 'react-feather'
// Market Icon Import
import nielsen from '../../../assets/images/markets/nielsen.png';
import mintel from '../../../assets/images/markets/mintel.png';
import npd from '../../../assets/images/markets/npd.png';
import crunchbase from '../../../assets/images/markets/crunchbase.png';
import cxi from '../../../assets/images/markets/cxi.png';

const Markets = () => {
  return (
      <div className={"page-content container-fluid"} style={{marginBottom: 35}}>
        <div className="analytics-markets">
          <Row>
            <h4 className={'mb-3'}>Connect your market research data to your innovation team.</h4>
            <p style={{fontSize: 16}}>Whether it is your own data or third party, find out more about some key partners and contact us to connect to other partners</p>
          </Row>
          <Row>
            <div className={"market-connection"}>
              <span style={{height: 150}}>
                <img src={npd} height={150}></img>
              </span>
              <a href="mailto:sales@journeyfoods.com?subject=NPD Inquiry">
                <Button color="secondary" outline className="mt-4 color-battleship-grey">
                  <Share2 className="mr-2 color-battleship-grey" size={17} />
                  Request
                </Button>
              </a>
            </div>
            <div className={"market-connection"}>
              <span style={{height: 150}}>
                <img src={crunchbase} height={150}></img>
              </span>
              <a href="mailto:sales@journeyfoods.com?subject=Crunchbase Inquiry">
                <Button color="secondary" outline className="mt-4 color-battleship-grey">
                  <Share2 className="mr-2 color-battleship-grey" size={17} />
                  Request
                </Button>
              </a>
            </div>
            <div className={"market-connection"}>
              <span style={{height: 150}}>
                <img src={mintel} height={150}></img>
              </span>
              <a href="mailto:sales@journeyfoods.com?subject=Mintel Inquiry">
                <Button color="secondary" outline className="mt-4 color-battleship-grey">
                  <Share2 className="mr-2 color-battleship-grey" size={17} />
                  Request
                </Button>
              </a>
            </div>
            <div className={"market-connection"}>
              <span style={{height: 150}}>
                <img src={nielsen} height={150}></img>
                </span>
              <a href="mailto:sales@journeyfoods.com?subject=Nielsen Inquiry">
                <Button color="secondary" outline className="mt-4 color-battleship-grey">
                  <Share2 className="mr-2 color-battleship-grey" size={17} />
                  Request
                </Button>
              </a>
            </div>
            <div className={"market-connection"}>
              <span style={{height: 150}}>
                <img src={cxi} width={150} ></img>
              </span>
              <a href="mailto:sales@journeyfoods.com?subject=Customerx.i Inquiry">
              <Button disabled={true} color="secondary" outline className="mt-4 color-battleship-grey">
                  <Share2 className="mr-2 color-battleship-grey" size={17} />
                  Coming Soon
              </Button>
              </a>
            </div>
            {/* <div className={"market-connection"}>
              <span style={{height: 150}}>
                <img src={iri} width={150} ></img>
              </span>
              <Button color="secondary" style={{backgroundColor: '#3f65f1'}} outline className="mt-4">
                <Share2 className="mr-2" color="#fff" size={17} />
                Connect
              </Button>
            </div> */}
          </Row>
        </div>

      </div>
  )
};

export default Markets