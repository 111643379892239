import React, { useEffect, useState } from "react";
import EmailScheduleForm from "./components/forms/emailScheduleForm/EmailScheduleForm";
import { connect, useSelector } from "react-redux";
import adminService from "../../services/adminService";
import { IEmailSchedule } from "../../interfaces/admin";
import Button from "../../components/common/button";
import Loader from "../../components/loader";
import { IApplicationState } from "../../redux/reducers";
import EmailForm from "./components/forms/email/EmailForm";

interface IProps {}
const Email: React.FC<IProps> = () => {
	return (
		<div style={{ padding: "10px 0" }} className={"d-flex  justify-content-center pt-5"}>
			<div className="admin_email">
				<Loader isLoading={false}>
					<EmailForm />
				</Loader>
			</div>
		</div>
	);
};

export default Email;
