import React from "react";
import Header from "../../../components/common/header";
import Sidebar from "../../../components/common/sidebar";
import { Row, Col, Progress, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap";

class Integration extends React.Component {
  render() {
    return (
      <>
        <Header />
        <data className="page-wrapper d-block">
          <div className="page-content container-fluid position-relative">
            <div className="integration">
              <Row>
                <Col md="10">
                  <div>
                    <Card className="shadow border-radius-12">
                      <div className="h-50 bg-very-light-blue d-flex justify-content-center align-items-center p-5 border-radius-top-12">
                        <img width="200px" height="67px" src="https://cdn.clipart.email/caade7009a201107098eaf3fae40f011_google-logo-gray-uldissprogis_544-184.png" alt="" />
                      </div>
                      <CardBody>
                        <CardTitle>Integration Card</CardTitle>
                        <CardText>Description of integration and whats it pulls from goes here…</CardText>
                        <Button color="primary" className="mt-4">
                          <i className="fas fa-share-alt mr-2"></i>
                        Connected</Button>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card className="shadow border-radius-12">
                      <div className="h-50 bg-very-light-blue d-flex justify-content-center align-items-center p-5 border-radius-top-12">
                        <img width="200px" height="67px" src="https://cdn.clipart.email/caade7009a201107098eaf3fae40f011_google-logo-gray-uldissprogis_544-184.png" alt="" />
                      </div>
                      <CardBody>
                        <CardTitle>Integration Card</CardTitle>
                        <CardText>Description of integration and whats it pulls from goes here…</CardText>
                        <Button color="secondary" className="mt-4">
                          <i className="fas fa-share-alt mr-2"></i>
                        Upload Connection</Button>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card className="shadow border-radius-12">
                      <div className="h-50 bg-very-light-blue d-flex justify-content-center align-items-center p-5 border-radius-top-12">
                        <img width="200px" height="67px" src="https://cdn.clipart.email/caade7009a201107098eaf3fae40f011_google-logo-gray-uldissprogis_544-184.png" alt="" />
                      </div>
                      <CardBody>
                        <CardTitle>Integration Card</CardTitle>
                        <CardText>Description of integration and whats it pulls from goes here…</CardText>
                        <Button color="secondary" className="mt-4">
                          <i className="fas fa-share-alt mr-2"></i>
                        Upload Connection</Button>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card className="shadow border-radius-12">
                      <div className="h-50 bg-very-light-blue d-flex justify-content-center align-items-center p-5 border-radius-top-12">
                        <img width="200px" height="67px" src="https://cdn.clipart.email/caade7009a201107098eaf3fae40f011_google-logo-gray-uldissprogis_544-184.png" alt="" />
                      </div>
                      <CardBody>
                        <CardTitle>Integration Card</CardTitle>
                        <CardText>Description of integration and whats it pulls from goes here…</CardText>
                        <Button color="secondary" className="mt-4">
                          <i className="fas fa-share-alt mr-2"></i>
                        Upload Connection</Button>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card className="shadow border-radius-12">
                      <div className="h-50 bg-very-light-blue d-flex justify-content-center align-items-center p-5 border-radius-top-12">
                        <img width="200px" height="67px" src="https://cdn.clipart.email/caade7009a201107098eaf3fae40f011_google-logo-gray-uldissprogis_544-184.png" alt="" />
                      </div>
                      <CardBody>
                        <CardTitle>Integration Card</CardTitle>
                        <CardText>Description of integration and whats it pulls from goes here…</CardText>
                        <Button color="secondary" className="mt-4">
                          <i className="fas fa-share-alt mr-2"></i>
                        Upload Connection</Button>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card className="shadow border-radius-12">
                      <div className="h-50 bg-very-light-blue d-flex justify-content-center align-items-center p-5 border-radius-top-12">
                        <img width="200px" height="67px" src="https://cdn.clipart.email/caade7009a201107098eaf3fae40f011_google-logo-gray-uldissprogis_544-184.png" alt="" />
                      </div>
                      <CardBody>
                        <CardTitle>Integration Card</CardTitle>
                        <CardText>Description of integration and whats it pulls from goes here…</CardText>
                        <Button color="secondary" className="mt-4">
                          <i className="fas fa-share-alt mr-2"></i>
                        Upload Connection</Button>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card className="shadow border-radius-12">
                      <div className="h-50 bg-very-light-blue d-flex justify-content-center align-items-center p-5 border-radius-top-12">
                        <img width="200px" height="67px" src="https://cdn.clipart.email/caade7009a201107098eaf3fae40f011_google-logo-gray-uldissprogis_544-184.png" alt="" />
                      </div>
                      <CardBody>
                        <CardTitle>Integration Card</CardTitle>
                        <CardText>Description of integration and whats it pulls from goes here…</CardText>
                        <Button color="secondary" className="mt-4">
                          <i className="fas fa-share-alt mr-2"></i>
                        Upload Connection</Button>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card className="shadow border-radius-12">
                      <div className="h-50 bg-very-light-blue d-flex justify-content-center align-items-center p-5 border-radius-top-12">
                        <img width="200px" height="67px" src="https://cdn.clipart.email/caade7009a201107098eaf3fae40f011_google-logo-gray-uldissprogis_544-184.png" alt="" />
                      </div>
                      <CardBody>
                        <CardTitle>Integration Card</CardTitle>
                        <CardText>Description of integration and whats it pulls from goes here…</CardText>
                        <Button color="secondary" className="mt-4">
                          <i className="fas fa-share-alt mr-2"></i>
                        Upload Connection</Button>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>

            </div>
            <span className="action-button">
              <button className="btn btn-circle btn btn-lg btn-primary"><i className="fa fa-plus fa-lg"></i>
              </button>
            </span>
          </div>
        </data >
      </>
    )
  }
}


export default Integration;


