import { user } from "../actionContants";
import {
	ILoginRequestAction,
	ILoginResponseAction,
	ILogoutAction,
	IProfileResponseAction,
	IUserPreferenceResponseAction,
	IGetAllCompanyUsersAction,
	ISelectBrandAction
} from "./userAction";
import { ILoginRequest, ILoginUserResponse } from "../../../interfaces/user";

export function loginAction(
	payload: ILoginRequest,
	resolve?: Function,
	reject?: Function
): ILoginRequestAction {
	return {
		payload,
		type: user.LOGIN_REQUEST,
		reject,
		resolve
	};
}

export function getProfileResponseAction(payload: any): IProfileResponseAction {
	return {
		type: user.PROFILE_RESPONSE,
		payload
	};
}

export function loginResponseAction(payload: ILoginUserResponse): ILoginResponseAction {
	return {
		payload,
		type: user.LOGIN_RESPONSE
	};
}

export function logoutAction(): ILogoutAction {
	return {
		type: user.LOGOUT_REQUEST
	};
}

export function userPreferenceResponseAction(payload: any): IUserPreferenceResponseAction {
	return {
		type: user.USER_PREFERENCE_RESPONSE,
		payload
	};
}

export function getAllCompanyUsersAction(payload: any): IGetAllCompanyUsersAction {
	return {
		type: user.GET_ALL_COMPANY_USERS,
		payload
	};
}

export function selectBrandResponseAction(payload: any): ISelectBrandAction {
	return {
		type: user.SELECT_BRAND,
		payload
	};
}
