import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { routes } from "../../routes";
import { Activity, Box, Loader as LoaderIcon } from "react-feather";
import { IApplicationState } from "../../../redux/reducers";
import dashboardService from "../../../services/dashboardService";
import { IDashboardState } from "../../../redux/reducers/dashboardReducer";
import { IProductAnalyticsData } from "../../../interfaces/dashboard";
import AccountNotifications from "../../newDashboard/components/accountNotifications";
import Upgrade from "../../../components/common/upgrade";
import LineChart from "../../../components/charts/lineChart";
import { DataSet } from "../../../components/charts/interfaces";

interface IProps extends RouteComponentProps {
	profile: any;
	dashboard: IDashboardState;
	fetchDashboardOverview: () => Promise<any>;
	getDashboardProductAnalytics: () => Promise<any>;
}

//TODO:
// (1) Refactor Crosshair & associated MarkSeries to prevent repeated conditional of crosshair
//     - XYPlot doesn't response well to rendering both under same <></> or <div></div> parent tag
const ProductOverview: React.FC<IProps> = (props) => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		Promise.all([props.fetchDashboardOverview(), props.getDashboardProductAnalytics()]).finally(
			() => {
				setLoading(false);
			}
		);
	}, []);

	const reduceProductData = (label: string, type: string) => {
		let dataset: DataSet = {
			label: label,
			data: [],
			fill: false,
			backgroundColor: "",
			borderColor: "#fff"
		};
		props.dashboard?.product_analytics?.forEach((d: IProductAnalyticsData) => {
			dataset.data = [...dataset.data, type == "retail" ? d.retail : d.concept];
		});
		return dataset;
	};

	return (
		<div className="product-overview mt-n3">
			<Row className="mb-2">
				<div className={"mt-3 mr-2"}>
					<AccountNotifications mini filterBy={"product"} />
				</div>
				<div className="mr-2 mt-3">
					<Card className="active-product mr-1 mb-0">
						<CardBody className="d-flex">
							<div>
								<CardTitle>
									<h1 className="font-weight-semibold mb-0">
										{props.profile?.company?.subscription_type == "supplier" ? (
											<>-</>
										) : (
											<>{props.dashboard.active_products_count}</>
										)}
									</h1>
									{loading ? (
										<LoaderIcon className="fa-spin ml-2" />
									) : (
										<Activity className="ml-2" />
									)}
								</CardTitle>
								<CardSubtitle>
									Active <br /> Products
								</CardSubtitle>
								{props.location.pathname === routes.DASHBOARD && (
									<CardSubtitle>
										<Link to={routes.PRODUCTS}>View all</Link>
									</CardSubtitle>
								)}
							</div>
							<div className={"product-chart"}>
								{props.profile?.company?.subscription_type == "supplier" ? (
									<Upgrade />
								) : (
									<div className={"ml-2 mt-2 mb-2"} style={{ height: 165, maxHeight: 165 }}>
										<LineChart
											data={{
												labels: (props.dashboard.product_analytics || []).map(
													(d: IProductAnalyticsData, index: number) => {
														return (index + 1).toString();
													}
												),
												datasets: [reduceProductData("Retail Products", "retail")]
											}}
											noGridLines
											color={"#fff"}
											xLabel="Last 7 Days"
										/>
									</div>
								)}
							</div>
						</CardBody>

						{/* <CardBody className="chart">
                        </CardBody> */}
					</Card>
				</div>
				<div className="mt-3">
					<Card className="concept-product mr-1 mb-0">
						<CardBody>
							<div>
								<CardTitle>
									<h1 className="font-weight-semibold mb-0 color-nile-blue">
										{props.profile?.company?.subscription_type == "supplier" ? (
											<>-</>
										) : (
											<>{props.dashboard.concept_products_count}</>
										)}
									</h1>
									{loading ? (
										<LoaderIcon className="fa-spin ml-2" />
									) : (
										<Box className="ml-2 color-nile-blue" />
									)}
								</CardTitle>
								<CardSubtitle>
									Concept <br /> Products
								</CardSubtitle>
								{props.location.pathname === routes.DASHBOARD && (
									<CardSubtitle>
										<Link to={routes.PRODUCTS}>View all</Link>
									</CardSubtitle>
								)}
							</div>
							<div className={"d-flex product-chart"}>
								{props.profile?.company?.subscription_type == "supplier" ? (
									<Upgrade />
								) : (
									<div
										className={"ml-2 mt-2 mb-2"}
										style={{ height: 165, maxHeight: 165, width: "100%" }}
									>
										<LineChart
											data={{
												labels: (props.dashboard.product_analytics || []).map(
													(d: IProductAnalyticsData, index: number) => {
														return (index + 1).toString();
													}
												),
												datasets: [reduceProductData("Concept Products", "concept")]
											}}
											noGridLines
											color={"#fff"}
											xLabel="Last 7 Days"
										/>
									</div>
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Row>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile,
	dashboard: state.dashboard
});

const mapDispatchToProps = {
	fetchDashboardOverview: () => dashboardService.getDashboardOverview(),
	getDashboardProductAnalytics: () => dashboardService.getDashboardProductAnalytics()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductOverview));
