import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import { IProduct } from "../../../../../interfaces/products";
import { IApplicationState } from "../../../../../redux/reducers";
import CompareCard from "./compareCard";

interface IProps {
	handleClose: () => void;
}

const VersionComparison: React.FC<IProps> = ({ ...props }) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);
	const [productOne, setProductOne] = useState<IProduct>();
	const [productTwo, setProductTwo] = useState<IProduct>();

	const handleClose = () => {
		setIsOpen(false);
		props.handleClose();
	};
	return (
		<Modal isOpen={isOpen} toggle={handleClose} centered className={"version-comparison-modal"}>
			<CompareCard
				isCurrent
				productSelectCallback={(p: IProduct) => setProductOne(p)}
				comparisonProduct={productTwo}
			/>
			<CompareCard
				productSelectCallback={(p: IProduct) => setProductTwo(p)}
				comparisonProduct={productOne}
			/>
		</Modal>
	);
};

const mapStateToProps = (state: IApplicationState) => ({});

export default connect(mapStateToProps)(VersionComparison);
