import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../redux/reducers";
import {
	Table,
	Modal,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	InputGroup,
	InputGroupAddon
} from "reactstrap";
import Button from "../../components/common/button";
import { Upload, Download, X, FileText, Loader, Settings, CheckCircle } from "react-feather";
import reportService from "../../services/reportService";
import { REPORTS_ALLOWED_FILE_EXTENSIONS } from "../../utils/constants";
import integrationService from "../../services/integrationService";
import moment from "moment";
import Select, { OptionsType, ValueType } from "react-select";
import { Link } from "react-router-dom";
import { IUploadRequest } from "../../interfaces/integration";
import { formatRequestType } from "../../utils/integration";
import { toCamelCase } from "../../utils/string";

interface IProps {
	getCompanies: () => any;
	addReport: (
		reportType: string,
		reportFile: File,
		reportDescription: string,
		requestedBy: string
	) => Promise<any>;
	csv_requests: any[];
	getAllUploadRequests: () => Promise<any>;
	uploadRequestFile: (request_id: string, request_file: File, data_type: string) => Promise<any>;
}

const UploadsTable: React.FC<IProps> = ({
	csv_requests,
	getAllUploadRequests,
	uploadRequestFile,
	...props
}) => {
	const [modal, setModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [request, setRequest] = useState<IUploadRequest>();
	const [uploadedFile, setUploadedFile] = useState<any>();
	const [uploading, setUploading] = useState<boolean>();
	const [requestDataFormat, setRequestDataFormat] = useState<string>();

	function toggleModal() {
		setModal(!modal);
	}

	function setSelectedRequest(request: any) {
		setRequest(request);
		toggleModal();
	}

	function calculateTargetEnd(request: any) {
		return request.invoice?.invoice_status == "paid"
			? moment(request.invoice?.updated_at).add(2, "days").format("MMM DD YYYY HH:mm a")
			: "Awaiting Payment";
	}

	const uploadFile = () => {
		if (uploadedFile && request && requestDataFormat) {
			setUploading(true);
			uploadRequestFile(request.id, uploadedFile, requestDataFormat).finally(() => {
				getAllUploadRequests().finally(() => {
					setUploading(false);
					setModal(false);
				});
			});
		}
	};

	const onDataTypeSelect = (option: any) => {
		if (option.value) {
			setRequestDataFormat(option.value);
		}
	};

	useEffect(() => {
		setLoading(true);
		Promise.all([getAllUploadRequests()]).finally(() => {
			setLoading(false);
		});
	}, []);

	return (
		<div className="user-reports">
			<div className="page-header">
				<h3>All Requests</h3>
			</div>

			{loading ? (
				<Loader className="fa-spin" />
			) : (
				<>
					<Table responsive hover>
						<thead>
							<tr>
								<th>Request Type</th>
								<th>Request Date</th>
								<th>Target End</th>
								<th>Requested By</th>
								<th>Payment</th>
								<th>Status</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{csv_requests.map((request: IUploadRequest) => {
								return (
									<tr key={request?.id}>
										<td>{formatRequestType(request)}</td>
										<td>{moment(request.request_date).format("MMM DD YYYY HH:mm a")}</td>
										<td>{calculateTargetEnd(request)} </td>
										<td>{request.requested_by.name}</td>
										<td>{request.invoice?.invoice_status == "open" ? "Unpaid" : "Paid"}</td>
										<td>
											<span className={`${request.status.toLowerCase()} mr-2`}>
												<FileText size={17} className="mr-2" />
												{request.status.toLowerCase()}
											</span>
										</td>
										<td className="actions d-flex justify-content-end">
											<Button color="primary" onClick={() => setSelectedRequest(request)}>
												<Settings size={17} className="mr-2" />
												Manage
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</>
			)}

			<Modal isOpen={modal}>
				<ModalHeader>
					<Settings className="color-bg-blue mr-2" size={24} />
					Manage Upload Request For {request?.requested_by.name}
					<X className="float-right" onClick={toggleModal} />
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup className="mb-3">
							<Label>Request Type</Label>
							<Input
								placeholder="Report Type"
								value={request ? formatRequestType(request) : ""}
								name="name"
								disabled={true}
								style={{ width: "100%", padding: "8px", borderRadius: 6 }}
							/>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label>Status</Label>
							<Select
								placeholder={"Unassigned"}
								defaultValue={{ label: request?.status.toLowerCase(), value: request?.status }}
								isClearable={true}
								options={[
									{ label: "In Progress", value: "In Progress" },
									{ label: "Completed", value: "Completed" },
									{ label: "Verified", value: "Verified" }
								]}
								styles={{
									control: () => {
										let border = "#f4a850";
										switch (request?.status) {
											case "requested":
												break;
											case "In Progress":
												border = "#3f65f1";
												break;
											case "Completed":
												border = "#0ed7ac";
												break;
											case "Verified":
												border = "#0ed7ac";
												break;
											default:
												break;
										}

										return {
											borderRadius: 6,
											border: "1px solid",
											borderColor: border,
											display: "flex",
											padding: "1px"
										};
									}
								}}
							/>
						</FormGroup>
						<FormGroup>
							<Label>Select Data Format</Label>
							<Select
								placeholder={"Unassigned"}
								defaultValue={{
									label: request?.data_type ? toCamelCase(request.data_type) : "Unassigned",
									value: request?.data_type || ""
								}}
								isClearable={true}
								options={[
									{ label: "Products", value: "products" },
									{ label: "Ingredients", value: "ingredients" },
									{ label: "Product Recipe", value: "product_ingredients" }
								]}
								onChange={onDataTypeSelect}
							/>
						</FormGroup>
						<FormGroup className="mb-3">
							<Label>Upload File</Label>
							<br />

							<InputGroup>
								<Input
									type="file"
									accept={REPORTS_ALLOWED_FILE_EXTENSIONS.join(", ")}
									style={{
										padding: "8px",
										borderStyle: "dashed",
										borderColor: "#cfdbea",
										borderRadius: 6
									}}
									onChange={(event: any) => setUploadedFile(event.target.files[0])}
								/>
								<InputGroupAddon addonType="append">
									<Upload
										className="color-bg-blue"
										size={16}
										style={{ marginLeft: "-29px", marginTop: "15px" }}
									/>
								</InputGroupAddon>
							</InputGroup>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label>Description</Label>
							<Input
								type="textarea"
								rows={6}
								value={request?.description}
								name="description"
								disabled={true}
								placeholder="Description"
								style={{ width: "100%", padding: "8px", borderRadius: 6 }}
							/>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Link to={`${request?.upload_request_uri}`} target="_blank">
						<Button className="color-white mr-2" color="primary">
							<Download size={18} /> Download File
						</Button>
					</Link>
					<Button
						disabled={uploadedFile == undefined || uploading || !requestDataFormat}
						onClick={() => uploadFile()}
						className="color-white mr-2"
						color="success"
					>
						{uploading ? (
							<>
								<Loader className="fa-spin" size={18} /> Uploading
							</>
						) : (
							<>
								<CheckCircle size={18} /> Upload File
							</>
						)}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => {
	return {
		companies: state.company.companies,
		csv_requests: state.integration.requests
	};
};

const mapDispatchToProps = {
	addReport: (
		reportType: string,
		reportFile: File,
		reportDescription: string,
		requestedBy?: string
	) => reportService.addReport(reportType, reportFile, reportDescription, requestedBy),
	getAllUploadRequests: () => integrationService.getAllUploadRequests(),
	uploadRequestFile: (request_id: string, request_file: File, data_type: string) =>
		integrationService.uploadRequestFile(request_id, request_file, data_type)
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadsTable);
