import { Api } from "./api";
import { Dispatch } from "react";
import { IApplicationState } from "../redux/reducers";
import config from "../utils/config";
import { getAllNotificationsAction } from "../redux/actions/notifications/notifications";

class NotificationService extends Api {
	saveDeviceToken(token: string) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.post(config.api.endpoints.notifications.token, {
					token
				});
			} catch (error) {
				this.handleError(error, "Save Device token");
			}
		};
	}

	getAllNotifications() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.notifications.all);
				dispatch(getAllNotificationsAction(response.data));
			} catch (error) {
				this.handleError(error, "Get all notifications");
			}
		};
	}

	setNotificationAsRead(notificationId: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.put(
					config.api.endpoints.notifications.readNotification(notificationId),
					{ is_read: true }
				);
			} catch (error) {
				this.handleError(error, "Read notification");
			}
		};
	}
}

export default new NotificationService();
