import React, { useEffect, useState } from "react";
import SuitabilityBar from "../../../../components/suitabilityBar";
import packagePlaceholderImg from "../../../../assets/images/product-packaging-placeholder.png";
import { IPackage } from "../../../../interfaces/package";
import { IProductPackage } from "../../../../interfaces/products";
import packagingService from "../../../../services/packagingService";
import { connect } from "react-redux";
import Loader from "../../../../components/loader";

interface IProps {
	pack?: IPackage;
	current?: IProductPackage;
	getPackageById: (id: string) => Promise<any>;
	selectPackage: (p: IPackage) => void;
}

const PackageItem: React.FC<IProps> = ({
	pack,
	current,
	getPackageById,
	selectPackage
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [packaging, setPackage] = useState<IPackage | undefined>(pack);

	useEffect(() => {
		if (current) {
			setLoading(true);
			getPackageById(current.id)
				.then((res) => setPackage(res))
				.finally(() => {
					setLoading(false);
				});
		}
	}, []);
	return (
		<>
			{(packaging && !loading && (
				<div className="persona mini" style={{ flexDirection: "column" }}>
					{current && (
						<div
							className="selected-tag"
							data-tooltip={"Currently selected for use"}
						>
							<i
								style={{ color: "#fff" }}
								className="icon-icon-nav-packaging"
							/>
						</div>
					)}
					<div
						className="d-flex flex-column"
						style={{ width: "100%" }}
						onClick={() => selectPackage(packaging)}
					>
						<img
							src={packagePlaceholderImg}
							alt=""
							style={{ borderRadius: "0" }}
						/>
						<div className="product-info flex-grow-1">
							<h4
								className="mb-0"
								style={{
									fontSize: "16px",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									width: "170px"
								}}
							>
								{packaging?.name}
							</h4>
							<div className="stats mt-3 mb-2">
								<div className="" style={{ flex: 1 }}>
									<p
										className="type"
										style={{ fontSize: "11px", letterSpacing: "0.39px" }}
									>
										Market:{" "}
									</p>
									<p
										className="value"
										style={{
											color: "#657280",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											width: "80px"
										}}
									>
										{packaging?.region_available?.join(", ")}
									</p>
								</div>
								<div className="" style={{ flex: 1 }}>
									<p
										className="type"
										style={{ fontSize: "11px", letterSpacing: "0.39px" }}
									>
										Material:{" "}
									</p>
									<p
										className="value"
										style={{
											color: "#657280",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											width: "80px"
										}}
									>
										{packaging?.material?.join(", ")}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div style={{ width: "100%" }}>
						<SuitabilityBar
							type={"packaging"}
							title={"Packaging score"}
							value={packaging?.packaging_score}
						/>
					</div>
				</div>
			)) || <Loader isLoading={loading} />}
		</>
	);
};

const mapDispatchToProps = {
	getPackageById: (id: string) => packagingService.getPackageById(id)
};

export default connect(null, mapDispatchToProps)(PackageItem);
