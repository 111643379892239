import { IHubspotFormData, IHubspotFormField } from "../interfaces/hubspot";

export const HUBSPOT_POST_URL = `https://api.hsforms.com/submissions/v3/integration/submit`;

const parseFields = (data: any): IHubspotFormField[] => {
	const formFields: IHubspotFormField[] = [];
	for (let key in data) {
		formFields.push({
			name: key,
			value: data[key]
		});
	}
	return formFields;
};

export const normalizeHubspotFormData = (data: any): IHubspotFormData => ({
	submittedAt: Date.now().toString(),
	fields: parseFields(data),
	// context: {
	//     hutk: "journeyfoods", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
	//     pageUri: "www.example.com/page",
	//     pageName: "Example page"
	// },
	legalConsentOptions: {
		consent: {
			// Include this object when GDPR options are enabled
			consentToProcess: true,
			text:
				"I agree to allow Example Company to store and process my personal data.",
			communications: [
				{
					value: true,
					subscriptionTypeId: 999,
					text:
						"I agree to receive marketing communications from Example Company."
				}
			]
		}
	}
});
