import React, { useState } from 'react';
import { Box } from 'react-feather'
import Button from "../../../components/common/button";

const OPTIONS = {
  'other': ['easy opening', 'easy to open'],
  }

const Product: React.FC = () => {
  const [selectedOther, setSelectedOther] = useState<string[]>([]);

  const onSelectOther = (s: string) => {
    const index = selectedOther.findIndex((o) => o===s)
    if (index > -1) {
      const temp = [...selectedOther]
      temp.splice(index, 1)
      setSelectedOther(temp)
    } else {
      setSelectedOther([...selectedOther, s])
    }
  }

  return (
    <>
      <div style={{borderBottom: '1px dashed #cedbe9', paddingBottom: 15, paddingTop: 11}}>
        {/* <Label className="color-battleship-grey">Categories</Label> */}
        <div>
          {
            OPTIONS.other.map((s) => 
              <>
                {
                  selectedOther.findIndex((o) => o === s) > -1 ?
                  <div style={{
                    display: 'inline-block', 
                    padding: 5,
                    borderRadius: 8,
                    marginRight: 6,
                    marginBottom: 6,
                    backgroundColor: '#3f65f1',
                    border: '2px solid #3f65f1',
                    cursor: 'pointer',
                    color: '#fff'
                  }} onClick={() => onSelectOther(s)}>
                    <Box color="#fff" /> {s}
                  </div>
                  : <div style={{
                    display: 'inline-block', 
                    padding: 5,
                    borderRadius: 8,
                    marginRight: 6,
                    marginBottom: 6,
                    border: '2px solid #cedbe9',
                    cursor: 'pointer'
                  }} onClick={() => onSelectOther(s)}>
                    <Box color="#3f65f1" /> {s}
                  </div>
                }
              </>
            )
          }
        </div>
      </div>
      <div style={{paddingTop: 17}}>
        <p>
          These preferences will help tailor our product suggestions and filter your <br />
          live searching whilst using the app.
        </p>
        <Button
          className="btn btn-primary color-white ls-5 font-weight-semibold mt-3"
          type="submit"
        >Save account settings</Button>
      </div>
    </>
  )
};

export default Product;