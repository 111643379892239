import React, { useEffect, useState } from "react";
import { Bell, ChevronDown, ChevronUp, Grid, List } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { Button, Card, Col, Collapse, Row, Table, Tooltip } from "reactstrap";
import Loader from "../../components/loader";
import { PRODUCT_TYPE } from "../../interfaces/products";
import { IApplicationState } from "../../redux/reducers";
import ingredientService from "../../services/ingredientService";
import productService from "../../services/productService";
import { TableSkeleton } from "../../utils/ghostUI";
import { getIngredientImagePlaceholder } from "../../utils/ingredient";
import { toCamelCase } from "../../utils/string";
import { Bookmark } from "react-feather";
import ProductItem from "../products/components/productItem";
import IngredientItem from "../ingredients/components/ingredientItem";
import { IIngredient } from "../../interfaces/ingredient";
import Pagination from "../../components/pagination";
import { IPaginateList } from "../../interfaces/pagination";
import { Dispatch } from "redux";
import { paginateAction } from "../../redux/actions/pagination/pagination";
import { ingredients, search } from "../../redux/actions/actionContants";
import searchService from "../../services/searchService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "../routes";

const FilterItem = ({ title, onExpend, id, children }: any) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="mb-2">
			<Row className={"align-items-center"}>
				<Col>
					{isOpen ? (
						<ChevronUp
							style={{ cursor: "pointer" }}
							onClick={() => {
								setIsOpen(false);
							}}
						/>
					) : (
						<ChevronDown
							style={{ cursor: "pointer" }}
							onClick={() => {
								setIsOpen(true);
							}}
						/>
					)}
					<span
						onClick={() => {
							setIsOpen((prevVal: boolean) => !prevVal);
						}}
						className="pl-1"
						style={{
							fontFamily: "Biotif",
							cursor: "pointer",
							userSelect: "none",
							fontWeight: 700,
							fontSize: "16px"
						}}
					>
						{title}
					</span>
				</Col>
			</Row>
			<Collapse isOpen={isOpen}>{children}</Collapse>
		</div>
	);
};

interface IProps extends RouteComponentProps {
	term: string | undefined;
	viewedProducts: Array<any>;
	paginatedSearchResults: IPaginateList<any[]>;
	ingredients: IPaginateList<IIngredient[]>;
	searchLoading: boolean;
	getAllIngredient: () => Promise<any>;
	getViewedProducts: () => Promise<any>;
	addToPortfolio: (id: string) => Promise<any>;
	searchIngredient: (term: string) => Promise<any>;
	paginateSearchResults: (term: string) => Promise<any>;
	paginate: (page: number) => void;
	getIngredientFilterMenu: (page: number, term: string) => void;
}
const SearchV2: React.FC<IProps> = ({
	paginatedSearchResults: { list, pagination },
	paginate,
	...props
}) => {
	const [display, setDisplay] = useState<string>("list");
	const [isLoading, setIsLoading] = useState<boolean>();
	const [logoTooltip, setLogoTooltip] = useState<boolean>();

	useEffect(() => {
		if (props.term) {
			// props.getIngredientFilterMenu(1, props.term);
		}
	}, [props.term]);
	useEffect(() => {
		if (props.term && pagination.page > 1) {
			props.paginateSearchResults(props.term);
		}
	}, [props.term]);

	useEffect(() => {
		try {
			setIsLoading(true);
			Promise.all([]).finally(() => {
				props.getViewedProducts();
				props.getAllIngredient();
				setIsLoading(false);
			});
		} catch {
			setIsLoading(false);
		}
	}, []);

	return (
		<div style={{ fontFamily: "Biotif" }}>
			<div className="d-flex justify-content-between">
				<div>
					<Button
						style={{ background: "#FFB3B3" }}
						// onClick={() => setProductViewType("updated")}
						className={`btn-round ml-2 color-white `}
					>
						<Bell className="mr-2" size={18} />
						Latest updates
					</Button>
					<Button
						style={{
							background: "#C747F46E"
						}}
						// onClick={() => setProductViewType("journey")}
						className={`btn-round ml-2 color-white`}
					>
						<img
							style={{ width: "18px", height: " 18px", paddingRight: "5px", marginRight: "5px" }}
							src={require("../../assets/images/JourneyAI_white.png")}
						/>
						{/* // JourneyAI_white.png" */}
						Journey AI
					</Button>
				</div>
				<div className="d-flex   mb-4 mr-4 align-items-center">
					<img
						id="search_logo"
						style={{
							width: "18px",
							height: "18px",
							paddingRight: "3px",
							marginRight: "5px"
						}}
						src={require("../../assets/images/JourneyAI_purple.png")}
					/>
					<Tooltip
						className="simple-tooltip"
						isOpen={logoTooltip}
						placement="bottom"
						target="search_logo"
						toggle={() => setLogoTooltip(!logoTooltip)}
					>
						JourneyAI recommends ingredients based on your portfolio.
					</Tooltip>
					<span style={{ fontSize: "18px" }}>Powered by JourneyAI</span>
				</div>
			</div>

			<Col>
				<Row>
					<Col>
						{Boolean(props.term) && (
							<h3 style={{ fontWeight: "normal" }}>
								{pagination.total} results for
								<span style={{ color: "#17C9DC" }}> “{props.term}”</span>
							</h3>
						)}
						{/* <span>
							You have 6 filters turned on. <u>Reset</u>
						</span> */}
					</Col>
					<Col>
						<div className={"display-options"}>
							<Grid
								size={24}
								className={display == "grid" ? "active display-icon" : "display-icon"}
								onClick={() => {
									setDisplay("grid");
								}}
							/>
							<List
								size={24}
								className={display == "list" ? "active display-icon" : "display-icon"}
								onClick={() => {
									setDisplay("list");
								}}
							/>
						</div>
					</Col>
				</Row>
			</Col>
			<Col className="mt-5 pl-0 d-flex">
				<Col md="2">
					<div className="d-flex align-items-center mb-3">
						<h3>Filter by</h3> {/* <u style={{ cursor: "pointer" }} className="ml-3">
							Clear
						</u> */}
					</div>
					<FilterItem title="Cost"></FilterItem>
					<FilterItem title="Supplier (2)"></FilterItem>
					<FilterItem title="Supplier Location"></FilterItem>
					<FilterItem title="MOQ (1)"></FilterItem>
					<FilterItem title="Supply Chain Risk (2)"></FilterItem>
					<FilterItem title="Data Sheet"></FilterItem>
					<FilterItem title="Regulation"></FilterItem>
					<FilterItem title="Market"></FilterItem>
				</Col>
				<Col md="10">
					{display === "grid" && (
						<div className="content products-section__content">
							<Loader isLoading={Boolean(isLoading || props.searchLoading)}>
								<Col xl="12" className="col-lg-12 search-v2-grid">
									{list.map((ingredient: any) => (
										<Card
											onClick={() => props.history.push(`${routes.INGREDIENTS}/${ingredient.id}`)}
											style={{
												// width: "300px",
												borderRadius: "12px",
												borderBottom: "4px solid #17C9DC ",
												overflow: "hidden"
											}}
										>
											<div style={{ position: "relative" }}>
												<ReactImageFallback
													style={{ width: "100%", height: "200px" }}
													src={ingredient.image_uri}
													fallbackImage={getIngredientImagePlaceholder()}
													alt="Product Image"
												/>
												{ingredient.is_star && (
													<img
														style={{
															width: "18px",
															height: "18px",
															paddingRight: "3px",
															marginLeft: "25px",
															position: "absolute",
															top: 10,
															right: 10
														}}
														src={require("../../assets/images/JourneyAI_purple.png")}
													/>
												)}
											</div>
											<div className="pt-1 pl-3 pr-1">
												<div className="d-flex align-items-center justify-content-between">
													<h5
														className="mb-0 text-truncate"
														style={{ fontSize: "16px", color: "#041928" }}
													>
														{ingredient.jf_display_name}
													</h5>
													<div>
														<i
															className={`${
																ingredient.country?.alpha_2?.toLowerCase() || "us"
															} flag list-flag`}
														></i>
													</div>
												</div>
												<h5 className="mb-0" style={{ color: "rgba(4, 25, 40, 0.5)" }}>
													{ingredient.manufacturer?.name}
												</h5>
												<div className="d-flex align-items-center justify-content-between pb-1">
													<span style={{ color: "rgba(4, 25, 40, 0.5)" }}>
														${ingredient.cost ? ingredient.cost : 0}/kg
													</span>
													<Bookmark
														size={18}
														style={{ cursor: "pointer" }}
														onClick={(e) => {
															e.stopPropagation();
															props.addToPortfolio(ingredient.id);
														}}
													/>
												</div>
											</div>
										</Card>
									))}
								</Col>
							</Loader>
						</div>
					)}
					{display === "list" && (
						<Table borderless className="search-v2-table">
							<thead>
								<tr>
									<th></th>
									<th></th>
									<th>Ingredient</th>
									<th>Supplier</th>
									<th>Country</th>
									<th>Price</th>
									<th></th>
								</tr>
							</thead>
							{isLoading || props.searchLoading ? (
								<TableSkeleton />
							) : (
								<tbody>
									{list.map((ingredient, index) => {
										return (
											<tr
												onClick={() => props.history.push(`${routes.INGREDIENTS}/${ingredient.id}`)}
												style={{
													background: "#F9F9FA",
													boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
													borderRadius: "12px",
													cursor: "pointer"
												}}
												className="list-row"
												key={index}
											>
												<td>
													<ReactImageFallback
														style={{ borderRadius: 6, width: "60px", height: "60px" }}
														src={ingredient.image_uri}
														fallbackImage={getIngredientImagePlaceholder()}
														alt="Product Image"
													/>
												</td>
												<td>
													{ingredient.is_star && (
														<img
															style={{
																width: "18px",
																height: "18px",
																paddingRight: "3px",
																marginLeft: "25px"
															}}
															src={require("../../assets/images/JourneyAI_purple.png")}
														/>
													)}
												</td>
												<td>{ingredient.jf_display_name}</td>
												<td>{ingredient.manufacturer?.name}</td>
												<td>
													<i
														className={`${
															ingredient.country?.alpha_2?.toLowerCase() || "us"
														} flag list-flag ml-3`}
														style={{ height: "20px", width: "40px" }}
													></i>
												</td>
												<td>${ingredient.price ? ingredient.price : 0} / unit</td>
												<td>
													<Bookmark
														style={{ cursor: "pointer" }}
														color="#17C9DC"
														onClick={(e) => {
															e.stopPropagation();
															props.addToPortfolio(ingredient.id);
														}}
													/>
												</td>
											</tr>
										);
									})}
								</tbody>
							)}
						</Table>
					)}
					{Boolean(list.length) && (
						<div className="search-v2-pagination">
							<Pagination
								pages={pagination.pages}
								page={pagination.page}
								size={pagination.size}
								total={pagination.total}
								handlePageChange={paginate}
							/>
						</div>
					)}
				</Col>
			</Col>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	searchResults: state.search.result,
	paginatedSearchResults: state.search.paginated_results,
	term: state.search.term,
	viewedProducts: state.product.recentlyViewed,
	ingredients: state.ingredient.ingredients,
	searchLoading: state.search.loading
});

const mapDispatchToProps = {
	getAllIngredient: () => ingredientService.getIngredients(),
	searchIngredient: (term: string) => ingredientService.searchIngredient(term),
	paginateSearchResults: (term: string) => searchService.paginateSearch(term),
	getViewedProducts: () => productService.getRecentlyViewed(),
	addToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	getIngredientFilterMenu: (page: number, name: string) =>
		ingredientService.getIngredientFilterMenu(page, name),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(search.UPDATE_PAGINATION, page));
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchV2));
