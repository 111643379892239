import { SUBSCRIPTION } from "../../interfaces/subscription";
import { loadStripe, PaymentMethod, StripeError } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import paymentService from "../../services/paymentService";
import { IApplicationState } from "../../redux/reducers";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "../../routes/routes";
import { Loader } from "react-feather";
import StripeClimate from "./stripeClimate";
import { availableSubscriptionPlans, trialSubscriptionPlans } from "../../utils/constants";
import CustomSelect from "../common/customSelect";
import { countries } from "../../utils/array";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { phoneRegExp } from "../../validations/constant";
import { format } from "path";

/* CheckForm contains Stripe Elements that are validated and accessed inside of a Stripe <Element> component through the exported CheckoutModal class which 
    passes in a Stripe object promise that handles validation and secure payment processing.
*/

interface CheckoutProps {
	subscriptionPlan: availableSubscriptionPlans;
	processPayment: (
		paymentMethodId: string,
		promotionCode?: string,
		authorizePaymentCallback?: (pi_client_secret: string) => Promise<any>
	) => Promise<any>;
}

const CheckoutForm: React.FC<CheckoutProps> = ({ subscriptionPlan, processPayment }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState<StripeError | null>(null);
	const [cardComplete, setCardComplete] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>();
	const [billingDetails, setBillingDetails] = useState({
		email: "",
		phone: "",
		name: "",
		address: {
			city: "",
			country: "",
			line1: "",
			postal_code: "",
			state: ""
		}
	});
	const [promotionCode, setPromotionCode] = useState<string | undefined>();
	const [paid, setPaid] = useState<boolean>();

	/*
	 * Returns a promise of either an error or resolved payment intent following card authorization with Stripe
	 * - Required when a card's initial payment attempt 'requires_action' (authentication)
	 *
	 * @param {number} pi_client_secret The payment Stripe client secret for a payment intent with 'requires_action' status
	 * @retunrs {Promise<any>} payment intent with a 'succeeded' status or Stripe error
	 */
	const authorizePaymentCallBack = async (pi_client_secret: string) => {
		try {
			const response = await stripe?.confirmCardPayment(pi_client_secret).then((res: any) => {
				if (res.error) {
					setError(res.error);
					setProcessing(false);
					return res;
				} else if (res.paymentIntent.status == "succeeded") {
					setPaid(true);
					return res.paymentIntent;
				}
			});
			return response;
		} catch (error) {
			return error;
		}
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		if (error) {
			if (elements != null) {
				elements.getElement("card")?.focus();
			}
			return;
		}

		if (cardComplete) {
			setProcessing(true);
		}

		const card = elements.getElement(CardElement);
		let payment_method: any;
		if (card != null) {
			// Create a payment method; id is used for confirm card payment
			payment_method = await stripe.createPaymentMethod({
				type: "card",
				card: card,
				billing_details: billingDetails
			});
		}

		if (payment_method?.error && card != null) {
			setError(payment_method?.error);
			setProcessing(false);
		} else {
			if (payment_method != null) {
				try {
					await processPayment(
						payment_method.paymentMethod.id,
						promotionCode,
						authorizePaymentCallBack
					).then((res: any) => {
						switch (res?.status) {
							case 500:
							case 406:
								setError(
									res?.data || "An unknown error occurred. Please contact sales@journeyfoods.com"
								);
								break;
							case 200:
								setPaid(true);
								break;
							default:
								break;
						}
					});
				} catch (error) {
					// setError(error?.message);
					setProcessing(false);
				} finally {
					setProcessing(false);
				}
			}
		}
	};

	const reset = () => {
		setError(null);
		setProcessing(false);
		setPromotionCode(undefined);
		const cardElement = elements?.getElement(CardElement);
		if (cardElement) cardElement.clear();
	};

	return (
		<div>
			<Formik
				initialValues={{
					name: billingDetails.name,
					email: billingDetails.email,
					phone: billingDetails.phone,
					promotionCode: promotionCode,
					addressLine1: billingDetails.address.line1,
					city: billingDetails.address.city,
					state: billingDetails.address.state,
					postalCode: billingDetails.address.postal_code,
					country: billingDetails.address.country
				}}
				validationSchema={yup.object({
					name: yup.string().required("Required"),
					email: yup.string().email("Invalid email address").required("Required"),
					phone: yup
						.string()
						.matches(phoneRegExp, "Phone number is not valid")
						.required("Required"),
					promotionCode: yup.string().notRequired(),
					addressLine1: yup.string().required("Required"),
					city: yup.string().required("Required"),
					state: yup.string().required("Required"),
					postalCode: yup.string().required("Required"),
					country: yup.string().required("Required")
				})}
				onSubmit={() => {
					//Placeholder for form submission; form submission is handled by custom handleSubmit function
					return;
				}}
			>
				{({ setFieldValue, isValid, errors, setTouched }) => {
					return (
						<Form className="checkout-form">
							<Row>
								<Col>
									<FormGroup>
										<Label for={"name"}>
											<span>Name</span>
											<ErrorMessage name={"name"}>
												{(msg) => <span className={"ml-2 text-danger"}>{`*${msg}`}</span>}
											</ErrorMessage>
										</Label>
										<Input
											tag={Field}
											className="checkout-form-input"
											type="text"
											name="name"
											id="name"
											placeholder="Jane Doe"
											autoComplete="name"
											value={billingDetails.name}
											onChange={(e) => {
												setBillingDetails({
													...billingDetails,
													name: e.target.value
												});
												setFieldValue("name", e.target.value);
											}}
										/>
									</FormGroup>
									<FormGroup>
										<Label for={"email"}>
											<span>Email</span>
											<ErrorMessage name={"email"}>
												{(msg) => <span className={"ml-2 text-danger"}>{`*${msg}`}</span>}
											</ErrorMessage>
										</Label>
										<Input
											tag={Field}
											className="checkout-form-input"
											type="email"
											name="email"
											id="email"
											placeholder="example@gmail.com"
											autoComplete="email"
											value={billingDetails.email}
											onChange={(e) => {
												setBillingDetails({
													...billingDetails,
													email: e.target.value
												});
												setFieldValue("email", e.target.value);
											}}
										/>
									</FormGroup>
									<FormGroup>
										<Label for={"phone"}>
											<span>Phone</span>
											<ErrorMessage name={"phone"}>
												{(msg) => <span className={"ml-2 text-danger"}>{`*${msg}`}</span>}
											</ErrorMessage>
										</Label>
										<Input
											tag={Field}
											className="checkout-form-input"
											type="tel"
											name="phone"
											id="phone"
											placeholder="(941) 555-0123"
											autoComplete="tel"
											value={billingDetails.phone}
											onChange={(e) => {
												setBillingDetails({
													...billingDetails,
													phone: e.target.value
												});
												setFieldValue("phone", e.target.value);
											}}
										/>
									</FormGroup>
									<FormGroup>
										<Label>
											<span>Credit card information</span>
										</Label>
										<div className="checkout-card-input">
											<CardElement
												onChange={(e) => {
													e.error ? setError(e.error) : setError(null);
													setCardComplete(e.complete);
													setTouched({});
												}}
											/>
										</div>
									</FormGroup>
									<FormGroup>
										<Label for={"promotion_code"}>
											<span>Promotion code</span>
											<ErrorMessage name={"promotionCode"}>
												{(msg) => <span className={"ml-2 text-danger"}>{`*${msg}`}</span>}
											</ErrorMessage>
										</Label>
										<Input
											tag={Field}
											className="checkout-form-input"
											type="text"
											name="promotion_code"
											id="promotion_code"
											placeholder="Optional promo code"
											autoComplete="tel"
											value={promotionCode}
											onChange={(e) => {
												setPromotionCode(e.target.value);
												setFieldValue("promotionCode", e.target.value);
											}}
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for={"addressLine1"}>
											<span>Address line 1</span>
											<ErrorMessage name={"addressLine1"}>
												{(msg) => <span className={"ml-2 text-danger"}>{`*${msg}`}</span>}
											</ErrorMessage>
										</Label>
										<Input
											tag={Field}
											className="checkout-form-input"
											type="text"
											name="addressLine1"
											id="addressLine1"
											placeholder="Street address"
											value={billingDetails.address.line1}
											onChange={(e) => {
												setBillingDetails({
													...billingDetails,
													address: { ...billingDetails.address, line1: e.target.value }
												});
												setFieldValue("addressLine1", e.target.value);
											}}
										/>
									</FormGroup>
									<FormGroup>
										<Label for={"city"}>
											<span>City</span>
											<ErrorMessage name={"city"}>
												{(msg) => <span className={"ml-2 text-danger"}>{`*${msg}`}</span>}
											</ErrorMessage>
										</Label>
										<Input
											tag={Field}
											className="checkout-form-input"
											type="text"
											name="city"
											id="city"
											placeholder="Street address"
											value={billingDetails.address.city}
											onChange={(e) => {
												setBillingDetails({
													...billingDetails,
													address: { ...billingDetails.address, city: e.target.value }
												});
												setFieldValue("city", e.target.value);
											}}
										/>
									</FormGroup>
									<FormGroup>
										<Label for={"state"}>
											<span>State</span>
											<ErrorMessage name={"state"}>
												{(msg) => <span className={"ml-2 text-danger"}>{`*${msg}`}</span>}
											</ErrorMessage>
										</Label>
										<Input
											tag={Field}
											className="checkout-form-input"
											type="text"
											name="state"
											id="state"
											placeholder="State"
											value={billingDetails.address.state}
											onChange={(e) => {
												setBillingDetails({
													...billingDetails,
													address: { ...billingDetails.address, state: e.target.value }
												});
												setFieldValue("state", e.target.value);
											}}
										/>
									</FormGroup>
									<FormGroup>
										<Label for={"postalCode"}>
											<span>Postal code</span>
											<ErrorMessage name={"postalCode"}>
												{(msg) => <span className={"ml-2 text-danger"}>{`*${msg}`}</span>}
											</ErrorMessage>
										</Label>
										<Input
											tag={Field}
											className="checkout-form-input"
											type="text"
											name="postalCode"
											id="postalCode"
											placeholder="Postal code"
											value={billingDetails.address.postal_code}
											onChange={(e) => {
												setBillingDetails({
													...billingDetails,
													address: { ...billingDetails.address, postal_code: e.target.value }
												});
												setFieldValue("postalCode", e.target.value);
											}}
										/>
									</FormGroup>
									<FormGroup>
										<Label for={"country"}>
											<span>Country</span>
											<ErrorMessage name={"country"}>
												{(msg) => <span className={"ml-2 text-danger"}>{`*${msg}`}</span>}
											</ErrorMessage>
										</Label>
										<CustomSelect
											tag={Field}
											cssStyles={{ border: "2px solid #cedbe9", borderRadius: "6px" }}
											placeholder={"Select Country"}
											options={countries}
											name={"country"}
											id={"country"}
											onChange={(e) => {
												setBillingDetails({
													...billingDetails,
													address: { ...billingDetails.address, country: e?.value || "" }
												});
												setFieldValue("country", e?.value);
											}}
										/>
									</FormGroup>
								</Col>
							</Row>
							{error ? (
								<Row noGutters>
									<div>
										<span style={{ color: "#e85a73", marginRight: 5 }}>{error.message}</span>
									</div>
								</Row>
							) : (
								<></>
							)}
							<Row noGutters>
								<Col className={"align-items-center mb-3"}>
									<Row noGutters className={"justify-content-center"}>
										<Button
											disabled={processing || !cardComplete || paid || !isValid}
											className="checkout-form-submit"
											onClick={handleSubmit}
										>
											{processing ? (
												<Loader className="fa-spin mr-2" size={18} color="#fff" />
											) : (
												<></>
											)}
											{processing ? "Processing" : "Subscribe"}
										</Button>
									</Row>
								</Col>
							</Row>
						</Form>
					);
				}}
			</Formik>
			<StripeClimate />
		</div>
	);
};

interface IProps extends RouteComponentProps {
	subscriptionPlan: availableSubscriptionPlans;
	active?: SUBSCRIPTION;
	company_id: string | undefined;
	makeSubscriptionPayment: (
		tier: string,
		paymentMethodId: string,
		promotionCode?: string
	) => Promise<any>;
	confirmSubscribe: (id: string, tier: string) => Promise<any>;
}

const SubscriptionCheckoutModal: React.FC<IProps> = ({
	subscriptionPlan,
	active,
	company_id,
	confirmSubscribe,
	...props
}) => {
	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

	const processPayment = async (
		paymentMethodId: string,
		promotionCode?: string,
		authorizePaymentCallback?: (pi_client_secret: string) => Promise<any>
	) => {
		return await props
			.makeSubscriptionPayment(subscriptionPlan, paymentMethodId, promotionCode)
			.then(async (res: any) => {
				if (res?.status == 200) {
					props.history.push(routes.LOGIN);
				} else if (res?.response?.status == 406) {
					return res?.response;
				} else if (res?.status == "requires_action") {
					if (authorizePaymentCallback) {
						await authorizePaymentCallback(res.client_secret).then((res: any) => {
							if (res.status == "succeeded") {
								confirmSubscribe(res.id, subscriptionPlan).then((res: any) => {
									if (res.status == 200) {
										props.history.push(routes.LOGIN);
									} else {
										return res;
									}
								});
							}
						});
					}
				} else return res?.response;
			});
	};

	return (
		<div className={"checkout-container"}>
			<Elements stripe={stripePromise}>
				<CheckoutForm processPayment={processPayment} subscriptionPlan={subscriptionPlan} />
			</Elements>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	company_id: state.user.profile?.company.id
});

const mapDispatchToProps = {
	makeSubscriptionPayment: (tier: string, paymentMethodId: string, promotionCode?: string) =>
		paymentService.makeSubscriptionPayment(tier, paymentMethodId, promotionCode),
	confirmSubscribe: (id: string, tier: string) => paymentService.confirmSubscribe(id, tier)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubscriptionCheckoutModal));
