import airtableLogo from "./airtable_logo.png";
import airtableThumbnail from "./airtable_thumbnail.png";
import googleCloudLogo from "./google_cloud_logo.png";
import googleCloudThumbnail from "./google_cloud_thumbnail.png";
import googleSheetsLogo from "./google_sheets_logo.png";
import googleSheetsThumbnail from "./google_sheets_thumbnail.png";
import imbLogoThumbnail from "./ibm_logo_thumbnail.png";
import microsoftDynamicsLogo from "./microsoft_dynamics_thumbnail.png";
import microsoftDynamicThumbnail from "./microsoft_dynamics_thumbnail.png";
import odooLogoThumbnail from "./odoo_logo_thumbnail.png";
import oracleLogo from "./oracle_logo.png";
import oracleThumbnial from "./oracle_thumbnail.png";
import sapIoLogoThumbnail from "./sap_io_logo_thumbnail.png";
import shopifyLogo from "./shopify_logo.png";
import shopifyThumbnail from "./shopify_thumbnail.png";
import csvLogo from "./csv.png";
import sapLogo from "../../../assets/images/markets/sap.png";
import excelLogo from "./excel.png";

export default {
	airtable: {
		logo: airtableLogo,
		thumbnail: airtableThumbnail
	},
	google_cloud: {
		logo: googleCloudLogo,
		thumbnail: googleCloudThumbnail
	},
	google_sheets: {
		logo: googleSheetsLogo,
		thumbnail: googleSheetsThumbnail
	},
	ibm: {
		logo: imbLogoThumbnail,
		thumbnail: imbLogoThumbnail
	},
	microsoft_dynamics: {
		logo: microsoftDynamicsLogo,
		thumbnail: microsoftDynamicThumbnail
	},
	odoo: {
		logo: odooLogoThumbnail,
		thumbnail: odooLogoThumbnail
	},
	oracle: {
		logo: oracleLogo,
		thumbnail: oracleThumbnial
	},
	sap_io: {
		logo: sapIoLogoThumbnail,
		thumbnail: sapIoLogoThumbnail
	},
	sap: {
		logo: sapLogo,
		thumbnail: sapLogo
	},
	shopify: {
		logo: shopifyLogo,
		thumbnail: shopifyThumbnail
	},
	csv: {
		logo: csvLogo,
		thumbnail: csvLogo
	},
	excel: {
		logo: excelLogo,
		thumbnail: excelLogo
	}
};
