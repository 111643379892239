import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Row, Col, Card } from "reactstrap";
import { Input } from "reactstrap";
import { Truck, Users, Box, Share2 } from "react-feather";
import dashboardService from "../../../services/dashboardService";
import analyticsService from "../../../services/analyticsService";
import { IDashboardState } from "../../../redux/reducers/dashboardReducer";
import { IApplicationState } from "../../../redux/reducers";
import { DefaultNumberFormat } from "../../../utils/numbers";
import { Loader } from "react-feather";
import { ITimeSavedData, IProductChangesData } from "../../../interfaces/analytics";
import {
	filterTimeSavedAction,
	filterProductChangesAction
} from "../../../redux/actions/analysis/analysis";
import cxi from "../../../assets/images/markets/cxi.png";
import CommodityGraphs from "../components/commodityGraphs";
import {
	calculateLabels,
	sampleCostData,
	samplePreferenceData,
	sampleProductChangesData,
	sampleSustainabilityData,
	sampleTimeSavedData
} from "../../../components/charts/sampleData";
import LineChart from "../../../components/charts/lineChart";
import CustomSelect from "../../../components/common/customSelect";
import { optionCSS } from "react-select/src/components/Option";
import { preference } from "../../../redux/actions/actionContants";

const chartFilterOptions = [
	{ label: "Last Year", value: 12 },
	{ label: "Last 6 Months", value: 6 },
	{ label: "Last 3 Months", value: 3 },
	{ label: "Last Month", value: 1 }
];

interface IProps {
	dashboard: IDashboardState;
	time_saved: ITimeSavedData[];
	time_saved_filter: number;
	product_changes: IProductChangesData[];
	product_changes_filter: number;
	fetchDashboardOverview: () => Promise<any>;
	getTimeSaved: () => Promise<any>;
	filterTimeSaved: (months: number) => void;
	getProductChanges: () => Promise<any>;
	filterProductChanges: (months: number) => void;
}

const Analytics: React.FC<IProps> = ({
	dashboard,
	time_saved,
	time_saved_filter,
	product_changes,
	product_changes_filter,
	filterTimeSaved,
	filterProductChanges,
	fetchDashboardOverview,
	getTimeSaved,
	getProductChanges
}) => {
	const colOneRef = useRef<HTMLDivElement>(null);
	const colTwoRef = useRef<HTMLDivElement>(null);
	const colThreeRef = useRef<HTMLDivElement>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [timeSavedFilter, setTimeSavedFilter] = useState<any>({ label: "Last 6 Months", value: 6 });
	const [productChangesFilter, setProductChangesFilter] = useState<any>({
		label: "Last 6 Months",
		value: 6
	});
	const [sustainabilityFilter, setSustainabilityFilter] = useState<any>({
		label: "Last 6 Months",
		value: 6
	});
	const [preferenceFilter, setPreferenceFilter] = useState<any>({
		label: "Last 6 Months",
		value: 6
	});
	const [costSavingsFilter, setCostSavingsFilter] = useState<any>({
		label: "Last 6 Months",
		value: 6
	});

	// Setting for demo purposes
	time_saved_filter = 3;
	product_changes_filter = 3;

	const onFilterChange = (option: any, filter: string) => {
		switch (filter) {
			case "time_saved":
				setTimeSavedFilter(option);
				console.log(calculateLabels(option.value));
				return;
			case "product_changes":
				setProductChangesFilter(option);
				return;
			case "sustainability":
				setSustainabilityFilter(option);
				return;
			case "preference":
				setPreferenceFilter(option);
				return;
			case "cost_savings":
				setCostSavingsFilter(option);
				return;
			default:
				return;
		}
	};

	useEffect(() => {
		setLoading(true);
		Promise.all([fetchDashboardOverview(), getTimeSaved(), getProductChanges()]).finally(() => {
			// Promise.all([fetchDashboardOverview(), getProductChanges()]).finally(() => {
			setLoading(false);
		});
	}, []);

	async function onTimeSavedFilterChanged(months: number) {
		await filterTimeSaved(months);
		getTimeSaved();
	}

	async function onProductChangesChanged(months: number) {
		await filterProductChanges(months);
		getProductChanges();
	}

	return (
		<div className="analytics">
			{/* Header Cards */}
			<Row className="stats mb-5">
				<div className="d-flex align-items-center ingredient">
					<div>
						<span className="d-flex align-items-center">
							{loading ? (
								<Loader className="fa-spin" />
							) : (
								<h1 className="mr-2 mb-0 font-weight-semibold">
									{DefaultNumberFormat(dashboard?.total_ingredients)}
								</h1>
							)}

							<i className="icon-nav-ingredients" />
						</span>

						<h5 className="font-weight-normal">Total Ingredients</h5>
					</div>
				</div>
				<div className="d-flex align-items-center product">
					<div>
						<span>
							{loading ? (
								<Loader className="fa-spin" />
							) : (
								<h1 className="mr-2 mb-0 font-weight-semibold">
									{DefaultNumberFormat(dashboard?.total_products)}
								</h1>
							)}
							<Box />
						</span>
						<h5 className="font-weight-normal">Total Products</h5>
					</div>
				</div>
				<div className="d-flex align-items-center user">
					<div>
						<span>
							{loading ? (
								<Loader className="fa-spin" />
							) : (
								<h1 className="mr-2 mb-0 font-weight-semibold">
									{DefaultNumberFormat(dashboard?.total_users)}
								</h1>
							)}
							<Users />
						</span>
						<h5 className="font-weight-normal">Total Users</h5>
					</div>
				</div>
				<div className="d-flex align-items-center manufacturer">
					<div>
						<span>
							{loading ? (
								<Loader className="fa-spin" />
							) : (
								<h1 className="mr-2 mb-0 font-weight-semibold">
									{DefaultNumberFormat(dashboard?.manufacturers)}
								</h1>
							)}
							<Truck />
						</span>
						<h5 className="font-weight-normal">Manufacturers</h5>
					</div>
				</div>
			</Row>

			{/* Time Saved and Product Changes Graphs */}
			<Row className="chart-card">
				<Col xs={12} md={12} lg={6} className="px-0 pr-4">
					<div ref={colOneRef}>
						{/* <div className='coming-soon'>
                            <p>Coming Soon</p>
                        </div> */}
						<div className="chart-header">
							<div>
								<p className="font-18 font-weight-semibold mb-0">Time Saved</p>
								<small className="font-14 color-battleship-grey">
									for product development over time
								</small>
							</div>
							<CustomSelect
								placeholder={"Filter date range..."}
								currentValue={timeSavedFilter}
								onChange={(option: any) => {
									onFilterChange(option, "time_saved");
								}}
								options={chartFilterOptions}
								width={175}
								cssStyles={{ border: "2px solid #cedbe9", borderRadius: 6 }}
								isClearable={false}
							/>
						</div>
						<div className={"mt-2 mb-2"} style={{ height: 200 }}>
							<LineChart data={sampleTimeSavedData(timeSavedFilter.value)} />
						</div>
					</div>
				</Col>
				<Col xs={12} md={12} lg={6} className="px-0 pr-4">
					<div ref={colTwoRef}>
						{/* <div className='coming-soon'>
                            <p>Coming Soon</p>
                        </div> */}
						<div className="chart-header">
							<div>
								<p className="font-18 font-weight-semibold mb-0">Product Changes</p>
								<small className="font-14 color-battleship-grey">Compared month-on-month</small>
							</div>
							<CustomSelect
								placeholder={"Filter date range..."}
								currentValue={productChangesFilter}
								onChange={(option: any) => {
									onFilterChange(option, "product_changes");
								}}
								options={chartFilterOptions}
								width={175}
								cssStyles={{ border: "2px solid #cedbe9", borderRadius: 6 }}
								isClearable={false}
							/>
						</div>
						<div className={"mt-2 mb-2"} style={{ height: 200 }}>
							<LineChart data={sampleProductChangesData(productChangesFilter.value)} />
						</div>
					</div>
				</Col>
			</Row>

			{/* Sustainability and Preference Graphs */}
			<Row className="chart-card">
				<Col xs={12} md={12} lg={6} className="px-0 pr-4">
					<div ref={colOneRef}>
						{/* <div className='coming-soon'>
                            <p>Coming Soon</p>
                        </div> */}
						<div className="chart-header">
							<div>
								<p className="font-18 font-weight-semibold mb-0">Sustainability</p>
								<small className="font-14 color-battleship-grey">
									for product development over time
								</small>
							</div>
							<CustomSelect
								placeholder={"Filter date range..."}
								currentValue={sustainabilityFilter}
								onChange={(option: any) => {
									onFilterChange(option, "sustainability");
								}}
								options={chartFilterOptions}
								width={175}
								cssStyles={{ border: "2px solid #cedbe9", borderRadius: 6 }}
								isClearable={false}
							/>
						</div>
						<div className={"mt-2 mb-2"} style={{ height: 200 }}>
							<LineChart data={sampleSustainabilityData(sustainabilityFilter.value)} />
						</div>
					</div>
				</Col>
				<Col xs={12} md={12} lg={6} className="px-0 pr-4">
					<div ref={colTwoRef}>
						{/* <div className='coming-soon'>
                            <p>Coming Soon</p>
                        </div> */}
						<div className="chart-header">
							<div>
								<p
									className="font-18 font-weight-semibold mb-0"
									style={{ display: "flex", alignItems: "center" }}
								>
									Preference
								</p>
								<small className="font-14 color-battleship-grey">Compared month-on-month</small>
							</div>
							<CustomSelect
								placeholder={"Filter date range..."}
								currentValue={preferenceFilter}
								onChange={(option: any) => {
									onFilterChange(option, "preference");
								}}
								options={chartFilterOptions}
								width={175}
								cssStyles={{ border: "2px solid #cedbe9", borderRadius: 6 }}
								isClearable={false}
							/>
						</div>
						<div className={"mt-2 mb-2"} style={{ height: 200 }}>
							<LineChart data={samplePreferenceData(preferenceFilter.value)} />
						</div>
					</div>
				</Col>
			</Row>

			{/* Cost Savings Graph */}
			<Row className="chart-card mt-4 mb-3">
				<Col xs={12} md={12} lg={12} className="px-0 pr-4">
					<div ref={colThreeRef}>
						{/* <div className='coming-soon'>
                            <p>Coming Soon</p>
                        </div> */}
						<div className="chart-header">
							<div>
								<p className="font-18 font-weight-semibold mb-0">Cost savings</p>
								<small className="font-14 color-battleship-grey">
									for product development over time
								</small>
							</div>
							<CustomSelect
								placeholder={"Filter date range..."}
								currentValue={costSavingsFilter}
								onChange={(option: any) => {
									onFilterChange(option, "cost_savings");
								}}
								options={chartFilterOptions}
								width={175}
								cssStyles={{ border: "2px solid #cedbe9", borderRadius: 6 }}
								isClearable={false}
							/>
						</div>
						<div className={"mb-2 mt-2"} style={{ height: 300 }}>
							<LineChart data={sampleCostData(costSavingsFilter.value)} />
						</div>
					</div>
				</Col>
			</Row>

			{/* <Row className="chart-card mt-4">
				<CommodityGraphs />
			</Row> */}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	dashboard: state.dashboard,
	time_saved: state.analytics.time_saved,
	time_saved_filter: state.analytics.time_saved_filter,
	product_changes: state.analytics.product_changes,
	product_changes_filter: state.analytics.product_changes_filter
});

const mapDispatchToProps = {
	fetchDashboardOverview: () => dashboardService.getDashboardOverview(),
	getTimeSaved: () => analyticsService.getTimeSaved(),
	filterTimeSaved: (months: number) => (dispatch: Dispatch) => {
		dispatch(filterTimeSavedAction(months));
	},
	getProductChanges: () => analyticsService.getProductChanges(),
	filterProductChanges: (months: number) => (dispatch: Dispatch) => {
		dispatch(filterProductChangesAction(months));
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
