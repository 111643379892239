import { IReport } from "../../interfaces/report";
import { IPagination } from "../../interfaces/pagination";
import { ActionType } from "../actions/action";
import { report } from "../actions/actionContants";
import { ITEMS_PER_PAGE } from "../../utils/constants";

export interface IReportState {
	reports: {
		list: IReport[];
		pagination: IPagination;
	};
	requests: IReport[];
}

const INIT_STATE: IReportState = {
	reports: {
		list: [],
		pagination: {
			page: 1,
			pages: 0,
			size: ITEMS_PER_PAGE,
			total: 0
		}
	},
	requests: []
};

export default function (state: IReportState = INIT_STATE, action: ActionType) {
	switch (action.type) {
		case report.GET_ALL_REPORTS_RESPONSE:
			return {
				...state,
				reports: {
					list: action.payload.list,
					pagination: action.payload.pagination
				}
			};
		case report.PAGINATE_REPORTS:
			return {
				...state,
				reports: {
					...state.reports,
					pagination: {
						...state.reports.pagination,
						page: action.payload
					}
				}
			};
		case report.GET_ALL_REPORT_REQUESTS_RESPONSE:
			return {
				...state,
				requests: action.payload
			};
	}

	return state;
}
