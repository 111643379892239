import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { idText } from "typescript";
import { IIngredient } from "../../../interfaces/ingredient";
import ingredientService from "../../../services/ingredientService";
import { IngredientNutrientsArray } from "../../../utils/ingredient";

interface IProps {
	ingredient_id: string;
	getIngredientById: (id: string) => Promise<any>;
}
const RegulationRow: React.FC<IProps> = ({ ingredient_id, getIngredientById, ...props }) => {
	const [ingredient, setIngredient] = useState<IIngredient>();

	useEffect(() => {
		getIngredientById(ingredient_id).then((res: IIngredient) => {
			setIngredient(res);
		});
	}, []);

	const parseClaim = () => {
		const regulationInfo = ingredient?.country_data;
		if (regulationInfo) {
			return regulationInfo[0];
		}
		return;
	};

	return (
		<>
			{ingredient ? (
				<tr key={ingredient_id}>
					<td>{ingredient.name}</td>
					<td>{parseClaim()?.claim}</td>
					<td>{parseClaim()?.country?.alpha_2}</td>
					<td>{parseClaim()?.regulations?.length}</td>
				</tr>
			) : (
				<tr></tr>
			)}
		</>
	);
};

const mapDispatchToProps = {
	getIngredientById: (id: string) => ingredientService.getIngredientById(id)
};

export default connect(null, mapDispatchToProps)(RegulationRow);
