import React, { useEffect, useState } from "react";
import { DropdownMenu, DropdownItem } from "reactstrap";
import { INotification } from "../../../interfaces/notification";
import { X, Box, AlertTriangle, AlertCircle, Package, Globe } from "react-feather";
import moment from "moment";
import { Link } from "react-router-dom";
import ingredientIcon from "../../../assets/images/icon-nav-ingredients.svg";
import { IApplicationState } from "../../../redux/reducers";
import { IProduct } from "../../../interfaces/products";
import { IPaginateList } from "../../../interfaces/pagination";
import { connect } from "react-redux";
import productService from "../../../services/productService";
import { IBrand } from "../../../interfaces/company";
import userService from "../../../services/userService";
import { stat } from "fs";

interface IProps {
	selected_brand: IBrand;
	getBrands: () => Promise<any[]>;
	handleSelect: (brand: IBrand) => void;
}

const BrandDropdown: React.FC<IProps> = (props) => {
	const [loading, setLoading] = useState<boolean>();
	const [brands, setBrands] = useState<IBrand[]>([]);

	useEffect(() => {
		setLoading(true);
		props
			.getBrands()
			.then((res: any) => {
				setBrands(res);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<div className="header-notification-container">
			<DropdownMenu className={"brand"}>
				{
					<div className="dropdown__notifications">
						<>
							<DropdownItem key={"All Brands"} onClick={() => props.handleSelect({ id: "" })}>
								<div className="brand dropdown__notification color-battleship-grey">
									<div className="notification__detail">
										<span className="notification__title">
											<Globe className={"color-bg-slate brand-icon"} height={24} /> All Brands
										</span>
									</div>
								</div>
								{/* <div className="dropdown-divider" /> */}
							</DropdownItem>
							{brands?.map((brand) => (
								<DropdownItem key={brand.id} onClick={() => props.handleSelect(brand)}>
									<div className="brand dropdown__notification color-battleship-grey">
										<div className="notification__detail">
											<span className="notification__title">
												<Package
													className={"brand-icon"}
													height={24}
													color={"#fff"}
													style={{ backgroundColor: "#3f65f1", padding: 5, borderRadius: "50%" }}
												/>
												{brand.name}
											</span>
										</div>
									</div>
									{/* <div className="dropdown-divider" /> */}
								</DropdownItem>
							))}
						</>
					</div>
				}
			</DropdownMenu>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	selected_brand: state.user.selected_brand
});

const mapDispatchToProps = {
	getBrands: () => userService.getBrands()
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandDropdown);
