import { REPORT_STATUSES, IReport } from "../../../../interfaces/report";
import sampleJourneyBites from "./reports/sampleJourneyBites.pdf";

export const SAMPLE_REPORTS = [
	{
		id: "custom-1",
		report_type: "Product",
		description: "Product report for Mango Cayenne Spice Organic Journey Bites",
		status: REPORT_STATUSES.UPLOADED,
		request_date: new Date().toDateString(),
		report_uri: sampleJourneyBites
	}
];
