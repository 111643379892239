import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Progress } from "reactstrap";
import { INotification } from "../../../interfaces/notification";
import { getAllNotificationsAction } from "../../../redux/actions/notifications/notifications";
import { IApplicationState } from "../../../redux/reducers";
import notificationService from "../../../services/notificationService";
import { Bell, Loader as LoaderIcon } from "react-feather";
import { routes } from "../../routes";
import { IUser } from "../../../interfaces/user";
import Upgrade from "../../../components/common/upgrade";

interface IProps {
	filterBy?: string;
	notifications: INotification[];
	getAllNotifications: () => Promise<any>;
	profile?: IUser | undefined;
	mini?: boolean;
}

const AccountNotifications: React.FC<IProps> = (props) => {
	const [loadingNotifications, setLoadingNotifications] = useState<boolean>(false);

	useEffect(() => {
		setLoadingNotifications(true);
		props.getAllNotifications().finally(() => setLoadingNotifications(false));
	}, []);

	return (
		<Card className={`notifications ${props.mini ? "mini" : ""}`}>
			<CardBody>
				<h4>
					<span>
						{props.filterBy
							? props.filterBy.slice(0, 1).toUpperCase() + props.filterBy.slice(1) + " Actions"
							: "Account Actions"}
					</span>
					<Bell className={"ml-2"} size={24} />
				</h4>
				<div>
					<h1>
						{loadingNotifications ? (
							<LoaderIcon className="fa-spin ml-2" size={18} />
						) : (
							props.notifications.filter((n) => {
								// Filter by notification type if not on account page
								return (
									!n.is_read &&
									n.message !== "New Ingredient Added" &&
									(props.filterBy
										? n?.message_type?.toLowerCase() == props.filterBy?.toLowerCase()
										: true)
								);
							}).length
						)}
					</h1>
					<h5>
						Notifications
						<br />
						Pending
					</h5>
					<Link to={routes.NOTIFICATIONS}>{"View all >>"}</Link>
				</div>
				<div>
					<h1>{loadingNotifications ? <LoaderIcon className="fa-spin ml-2" size={18} /> : 0}</h1>
					<h5>
						Actions
						<br />
						Pending
					</h5>
					<Link to={routes.NOTIFICATIONS}>{"View all >>"}</Link>
				</div>
				{props.mini ? (
					<></>
				) : (
					<div className="account-completion">
						<p>Account Completion</p>
						<Progress
							value={75}
							className="rounded-pill mt-1"
							style={{
								boxShadow: "none",
								backgroundColor: "#E1ECF3"
							}}
						/>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	notifications: state.notification.all,
	profile: state.user.profile
});

const mapDispatchToProps = {
	getAllNotifications: () => notificationService.getAllNotifications()
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountNotifications);
