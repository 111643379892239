import React, { useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../redux/reducers";
import { IProduct } from "../../interfaces/products";
import moment from "moment";
import VersionRow from "./versionRow";
import { Row, Col, CustomInput, Table } from "reactstrap";
import { Minus, ShoppingCart, Percent, ChevronUp, ChevronDown } from "react-feather";
import { uniqBy } from "../../utils/array";

interface IProps {
	versions: IProduct[];
}

const VersionHistory: React.FC<IProps> = ({ versions }) => {
	const [showHistory, setShowHistory] = useState<boolean>(false);

	return (
		<section className="version-history">
			<Row>
				<Col md="12" className="p-0">
					<Table className="w-100 current-version" borderless>
						{versions
							.filter((product: IProduct) => product.active)
							.map((activeVersion: IProduct) => {
								const optimizations = activeVersion.optimized_feature;

								return (
									<>
										{/* TODO: Encapsulate into a functionaly component as opposed to performing all optimization display calculations here  */}
										<tr>
											<th colSpan={2}>
												<span className="font-weight-normal">Current version</span>
											</th>
											<th>
												<span className={"header-indicator"}>
													{" "}
													{optimizations?.nutrition && Math.sign(optimizations?.nutrition) != 0 ? (
														<>
															{Math.sign(optimizations?.nutrition) > 0 ? (
																<span style={{ fontSize: 16, color: "#51c5ac" }}>
																	+{Math.ceil(optimizations?.nutrition * 100) / 100}
																</span>
															) : (
																<span style={{ fontSize: 16, color: "#e85a73" }}>
																	{Math.ceil(optimizations?.nutrition * 100) / 100}
																</span>
															)}
															<Percent
																size={16}
																className={
																	Math.sign(optimizations?.nutrition) > 0
																		? "text-success"
																		: "text-danger"
																}
															/>
														</>
													) : (
														<Minus size={16} className="color-bg-slate" />
													)}
												</span>
											</th>
											<th>
												<span className={"header-indicator"}>
													{" "}
													{optimizations?.supply_chain &&
													Math.sign(optimizations?.supply_chain) != 0 ? (
														<>
															{Math.sign(optimizations?.supply_chain) > 0 ? (
																<span style={{ fontSize: 16, color: "#e85a73" }}>
																	+{Math.round(optimizations?.supply_chain * 100) / 100}
																</span>
															) : (
																<span style={{ fontSize: 16, color: "#51c5ac" }}>
																	{Math.round(optimizations?.supply_chain * 100) / 100}
																</span>
															)}
															<Percent
																size={16}
																className={
																	Math.sign(optimizations?.supply_chain) < 0
																		? "text-success"
																		: "text-danger"
																}
															/>
														</>
													) : (
														<Minus size={16} className="color-bg-slate" />
													)}
												</span>
											</th>
											<th>
												<span className={"header-indicator"}>
													{" "}
													{optimizations?.cost && Math.sign(optimizations?.cost) != 0 ? (
														<>
															{Math.sign(optimizations?.cost) > 0 ? (
																<span style={{ fontSize: 16, color: "#e85a73" }}>
																	+{Math.ceil(optimizations?.cost * 100) / 100}
																</span>
															) : Math.sign(optimizations?.cost) === 0 ? (
																<span style={{ fontSize: 16, color: "#51c5ac" }}>
																	{Math.ceil(optimizations?.cost * 100) / 100}
																</span>
															) : (
																<span style={{ fontSize: 16, color: "#51c5ac" }}>
																	{Math.ceil(optimizations?.cost * 100) / 100}
																</span>
															)}
															<Percent
																size={16}
																className={
																	Math.sign(optimizations?.cost) < 0
																		? "text-success"
																		: "text-danger"
																}
															/>
														</>
													) : (
														<Minus size={16} className="color-bg-slate" />
													)}
												</span>
											</th>
											<th>
												<span className={"header-indicator"}>
													{optimizations?.sustainability &&
													Math.sign(optimizations?.sustainability) != 0 ? (
														<>
															{Math.sign(optimizations?.sustainability) > 0 ? (
																<span style={{ fontSize: 16, color: "#51c5ac" }}>
																	+{Math.ceil(optimizations?.sustainability * 100) / 100}
																</span>
															) : (
																<span style={{ fontSize: 16, color: "#e85a73" }}>
																	{Math.ceil(optimizations?.sustainability * 100) / 100}
																</span>
															)}
															<Percent
																size={16}
																className={
																	Math.sign(optimizations?.sustainability) > 0
																		? "text-success"
																		: "text-danger"
																}
															/>
														</>
													) : (
														<Minus size={16} className="color-bg-slate" />
													)}
												</span>
											</th>
											<th>
												<span className={"header-indicator"}>
													{" "}
													{optimizations?.popularity &&
													Math.sign(optimizations?.popularity) != 0 ? (
														<>
															{Math.sign(optimizations?.popularity) > 0 ? (
																<span style={{ fontSize: 16, color: "#51c5ac" }}>
																	+{Math.ceil(optimizations?.popularity * 100) / 100}
																</span>
															) : (
																<span style={{ fontSize: 16, color: "#e85a73" }}>
																	{Math.ceil(optimizations?.popularity * 100) / 100}
																</span>
															)}
															<Percent
																size={16}
																className={
																	Math.sign(optimizations?.popularity) > 0
																		? "text-success"
																		: "text-danger"
																}
															/>
														</>
													) : (
														<Minus size={16} className="color-bg-slate" />
													)}
												</span>
											</th>
											{/* <th>
												<Minus className="color-bg-slate" />
											</th>
											<th>
												<Minus className="color-bg-slate" />
											</th> */}
										</tr>

										<tr className="font-weight-semibold active" key={activeVersion.id}>
											<td>
												<CustomInput type="radio" label={activeVersion.version} checked />
											</td>
											<td>
												<ShoppingCart className="mr-3" />
												{moment(activeVersion.insertion_date).format("MM/DD/YY")}
											</td>
											<td>
												<div
													data-tooltip={
														"Set product objectives to recieve ingredient recommendations and drive nutrition development goals"
													}
													className={
														!optimizations || Math.sign(optimizations?.nutrition) >= 0
															? "box-outline-success"
															: "box-outline-danger"
													}
												>
													Nutrition
												</div>
											</td>
											<td>
												<div
													data-tooltip={
														"Set product objectives to recieve ingredient recommendations and drive supply chain development goals"
													}
													className={
														!optimizations || Math.sign(optimizations?.supply_chain) >= 0
															? "box-outline-danger"
															: "box-outline-success"
													}
												>
													Supply Chain
												</div>
											</td>
											<td>
												<div
													data-tooltip={
														"Set product objectives to recieve ingredient recommendations and drive cost development goals"
													}
													className={
														!optimizations || Math.sign(optimizations?.cost) <= 0
															? "box-outline-success"
															: "box-outline-danger"
													}
												>
													Cost
												</div>
											</td>
											<td>
												<div
													data-tooltip={
														"Set product objectives to recieve ingredient recommendations and drive popularity development goals"
													}
													className={
														!optimizations || Math.sign(optimizations?.popularity) >= 0
															? "box-outline-success"
															: "box-outline-danger"
													}
												>
													Sustainability
												</div>
											</td>
											<td>
												<div
													data-tooltip={
														"Set product objectives to recieve ingredient recommendations and drive popularity development goals"
													}
													className={
														!optimizations || Math.sign(optimizations?.popularity) >= 0
															? "box-outline-success"
															: "box-outline-danger"
													}
												>
													Popularity
												</div>
											</td>
											{/* <td>
												<div
													data-tooltip={
														"Set product objectives to recieve ingredient recommendations and drive texture development goals"
													}
													className="box-secondary"
												>
													Texture
												</div>
											</td> */}
											{/* <td>
												<div
													data-tooltip={
														"Set product objectives to recieve ingredient recommendations and drive taste development goals"
													}
													className="box-secondary"
												>
													Taste
												</div>
											</td> */}
										</tr>
									</>
								);
							})}
						{/* End Current Version */}
					</Table>
					<div className={"expand-history"}>
						<div className={"expand-history-btn"} onClick={() => setShowHistory(!showHistory)}>
							{showHistory ? (
								<span>
									{"Version History"} <ChevronUp />{" "}
								</span>
							) : (
								<span>
									{"Version History"} <ChevronDown />{" "}
								</span>
							)}
						</div>
					</div>
					{showHistory && (
						<Table className="version-list" hover borderless>
							<thead>
								<tr className="font-weight-normal">
									<th>Version</th>
									<th className="color-battleship-grey">Date</th>
									<th colSpan={6} className="color-battleship-grey">
										Optimized features
									</th>
								</tr>
							</thead>
							<tbody>
								{/* Other Versions */}
								{uniqBy(versions, (product: IProduct) => product.version)
									.filter((product: IProduct) => !product.active)
									.map((product: IProduct, index, arr) => (
										<VersionRow version={product} />
									))}
								{/* End Other Versions */}
							</tbody>
						</Table>
					)}
				</Col>
			</Row>
		</section>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	versions: state.product.selectedVersions
});

export default connect(mapStateToProps)(VersionHistory);
