import { IInvoice } from "../../interfaces/invoice";
import { ActionType } from "../actions/action";
import { billing } from "../actions/actionContants";
/**TODO:
 * implement type definitions
 **/

export interface IBillingState {
	user_invoices: IInvoice[];
	admin_invoices: IInvoice[];
	selected_invoice: IInvoice | undefined;
}

const INIT_STATE: IBillingState = {
	user_invoices: [],
	admin_invoices: [],
	selected_invoice: undefined
};

export default function (
	state: IBillingState = INIT_STATE,
	action: ActionType
) {
	switch (action.type) {
		case billing.GET_ALL_INVOICES:
			return {
				...state,
				admin_invoices: action.payload
			};
		case billing.GET_COMPANY_INVOICES:
			return {
				...state,
				user_invoices: action.payload
			};
		case billing.GET_INVOICE:
			return {
				...state,
				selected_invoice: action.payload
			};
	}

	return state;
}
