import { connect } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { Loader, Search, X } from "react-feather";
import { useSelector } from "react-redux";
import { FormGroup, InputGroup, Input, Button } from "reactstrap";
import { ISearchIngredient } from "../../../../interfaces/ingredient";
import { IPaginateList } from "../../../../interfaces/pagination";
import { paginateAction } from "../../../../redux/actions/pagination/pagination";
import { IApplicationState } from "../../../../redux/reducers";
import searchService from "../../../../services/searchService";
import { useDebounce } from "../../../../utils/hooks";
import { search } from "../../../../redux/actions/actionContants";
import { Dispatch } from "redux";
import IngredientItem from "./ingredientItem";

interface IProps {
	onIngredientSelect: (ingredient: ISearchIngredient) => void;
	getSearchResults: (searchText: string) => Promise<any>;
	paginateSearchResults: (term: string) => Promise<any>;
	paginate: (page: number) => Promise<void>;
	searchFilter: any | undefined;
	paginatedSearchResults: IPaginateList<any[]>;
}

export interface IFilters {
	[type: string]: boolean;
}

const RecommendationIngredients: React.FC<IProps> = ({
	paginatedSearchResults: { list, pagination },
	onIngredientSelect,
	...props
}) => {
	const [searchLoading, setSearchLoading] = useState<boolean>(false);
	const [show, setShow] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [searchIngredients, setSearchIngredients] = useState<ISearchIngredient[]>([]);
	useSelector((state: IApplicationState) => state.search.result);
	const prevSearchRef = useRef<string | undefined>();

	function handleSearchChange(e: any) {
		const search = e.target.value;
		setSearchTerm(search);
	}

	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	const search = () => {
		if (debouncedSearchTerm) {
			setSearchLoading(true);
			props.paginateSearchResults(debouncedSearchTerm).finally(() => {
				setSearchLoading(false);
			});
		} else {
			props.paginateSearchResults(""); // Reset search results on empty search term
			setSearchIngredients([]);
		}
	};

	const clearSearch = () => {
		setSearchIngredients([]);
		setSearchTerm("");
	};

	const handleCreateIngredient = () => {
		if (debouncedSearchTerm) {
			const newIngredient = {
				jf_display_name: debouncedSearchTerm,
				id: `new-ingredient-${debouncedSearchTerm}`
			};
			onIngredientSelect(newIngredient);
		}
	};

	useEffect(() => {
		if (debouncedSearchTerm) {
			// Reset pagination to first page on search term change
			if (prevSearchRef.current !== debouncedSearchTerm) {
				setSearchIngredients([]);
				props.paginate(1);
			}
		}
		prevSearchRef.current = debouncedSearchTerm;
		search();
		document.addEventListener(
			"click",
			function (e: any) {
				if (e.target.closest(".live-search-container")) {
					return;
				}
			},
			false
		);
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (pagination.page > 1) search();
	}, [pagination.page]);

	useEffect(() => {
		if (list.length > 0) {
			setSearchIngredients(searchIngredients.concat(list));
		} else {
			setSearchIngredients([]);
		}
	}, [list]);

	return (
		<div className={"mt-4"}>
			<h5>Select Replacement Ingredients:</h5>
			<FormGroup className="mb-0">
				<InputGroup className={`${show ? "active" : ""}`}>
					{searchLoading ? (
						<Loader className="fa-spin" size={18} />
					) : (
						<Search className="color-bg-slate" size={18} />
					)}
					<Input
						onChange={handleSearchChange}
						onFocus={() => setShow(true)}
						onBlur={() => setShow(false)}
						type="search"
						name="search-text"
						id="search-text"
						className="ml-2 border-0"
						placeholder="Search for ingredients, products, claims etc."
						autoComplete=""
						value={searchTerm}
					/>
					{searchTerm ? <X onClick={clearSearch} color={"#e85a73"} size={18} /> : <></>}
				</InputGroup>
			</FormGroup>
			{!searchLoading && list.length == 0 && debouncedSearchTerm && (
				<p className="mt-2" style={{ color: "#3f65f1" }} onClick={() => handleCreateIngredient()}>
					Ingredient doesn't exist? Create It
				</p>
			)}
			{searchIngredients && (
				<>
					<ul>
						{searchIngredients.map((ingredient: ISearchIngredient, index: number) => {
							return (
								<IngredientItem
									key={index}
									item={ingredient}
									select={() => onIngredientSelect(ingredient)}
								/>
							);
						})}
						{pagination.page * pagination.size < pagination.total &&
						searchIngredients.length > 0 ? (
							<li
								key={"load-more"}
								style={{
									display: "flex",
									justifyContent: "center",
									width: "100%",
									paddingBottom: 15
								}}
							>
								<Button
									disabled={searchLoading}
									style={{ width: "max-content" }}
									onClick={() => {
										props.paginate(pagination.page + 1);
										setSearchLoading(true);
									}}
								>
									{searchLoading ? <Loader size={14} className="fa-spin" /> : "Load More"}
								</Button>
							</li>
						) : (
							<></>
						)}
					</ul>
				</>
			)}
		</div>
	);
};

const mapDispatchToProps = {
	getSearchResults: (searchText: string) => searchService.getSearchResults(searchText),
	paginateSearchResults: (term: string) => searchService.paginateSearch(term),
	paginate: (page: number) => async (dispatch: Dispatch) => {
		dispatch(paginateAction(search.UPDATE_PAGINATION, page));
	}
};

const mapStateToProps = (state: IApplicationState) => ({
	paginatedSearchResults: state.search.paginated_results,
	searchFilter: state.search.filter
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationIngredients);
