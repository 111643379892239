import React from "react";
import { Star } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { IIngredient } from "../../../interfaces/ingredient";
import { IProduct } from "../../../interfaces/products";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { ImageSkeleton } from "../../../utils/ghostUI";
import { truncate } from "../../../utils/string";

interface IProps {
	item: any;
	type: string;
	onClick: () => void;
	starred?: boolean;
}
const RecentItem: React.FC<IProps> = (props) => {
	return (
		<div className={"recent-item"} onClick={props.onClick}>
			{props.starred ? (
				<div className="starred">
					<Star size={28} />
				</div>
			) : (
				<></>
			)}
			{props.type == "ingredients" ? (
				<ReactImageFallback
					initialImage={<ImageSkeleton className="recent-img-slider-ghost" />}
					src={config.api.endpoints.ingredients.ingredientImage(props.item.name)}
					fallbackImage={getIngredientImagePlaceholder()}
					alt="Ingredient Image"
				/>
			) : (
				<ReactImageFallback
					initialImage={<ImageSkeleton className="recent-img-slider-ghost" />}
					src={props.item.image_uri}
					fallbackImage={"https://drive.google.com/uc?id=1u6E7OSOTZzqdkMk3Tr-xd_9eK9ueiCDN"}
					alt="Product Image"
				/>
			)}
			{props?.item?.jf_display_name?.length > 12 || props.item.name?.length > 12 ? (
				<div data-tooltip={props.item.jf_display_name}>
					<h5>
						{props.type == "ingredients"
							? truncate(props.item.jf_display_name, 12)
							: truncate(props.item.name, 12)}
					</h5>
				</div>
			) : (
				<h5>
					{props.type == "ingredients"
						? truncate(props.item.jf_display_name, 12)
						: truncate(props.item.name, 12)}
				</h5>
			)}
		</div>
	);
};

export default RecentItem;
