import { FILTER_TYPE } from "../../../interfaces/admin";
import {
	IPreferenceResponse,
	IPreference
} from "../../../interfaces/preference";
import { IProfileResponseAction } from "../user/preferenceAction";
import { preference } from "../actionContants";

export function preferenceResponseAction(
	payload: IPreferenceResponse,
	resolve?: Function,
	reject?: Function
): IProfileResponseAction {
	return {
		type: preference.PREFERENCE_RESPONSE,
		payload,
		reject,
		resolve
	};
}

export function preferencesResponseAction(payload: IPreference[]) {
	return {
		type: preference.PREFERENCE_RESPONSE,
		payload
	};
}
