import { Api } from "./api";
import { Dispatch } from "redux";
import {
	preferenceResponseAction,
	preferencesResponseAction
} from "../redux/actions/user/preferenceActions";
import { FILTER_TYPE } from "../interfaces/admin";
import config from "../utils/config";
import { IApplicationState } from "../redux/reducers";
import { info } from "../utils/notification";

export class PortfolioService extends Api {
	getFilters() {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(config.api.endpoints.preferences.portfolio);
				dispatch(preferencesResponseAction(response.data));
			} catch (error) {
				this.handleError(error, "Preferences Error");
			}
		};
	}

	getFilter(preferenceType: FILTER_TYPE, limit: number) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const {
				preference: { page }
			} = getState();
			let preferencePage = page[preferenceType] + 1;
			try {
				const response = await this.http.get(config.api.endpoints.preferences[preferenceType], {
					params: {
						limit,
						offset: preferencePage
					}
				});
				dispatch(
					preferenceResponseAction({
						data: response.data.data,
						preferenceType,
						page: preferencePage
					})
				);
			} catch (error) {
				this.handleError(error, "Preferences Error");
			}
		};
	}

	getIngredients() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.preferences.portfolio);
				return response.data;
			} catch (error) {
				this.handleError(error, "Ingredient Preferences error");
				return [];
			}
		};
	}

	getIngredientPreferences(userId: string) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.user.preferences(userId));
				return response.data;
			} catch (error) {
				if (error.response?.status === 404) {
					return;
				}
				this.handleError(error, "Ingredient Preferences error");
				return [];
			}
		};
	}

	updateIngredientPreferences(userId: string, body: any) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.post(config.api.endpoints.user.preferences(userId), body);
				info("Ingredient Preferences Saved");
				return response.data;
			} catch (error) {
				this.handleError(error, "Ingredient Preferences error");
				return [];
			}
		};
	}

	getPackaging() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.preferences.packaging);
				return response.data;
			} catch (error) {
				this.handleError(error, "Packaging Preferences error");
				return [];
			}
		};
	}

	getPackagingPreferences(userId: string) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.preferences.packagingPreferences(userId)
				);
				return response.data;
			} catch (error) {
				if (error.response?.status === 404) {
					return;
				}
				this.handleError(error, "Packaging Preferences error");
				return [];
			}
		};
	}

	updatePackagingPreferences(userId: string, body: any) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.post(
					config.api.endpoints.preferences.packagingPreferences(userId),
					body
				);
				info("Packaging Preferences Saved");
				return response.data;
			} catch (error) {
				this.handleError(error, "Packaging Preferences error");
				return [];
			}
		};
	}

	getProduct() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.preferences.product);
				return response.data;
			} catch (error) {
				this.handleError(error, "Product Preferences error");
				return [];
			}
		};
	}

	getProductPreferences(userId: string) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.preferences.productPreferences(userId)
				);
				return response.data;
			} catch (error) {
				if (error.response?.status === 404) {
					return;
				}
				this.handleError(error, "Product Preferences error");
				return [];
			}
		};
	}

	updateProductPreferences(userId: string, body: any) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.post(
					config.api.endpoints.preferences.productPreferences(userId),
					body
				);
				info("Product Preferences Saved");
				return response.data;
			} catch (error) {
				this.handleError(error, "Product Preferences error");
				return [];
			}
		};
	}
}

export default new PortfolioService();
