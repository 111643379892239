import React from "react";
import { withFormik, InjectedFormikProps } from "formik";
import { Form } from "reactstrap";
import { ForgotPasswordSchema } from "../../../../validations/forgotPassword";
import ForgotPasswordFormElements, {
	IForgotPasswordFormElements
} from "./forgotPasswordFormFields";
import Button from "../../../../components/common/button";
import userService from "../../../../services/userService";
import { connect } from "react-redux";

interface IForgotPasswordProps {
	sendForgotPasswordMail: (email: string) => Promise<any>;
	setNotification: (isOpen: boolean) => void;
	setEmail: (email: string) => void;
}

const ForgotPasswordForm: React.FC<InjectedFormikProps<IForgotPasswordProps, any>> = (props) => {
	return (
		<Form onSubmit={props.handleSubmit} className="forget-password-form">
			<ForgotPasswordFormElements {...props} />
			<div className="forget-password-button">
				<Button
					type="submit"
					size="lg"
					loading={props.isSubmitting}
					disabled={!props.isValid}
					className="sbm-button"
				>
					Send me instructions
				</Button>
			</div>
		</Form>
	);
};

const WrappedForm = withFormik<IForgotPasswordProps, IForgotPasswordFormElements>({
	enableReinitialize: true,
	validationSchema: ForgotPasswordSchema,
	mapPropsToValues: () => {
		return {
			email: ""
		};
	},
	handleSubmit: async (
		{ email },
		{ setSubmitting, props: { setEmail, setNotification, sendForgotPasswordMail } }
	) => {
		try {
			setSubmitting(true);
			await sendForgotPasswordMail(email);
			setNotification(true);
			setEmail(email);
		} catch (err) {
		} finally {
			setSubmitting(false);
		}
	}
});

const mapDispatchToProps = {
	sendForgotPasswordMail: (email: string) => userService.sendResetPasswordMail(email)
};

export default connect(null, mapDispatchToProps)(WrappedForm(ForgotPasswordForm));
