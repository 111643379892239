import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { IApplicationState } from "../../../../redux/reducers";
import { Button, Col, Row, Table } from "reactstrap";
import { AlertCircle, ArrowRight, CheckCircle, Loader } from "react-feather";
import InvoiceSingle from "./invoiceSingle";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "../../../routes";
import paymentService from "../../../../services/paymentService";
import { IInvoice } from "../../../../interfaces/invoice";
import moment from "moment";

interface IProps extends RouteComponentProps {
	companyInvoices: IInvoice[];
	getCompanyInvoices: () => Promise<any>;
}

const STATUS_STYLES = {
	PENDING: {
		backgroundColor: "#f7c080"
	},
	PAID: {
		backgroundColor: "#affaea"
	}
};

const SAMPLE_INVOICES: IInvoice[] = [
	{
		company: null,
		id: "fake_invoice_id",
		invoice_id: "fake_invoice_id",
		invoice_status: "paid",
		meta_data: {
			service: "Supplier Subscription (DEMO)",
			description: "Yearly charge for supplier tier platform access",
			price: "$60"
		},
		created_at: new Date().toString(),
		updated_at: new Date().toString(),
		type: "subscription"
	},
	{
		company: null,
		id: "fake_invoice_id",
		invoice_id: "fake_invoice_id",
		invoice_status: "paid",
		meta_data: {
			service: "CSV Data Upload (DEMO)",
			description: "One time fee for data upload services",
			price: "$100"
		},
		created_at: new Date().toString(),
		updated_at: new Date().toString(),
		type: "single"
	}
];

const Invoices: React.FC<IProps> = ({ companyInvoices, getCompanyInvoices, ...props }) => {
	const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | undefined>();
	const [loading, setLoading] = useState<boolean>();
	const [paymentMade, setPaymentMade] = useState<boolean>();

	useEffect(() => {
		setLoading(true);
		getCompanyInvoices().finally(() => {
			setLoading(false);
		});
	}, [paymentMade]);

	const hasPendingInvoices = () => {
		return companyInvoices.some((value: IInvoice) => value.invoice_status === "open");
	};

	const sortByDate = () => {
		return companyInvoices.sort(function (a, b) {
			return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
		});
	};

	return (
		<Col className={"mt-2 invoices"}>
			{!selectedInvoice ? (
				<>
					<Row className={"mt-2 mb-3"}>
						<h3>Invoices</h3>
						<h4>
							Status:
							{loading ? (
								<Loader style={{ marginLeft: 15 }} className="fa-spin" />
							) : hasPendingInvoices() ? (
								<>
									<span className={"invoice-status"}>Pending Charges</span>
									<AlertCircle color={"#f5a850"} />
								</>
							) : (
								<>
									<span className={"invoice-status"}>Paid</span>
									<CheckCircle color={"#0ed7ac"} />
								</>
							)}
						</h4>
					</Row>
					<Row>
						<Table className={"clean-table"}>
							<thead>
								<tr>
									<th>Created On</th>
									<th> Service </th>
									<th> Description </th>
									<th> Status </th>
									<th> Price </th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{(companyInvoices?.length !== 0 ? sortByDate() : []).map((i: IInvoice) => (
									<tr>
										<td> {moment(i.created_at).format("MM/DD/YYYY")} </td>
										<td> {i?.meta_data?.service} </td>
										<td> {i?.meta_data?.description} </td>
										<td>
											{" "}
											<span> {i?.invoice_status == "open" ? "Unpaid" : "Paid"} </span>
										</td>
										<td> {i?.meta_data?.price} </td>
										<td>
											<Button
												color="secondary"
												outline
												className="color-battleship-grey"
												onClick={() =>
													i.type == "subscription"
														? props.history.push(routes.SUBSCRIPTION)
														: setSelectedInvoice(i)
												}
											>
												<ArrowRight size={18} className="mr-2" />
												View
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Row>
				</>
			) : (
				<InvoiceSingle
					id={selectedInvoice.id}
					invoice={selectedInvoice}
					handleNavBack={(paymentMade?: boolean) => {
						setSelectedInvoice(undefined);
						if (paymentMade) setPaymentMade(true); // update invoices if payment was made
					}}
				/>
			)}
		</Col>
	);
};
const mapStateToProps = (state: IApplicationState) => ({
	companyInvoices: state.billing.user_invoices
});

const mapDispatchToProps = {
	getCompanyInvoices: () => paymentService.getCompanyInvoices()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Invoices));
