import React, { useEffect, useRef, useState } from "react";
import VerificationCodeForm from "./verificationCodeForm";
import firebase from "firebase";

interface IProps {
	mfaResolver: any;
	phoneNumber: string;
	onSubmitVerificationCode: (verificationId: string, verificationCode: string) => Promise<void>;
	onBack?: () => void;
	showRedirect?: boolean;
}
const VerificationCode: React.FC<IProps> = ({
	mfaResolver,
	phoneNumber,
	onSubmitVerificationCode,
	onBack,
	showRedirect
}) => {
	const reCaptchaRef = useRef<HTMLDivElement | null>();
	const [
		reCaptchaVerifier,
		setReCaptchaVerifier
	] = useState<firebase.auth.RecaptchaVerifier | null>(null);
	const [phoneInfoOptions, setPhoneInfoOptions] = useState<any>({});
	const [verificationId, setVerificationId] = useState<string>("");

	useEffect(() => {
		const reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(reCaptchaRef.current, {
			size: "invisible"
		});
		setReCaptchaVerifier(reCaptchaVerifier);
		setPhoneInfoOptions({
			multiFactorHint: mfaResolver.hints[0],
			session: mfaResolver.session
		});
	}, []);

	useEffect(() => {
		if (!(phoneInfoOptions && reCaptchaVerifier)) return;
		var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
		// Send SMS verification code.
		phoneAuthProvider
			.verifyPhoneNumber(phoneInfoOptions, reCaptchaVerifier)
			.then(function (verificationId) {
				setVerificationId(verificationId);
			});
	}, [phoneInfoOptions]);

	const handleSubmit = async (verificationCode: string) => {
		if (!verificationId) return;
		await onSubmitVerificationCode(verificationId, verificationCode)
			.then(() => {
				//
			})
			.catch((err) => {
				// Do something with an error
			});
	};

	return (
		<div>
			<div ref={(ref) => (reCaptchaRef.current = ref)}></div>
			<VerificationCodeForm
				phoneNumber={phoneNumber}
				onSubmit={handleSubmit}
				onBack={onBack}
				showRedirect={showRedirect}
			/>
		</div>
	);
};

export default VerificationCode;
