import { IIntegration } from "../../interfaces/integration";
import { routes } from "../routes";
import images from "./images";

export const INTEGRATIONS = [
	{
		name: "Airtable",
		website: "https://airtable.com",
		logo: images.airtable.logo,
		thumbnail: images.airtable.thumbnail,
		description:
			"Airtable is a collaborative platform that provides users with a space to collaboratively organize everything from group projects to product data. Airtable's databases seamlessly connect with Journey Foods, creating low dynamic, one to one connections for overlapping ingrediants and products."
	},
	{
		name: "Google Cloud",
		website: "https://cloud.google.com",
		logo: images.google_cloud.logo,
		thumbnail: images.google_cloud.thumbnail,
		description:
			"A place to build and run software, Google Cloud helps users modernize their infrastructure, manage data and incorporate AI into their everyday workflow. Integrating Google Cloud with Journey Foods creates simple one to one connections by matching our ingrediants and products to the data that exists in your cloud."
	},
	{
		name: "Google Sheets",
		path: routes.GOOGLE_SHEETS,
		website: "https://www.google.com/sheets/about/",
		logo: images.google_sheets.logo,
		thumbnail: images.google_sheets.thumbnail,
		description:
			"A place to create and collaborate on spreadsheets, Google Sheets allows users to work on data from wherever they are. Connecting your Google Sheets account with our ingredient and product database cuts down the time and effort to get our technologies to cooperate by creating low dynamic one to one connections."
	},
	{
		name: "IBM",
		website: "https://dynamics.microsoft.com/en-us/",
		logo: images.ibm.logo,
		thumbnail: images.ibm.thumbnail,
		description:
			"Through a series of AI driven business applications, Microsoft Dynamics aims to help companies run their businesses faster and produce better results. A high dynamic connection, integrating Microsoft Dynamics with Journey Foods results in lower development costs, greater scalability and faster implementations."
	},
	{
		name: "Microsoft Dynamics",
		website: "https://dynamics.microsoft.com/en-us/",
		logo: images.microsoft_dynamics.logo,
		thumbnail: images.microsoft_dynamics.thumbnail,
		description:
			"Through a series of AI driven business applications, Microsoft Dynamics aims to help companies run their businesses faster and produce better results. A high dynamic connection, integrating Microsoft Dynamics with Journey Foods results in lower development costs, greater scalability and faster implementations."
	},
	{
		name: "Odoo",
		website: "https://www.odoo.com",
		logo: images.odoo.logo,
		thumbnail: images.odoo.thumbnail,
		description:
			"Odoo helps users work better through a series of integrated apps, with the goal of giving users a place to manage all of their business functions in one place. Integretating your Odoo data with Journey Foods will create a high dynamic connection that will decrease the time and costs to create matches between your products, ingredients and client lists with our data."
	},
	{
		name: "Oracle ERP",
		website: "https://www.oracle.com/applications/erp/",
		logo: images.oracle.logo,
		thumbnail: images.oracle.thumbnail,
		description:
			"Oracle ERP provides resources for users for everything from finanical planning to optimizing operations through AI. A high dynamic connection with ERP and CRM tools, integrating Oracle with Journey Foods will allow quicker, more efficient collaboration between your ingredients and products and our database."
	},
	{
		name: "SAP.io Marketplace",
		website: "https://sap.io",
		logo: images.sap_io.logo,
		thumbnail: images.sap_io.thumbnail,
		description:
			"SAP.io helps innovators build products, find customers and change industries. Integrating SAP.io with Journey Foods creates a high dynamic connection between your data and our database."
	},
	{
		name: "Shopify",
		website: "https://www.shopify.com",
		logo: images.shopify.logo,
		thumbnail: images.shopify.thumbnail,
		description:
			"Shopify not only helps users build and manage ecommerce websites, but also provides real time analystics and reports that allows companies to go farther, faster. Connecting your ecommerce wesbite on Shopify to our Journey Foods database creates effcient, low dynamic connections between your products and the ingrediants you use with the products and ingrediants in our database."
	},
	{
		name: "CSV",
		logo: images.csv.logo,
		thumbnail: images.csv.thumbnail,
		description:
			"A comma-separated values file is a delimited text file that uses a comma to separate values. Each line of the file is a data record. Each record consists of one or more fields, separated by commas. The use of the comma as a field separator is the source of the name for this file format.",
		route: routes.CSV_INTEGRATION
	},
	{
		name: "Excel",
		logo: images.excel.logo,
		thumbnail: images.csv.thumbnail,
		description:
			"Microsoft Excel is a spreadsheet developed by Microsoft for Windows, macOS, Android and iOS. It features calculation, graphing tools, pivot tables, and a macro programming language called Visual Basic for Applications.",
		route: routes.EXCEL_INTEGRATION
	}
];
