import { IFilterOption } from "../routes/packaging/packaging/components/packageFilters";
import { IPackageFilters } from "../services/packagingService";

export const formatPackagingFilters = (appliedFilters: IFilterOption[] | undefined) => {
	if (!appliedFilters) return;
	return appliedFilters.reduce((formatted: IPackageFilters, o: IFilterOption) => {
		switch (o.preference_type) {
			case "Sustainability":
				formatted["sustainability_id"] = o.id;
				break;
			case "Filling Process":
				formatted["filling_process_id"] = o.id;
				break;
			case "End User Benefits":
				formatted["end_user_benefits_id"] = o.id;
				break;
			case "Form":
				formatted["form_id"] = o.id;
				break;
			case "Material":
				formatted["material_family_id"] = o.id;
				break;
			default:
				console.log(
					`Unexpected preference type, '${o.preference_type}' in formatting packaging filter options`
				);
				break;
		}
		return formatted;
	}, {});
};
