import { IUploadRequest } from "../interfaces/integration";
import images from "../routes/integration/images";
import { toCamelCase } from "./string";

export const getIntegrationImage = (name: string, thumbnail?: boolean) => {
	switch (name) {
		case "Google Sheets":
			return thumbnail ? images.google_sheets.thumbnail : images.google_sheets.logo;
		case "Google Cloud":
			return thumbnail ? images.google_cloud.thumbnail : images.google_cloud.logo;
		case "Airtable":
			return thumbnail ? images.airtable.thumbnail : images.airtable.logo;
		case "IBM":
			return thumbnail ? images.ibm.thumbnail : images.ibm.logo;
		case "Odoo":
			return thumbnail ? images.odoo.thumbnail : images.odoo.logo;
		case "Oracle ERP":
			return thumbnail ? images.oracle.thumbnail : images.oracle.logo;
		case "Shopify":
			return thumbnail ? images.shopify.thumbnail : images.shopify.logo;
		case "Microsoft Dynamics":
			return thumbnail ? images.microsoft_dynamics.thumbnail : images.microsoft_dynamics.logo;
		case "SAP":
			return thumbnail ? images.sap.thumbnail : images.sap.logo;
	}
};

export const formatRequestType = (request: IUploadRequest) => {
	switch (request.request_type) {
		case "XLSX_UPLOAD":
			return "Excel Upload";
		case "CSV_UPLOAD":
			return "CSV Upload";
		default:
			return `Unknown data type ${request.data_type ? ", " + toCamelCase(request.data_type) : ""}`;
	}
};
