import React from "react";
import { Button, CardImg, Row } from "reactstrap";

interface IProps {
	onNavigateInfo: () => void;
}

const RecommendationSubmitted: React.FC<IProps> = ({ onNavigateInfo }) => {
	return (
		<>
			<Row className="justify-content-center">
				<div style={{ width: "443px" }}>
					<CardImg
						height="296"
						width="400"
						src={require("../../../assets/images/recomendation/recomendation.png")}
					></CardImg>
				</div>
			</Row>
			<Row className="text-center flex-column">
				<b>Thank you for your rating! </b> <br />
				<span>Your feedback helps us give you better recommendations.</span>
			</Row>
			<Row className={"justify-content-center"} style={{ marginTop: "13px" }}>
				<Button className={"recommendation-btn-success btn-success"} onClick={onNavigateInfo}>
					Back to Recommendation
				</Button>
			</Row>
		</>
	);
};

export default RecommendationSubmitted;
