import countryList from "react-select-country-list";

export const uniqBy = (arr: any, predicate: any) => {
	const cb = typeof predicate === "function" ? predicate : (o: any) => o[predicate];

	return [
		...arr
			.reduce((map: any, item: any) => {
				const key = item === null || item === undefined ? item : cb(item);

				map.has(key) || map.set(key, item);

				return map;
			}, new Map())
			.values()
	];
};

const countries = countryList().getData();
const findCountryByShortCode = (shortcode: string) =>
	countries.find((country) => country.value === shortcode);

export { findCountryByShortCode, countries };
