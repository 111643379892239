import { Api } from "./api";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { IIntegration } from "../interfaces/integration";
import { ICompany } from "../interfaces/company";
import config from "../utils/config";
import { GetCompanyResponseAction } from "../redux/actions/company/company";
import { info } from "../utils/notification";
import companyReducer from "../redux/reducers/companyReducer";
import { COMPANY_PERMISSIONS } from "../interfaces/permissions";

class CompanyService extends Api {
	getCompanies() {
		return async (dispatch: Dispatch) => {
			try {
				const response: AxiosResponse = await this.http.get(config.api.endpoints.company.companies);
				dispatch(GetCompanyResponseAction(response.data));
			} catch (error) {
				this.handleError(error);
			}
		};
	}

	updateCompanyPlantBased(plant_based: string) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.patch(config.api.endpoints.company.plant_based, {
					plant_based
				});
				info("Company Plant Based successfully updated.");
			} catch (error) {
				this.handleError(error, "Company Plant Based");
			}
		};
	}

	updateCompanyMarkets(markets: { id: string }[]) {
		return async () => {
			try {
				const response = await this.http.patch(config.api.endpoints.company.markets.add, {
					markets: markets
				});
				info("Company Markets Successfully Updated");
			} catch (error) {
				this.handleError(error, "Updating Company Market(s)");
			}
		};
	}

	removeCompanyMarket(id: string) {
		return async () => {
			try {
				const response = await this.http.delete(config.api.endpoints.company.markets.remove(id));
			} catch (error) {
				this.handleError(error, "Removing Market");
			}
		};
	}

	updateCompanyIntegrations(company: ICompany, integrations: IIntegration[]) {
		return async (dispatch: Dispatch) => {
			try {
				const body = {
					...company,
					integrations
				};
				const response = await this.http.put(
					config.api.endpoints.company.integrations(company.id),
					body
				);
				info("Company Integrations successfully updated.");
			} catch (error) {
				this.handleError(error, "Company Integrations");
			}
		};
	}

	updateCompanyPermissions(company: ICompany, permission: { [key: string]: string }) {
		return async (dispatch: Dispatch) => {
			try {
				const body = permission;
				const response = await this.http.patch(
					config.api.endpoints.company.permissions(company.id),
					body
				);
				info("Company Permissions successfully updated");
			} catch (error) {
				this.handleError(error, "Company Permissions");
			}
		};
	}

	companyHasPermission(company: ICompany, permission: any) {
		return company.hasOwnProperty(permission) && company[permission] == "enable";
	}

	getUserCompany(companies: ICompany[], userCompanyId: string) {
		return companies.find((company) => {
			return company.id === userCompanyId;
		});
	}

	updateCompanyType(types: { id: string }[]) {
		return async (dispatch: Dispatch) => {
			try {
				const params = {
					company_types: types
				};
				const response = await this.http.patch(
					config.api.endpoints.company.update_company_types,
					params
				);
				info("Company Type Successfully Updated");
			} catch (error) {
				this.handleError(error, "Company Type");
			}
		};
	}

	removeCompanyType(id: string) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.delete(
					config.api.endpoints.company.remove_company_type(id)
				);
				info("Company Type Successfully Removed", { type: "error" });
			} catch (error) {
				this.handleError(error, "Company Type");
			}
		};
	}

	getCompanyTypes() {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(config.api.endpoints.company.types);
				return response.data;
			} catch (error) {
				this.handleError(error, "Retrieving Company Types");
			}
		};
	}
}

export default new CompanyService();
