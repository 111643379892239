import React, { useState } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import Button from "../../../../components/common/button";
import ReactInputVerificationCode from "react-input-verification-code";

interface IProps {
	phoneNumber: string;
	isSubmitting: boolean;
	onChange: (data: string) => void;
	onSubmit: (event: any) => Promise<void>;
	onBack?: () => void;
	showRedirect?: boolean;
}

const LoginVerify: React.FC<IProps> = (props) => {
	const CODE_LENGTH = 6;
	const [codeLength, setCodeLength] = useState(CODE_LENGTH);

	const handleOnChange = (inputCode: string) => {
		setCodeLength(CODE_LENGTH - inputCode.length);
		props.onChange(inputCode);
	};

	return (
		<Card className="login-verify">
			<div className="login-verify-icon">
				{codeLength ? (
					<svg
						width="44"
						height="44"
						viewBox="0 0 44 44"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_1304_8390)">
							<path
								d="M22 40.3334C32.1252 40.3334 40.3333 32.1253 40.3333 22.0001C40.3333 11.8749 32.1252 3.66675 22 3.66675C11.8747 3.66675 3.66663 11.8749 3.66663 22.0001C3.66663 32.1253 11.8747 40.3334 22 40.3334Z"
								stroke="#767676"
								strokeWidth="3.67"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M22 14.6667V22.0001"
								stroke="#767676"
								strokeWidth="3.67"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M22 29.3333H22.0183"
								stroke="#767676"
								strokeWidth="3.67"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</g>
						<defs>
							<clipPath id="clip0_1304_8390">
								<rect width="44" height="44" fill="white" />
							</clipPath>
						</defs>
					</svg>
				) : (
					<svg
						width="44"
						height="44"
						viewBox="0 0 44 44"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M40.3333 20.3133V22C40.3311 25.9534 39.0509 29.8002 36.6838 32.9667C34.3166 36.1331 30.9893 38.4495 27.1981 39.5705C23.4069 40.6914 19.355 40.5568 15.6465 39.1867C11.9381 37.8167 8.77186 35.2845 6.62008 31.968C4.46831 28.6514 3.44627 24.7281 3.7064 20.7832C3.96652 16.8384 5.49487 13.0833 8.0635 10.078C10.6321 7.07267 14.1034 4.9782 17.9597 4.10694C21.8159 3.23567 25.8505 3.63429 29.4617 5.24333"
							stroke="#1DD6AE"
							strokeWidth="3.66667"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M40.3333 7.33333L22 25.685L16.5 20.185"
							stroke="#1DD6AE"
							strokeWidth="3.66667"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				)}
			</div>
			<div className="login-verify-header">Verify Authentication Code</div>

			<div className="login-verify-text">
				Please enter the 6 digit code sent to <br />
				{props.phoneNumber}
			</div>

			<div className="custom-styles">
				<ReactInputVerificationCode placeholder="" length={CODE_LENGTH} onChange={handleOnChange} />
			</div>

			<div className="login-verify-button-container">
				<Button
					className="sbm-button"
					disabled={!!codeLength}
					loading={props.isSubmitting}
					size="lg"
					onClick={props.onSubmit}
				>
					{codeLength ? codeLength + " digits left" : "Let’s go!"}
				</Button>
			</div>

			{props.showRedirect && (
				<div className="login-verify-action">
					<Link onClick={props.onBack} to={routes.LOGIN}>
						Back to login
					</Link>
				</div>
			)}
		</Card>
	);
};

export default LoginVerify;
