import data from "@iconify-icons/mdi/crown";
import React from "react";
import { Line } from "react-chartjs-2";
import { colors } from "react-select/src/theme";
import { ILineData } from "./interfaces";

interface IProps {
	data: ILineData;
	noGridLines?: boolean;
	color?: string;
	xLabel?: string;
	yStepSize?: number;
}

const getStepSize = (data: number[]) => {
	if (!data) return;
	const stepSize = Math.max(...data) / 2;
	return stepSize;
};

const LineChart: React.FC<IProps> = (props) => {
	return (
		<Line
			data={props.data}
			height={200}
			legend={{
				display: false
			}}
			options={{
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					// The following will affect the vertical lines (xAxe) of your dataset
					xAxes: [
						{
							gridLines: {
								// You can change the color, the dash effect, the main axe color, etc.
								display: !props.noGridLines,
								drawBorder: true,
								borderDash: [8, 8],
								color: "#e1ecf3"
							},
							ticks: {
								fontColor: props.color
							},
							scaleLabel: {
								display: props.xLabel != undefined,
								labelString: props.xLabel,
								fontColor: props.color
							}
						}
					],

					// And this will affect the horizontal lines (yAxe) of your dataset
					yAxes: [
						{
							gridLines: {
								display: !props.noGridLines,
								borderDash: [8, 8],
								drawBorder: true,
								color: "#e1ecf3"
							},
							ticks: {
								suggestedMin: 0,
								beginAtZero: true,
								autoSkip: true,
								maxTicksLimit: 3,
								stepSize: props.yStepSize || getStepSize(props?.data?.datasets[0]?.data),
								fontColor: props.color
							}
						}
					]
				}
			}}
		/>
	);
};

export default LineChart;
