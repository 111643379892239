import React from "react";
import { routes } from "../../routes";

const DashboardProductAction = (props: any) => {
	return (
		<div className="products__actions">
			<div className="header-svgs">
				<div className="svg--back" onClick={props.onClose}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19 12H5"
							stroke="#111111"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M12 19L5 12L12 5"
							stroke="#111111"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="svg--cancel" onClick={props.onClose}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18 6L6 18"
							stroke="#111111"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M6 6L18 18"
							stroke="#111111"
							strokeWidth="2"
							strokeLinecap="round"
							stroke-Linejoin="round"
						/>
					</svg>
				</div>
			</div>
			<div className="section">
				<div className="section__content">
					<div className="content__heading">Create a new product</div>
					<div className="content__paragraph">How would you like to start?</div>
				</div>
				<div className="section__option">
					<div>
						<div
							className="option__svg svg-dashed"
							onClick={() => {
								props.history.push(routes.PRODUCTS, { addFormOpen: true });
							}}
						>
							<svg
								width="42"
								height="42"
								viewBox="0 0 42 42"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M21 8.75V33.25"
									stroke="#17C9DC"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.75 21H33.25"
									stroke="#17C9DC"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className="action__items">
							<div className="items__heading ">Manually Add </div>
							<div className="items__name">Start fresh</div>
						</div>
					</div>
					<div>
						<div
							className="option__svg "
							onClick={() => {
								props.history.push(routes.INTEGRATIONS);
							}}
						>
							<svg
								width="42"
								height="42"
								viewBox="0 0 42 42"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M31.5 14C34.3995 14 36.75 11.6495 36.75 8.75C36.75 5.85051 34.3995 3.5 31.5 3.5C28.6005 3.5 26.25 5.85051 26.25 8.75C26.25 11.6495 28.6005 14 31.5 14Z"
									stroke="#C747F4"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10.5 26.25C13.3995 26.25 15.75 23.8995 15.75 21C15.75 18.1005 13.3995 15.75 10.5 15.75C7.60051 15.75 5.25 18.1005 5.25 21C5.25 23.8995 7.60051 26.25 10.5 26.25Z"
									stroke="#C747F4"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M31.5 38.5C34.3995 38.5 36.75 36.1495 36.75 33.25C36.75 30.3505 34.3995 28 31.5 28C28.6005 28 26.25 30.3505 26.25 33.25C26.25 36.1495 28.6005 38.5 31.5 38.5Z"
									stroke="#C747F4"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M15.0325 23.6426L26.985 30.6076"
									stroke="#C747F4"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M26.9675 11.3926L15.0325 18.3576"
									stroke="#C747F4"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className="action__items">
							<div className="items__heading">Import Spreadsheet </div>
							<div className="items__name">Add existing product(s)</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardProductAction;
