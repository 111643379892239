import React, { useEffect, useState } from "react";
import { Loader } from "react-feather";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import { StripeSubscriptionModifyAction } from "../../../../../interfaces/invoice";
import { IUser } from "../../../../../interfaces/user";
import { IApplicationState } from "../../../../../redux/reducers";
import paymentService from "../../../../../services/paymentService";
import { formatDate } from "../../../../../utils/date";
import { toCamelCase } from "../../../../../utils/string";

interface IProps {
	profile?: IUser | undefined;
	subscriptionDetails: { price: string; interval: string };
	modifySubscription: (modifyAction: StripeSubscriptionModifyAction) => Promise<any>;
}

const BillingPeriod: React.FC<IProps> = ({ subscriptionDetails, modifySubscription, ...props }) => {
	const [editBillingPeriod, setEditBillingPeriod] = useState<boolean>(false);
	const [
		selectedModifyAction,
		setSelectedModifyAction
	] = useState<StripeSubscriptionModifyAction>();
	const [loading, setLoading] = useState<boolean>(false);
	const [isRenewing, setIsRenewing] = useState<boolean>(true);
	const [showConfirm, setShowConfirm] = useState<boolean>(false);

	useEffect(() => {
		if (props.profile?.company.subscription_renewal == undefined) return;
		setIsRenewing(props.profile.company.subscription_renewal);
	}, [props.profile]);

	const handleEditBillingPeriod = () => {
		setEditBillingPeriod(!editBillingPeriod);
	};

	const handleChangeBillingPeriod = (modifyAction: StripeSubscriptionModifyAction) => {
		setSelectedModifyAction(modifyAction);
		setShowConfirm(true);
	};

	const confirmChange = () => {
		if (!selectedModifyAction) return;
		setLoading(true);
		modifySubscription(selectedModifyAction).finally(() => {
			setLoading(false);
			setShowConfirm(false);
		});
	};

	const handleConfirm = (confirm: boolean) => {
		if (!confirm) {
			setShowConfirm(false);
			return;
		}
		confirmChange();
	};

	const getModifyActionWarning = () => {
		switch (selectedModifyAction) {
			case StripeSubscriptionModifyAction.CANCEL:
				return `Are you sure you want to cancel your subscription? You will no longer have platform access after ${formatDate(
					props.profile?.company.subscription_period_end
				)}.
				You can reactivate your subscription at any time or renew your subscription after your current period ends to gain access to your data again.`;
			case StripeSubscriptionModifyAction.AUTO_RENEW_ON:
				return `Do you want to turn auto renewal of your subscription on? You're card will be charged on ${formatDate(
					props.profile?.company.subscription_period_end
				)} at the end of your current subscription period.`;
			case StripeSubscriptionModifyAction.AUTO_RENEW_OFF:
				return `Are you sure you want to cancel auto renewal of your subscription? You will no longer have platform access after ${formatDate(
					props.profile?.company.subscription_period_end
				)}.
				You can reactivate your subscription at any time.`;
			default:
				return "";
		}
	};

	return (
		<>
			<div className={"d-flex flex-row w-25 mb-3 align-items-end"}>
				<h3 style={{ margin: 0 }}>Billing Period</h3>
				<span
					onClick={handleEditBillingPeriod}
					className={"align-bottom"}
					style={{
						marginLeft: "auto",
						marginRight: 0,
						justifySelf: "flex-end",
						color: "#3f65f1",
						cursor: "pointer"
					}}
				>
					{!editBillingPeriod ? "Edit" : "Cancel"}
				</span>
			</div>
			<div
				className="font-16 w-25"
				style={{ backgroundColor: "#e1ecf37e", padding: "15px 20px", borderRadius: "6px" }}
			>
				<p className="font-16">
					<span>Active:</span>
					<span className="color-bg-blue font-16 ml-2">
						{props.profile?.company.subscription_type ? (
							toCamelCase(props.profile?.company.subscription_type)
						) : (
							<Loader className="fa-spin" size={16} />
						)}
					</span>
				</p>
				<p>
					<span>
						{props.profile?.company.subscription_renewal ? "Next renewal:" : "Subscription ends:"}
					</span>
					<span>
						{" "}
						{formatDate(props.profile?.company.subscription_period_end) ||
							"Unavailable, please contact sales@journeyfoods.com"}
					</span>
				</p>

				{editBillingPeriod ? (
					<div className="mt-3">
						{subscriptionDetails && (
							<p>
								<span>Price: </span>
								<span className="opacity-25">{`${subscriptionDetails.price}/${subscriptionDetails.interval}`}</span>
							</p>
						)}
						<Form>
							<FormGroup check>
								<Label>
									<Input
										type="checkbox"
										checked={isRenewing}
										disabled={loading}
										onChange={() =>
											handleChangeBillingPeriod(
												isRenewing
													? StripeSubscriptionModifyAction.AUTO_RENEW_OFF
													: StripeSubscriptionModifyAction.AUTO_RENEW_ON
											)
										}
									/>
									{isRenewing ? "Turn auto-renewal off" : "Turn auto-renewal on"}
								</Label>
							</FormGroup>
						</Form>
						{/* <Button
							color="danger"
							onClick={() => handleChangeBillingPeriod(StripeSubscriptionModifyAction.CANCEL)}
						>
							Cancel Subscription
						</Button> */}
					</div>
				) : (
					<></>
				)}
			</div>
			<Modal isOpen={showConfirm}>
				<ModalBody>
					<p className={"font-16"}>{getModifyActionWarning()}</p>
					<div className={"d-flex flex-row justify-content-center"}>
						<Button
							disabled={loading}
							className="btn-success mr-2"
							onClick={() => handleConfirm(true)}
						>
							Confirm
						</Button>
						<Button
							disabled={loading}
							className="btn-danger ml-2"
							onClick={() => handleConfirm(false)}
						>
							Cancel
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile
});

const mapDispatchToProps = {
	modifySubscription: (modifyAction: StripeSubscriptionModifyAction) =>
		paymentService.modifySubscription(modifyAction)
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPeriod);
