import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { AlertTriangle, X } from "react-feather";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { IIngredient } from "../../../interfaces/ingredient";
import { IApplicationState } from "../../../redux/reducers";
import { withRouter } from "react-router";
import { ingredient } from "../../../utils/notification";
import { ICountryData } from "../../../interfaces/ingredient";

interface IProps {
	type: InfoType;
	ingredient: IIngredient | null;
	isOpen: boolean;
	country: string;
	toggleModal: (open: boolean) => void;
}

export type InfoType = "regulation"; // potentially add datasheet and claim types for display

const IngredientInfoModal: React.FC<IProps> = (props) => {
	const [countryInfo, setCountryInfo] = useState<ICountryData[] | null>();

	const getCountryInfo = () => {
		const countryData = props.ingredient?.country_data?.filter((country) => {
			return country.country.alpha_2 === props.country || country.country.alpha_3 === props.country;
		});
		setCountryInfo(countryData);
	};

	useEffect(() => {
		getCountryInfo();
	}, [props.country]);

	return (
		<Modal
			isOpen={props.isOpen}
			className={props.type === "regulation" ? "regulation-notification" : ""}
		>
			<ModalHeader>
				<AlertTriangle size={23} className="text-danger" />
				<span className={"ml-3"}>
					{props.ingredient ? props.ingredient.name : "Something went wrong..."} Regulations
				</span>
				<X className="float-right" onClick={() => props.toggleModal(false)} />
			</ModalHeader>
			<ModalBody>
				<Table>
					<thead>
						<tr key={"regulation-table-header"}>
							<th>Permitted In</th>
							<th>Max Level</th>
							<th>Max Use Condition</th>
							<th>Used For</th>
							<th>Exception</th>
							<th>Link</th>
						</tr>
					</thead>
					<tbody>
						{countryInfo
							? countryInfo[0]?.regulations?.map((regulation: any, index: number) => {
									return (
										<tr key={index}>
											<td>{regulation.permitted_in}</td>
											<td>{regulation.max_level}</td>
											<td>{regulation.max_use_condition}</td>
											<td>{regulation.used_for}</td>
											<td>{regulation.exception}</td>
											<td>{regulation.link}</td>
										</tr>
									);
							  })
							: ""}
					</tbody>
				</Table>
			</ModalBody>
		</Modal>
	);
};

const mapStateToProps = (state: IApplicationState) => {
	return {
		ingredient: state.ingredient.selected
	};
};

export default connect(mapStateToProps)(IngredientInfoModal);
