import { SUBSCRIPTION } from "../../interfaces/subscription";
import { loadStripe, PaymentMethod, StripeError } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import paymentService from "../../services/paymentService";
import { IApplicationState } from "../../redux/reducers";
import { connect } from "react-redux";
import { Loader } from "react-feather";
import StripeClimate from "./stripeClimate";

/* CheckForm contains Stripe Elements that are validated and accessed inside of a Stripe <Element> component through the exported CheckoutModal class which 
    passes in a Stripe object promise that handles validation and secure payment processing.
*/

type OptionType = {
	tier: string;
	label: string;
};

interface CheckoutProps {
	selectedSubscription?: string;
	companyId?: string;
	invoiceId: string;
	onSuccess: (id?: string) => void;
}

const CheckoutForm: React.FC<CheckoutProps> = ({ invoiceId, onSuccess }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState<StripeError | null>(null);
	const [cardComplete, setCardComplete] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [paid, setPaid] = useState(false);

	const processPayment = async (paymentMethodId: string) => {
		return await paymentService
			.payInvoice(invoiceId, paymentMethodId)()
			.then(async (res: any) => {
				switch (res?.status) {
					case 500:
						return res;
					case "requires_action":
						// Initialize card authorization
						await stripe?.confirmCardPayment(res.client_secret).then((res: any) => {
							if (res.error) {
								setError(res.error);
								setProcessing(false);
							} else if (res.paymentIntent.status == "succeeded") {
								setPaid(true);
								setProcessing(false);
								onSuccess(res.paymentIntent.id);
							}
						});
						break;
					case 200:
						onSuccess();
						return res;
				}
			});
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setProcessing(true);

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		if (error) {
			if (elements != null) {
				elements.getElement("card")?.focus();
			}
			return;
		}

		if (cardComplete) {
			setProcessing(true);
		}

		const card = elements.getElement(CardElement);
		let payment_method: any;
		if (card != null) {
			// Create a payment method; id is used for confirm card payment
			payment_method = await stripe.createPaymentMethod({
				type: "card",
				card: card
			});
		} else {
			setProcessing(false);
		}

		if (payment_method?.error && card != null) {
			setError(payment_method?.error);
			setProcessing(false);
		} else {
			if (payment_method != null) {
				try {
					await processPayment(payment_method.paymentMethod.id).then((res: any) => {
						switch (res?.status) {
							case 500:
								throw Error("Please check that your entered the correct card information");
							case 200:
								setPaid(true);
								setProcessing(false);
								break;
							default:
								setProcessing(false);
								break;
						}
					});
				} catch (error) {
					setError(error);
					setProcessing(false);
				}
			}
		}
	};

	const reset = () => {
		setError(null);
		setProcessing(false);
	};

	return (
		<div>
			<Form className="checkout-form">
				<FormGroup>
					<Label>Card information</Label>
					<div className="checkout-card-input">
						<CardElement
							onChange={(e) => {
								e.error ? setError(e.error) : setError(null);
								setCardComplete(e.complete);
							}}
						/>
					</div>
				</FormGroup>
				{error ? <div style={{ color: "#e85a73" }}>{error.message}</div> : <></>}
				<div className={"d-flex justify-content-center align-items-center"}>
					<Button
						disabled={processing || !cardComplete || paid}
						className="complete-payment"
						onClick={handleSubmit}
					>
						{processing ? <Loader className="fa-spin mr-2" size={18} color="#fff" /> : <></>}
						{processing ? "Processing Payment" : "Submit"}
					</Button>
				</div>
			</Form>
			<StripeClimate />
		</div>
	);
};

interface IProps {
	active?: SUBSCRIPTION;
	company_id: string | undefined;
	invoiceId: string;
	onSuccess: () => void;
	getCompanyInvoices: () => Promise<any>;
	confirmPayment: (id: string) => Promise<any>;
}

const InvoicePaymentModal: React.FC<IProps> = ({
	active,
	company_id,
	invoiceId,
	onSuccess,
	getCompanyInvoices,
	confirmPayment
}) => {
	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

	const handlePaymentSuccess = (id?: string) => {
		if (!id) {
			onSuccess();
			getCompanyInvoices();
			return;
		}
		// confirm authorized card payment if an card authorization was required
		confirmPayment(id).then((res: any) => {
			switch (res.status) {
				case 500:
					return res;
				case 200:
					onSuccess();
					getCompanyInvoices();
					return res;
				case "requires_action":
					//authorizePaymentCallback
					break;
			}
		});
	};

	return (
		<div className={"checkout-container"}>
			<Elements stripe={stripePromise}>
				<CheckoutForm
					onSuccess={handlePaymentSuccess}
					invoiceId={invoiceId}
					companyId={company_id}
				/>
			</Elements>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	company_id: state.user.profile?.company.id,
	companyInvoices: state.billing.user_invoices
});

const mapDispatchToProps = {
	getCompanyInvoices: () => paymentService.getCompanyInvoices(),
	confirmPayment: (id: string) => paymentService.confirmPayment(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePaymentModal);
