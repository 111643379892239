import { ICompany } from "../../interfaces/company";
import { ActionType } from "../actions/action";
import { company } from "../actions/actionContants";

export interface ICompanyState {
	companies: ICompany[];
}

const INIT_STATE: ICompanyState = {
	companies: []
};

export default function (
	state: ICompanyState = INIT_STATE,
	action: ActionType
) {
	switch (action.type) {
		case company.GET_COMPANY_RESPONSE:
			return {
				...state,
				companies: action.payload
			};
		default:
			return state;
	}
}
