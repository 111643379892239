import {
	IGetPackagingSearchReponse,
	IGetPaginatedSearchResponse,
	IGetSearchResponse,
	ISearchPortfolioProductsResponse,
	ISetPackageSearchLoading,
	ISetPackageSearchTerm,
	ISetSearchFilter,
	ISetSearchLoading,
	ISetSearchTerm
} from "./search.d";
import { search } from "../actionContants";
import { ISearchIngredient } from "../../../interfaces/ingredient";
import { IPaginateList } from "../../../interfaces/pagination";
import { IPackage } from "../../../interfaces/package";
import { IProduct } from "../../../interfaces/products";

export function getSearchResponseAction(payload: any, term: string): IGetSearchResponse {
	return {
		type: search.SEARCH_FILTER_RESPONSE,
		payload,
		term
	};
}

export function getPaginatedSearchResultsResponseAction(
	payload: IPaginateList<ISearchIngredient[]>,
	filter?: string
): IGetPaginatedSearchResponse {
	return {
		type: search.PAGINATE_SEARCH_RESULTS,
		payload,
		filter
	};
}

export function getPackagingSearchResponseAction(
	payload: IPaginateList<IPackage[]>,
	filter?: string
): IGetPackagingSearchReponse {
	return {
		type: search.PACKAGING_SEARCH_RESPONSE,
		payload,
		filter
	};
}

export function setSearchFilterAction(filter: string | undefined): ISetSearchFilter {
	return {
		type: search.SET_SEARCH_FILTER,
		filter
	};
}

export function setSearchTermAction(term: string): ISetSearchTerm {
	return {
		type: search.SET_SEARCH_TERM,
		term
	};
}

export function setSearchLoadingAction(loading: boolean): ISetSearchLoading {
	return {
		type: search.SET_SEARCH_LOADING,
		loading
	};
}

export function setPackageSearchTermAction(term: string): ISetPackageSearchTerm {
	return {
		type: search.SET_PACKAGE_SEARCH_TERM,
		term
	};
}

export function setPackageSearchLoadingAction(loading: boolean): ISetPackageSearchLoading {
	return {
		type: search.SET_PACKAGE_SEARCH_LOADING,
		loading
	};
}

export function searchPortfolioProductsResponseAction(
	payload: IProduct[]
): ISearchPortfolioProductsResponse {
	return {
		type: search.PORTFOLIO_PRODUCTS_SEARCH_RESPONSE,
		payload
	};
}
