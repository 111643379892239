import React from "react";

import { Switch, Redirect, Route } from "react-router-dom";
import { routes } from "../routes";
import Tabs from "../../components/tab";

import Notifications from "./notifications";

const NotificationsRouter: React.FC = () => (
  <div className="notifications-page">
      {/* <Tabs tabs={[
        {
          name: "All",
          route: routes.NOTIFICATIONS,
        },
        {
          name: "Products",
          route: routes.NOTIFICATIONS_PRODUCTS
        },
        {
          name: "Ingredients",
          route: routes.NOTIFICATIONS_INGREDIENTS
        },
        {
          name: "Users",
          route: routes.NOTIFICATIONS_USERS
        }
      ]} /> */}
      <div className="py-3">
        <switch>
          <Route exact path={routes.NOTIFICATIONS} component={Notifications} />
          {/* <Route exact path={routes.NOTIFICATIONS_PRODUCTS} component={Notifications} />
          <Route exact path={routes.NOTIFICATIONS_INGREDIENTS} component={Notifications} />
          <Route exact path={routes.NOTIFICATIONS_USERS} component={Notifications} /> */}
        </switch>
      </div>
  </div>
)

export default NotificationsRouter;