import React from "react";
import {
	FaCcVisa,
	FaCcMastercard,
	FaCcAmex,
	FaCcDinersClub,
	FaCcDiscover,
	FaCcJcb
} from "react-icons/fa";
import { BsCreditCardFill } from "react-icons/bs";

export const getCreditCardIcon = (brand: string) => {
	const cardStyles = {
		size: "28px"
	};
	switch (brand) {
		case "visa":
			return <FaCcVisa title="Visa Icon" {...cardStyles} />;
		case "mastercard":
			return <FaCcMastercard title="Mastercard Icon" {...cardStyles} />;
		case "amex":
			return <FaCcAmex title="American Express Icon" {...cardStyles} />;
		case "diners":
			return <FaCcDinersClub title="Diners Club Icon" {...cardStyles} />;
		case "discover":
			return <FaCcDiscover title="Discover Icon" {...cardStyles} />;
		case "jcb":
			return <FaCcJcb title="Jcb Icon" {...cardStyles} />;
		default:
			return <BsCreditCardFill title="Credit Card Icon" {...cardStyles} />;
	}
};
