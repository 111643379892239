import { company } from "../actionContants";
import { IGetCompanyResponseAction } from "./company.d";
import { ICompany } from "../../../interfaces/company";

export function GetCompanyResponseAction(
	payload: ICompany[]
): IGetCompanyResponseAction {
	return {
		type: company.GET_COMPANY_RESPONSE,
		payload
	};
}
