import React from "react";
import { withFormik, InjectedFormikProps } from "formik";
import LoginFormElements, { ILoginFormElementsValues } from "./loginFormElements";
import { Form } from "reactstrap";
import { connect } from "react-redux";
import { LoginSchema } from "../../../../validations/loginValidation";
import Button from "../../../../components/common/button";
import userService from "../../../../services/userService";
import { ILoginRequest, ILoginUserResponse } from "../../../../interfaces/user";

interface ILoginFormProps {
	updatePhoneNumber: (number: string) => void;
	secondFactorRequiredCallback: (mfaResolver: any) => void;
	login: (
		request: ILoginRequest,
		resolve?: Function,
		reject?: Function
	) => Promise<ILoginUserResponse>;
	loginWithFirebase: (request: ILoginRequest) => Promise<any>;
}

const LoginForm: React.FC<InjectedFormikProps<ILoginFormProps, ILoginFormElementsValues>> = (
	props
) => {
	return (
		<Form onSubmit={props.handleSubmit}>
			<div className="header">
				<h1>Login</h1>
			</div>

			<LoginFormElements {...props} />

			{/* 
			<Button
				type="submit"
				className="btn-green color-white"
				style={{ color: "white" }}
				loading={props.isSubmitting}
				disabled={!props.isValid}
			>
				Login
			</Button> */}

			<div className="btn-container">
				<Button
					type="submit"
					className="color-whit"
					size="lg"
					style={{ color: "white" }}
					loading={props.isSubmitting}
					disabled={!props.isValid}
				>
					Login
				</Button>
			</div>
		</Form>
	);
};
// const LoginForm: React.FC<
//     InjectedFormikProps<ILoginFormProps, ILoginFormElementsValues>
// > = (props) => {

//     return (
//         <Form onSubmit={props.handleSubmit}>
//             <div className="header">
// 				<h1>Create Account </h1>
// 			</div>
//             <LoginFormElements {...props} />
//             <div className="btn-container">
//                 <Button type="submit" className="sbm-button" 	size="lg" style={{color: "white"}} loading={props.isSubmitting} disabled={!props.isValid}>Login</Button>
//             </div>
//         </Form>
//     )
// }

const WrappedForm = withFormik<ILoginFormProps, ILoginFormElementsValues>({
	enableReinitialize: true,
	validationSchema: LoginSchema,
	mapPropsToValues: () => ({
		username: "",
		password: ""
	}),

	handleSubmit: async ({ username, password }, { props, setSubmitting }) => {
		setSubmitting(true);
		await props.loginWithFirebase({ username, password }).then(async (res) => {
			console.log("loginWithFirebase", res);

			if (res?.a?.mfaInfo?.[0]?.phoneInfo) {
				props.updatePhoneNumber(res?.a?.mfaInfo?.[0]?.phoneInfo);
			}
			if (res && res.code == "auth/multi-factor-auth-required") {
				props.secondFactorRequiredCallback(res.resolver);
			} else {
				console.log("login");

				await props.login({
					password,
					username
				});
			}
		});

		setSubmitting(false);
	}
});

const mapDispatchToProps = (dispatch: any) => ({
	login: ({ username, password }: ILoginRequest, resolve?: Function, reject?: Function) =>
		dispatch(userService.login(username, password, resolve, reject)),
	loginWithFirebase: ({ username, password }: ILoginRequest) =>
		dispatch(userService.loginWithFirebase(username, password))
});

export default connect(null, mapDispatchToProps)(WrappedForm(LoginForm));
