import React, { useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../../redux/reducers";
import { INTEGRATIONS } from "../../../integrations";
import { IIntegration } from "../../../../../interfaces/integration";
import integrationService from "../../../../../services/integrationService";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { routes } from "../../../../routes";
import Tabs from "../../../../../components/tab";
import DataSources from "./dataSources";
import Markets from "../../../../../routes/analysis/markets";
import UserRequests from "../userRequests";

interface IProps extends RouteComponentProps {
	integrations: IIntegration[];
	profile: any;
	getIntegrations: () => Promise<any>;
}

const Integration: React.FC<IProps> = (props) => {
	const csv_integration = INTEGRATIONS.find((integration) => integration.name === "CSV");

	useEffect(() => {
		props.getIntegrations();
	}, []);

	return (
		<>
			<div className="page-content container-fluid position-relative">
				<Tabs
					tabs={[
						{
							name: "Data Source Connections",
							route: routes.INTEGRATIONS_SOURCES
						},
						{
							name: "Markets",
							route: routes.INTEGRATIONS_MARKETS
						},
						// {
						//     name: "Partner Integrations",
						//     route: routes.INTEGRATIONS_PARTNERS,
						// },
						// {
						//   name: "Perks",
						//   route: routes.INTEGRATIONS_PERKS,
						// },
						{ name: "Requests", route: routes.INTEGRATIONS_REQUESTS }
					]}
				/>
				<Switch>
					<Route exact path={routes.INTEGRATIONS_SOURCES} component={DataSources} />
					<Route exact path={routes.INTEGRATIONS_MARKETS} component={Markets} />
					<Route exact path={routes.INTEGRATIONS_PERKS} component={DataSources} />
					<Route exact path={routes.INTEGRATIONS_PARTNERS} component={DataSources} />
					<Route exact path={routes.INTEGRATIONS_REQUESTS} component={UserRequests} />
					<Redirect to={routes.INTEGRATIONS_SOURCES} from={routes.INTEGRATIONS} />
				</Switch>
			</div>
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => {
	return {
		integrations: state.integration.integrations,
		profile: state.user.profile
	};
};

const mapDispatchToProps = {
	getIntegrations: () => integrationService.getIntegrations()
};

export default connect(mapStateToProps, mapDispatchToProps)(Integration);
