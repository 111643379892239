import React, { useEffect, useState } from "react";
import { Box, X } from "react-feather";

interface IProps {
	prefs: any;
	onRemove: (pref: any) => void;
}

const SelectedProductPreferences: React.FC<IProps> = ({ prefs, onRemove, ...props }) => {
	const [beveragePrefs, setBeveragePrefs] = useState<any[]>();
	const [foodPrefs, setFoodPrefs] = useState<any[]>();
	const [formPrefs, setFormPrefs] = useState<any[]>();

	useEffect(() => {
		organizePrefs();
	}, []);

	useEffect(() => {
		organizePrefs();
	}, [prefs]);

	const organizePrefs = () => {
		const preferences = prefs?.preferences;
		if (preferences) {
			const filtered = preferences.filter((p: any) =>
				["Beverages", "Food", "Form"].includes(p.category)
			);
			setBeveragePrefs(filtered.filter((p: any) => p.category == "Beverages"));
			setFoodPrefs(filtered.filter((p: any) => p.category == "Food"));
			setFormPrefs(filtered.filter((p: any) => p.category == "Form"));
		}
	};

	return (
		<div className="selected-product-pref">
			{beveragePrefs && beveragePrefs.length > 0 && (
				<div className="selected-pref-section">
					<label className="color-battleship-grey">Beverages</label>
					{beveragePrefs.map((pref: any) => (
						<div key={pref.id} className="selected-pref">
							<span className="remove-pref" onClick={() => onRemove(pref)}>
								<X size={12} />
							</span>
							<div className="category">
								<Box style={{ marginRight: 10 }} size={23} />
								{pref.title}
							</div>
						</div>
					))}
				</div>
			)}
			{foodPrefs && foodPrefs.length > 0 && (
				<div className="selected-pref-section">
					<label className="color-battleship-grey">Food</label>
					{foodPrefs.map((pref: any) => (
						<div key={pref.id} className="selected-pref">
							<span className="remove-pref" onClick={() => onRemove(pref)}>
								<X size={12} />
							</span>
							<div className="category">
								<Box style={{ marginRight: 10 }} size={23} />
								{pref.title}
							</div>
						</div>
					))}
				</div>
			)}
			{formPrefs && formPrefs.length > 0 && (
				<div className="selected-pref-section">
					<label className="color-battleship-grey">Form</label>
					{formPrefs.map((pref: any) => (
						<div key={pref.id} className="selected-pref">
							<span className="remove-pref" onClick={() => onRemove(pref)}>
								<X size={12} />
							</span>
							<div className="category">
								<Box style={{ marginRight: 10 }} size={23} />
								{pref.title}
							</div>
						</div>
					))}
				</div>
			)}
			{/* {preferences
				?.filter((p: any) => ["Beverages", "Food", "Form"].includes(p.category))
				.map((pref: any) => (
					<div className="selected-pref">
						<span className="remove-pref" onClick={() => onRemove(pref)}>
							<X size={12} />
						</span>
						<div className="category">
							<Box style={{ marginRight: 10 }} size={23} />
							{pref.title}
						</div>
					</div>
				))} */}
		</div>
	);
};

export default SelectedProductPreferences;
