import React, { useEffect, useState } from "react";
import { withFormik, InjectedFormikProps } from "formik";
import { connect } from "react-redux";
import { Form } from "reactstrap";
import ProductFormElements, { IProductFormElements } from "./productFormElements";
import Button from "../../../../components/common/button";
import { Save, Trash } from "react-feather";
import { IProduct } from "../../../../interfaces/products";
import companyService from "../../../../services/companyService";
import moment from "moment";
import { ProductValidation } from "../../../../validations/productValidation";
import adminService from "../../../../services/adminService";
import { ICompany } from "../../../../interfaces/company";
import { IApplicationState } from "../../../../redux/reducers";
import productService from "../../../../services/productService";
import ingredientService from "../../../../services/ingredientService";
import { formatProductFormData } from "../../../../utils/product";

interface IEditProductFormProps {
	companies: ICompany[];
	product: IProduct | null;
	isAdmin?: boolean;
	edit: (productId: string, productData: any, isAdmin?: boolean) => Promise<any>;
	getCompanies: () => any;
	searchIngredient: (name: string) => Promise<any>;
	toggleForm: () => void;
	isOpen?: boolean;
	onRemoveHandler?: () => void;
	createNewProductVersion: (masterProductId: string, product: IProduct) => Promise<any>;
}

const EditProductForm: React.FC<
	InjectedFormikProps<IEditProductFormProps, IProductFormElements>
> = (props) => {
	const [buildingVersion, setBuildingVersion] = useState<boolean>(false);

	useEffect(() => {
		if (props.isAdmin) {
			props.getCompanies();
		}
	}, []);

	useEffect(props.resetForm, [props.isOpen]);

	const onCreateNewProductVersion = () => {
		if (props.product?.master_product_id) {
			setBuildingVersion(true);

			// Data formatted product data to hopefully pass to create new version so that saving a new version allows for product form updates to take effect
			const data = formatProductFormData(props.values, props?.product);

			props
				// .createNewProductVersion(props.product?.master_product_id, props?.product)
				.createNewProductVersion(props.product?.master_product_id, data)
				.finally(() => {
					setBuildingVersion(false);
					window.location.reload();
				});
		}
	};

	return (
		<Form className="product-form">
			<ProductFormElements {...props} />
			<br />
			<hr />
			<span className="save">
				{props.onRemoveHandler && (
					<Button
						className="color-white btn-danger mr-2"
						type="button"
						color="primary"
						onClick={() => (props.onRemoveHandler ? props.onRemoveHandler() : null)}
					>
						<Trash size={18} className="mr-2" />
						Remove
					</Button>
				)}

				<Button
					className="color-white mr-2"
					type="button"
					color="primary"
					disabled={buildingVersion}
					onClick={onCreateNewProductVersion}
				>
					<Save size={18} className="mr-2" />
					{buildingVersion ? "Saving New Version" : "Save New Version"}
				</Button>
				<Button
					className="color-white"
					type="button"
					onClick={props.handleSubmit}
					loading={props.isSubmitting}
					disabled={props.isSubmitting}
				>
					<Save size={18} className="mr-2" />
					Save Detail
				</Button>
			</span>
		</Form>
	);
};

const WrappedForm = withFormik<IEditProductFormProps, IProductFormElements>({
	mapPropsToValues: ({ product }) => ({
		cost: product?.cost || 0,
		markup: product?.markup || 0,
		cost_margin: product?.cost_margin || 0,
		name: product?.name || "",
		company: product?.company ? { label: product.company.name, value: product.company } : null,
		serving_size: product?.serving_size || 0,
		unit_serving_size: product?.unit_serving_size || "g",
		fulfillment_date: moment(product?.fulfillment_date).format("YYYY-MM-DD"),
		created_date: moment(product?.created_date).format("YYYY-MM-DD"),
		manufacturer: product?.manufacturer
			? {
					label: product?.manufacturer.name,
					value: product?.manufacturer.id
			  }
			: null,
		product_type: product?.product_type
			? {
					label: product?.product_type.title,
					value: {
						category: product?.product_type.category,
						title: product?.product_type.title,
						id: product?.product_type.id
					}
			  }
			: null,
		notes: product?.notes || "",
		product_status: {
			label: product?.product_status ? "Active" : "Concept",
			value: product?.product_status || false
		},
		ingredients: product?.ingredients?.length
			? product.ingredients.map((ingredient) => ({
					...ingredient,
					unit: {
						label: ingredient.unit,
						value: ingredient.unit
					}
			  }))
			: [],
		flavor: product?.flavor || "",
		country: product?.country
			? {
					label: product?.country.name || "",
					value: product?.country.id || ""
			  }
			: null,
		currency: product?.currency
			? {
					label: product?.currency.name || "",
					value: product?.currency.id || ""
			  }
			: null,
		brand: product?.brand
			? {
					label: product?.brand.name || "",
					value: product?.brand.id || ""
			  }
			: null,
		code: product?.code || "",
		packaging: product?.packaging,
		objective:
			product?.objective?.map((obj: string) => ({
				label: obj,
				value: obj.toLowerCase()
			})) || []
	}),
	handleSubmit: async function (values, { props }) {
		try {
			const data = formatProductFormData(values, props?.product);
			if (props.product) {
				await props.edit(props.product.id, data, props.isAdmin).then(props.toggleForm);
			}
		} catch (error) {}
	},
	validationSchema: ProductValidation
});

const mapStateToProps = (state: IApplicationState, props: any) => ({
	companies: state.company.companies,
	company: state.user.profile?.company || {},
	product: props.isAdmin ? state.admin.products.selected : state.product.selected
});

const mapDispatchToProps = {
	edit: (productId: string, productData: IProduct, isAdmin?: boolean) => {
		if (isAdmin) {
			return adminService.editAdminProduct(productId, productData);
		}
		return productService.editProduct(productId, productData);
	},
	getCompanies: () => companyService.getCompanies(),
	searchIngredient: (name: string) => ingredientService.searchIngredient(name),
	createNewProductVersion: (masterProductId: string, product: IProduct) =>
		productService.createNewProductVersion(masterProductId, product)
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm(EditProductForm));
