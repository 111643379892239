import React, { useEffect, useState } from "react";
import LoginForm from "./form/loginForm";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";
import { AuthState, IUserState } from "../../../redux/reducers/userReducer";
import VerificationCode from "./verificationCode/verificationCode";
import firebase from "firebase";
import userService from "../../../services/userService";
import { error as notificationError } from "../../../utils/notification";

enum AuthStep {
	EMAIL_AND_PASSWORD = "EMAIL_AND_PASSWORD",
	SMS = "SMS"
}

interface IProps {
	user: IUserState;
	signUserInWithMFAToken: (refreshToken: string) => Promise<void>;
}

const Login: React.FC<IProps> = ({ user, ...props }) => {
	const [authStep, setAuthStep] = useState<AuthStep>(AuthStep.EMAIL_AND_PASSWORD);
	const [phoneNumber, setPhoneNumber] = useState("");

	const [mfaResolver, setMfaResolver] = useState<any>(() => {});

	useEffect(() => {
		if (user.auth_state === AuthState.TWO_FACTOR_AUTH) setAuthStep(AuthStep.SMS);
	}, [user.auth_state]);

	const secondFactorRequiredCallback = (mfaResolver: any) => {
		setMfaResolver(mfaResolver);
		setAuthStep(AuthStep.SMS);
	};

	const onHandleBack = () => {
		setAuthStep(AuthStep.EMAIL_AND_PASSWORD);
	};

	const onSubmitVerificationCode = async (verificationId: string, verificationCode: string) => {
		var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
		var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
		mfaResolver
			.resolveSignIn(multiFactorAssertion)
			.then((userCredential: any) => {
				//Do something with the returned userCredential
				const refreshToken = userCredential.user.refreshToken;
				props.signUserInWithMFAToken(refreshToken);
			})
			.catch((error: any) => {
				if (error.code === "auth/invalid-verification-code") {
					notificationError({
						title: "Invalid code",
						description: "Please ensure you have entered the correct code"
					});
				}
			});
	};

	const renderAuthStepView = () => {
		switch (authStep) {
			case AuthStep.EMAIL_AND_PASSWORD:
				return (
					<>
						<LoginForm
							updatePhoneNumber={(number: string) => {
								setPhoneNumber(number);
							}}
							secondFactorRequiredCallback={secondFactorRequiredCallback}
						/>
						<div className="login-action">
							<Link to={routes.FORGOT_PASSWORD}>Forgot password?</Link>
						</div>
					</>
				);
			case AuthStep.SMS:
				return (
					<VerificationCode
						mfaResolver={mfaResolver}
						phoneNumber={phoneNumber}
						onSubmitVerificationCode={onSubmitVerificationCode}
						onBack={onHandleBack}
						showRedirect={true}
					/>
				);
			default:
				return (
					<LoginForm
						updatePhoneNumber={(number: string) => {
							setPhoneNumber(number);
						}}
						secondFactorRequiredCallback={secondFactorRequiredCallback}
					/>
				);
		}
	};

	return <div className="login">{renderAuthStepView()}</div>;
};

const mapStateToProps = (state: IApplicationState) => ({
	user: state.user
});

const mapDispatchToProps = {
	signUserInWithMFAToken: (refreshToken: string) => userService.signUserInWithMFAToken(refreshToken)
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
