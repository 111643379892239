export const FoodProductTemplateDescriptions = [
	{
		column_header: "Journey Foods ID",
		description: "Our database will generate a qunique Journey Foods ID for each product.",
		data_requirements: "This column should be left as blank"
	},
	{
		column_header: "product_code",
		description:
			"The original product ID in your own database. It will be used to match the product ingredient information in the Recipe table.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "product_name",
		description: "The name of your product. For example, Organic Fruit Bar.",
		data_requirements: "Required. Can't be blank or missing."
	},
	{
		column_header: "company",
		description: "Company name.",
		data_requirements: "Required. Can't be blank or missing."
	},
	{
		column_header: "brand (company)",
		description: "The brand of your product.",
		data_requirements: "This column should be left as blank"
	},
	{
		column_header: "manufacturer",
		description: "The manufacturer of your product",
		data_requirements: "Required, but can be blank if your product is still a concept"
	},
	{
		column_header: "image_url",
		description: "The url link of your product image",
		data_requirements: "Not required "
	},
	{
		column_header: "product_form",
		description:
			"The form of your product. The values must be from the list: Solid, Liquid, Paste, Puree, Sauce, Solid, Capsule, Powder, Fragile",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "product_type",
		description: "The categories of your product. Comma separated",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "allergen",
		description:
			"The allergen of your product. The values must be from the list: Milk, Eggs, Fish, Shellfish, Tree Nuts, Peanuts, Wheat, Soybeans, Crustaceans, Mustard Seeds",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "certification",
		description: "The values must be from the list: Natural, Non-GMO, Organic, Sustainable",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "serving_size",
		description: "The serving size of your product",
		data_requirements: "Required. Can't be blank or missing. It must be a numeric value"
	},
	{
		column_header: "unit_serving_size",
		description: "The unit of the serving size",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "total_weight",
		description: "The total weight of your product",
		data_requirements: "Required, but can be blank. It must be a numeric value"
	},
	{
		column_header: "unit_weight",
		description: "The unit of the products weight",
		data_requirements: "Required, but can be blank."
	},
	{
		column_header: "product_status",
		description:
			"The status of your product. If your product is already produced, the value is 'Retail'; Other 'Concept'",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "flavor",
		description: "The flavor of your product",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "manufacturing_country",
		description:
			"The country where your product was or will be produced. Please use the full country name such as: United States, United Kingdom etc.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "purchase_country",
		description:
			"The country where your product is or will be purchased. Please use the full country name, such as, United States, United Kingdom etc.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "special_customer",
		description:
			"The special customer type, if any. Must be from the list: Baby, Toddler, Children, Pregnant, Senior",
		data_requirements: "Required, but can be blank where there are no special types of customers"
	},
	{
		column_header: "date_created",
		description:
			"The date when the idea of your product was created. The date format is MM/DD/YYYY",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "date_fulfilled",
		description: "The date when your product was produced. The date format is MM/DD/YYYY",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "cost",
		description: "The total cost of your product",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "markup_price",
		description:
			"The markup price is the difference between a product's selling price and the cost as a percentage of the cost. Only keep the number without the % sign. For exampple: 6.5% should be entered as 6.5",
		data_requirements: "Required, but can can be black when your product is still a concept"
	},
	{
		column_header: "currency",
		description: "The currency of your product price, such as US dollar, Canada dollar, Euro, etc.",
		data_requirements: "Required, can't be blank if your markup price is not blank"
	},
	{
		column_header: "Packaging Name",
		description: "The name of the package used for your product",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Packaging Manufacturer",
		description: "The name of the manufacturer of your product packaging",
		data_requirements: "Required, but can be blank"
	}
];

export const ProductRecipeTemplateDescriptions = [
	{
		column_header: "Journey Foods ID",
		description: "Our database will generate a qunique Journey Foods ID for each product",
		data_requirements: "This column should be left as blank"
	},
	{
		column_header: "Product Code",
		description:
			"The original product ID in your own database. It must be the same with the product_code in the Food Products file",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Ingredient Name",
		description:
			"The name of the ingredient used in your product. Each row stands for one ingredient used in a certain product e.g. Product A",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Ingredient Manufacturer",
		description: "The manufacturer of the ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Weight",
		description: "The weight of the ingredient used in one unit of the associated product",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Unit of Weight",
		description: "The unit of the weight. Such as mg, g, kg, oz, etc.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "% of Total Weight",
		description:
			"The percentage of a certain ingredient used in the associated product. value = (weight of ingredient/total weight)*100",
		data_requirements:
			"Required. Can't be blank or missing. It must be a numeric value with two decimals"
	},
	{
		column_header: "Price/Unit",
		description: "The price per unit of the ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Currency",
		description: "The currency of the price. Such as USD, CAD, EUR etc.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Unit of Price",
		description: "The unit of the product for the price. Such as mg, g, kg, oz etc.",
		data_requirements: "Required, but can be blank"
	}
];

export const IngredientTemplateDescriptions = [
	{
		column_header: "Journey Foods ID",
		description: "Our database will generate a qunique Journey Foods ID for each product",
		data_requirements: "This column should be left as blank"
	},
	{
		column_header: "Original Code",
		description: "The original id of your ingredient",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Ingredient Name",
		description: "The name of the ingredient",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Image url",
		description: "The url link of the image of your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Manufacturer",
		description: "The manufacturer of your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Country",
		description:
			"The country in which your ingredient was produced. Please use a full country name such e.g. United States, United Kingdom etc.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Unit Price",
		description: "The price of your ingredient per unit",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Unit of Weight",
		description: "The unit of weight based on 1 unit. Such as mg, g, kg, oz, etc.",
		data_requirements: "Required, but can be missing."
	},
	{
		column_header: "Currency",
		description: "The currency of the price e.g USD, CAD, EUR etc.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Alternative Names",
		description: "The alternative name of your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Scientific Name",
		description: "The scientific name of your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Ingredient Categories",
		description:
			"The ingredient categories that your ingredient belongs to. Such as: emulsifier, legume, sweetener, high heat, etc.",
		data_requirements:
			"Required. Can't be blank or missing. Data Type: text or array e.g. 'emulsifier' or [emulsifier, sweetener, ...] for more than one category "
	},
	{
		column_header: "Storage Conditions",
		description: "The storage conditions for your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Allergens",
		description:
			"The allergen of your ingredient. The values must be from the list: Milk, Eggs, Fish, Tree Nuts, Peanuts, Wheat, Soybeans, Crustaceans, Mustard seeds",
		data_requirements: "Required, but can be blank. Data Type: text or array."
	},
	{
		column_header: "Certification",
		description:
			"The certification of your ingredient. The values must be from the list: Natural, Non-GMO, USDA Organic, Organic, Sustainable, Fair Trade, Gluten-Free, Peanut-Free, Non-MSG, Made in America, Halal, Kosher, Vegan, Keto, Whole30",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Shelf Life",
		description: "The shelf life of your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Upcycled",
		description:
			"Upcycled ingredients are ingredients that otherwise would not have gone to human consumption. They are procured and produced by using verifiable supply chains, and have a positive impact on the environment (Upcycled Food Association)",
		data_requirements: "Required, but can be blank. Please input 'Yes' or 'No'"
	},
	{
		column_header: "Water Footprint",
		description: "The amount of fresh water that any given process or activity uses",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "GreenHouse Gas Emissions",
		description: "The greenhouse gas emissions that any given process or activity generates",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Acidification",
		description: "The acidification of your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Eutrophication",
		description: "The eutrophication of your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Land Use",
		description: "The total land used to produce your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Nutrition Source",
		description: "The nutrition source for your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Serving Size",
		description: "The serving size of your ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Engergy (kJ)",
		description: "The energy (kJ) per 100g of this ingredient",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Energy (kcal)",
		description: "The energy (kcal) per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Protein (g)",
		description: "The weight of the protein per 100g of food",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Total lipid (fat) (g)",
		description: "The total fat per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Carbohydrate, by difference (g)",
		description:
			"The sum of nutritionally available carbohydrates (dextrins, starches, and sugars), nutritionally unavailable carbohydrate (pentosans, pectins, hemicelluloses, and cellulose), and non-carbohydrates such as organic acids and lignins per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Fiber, total dietary (g)",
		description: "AOAC fibre per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Sugars, total (g)",
		description: "The total amount of sugar per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Calcium, Ca (mg)",
		description: "The calcium per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Vitamin D (D2 + D3) µg",
		description: "Total Vitamin D per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Vitamin C, total ascorbic acid (mg)",
		description: "The Vitamin C per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Cholesterol (mg)",
		description: "The cholesterol per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Fatty acids, total saturated (g)",
		description: "The total saturated fatty acids excluding branch per 100g food",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Fatty acids, total trans (g)",
		description: "The total trans fatty acids per 100g fatty acids",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Iron, Fe (mg)",
		description: "The amount of iron per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Potassium, K (mg)",
		description: "The amount of potassium per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Sodium, Na (mg)",
		description: "The amount of sodium per 100g food.",
		data_requirements: "Required. Can't be blank or missing"
	},
	{
		column_header: "Vitamin E (alpha-tocopherol) (mg)",
		description: "The amount of sodium per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Tocopherol, beta (mg)",
		description: "Beta-tocopherol per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Tocopherol, gamma (mg)",
		description: "Gamma-tocopherol per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Tocopherol, delta (mg)",
		description: "Delta-tocopherol per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Vitamin K (phylloquinone) µg",
		description: "Phylloquinone per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "4:0 (g)",
		description: "Butanoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "6:0 (g)",
		description: "Hexanoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "8:0 (g)",
		description: "Octanoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "10:0 (g)",
		description: "Decanoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "12:0 (g)",
		description: "Dodecanoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "14:0 (g)",
		description: "Tetradecanoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "16:0 (g)",
		description: "Hexadecanoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "18:0 (g)",
		description: "Octadecanoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Fatty acids, total monounsaturated (g)",
		description: "Monounsaturated fatty acids per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "16:1 undifferentiated (g)",
		description: "Hexadecenoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "18:1 undifferentiated (g)",
		description: "Octadecenoic acid per 100g food; C18:1 n-9.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "20:01",
		description: "Eicosenoic acid per 100g food; C20:1 n-9.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "22:1 undifferentiated (g)",
		description: "Docosenoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "Fatty acids, total polyunsaturated (g)",
		description: "Polyunsaturated fatty acids per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "18:2 undifferentiated (g)",
		description: "Octadecadienoic acid per 100g food; conj-A",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "18:3 undifferentiated (g)",
		description: "Octadecatrienoic acid per 100g food; n-3",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "18:04",
		description: "Octadecatetraenoic acid per 100g food",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "20:4 undifferentiated (g)",
		description: "Eicosatetraenoic acid per 100g food; n-6",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "20:5 n-3 (EPA) (g)",
		description: "cis n-3 Eicosapentaenoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "22:5 n-3 (DPA) (g)",
		description: "cis n-3 Docosapentaenoic acid per 100g food.",
		data_requirements: "Required, but can be blank"
	},
	{
		column_header: "22:6 n-3 (DHA) (g)",
		description: "cis n-3 Docosahexaenoic acid (DHA) per 100g food.",
		data_requirements: "Required, but can be blank"
	}
];
