export const HexToRgba = function (hex: string, opacity: number): string {
	const tempHex = hex.replace("#", "");
	const R = parseInt(tempHex.substring(0, 2), 16);
	const G = parseInt(tempHex.substring(2, 4), 16);
	const B = parseInt(tempHex.substring(4, 6), 16);

	return `rgba(${R}, ${G}, ${B}, ${opacity})`;
};

const HEX_VALUES = `01234567890ABCDEF`;

export const getRandomHexColor = function (): string {
	let hex = "#";

	hex += HEX_VALUES[Math.floor(Math.random() * 16)];
	hex += HEX_VALUES[Math.floor(Math.random() * 16)];
	hex += HEX_VALUES[Math.floor(Math.random() * 16)];
	hex += HEX_VALUES[Math.floor(Math.random() * 16)];
	hex += HEX_VALUES[Math.floor(Math.random() * 16)];
	hex += HEX_VALUES[Math.floor(Math.random() * 16)];
	return hex;
};
