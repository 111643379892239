import React from "react";
import Header from "../../../components/common/header";
import { Row, Col } from "reactstrap";

class KB extends React.Component {
  render() {
    return (
      <>
        <Header />
        <data className="page-wrapper d-block">
          <div className="page-content container-fluid position-relative">
            <div className="knowledge-hub">
              <Row>

                {/* title */}
                <Col md="12">
                  <h3>Knowledge hub</h3>
                </Col>

                {/* left content */}
                <Col md="5" className="mt-4">
                  <ul className="list-style-none">
                    <li>
                      <h5>Common Name</h5>
                      <p>General/standardized ingredient name (apple, pear, etc.)</p>
                    </li>
                    <li>
                      <h5>Variety Name</h5>
                      <p>Variation of general/standardized ingredient (granny smith apple, bosc pear, etc.)</p>
                    </li>
                    <li>
                      <h5>Subcategory</h5>
                      <p>Name of food subcategory / form factor for sample product - a specific brand's version of a food category</p>
                    </li>
                    <li>
                      <h5>Flavor</h5>
                      <p>AKA Variation - the exact flavor of the product variation these ingredients come from</p>
                    </li>
                    <li>
                      <h5>Journey Code</h5>
                      <p>Numerical code for sample product, with prefix to indicate category</p>
                    </li>
                    <li>
                      <h5>Category Code</h5>
                      <p>List of prefixes used to create Journey Code for each sample product</p>
                    </li>
                    <li>
                      <h5>Base Ingredients</h5>
                      <p>List of potential ingredients that make up the "texture base" for each sample product</p>
                    </li>
                    <li>
                      <h5>Ingredient Code</h5>
                      <p>Numerical code, unique identifier to track thousands of products</p>
                    </li>
                  </ul>
                </Col>

                {/* right content */}
                <Col md="5" className="mt-4">
                  <ul className="list-style-none">
                    <li>
                      <h5>Dashboard</h5>
                      <p>Dashboard where user can edit and manage products. Features included on the profile include subscription upgrade, links to edit and add products, product formulation requests and reports.</p>
                    </li>
                    <li>
                      <h5>Product Page</h5>
                      <p>Page within the user dashboard that shows all existing user products with their name, image, size, and ingredient list.</p>
                    </li>
                    <li>
                      <h5>Product Settings</h5>
                      <p>Product count and product breakdown visualization are featured on page.</p>
                    </li>
                    <li>
                      <h5>Nutrition Page</h5>
                      <p>Option to request a nutrition label also provided</p>
                    </li>
                    <li>
                      <h5>Ingredients Page</h5>
                      <p>A curated list of top 100 ingredients with the option to subscribe to or add ingredients to watchlist.</p>
                    </li>
                    <li>
                      <h5>Explore</h5>
                      <p>A search bar is also featured on this page to search other ingredients in the database.  </p>
                    </li>
                    <li>
                      <h5>Functional Foods</h5>
                      <p>A food containing health-giving additives and having medicinal benefit (Chia, ancient grains, spices and herbs)</p>
                    </li>
                    <li>
                      <h5>Need more?</h5>
                      <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    </li>
                    <li className="color-bg-blue">
                      <p className="mb-0">By email: hello@journeyfoods.com</p>
                      <p>By phone number: (847)859-9833</p>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </data >
      </>
    )
  }
}


export default KB;


