import React from "react";
import { SnakeCaseToNormal } from "../../../utils/string";
import Button from "../../../components/common/button";

interface IPlans {
    price: number;
    details: string[];
}


interface IProps {
    type: string;
    plans: IPlans[];
    handleSelect?: () => void;
    active?: boolean;
}

function getBackgroundForSubscription(type: string): string {
    switch (type.toLowerCase()) {
        case "fresh":
            return "linear-gradient(to bottom, #89e4d6, var(--bg-green))";
        case "growth":
            return "linear-gradient(to bottom, #fad388, var(--bg-yellow))";

        case "enterprise":
            return "linear-gradient(to bottom, #c2cfda, var(--bluey-grey))";
    }

    return "linear-gradient(to bottom, #739ef9, var(--bg-blue))";

}


const SubscriptionItem: React.FC<IProps> = ({
    type, plans, handleSelect
}) => (
        <div className="subscription-item mt-3">
            <div className="name" style={{ backgroundImage: getBackgroundForSubscription(type) }}>
                <p>{SnakeCaseToNormal(type)}</p>
            </div>

            <div className="details d-flex">

                <div className="plans">
                    {
                        plans.map((plan, index) => (
                            <div className="plan px-3 d-flex flex-column" style={{
                                ...(
                                    plans.length > 1 && index !== plans.length - 1 ? { borderRight: "1px solid #CEDBE9" } : {}
                                )
                            }}>
                                <div className="flex-grow-1">
                                    <p className="price">${plan.price}</p>
                                    <ul className="mb-0">
                                        {
                                            plan.details.map((text) => <li>{text}</li>)
                                        }
                                    </ul>
                                </div>
                                <div>
                                    <Button onClick={handleSelect} outline color="primary">Select</Button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )

export default SubscriptionItem;