import React from "react";
import Header from "../../../components/common/header";
import Sidebar from "../../../components/common/sidebar";
import { Row, Col, Progress } from "reactstrap";

class IngredientItem extends React.Component {
	render() {
		return (
			<>
				<Header />
				<data className="page-wrapper d-block">
					<div className="page-content container-fluid">
						<div className="ingredient-single">
							{/* ingredient detail */}
							<section className="ingredient-single__details">
								<Row>
									<Col md="2">
										<img
											src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
											alt="Ingredient Image"
										/>
									</Col>
									<Col md="10">
										<Row className="justify-content-between">
											<Col md="5">
												<div className="ingredient-single__name">
													<div className="d-flex">
														<h1 className="mr-3 mb-0">Ingredient Name</h1>
														<span className="btn btn-lg btn-yellow font-weight-semibold d-flex align-items-center">
															<i className="far fa-star fa-sm mr-2"></i>
															Starred
														</span>
													</div>
													<h5 className="font-weight-normal mt-2">
														Latin version of the name / reference
													</h5>
												</div>
											</Col>
											<Col md="3">
												<div className="ingredient-single__integration d-flex align-items-center">
													<h5 className="font-weight-normal mb-0 mr-3">Integration</h5>
													<i className="fas fa-lg fa-share-alt mr-2"></i>
													<img
														src="https://logos-download.com/wp-content/uploads/2016/08/SAP_logo.png"
														alt="SAP logo"
														width="120px"
														height="50px"
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col md="11">
												<div className="border-radius-6 p-4 shadow mt-3">
													<Row>
														<Col md="4">
															<div>
																<h5 className="mb-3">Suitability scale:</h5>
																<Progress color="success" value="75" className="h-12" />
															</div>
														</Col>
														<Col md="4">
															<div>
																<h5 className="mb-3">Sustainability scale:</h5>
																<Progress color="success" value="75" className="h-12" />
															</div>
														</Col>
													</Row>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
							</section>

							{/* minerals proportion */}
							<ul className="row list-style-none mt-4">
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Calcium</h5>
										<Progress color="success" value="75" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Vitamin D</h5>
										<Progress color="success" value="75" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Vitamin C</h5>
										<Progress color="success" value="75" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Carbohydrates</h5>
										<Progress color="warning" value="65" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Total Fat</h5>
										<Progress color="warning" value="50" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Saturated Fat</h5>
										<Progress color="danger" value="90" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Trans Fat</h5>
										<Progress color="danger" value="80" />
									</div>
								</li>

								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Calories</h5>
										<Progress color="danger" value="75" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Sugar</h5>
										<Progress color="danger" value="75" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Sodium</h5>
										<Progress color="success" value="75" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Potassium</h5>
										<Progress color="danger" value="75" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Cholestrol</h5>
										<Progress color="danger" value="75" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Protein</h5>
										<Progress color="success" value="75" />
									</div>
								</li>
								<li className="col-md-3 mt-4">
									<div>
										<h5 className="font-weight-normal">Iron</h5>
										<Progress color="success" value="75" />
									</div>
								</li>
							</ul>

							{/* Similar Ingredients */}
							<Row className="mt-5">
								<Col md="11">
									<h4>Similar Ingredients</h4>
									<Row className="mt-4">
										<Col md="3">
											<div className="p-4 border-radius-6 shadow d-flex">
												<Col md="3" className="d-flex align-items-center justify-content-center">
													<img
														width="60px"
														height="60px"
														src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
														alt=""
													/>
												</Col>
												<Col>
													<h5>Ingredient Name</h5>
													<div className="d-flex justify-content-between">
														<div>
															<span>Popularity</span>
															<div className="mt-1">
																<i className="far fa-user mr-2 fa-lg"></i>
																<span className="h6 color-bg-blue">0.12</span>
															</div>
														</div>
														<div>
															<span>Similarity</span>
															<div className="mt-1">
																<i className="far fa-user mr-2 fa-lg"></i>
																<span className="h6 color-bg-blue">0.12</span>
															</div>
														</div>
													</div>
												</Col>
											</div>
										</Col>
										<Col md="3">
											<div className="p-4 border-radius-6 shadow d-flex">
												<Col md="3" className="d-flex align-items-center justify-content-center">
													<img
														width="60px"
														height="60px"
														src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
														alt=""
													/>
												</Col>
												<Col>
													<h5>Ingredient Name</h5>
													<div className="d-flex justify-content-between">
														<div>
															<span>Popularity</span>
															<div className="mt-1">
																<i className="far fa-user mr-2 fa-lg"></i>
																<span className="h6 color-bg-blue">0.12</span>
															</div>
														</div>
														<div>
															<span>Similarity</span>
															<div className="mt-1">
																<i className="far fa-user mr-2 fa-lg"></i>
																<span className="h6 color-bg-blue">0.12</span>
															</div>
														</div>
													</div>
												</Col>
											</div>
										</Col>
										<Col md="3">
											<div className="p-4 border-radius-6 shadow d-flex">
												<Col md="3" className="d-flex align-items-center justify-content-center">
													<img
														width="60px"
														height="60px"
														src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
														alt=""
													/>
												</Col>
												<Col>
													<h5>Ingredient Name</h5>
													<div className="d-flex justify-content-between">
														<div>
															<span>Popularity</span>
															<div className="mt-1">
																<i className="far fa-user mr-2 fa-lg"></i>
																<span className="h6 color-bg-blue">0.12</span>
															</div>
														</div>
														<div>
															<span>Similarity</span>
															<div className="mt-1">
																<i className="far fa-user mr-2 fa-lg"></i>
																<span className="h6 color-bg-blue">0.12</span>
															</div>
														</div>
													</div>
												</Col>
											</div>
										</Col>
										<Col md="3">
											<div className="p-4 border-radius-6 shadow d-flex">
												<Col md="3" className="d-flex align-items-center justify-content-center">
													<img
														width="60px"
														height="60px"
														src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
														alt=""
													/>
												</Col>
												<Col>
													<h5>Ingredient Name</h5>
													<div className="d-flex justify-content-between">
														<div>
															<span>Popularity</span>
															<div className="mt-1">
																<i className="far fa-user mr-2 fa-lg"></i>
																<span className="h6 color-bg-blue">0.12</span>
															</div>
														</div>
														<div>
															<span>Similarity</span>
															<div className="mt-1">
																<i className="far fa-user mr-2 fa-lg"></i>
																<span className="h6 color-bg-blue">0.12</span>
															</div>
														</div>
													</div>
												</Col>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>

							{/* Similar Ingredients */}
							<Row className="mt-5">
								<Col md="11">
									<h3 className="font-weight-semibold">Matched Personas</h3>
									<Row className="mt-4">
										<Col md="3">
											<div className="p-4 border-radius-6 shadow d-flex">
												<Col md="3" className="d-flex align-items-center justify-content-center">
													<img
														width="60px"
														height="60px"
														src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
														alt=""
													/>
												</Col>
												<Col>
													<h5>Persona One</h5>
													<div className="d-flex justify-content-between">
														<div>
															<span>Age: </span>
															<span>32</span>
														</div>
														<div>
															<span>Code: </span>
															<span className="color-bg-yellow">B1</span>
														</div>
													</div>
												</Col>
											</div>
										</Col>
										<Col md="3">
											<div className="p-4 border-radius-6 shadow d-flex">
												<Col md="3" className="d-flex align-items-center justify-content-center">
													<img
														width="60px"
														height="60px"
														src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
														alt=""
													/>
												</Col>
												<Col>
													<h5>Persona One</h5>
													<div className="d-flex justify-content-between">
														<div>
															<span>Age: </span>
															<span>32</span>
														</div>
														<div>
															<span>Code: </span>
															<span className="color-bg-yellow">B1</span>
														</div>
													</div>
												</Col>
											</div>
										</Col>
										<Col md="3">
											<div className="p-4 border-radius-6 shadow d-flex">
												<Col md="3" className="d-flex align-items-center justify-content-center">
													<img
														width="60px"
														height="60px"
														src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
														alt=""
													/>
												</Col>
												<Col>
													<h5>Persona One</h5>
													<div className="d-flex justify-content-between">
														<div>
															<span>Age: </span>
															<span>32</span>
														</div>
														<div>
															<span>Code: </span>
															<span className="color-bg-yellow">B1</span>
														</div>
													</div>
												</Col>
											</div>
										</Col>
										<Col md="3">
											<div className="p-4 border-radius-6 shadow d-flex">
												<Col md="3" className="d-flex align-items-center justify-content-center">
													<img
														width="60px"
														height="60px"
														src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
														alt=""
													/>
												</Col>
												<Col>
													<h5>Persona One</h5>
													<div className="d-flex justify-content-between">
														<div>
															<span>Age: </span>
															<span>32</span>
														</div>
														<div>
															<span>Code: </span>
															<span className="color-bg-yellow">B1</span>
														</div>
													</div>
												</Col>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>

							{/* Matched Personas */}
							<Row></Row>
						</div>
					</div>
				</data>
			</>
		);
	}
}

export default IngredientItem;
