import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import Router from "./routes/router";
import * as serviceWorker from "./serviceWorker";

import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/style.scss";
import "react-vis/dist/main.scss";
import "./assets/css/flag.min.css";

ReactDOM.render(
    <Provider store={configureStore()}>
        <Router />
    </Provider>,
    document.getElementById("root")
)

serviceWorker.unregister();