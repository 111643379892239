import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { ArrowDown, LogOut, X } from "react-feather";
import userService from "../../services/userService";
import { connect } from "react-redux";
import { IApplicationState } from "../../redux/reducers";
import SubscriptionCheckoutModal from "../../components/checkout/subscriptionModal";
import FeatureTable from "../account/billing/components/featureTable";
import StripeClimate from "../../components/checkout/stripeClimate";
import { availableSubscriptionPlans, SUBSCRIPTION_OPTIONS } from "../../utils/constants";
import { toCamelCase } from "../../utils/string";
import SubscriptionCard from "./components/subscriptionCard";

interface IProps {
	logout: () => void;
}

const Subscribe: React.FC<IProps> = (props) => {
	const [viewDetails, setViewDetails] = useState<boolean>(false);
	const [modal, setModal] = useState<boolean>();
	const [selectedPlan, setSelectedPlan] = useState<availableSubscriptionPlans>();

	const handleSelectPlan = (plan: availableSubscriptionPlans) => {
		setSelectedPlan(plan);
		setModal(true);
	};

	return (
		<div className="subscribe">
			<div className="log-out" onClick={props.logout}>
				Logout <LogOut size={24} />
			</div>
			<div className={"subscription-options"}>
				<Row>
					<h3 className={"mb-3"}>
						<span style={{ color: "#3f65f1" }}>Plans</span> <br /> for Every Team
					</h3>
				</Row>
				<Row className={"mt-4"} style={{ width: "100%" }}>
					{Object.keys(SUBSCRIPTION_OPTIONS).map((key) => {
						const plan = SUBSCRIPTION_OPTIONS[key];
						return (
							<SubscriptionCard
								subscriptionName={plan.subscriptionName}
								subscriptionDetails={plan.subscriptionDetails}
								subscriptionPrice={plan.subscriptionPrice}
								subscriptionInterval={plan.subscriptionInterval}
								subscriptionDescription={plan.subscriptionDescription}
								dividerColor={plan.dividerColor}
								handleSelectPlan={handleSelectPlan}
							/>
						);
					})}
				</Row>
				<div className={"d-flex justify-content-center mt-3"}>
					<div className="view-features">
						<a href="#features">View All Features</a>
						<ArrowDown />
					</div>
				</div>
				<div className="notice">
					If you have already purchased a subscription directly through one of our team members,
					please contact us at <a href="mailto:hello@journeyfoods.com">hello@journeyfoods.com</a> to
					make sure that you have access to the platform.
				</div>
			</div>
			{/* CPG Features */}
			<div
				className={"mt-4"}
				id="features"
				style={{ padding: "0 100px", display: "flex", flexDirection: "column" }}
			>
				<h3 style={{ alignSelf: "center", margin: "0.5rem" }}>All Features</h3>
				<FeatureTable />
			</div>

			{/* Supplier Features */}
			<div
				className={"mt-4"}
				id="supplier-features"
				style={{ padding: "0 100px", display: "flex", flexDirection: "column" }}
			>
				<h3 className={"mb-3"} style={{ alignSelf: "center", margin: "0.5rem" }}>
					Journey Foods for Suppliers
				</h3>
				<FeatureTable supplier />
			</div>
			<div className={"d-flex align-item-center justify-content-center pb-3"}>
				<div style={{ width: 500 }}>
					<StripeClimate />
				</div>
			</div>
			{selectedPlan && (
				<Modal className="subscription-modal" isOpen={modal}>
					<ModalHeader>
						<div className={"modal-header-content"}>
							<span>{`Subscribe to ${toCamelCase(selectedPlan)} plan`}</span>
							<X
								onClick={() => {
									setModal(false);
								}}
								className={"close-modal"}
								size={24}
							></X>
						</div>
					</ModalHeader>
					<ModalBody>
						<SubscriptionCheckoutModal subscriptionPlan={selectedPlan} />
					</ModalBody>
				</Modal>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => {};

const mapDispatchToProps = {
	logout: () => userService.logout()
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
