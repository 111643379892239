import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Input } from "reactstrap";
import Button from "../../../../components/common/button";
import userService from "../../../../services/userService";
import firebase from "firebase";
import { phoneRegExp } from "../../../../validations/constant";
import ReactFlagsSelect from "react-flags-select";

interface IProps {
	phoneNumberVerifiedCallback: (phoneVerificationId: string) => void;
	setUserPhoneNumber: (phone_number: string) => Promise<void>;
}

const SetUserPhoneNumber: React.FC<IProps> = (props) => {
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [valid, setValid] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const reCaptchaRef = useRef<HTMLDivElement | null>();
	const [
		reCaptchaVerifier,
		setReCaptchaVerifier
	] = useState<firebase.auth.RecaptchaVerifier | null>(null);

	useEffect(() => {
		const reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(reCaptchaRef.current, {
			size: "invisible"
		});
		setReCaptchaVerifier(reCaptchaVerifier);
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const valid = phoneRegExp.test(value);
		if (error) setError(null);
		setPhoneNumber(value);
		setValid(valid);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setLoading(true);
		const user = firebase.auth().currentUser;
		if (!reCaptchaVerifier || !valid) return;
		return user?.multiFactor
			.getSession()
			.then((multiFactorSession) => {
				const phoneInfoOptions = {
					phoneNumber: "+1" + phoneNumber,
					session: multiFactorSession
				};
				var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
				// Send SMS verification code.
				return phoneAuthProvider
					.verifyPhoneNumber(phoneInfoOptions, reCaptchaVerifier)
					.then(function (verificationId) {
						props.phoneNumberVerifiedCallback(verificationId);
					})
					.catch((error) => {
						setLoading(false);
						setError(error.message);
					});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSelectCountry = (countryCode: string) => {
		console.log(countryCode);
	};

	return (
		<Form>
			<div
				style={{ display: "hidden", opacity: 0 }}
				ref={(ref) => (reCaptchaRef.current = ref)}
			></div>
			<h4 className={"mb-4"}>Enter your phone number for two factor authentication*</h4>
			<FormGroup className={"mb-4"}>
				<div className={"d-flex flex-row"}>
					<div>
						<Input
							type="tel"
							pattern="[0-9]*"
							placeholder="(123) 456-7890"
							onChange={handleChange}
							className="w-100 px-3 py-2 font-16"
							style={{ borderRadius: "6px" }}
						/>
						{error && <small className="text-danger">{error}</small>}
					</div>
				</div>
			</FormGroup>
			<p color={"secondary"}>{"*Standard message & data rates may apply"}</p>
			<Button
				type="submit"
				className="btn-green color-white"
				style={{ color: "white" }}
				loading={loading}
				disabled={!valid || error != null || loading}
				onClick={handleSubmit}
			>
				Submit
			</Button>
		</Form>
	);
};

const mapDispatchToProps = (dispatch: any) => ({
	setUserPhoneNumber: (password: string, resolve?: Function, reject?: Function) =>
		dispatch(userService.verfiyPassword(password, resolve, reject))
});

export default connect(null, mapDispatchToProps)(SetUserPhoneNumber);
