import { Api } from "./api";
import { Dispatch } from "react";
import { IApplicationState } from "../redux/reducers";
import config from "../utils/config";
import { getAllPackagesAction, selectPackageAction } from "../redux/actions/packaging/packaging";

export interface IPackageFilters {
	region_available?: string;
	sustainability_id?: string;
	filling_process_id?: string;
	end_user_benefits_id?: string;
	form_id?: string;
	material_family_id?: string;
}

class PackagingService extends Api {
	getAllPackages(userId: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			const {
				packaging: {
					packaging: { pagination }
				}
			} = getState();

			try {
				const response = await this.http.get(config.api.endpoints.packaging.all(userId), {
					params: {
						offset: pagination.page,
						limit: pagination.size
					}
				});

				dispatch(
					getAllPackagesAction({
						list: response.data.data,
						pagination: response.data.meta
					})
				);
			} catch (error) {
				if (error.response?.status == 404) {
					return;
				}
				this.handleError(error, "Get All Packages");
			}
		};
	}

	getFilteredPackages(filters?: IPackageFilters | undefined) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			const {
				packaging: {
					packaging: { pagination }
				}
			} = getState();

			try {
				const body = {
					sustainability: filters?.sustainability_id,
					filling_process: filters?.filling_process_id,
					end_user_benfits: filters?.end_user_benefits_id,
					form: filters?.form_id,
					material_family: filters?.material_family_id
				};
				const response = await this.http.get(config.api.endpoints.packaging.filter, {
					params: {
						offset: pagination.page,
						limit: pagination.size,
						...body
					}
				});

				dispatch(
					getAllPackagesAction({
						list: response.data.data,
						pagination: response.data.meta
					})
				);
			} catch (error) {
				this.handleError(error, "Get All Packages");
			}
		};
	}

	getPackageById(id: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				dispatch(selectPackageAction(null));
				const response = await this.http.get(config.api.endpoints.packaging.id(id));
				dispatch(selectPackageAction(response.data));
				return response.data;
			} catch (error) {
				this.handleError(error, "Package doesn't exist");
			}
		};
	}
}

export default new PackagingService();
