import { ActionType } from "../actions/action";
import { preference } from "../actions/actionContants";
import { IPreference } from "../../interfaces/preference";

export interface IPreferenceState {
	product: IPreference[];
	portfolio: IPreference[];
	company: IPreference[];
	category: IPreference[];
	preferences: IPreference[];
	page: {
		product: number;
		portfolio: number;
		company: number;
		category: number;
	};
}

const INIT_STATE: IPreferenceState = {
	product: [],
	portfolio: [],
	company: [],
	category: [],
	preferences: [],
	page: {
		product: 0,
		portfolio: 0,
		company: 0,
		category: 0
	}
};

interface Payload {
	data: any;
	preferenceType: "portfolio" | "company" | "product";
	page: number;
}

export default function (
	state: IPreferenceState = INIT_STATE,
	action: ActionType<Payload>
) {
	switch (action.type) {
		// case preference.PREFERENCE_RESPONSE:
		//     if (!action.payload) {
		//         return state;
		//     }
		//     return {
		//         ...state,
		//         [action.payload.preferenceType]: [...state[action.payload.preferenceType], ...action.payload.data],
		//         page: {
		//             ...state.page,
		//             [action.payload.preferenceType]: action.payload.page
		//         }
		//     }
		case preference.PREFERENCE_RESPONSE:
			return {
				...state,
				preferences: action.payload
			};
		default:
			return state;
	}
}
