import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { ExpendableCard } from "../../../components/cards/expendableCard";
import LineChart from "../../../components/charts/lineChart";
import { ArrowDown, ArrowUp, X, Check } from "react-feather";
import { analysisStep, recommendationTypes } from "./recommendationModal";
import { IIngredient } from "../../../interfaces/ingredient";
import { sampleCostPredictionData } from "../../../components/charts/sampleData";
import data from "@iconify-icons/mdi/crown";
import { getPercent } from "./recommendationIngredientsinfo";

interface ISustainabilityProps {
	title: string;
	prevValue: string;
	newValue: string;
}

const SustainabilityItem: React.FC<ISustainabilityProps> = ({ title, prevValue, newValue }) => {
	return (
		<Col className={"d-flex flex-column align-items-center"}>
			<h5 className={"recommendation-analysis-header"}>{title}</h5>
			<div className={"recommendation-analysis-content"}>
				<span>{prevValue || "- - "}</span>
				<span className={"pl-2 pr-2 pink"}>{newValue || " - -"}</span>
				{newValue < prevValue ? <ArrowDown size={18} /> : <ArrowUp size={18} />}
			</div>
		</Col>
	);
};

interface INutritionProps {
	title: string;
	prevValue: number;
	newValue: number;
}

const NutritionItem: React.FC<INutritionProps> = ({ title, prevValue, newValue }) => {
	return (
		<Col className={"d-flex justify-content-between"}>
			<h5 className={"recommendation-analysis-header"} style={{ flex: 4, paddingLeft: "50px" }}>
				{title}
			</h5>
			<span style={{ flex: 1, textAlign: "center" }}>{prevValue}</span>
			<span className={"pink"} style={{ flex: 1, textAlign: "center" }}>
				{newValue}
			</span>
			<span style={{ flex: 1 }}>
				{newValue < prevValue ? (
					<ArrowDown size={18} className={"ml-2"} style={{ marginBottom: "4px" }} />
				) : (
					<ArrowUp size={18} className={"ml-2"} style={{ marginBottom: "4px" }} />
				)}
			</span>
		</Col>
	);
};

const chartDates = [
	{
		id: 1,
		period: 1,
		value: "30 d"
	},
	{
		id: 2,
		period: 3,
		value: "90 d"
	},
	{
		id: 3,
		period: 6,
		value: "180 d"
	},
	{
		id: 4,
		period: 12,
		value: "1 y"
	}
];

interface IProps {
	activeAnalysis: analysisStep;
	activeRecommendation: recommendationTypes;
	oldIngredient: IIngredient;
	recommendedIngredient: IIngredient;
}

const RecommendationAnalysis: React.FC<IProps> = ({
	activeAnalysis,
	activeRecommendation,
	oldIngredient,
	recommendedIngredient
}) => {
	const [expendedCard, setExpendedCard] = useState({
		[analysisStep.COST_PREDICTION]: activeAnalysis === analysisStep.COST_PREDICTION,
		[analysisStep.SUSTAINABILITY_ANALYSIS]: activeAnalysis === analysisStep.SUSTAINABILITY_ANALYSIS,
		[analysisStep.NUTRITION_ANALYSIS]: activeAnalysis === analysisStep.NUTRITION_ANALYSIS
	});

	const [activeDate, setActiveDate] = useState(chartDates[2]);

	const updateExpendedCard = (id: any, value: boolean) => {
		setExpendedCard(() => {
			return {
				...{
					[analysisStep.COST_PREDICTION]: false,
					[analysisStep.SUSTAINABILITY_ANALYSIS]: false,
					[analysisStep.NUTRITION_ANALYSIS]: false
				},
				[id]: value
			};
		});
	};
	const cost = getPercent(Number(oldIngredient?.cost), Number(recommendedIngredient?.cost));

	const sustainability_score = getPercent(
		Number(oldIngredient?.sustainability_score),
		Number(recommendedIngredient?.sustainability_score)
	);

	const nutrition_score = getPercent(
		Number(oldIngredient?.nutrition_source),
		Number(recommendedIngredient?.sustainability_score)
	);

	return (
		<>
			<ExpendableCard
				classes={"mt-3"}
				id={analysisStep.COST_PREDICTION}
				title="Cost prediction"
				headerStyle={{ fontSize: "16px", fontFamily: "Biotif" }}
				info={`${cost} (${activeDate.value})`}
				infoSuccess={false}
				isOpen={expendedCard.COST_PREDICTION}
				onExpend={updateExpendedCard}
			>
				<Row className={"mt-3"}>
					<Col>Cost per/100g (USD)</Col>
					<Col className={"d-flex justify-content-end"}>
						<div className={"dataset-container"}>
							{chartDates.map((data: any) => {
								return (
									<span
										key={data.id}
										className={activeDate.id === data.id ? "active-span" : ""}
										onClick={() => {
											setActiveDate(data);
										}}
									>
										{data.value}
									</span>
								);
							})}
						</div>
					</Col>
				</Row>
				<Row>
					<div className={"mb-2 mt-2"} style={{ height: 200, width: "100%" }}>
						<LineChart data={sampleCostPredictionData(activeDate.period)} />
					</div>
				</Row>
			</ExpendableCard>

			<ExpendableCard
				id={analysisStep.SUSTAINABILITY_ANALYSIS}
				title="Sustainability analysis"
				info={`${sustainability_score}`}
				headerStyle={{ fontSize: "16px", fontFamily: "Biotif" }}
				infoSuccess={false}
				isOpen={expendedCard.SUSTAINABILITY_ANALYSIS}
				onExpend={updateExpendedCard}
			>
				<Row className={"mt-3"}>
					<SustainabilityItem
						title="Water Usage (l/kg)"
						prevValue={oldIngredient.water_footprint}
						newValue={recommendedIngredient.water_footprint}
					></SustainabilityItem>
					<SustainabilityItem
						title="GHG Emission (kg/kg)"
						prevValue={oldIngredient.greenhouse_gas_emissions}
						newValue={recommendedIngredient.greenhouse_gas_emissions}
					></SustainabilityItem>

					<Col className={"d-flex flex-column align-items-center"}>
						<h5 className={"recommendation-analysis-header"}>Upcycled?</h5>
						<div
							className={"d-flex justify-content-between"}
							style={{ cursor: "pointer", width: "75px" }}
						>
							<X size={15} color={"#04192880"} />
							<Check size={15} color={"#C747F4"} />
						</div>
					</Col>
				</Row>
			</ExpendableCard>

			{activeRecommendation === recommendationTypes.INGREDIENT_RECOMMENDATION && (
				<ExpendableCard
					id={analysisStep.NUTRITION_ANALYSIS}
					title="Nutrition analysis"
					info={`${nutrition_score}`}
					headerStyle={{ fontSize: "16px", fontFamily: "Biotif" }}
					infoSuccess={true}
					isOpen={expendedCard.NUTRITION_ANALYSIS}
					onExpend={updateExpendedCard}
				>
					<Row className={"mt-3"}>
						<NutritionItem
							title={"Energy (kCals)"}
							prevValue={Number(oldIngredient.df_energy_kcal || 0)}
							newValue={Number(recommendedIngredient.df_energy_kcal || 0)}
						></NutritionItem>
						<NutritionItem
							title={"Total sugars (g)"}
							prevValue={Number(oldIngredient.df_sugar || 0)}
							newValue={Number(recommendedIngredient.df_sugar || 0)}
						></NutritionItem>
					</Row>
					<Row className={"mt-2"}>
						<NutritionItem
							title={"Total fat (g)"}
							prevValue={Number(oldIngredient.df_fatty_acid_total_saturated || 0)}
							newValue={Number(recommendedIngredient.df_fatty_acid_total_saturated || 0)}
						></NutritionItem>
						<NutritionItem title={"Added sugars (g)"} prevValue={12} newValue={8}></NutritionItem>
					</Row>
					<Row className={"mt-2"}>
						<NutritionItem title={"Saturated fat (g)"} prevValue={2} newValue={5}></NutritionItem>
						<NutritionItem
							title={"Protein (g)"}
							prevValue={Number(oldIngredient.df_protein || 0)}
							newValue={Number(recommendedIngredient.df_protein || 0)}
						></NutritionItem>
					</Row>
					<Row className={"mt-2"}>
						<NutritionItem title={"Trans fat (g)"} prevValue={2} newValue={5}></NutritionItem>
						<NutritionItem
							title={"Potassium %DV"}
							prevValue={Number(oldIngredient.df_potassium || 0)}
							newValue={Number(recommendedIngredient.df_potassium || 0)}
						></NutritionItem>
					</Row>
					<Row className={"mt-2"}>
						<NutritionItem
							title={"Cholesterol (mg)"}
							prevValue={Number(oldIngredient.df_cholesterol || 0)}
							newValue={Number(recommendedIngredient.df_cholesterol || 0)}
						></NutritionItem>
						<NutritionItem
							title={"Calcium %DV"}
							prevValue={Number(oldIngredient.df_calcium || 0)}
							newValue={Number(recommendedIngredient.df_calcium || 0)}
						></NutritionItem>
					</Row>
					<Row className={"mt-2"}>
						<NutritionItem
							title={"Sodium (mg)"}
							prevValue={Number(oldIngredient.df_sodium || 0)}
							newValue={Number(recommendedIngredient.df_sodium || 0)}
						></NutritionItem>
						<NutritionItem
							title={"Iron %DV"}
							prevValue={Number(oldIngredient.df_iron || 0)}
							newValue={Number(recommendedIngredient.df_iron || 0)}
						></NutritionItem>
					</Row>
					<Row className={"mt-2"}>
						<NutritionItem
							title={"Total carbohydrate (g)"}
							prevValue={Number(oldIngredient.df_carbohydrate || 0)}
							newValue={Number(recommendedIngredient.df_carbohydrate || 0)}
						></NutritionItem>
						<NutritionItem
							title={"Vitamin C %DV"}
							prevValue={Number(oldIngredient.df_vitamin_c || 0)}
							newValue={Number(recommendedIngredient.df_vitamin_c || 0)}
						></NutritionItem>
					</Row>
					<Row className={"mt-2"}>
						<NutritionItem
							title={"Dietary fiber (g)"}
							prevValue={Number(oldIngredient.df_fiber || 0)}
							newValue={Number(recommendedIngredient.df_fiber || 0)}
						></NutritionItem>
						<NutritionItem
							title={"Vitamin D %DV"}
							prevValue={Number(oldIngredient.df_vitamin_d2_d3 || 0)}
							newValue={Number(recommendedIngredient.df_vitamin_d2_d3 || 0)}
						></NutritionItem>
					</Row>
				</ExpendableCard>
			)}
		</>
	);
};

export default RecommendationAnalysis;
