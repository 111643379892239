import { Api } from "./api";
import { Dispatch } from "redux";
import config from "../utils/config";
import { IApplicationState } from "../redux/reducers";
import { getTimeSavedAction, getProductChangesAction } from "../redux/actions/analysis/analysis";
import moment, { months } from "moment";

class AnalyticsService extends Api {
	getUserReports() {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(config.api.endpoints.analytics.reports);
			} catch (error) {}
		};
	}

	requestReport(payload: any) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.post(config.api.endpoints.analytics.reports, payload);
			} catch (error) {
				this.handleError(error, "Report request error");
			}
		};
	}

	getTimeSaved() {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				const {
					analytics: { time_saved_filter }
				} = getState();

				const response = await this.http.get(config.api.endpoints.analytics.timeSaved, {
					params: {
						start: moment().subtract(time_saved_filter, "months").format("YYYY-MM"),
						end: moment().format("YYYY-MM")
					}
				});
				dispatch(getTimeSavedAction(response.data));
			} catch (error) {
				// this.handleError(error, "Report Time Saved");
			}
		};
	}

	getProductChanges() {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				const {
					analytics: { product_changes_filter }
				} = getState();

				const response = await this.http.get(config.api.endpoints.analytics.productChanges, {
					params: {
						start: moment().subtract(product_changes_filter, "months").format("YYYY-MM"),
						end: moment().format("YYYY-MM")
					}
				});
				dispatch(getProductChangesAction(response.data));
			} catch (error) {
				this.handleError(error, "Report Products Changes");
			}
		};
	}
}

export default new AnalyticsService();
