import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes } from "../../routes";
import Tabs from "../../../components/tab";
import { Row, Col } from "reactstrap";
import Invoices from "./invoices";
import Subscription from "./subscription";
import SubscriptionOptions from "./components/subscriptionOptions";
import BillingInfo from "./billingInfo";

const BillingRouter: React.FC = () => (
	<div>
		<Tabs
			tabs={[
				{
					name: "Invoices",
					route: routes.INVOICES
				},
				{
					name: "Subscription",
					route: routes.SUBSCRIPTION
				},
				{
					name: "Billing Info",
					route: routes.BILLING_INFO
				},
				{
					name: "Upgrade",
					route: routes.UPGRADE
				}
			]}
		/>

		<div className="py-3">
			<Switch>
				<Route exact path={routes.INVOICES} component={Invoices} />
				<Route exact path={routes.SUBSCRIPTION} component={Subscription} />
				<Route exact path={routes.UPGRADE} component={SubscriptionOptions} />
				<Route exact path={routes.BILLING_INFO} component={BillingInfo} />
				<Redirect from={routes.BILLING} to={routes.SUBSCRIPTION} />
			</Switch>
		</div>
	</div>
);

export default BillingRouter;
