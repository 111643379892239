import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "../../redux/reducers";
import { 
  Table, 
  Modal, 
  ModalHeader, 
  ModalFooter, 
  ModalBody,
  Form, 
  FormGroup, 
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Button from "../../components/common/button";
import { Upload, Download, Plus, X, FileText, Loader } from "react-feather";
import ReportRow from "./components/reportRow";
import { IReport } from "../../interfaces/report";
import { ICompany } from "../../interfaces/company";
import reportService from "../../services/reportService";
import companyService from "../../services/companyService";
import { error } from "../../utils/notification";
import { REPORTS_ALLOWED_FILE_EXTENSIONS } from "../../utils/constants";
import adminService from "../../services/adminService";
import { paginateAdminReportRequests, paginateAdminReports } from "../../redux/actions/admin/adminActions";
import { IPagination } from "../../interfaces/pagination";
import Pagination from "../../components/pagination";

interface IProps {
  reports: IReport[];
  requests: IReport[];
  companies: ICompany[];
  getAdminReportRequests: () => Promise<any>;
  paginateReportRequests: (page: number) => void;
  paginationRequests: IPagination,
  getAdminReports: () => Promise<any>;
  paginateReports: (page: number) => void;
  paginationReports: IPagination;
  uploadReportFile: (reportId: string, reportFile: File) => Promise<any>;
  getCompanies: () => any;
  addReport: (reportType: string, reportFile: File, reportDescription: string, requestedBy: string) => Promise<any>;
}

enum SelectedTypes { reports = "Reports", requests = "Requests" }

const ReportsTable: React.FC<IProps> = ({ reports, requests, companies, getAdminReports, paginateReports, paginationReports, getAdminReportRequests, paginateReportRequests, paginationRequests, uploadReportFile, getCompanies, addReport }) => {
  const [modalNewReport, setModalNewReport] = useState<boolean>(false);
  const [reportType, setReportType] = useState<string>();
  const [reportDescription, setReportDescription] = useState<string>();
  const [reportRequestedBy, setRequestedBy] = useState<string>();
  const [reportNewFile, setReportNewFile] = useState<File>();
  const [adding, setAdding] = useState<boolean>(false)

  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [report, setReport] = useState<IReport>();
  const [reportFile, setReportFile] = useState<File>();

  const [selected, setSelected] = useState<SelectedTypes>(SelectedTypes.requests)

  function toggleModal() {
    setModal(!modal);
  }

  function selectReport(report: IReport) {
    setReport(report);
    setReportFile(undefined);
    setModal(true);
  }

  function selectReportFile(event: any) {
    setReportFile(event.target.files[0])
  }

  function selectReportNewFile(event: any) {
    setReportNewFile(event?.target.files[0])
  }

  function uploadReport() {
    if (report === undefined || reportFile === undefined) return;

    setUploading(true);

    uploadReportFile(report.id, reportFile).finally(() => {
      getAdminReportRequests().finally(() => {
        setUploading(false);
        setModal(false);
      })
    })
  }

  function createNewReport() {
    if(reportType === undefined || reportDescription === undefined || reportNewFile === undefined || reportRequestedBy === undefined) {
      error("Invalid Report params")
      return 
    } else {
      setAdding(true)
      addReport(reportType, reportNewFile, reportDescription, reportRequestedBy).finally(() => {
        setAdding(false)
        setModalNewReport(false)
      })
    }
  }

  async function onPageRequestsChanged(page: number) {
    await paginateReportRequests(page)
    setLoading(true)
    getAdminReportRequests().finally(() => {
      setLoading(false)
    })
  }

  async function onPageReportsChanged(page: number) {
    await paginateReports(page)
    setLoading(true)
    getAdminReports().finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([getCompanies(), getAdminReports(), getAdminReportRequests()]).finally(() => {
      setLoading(false)
    })
  }, [paginationReports.page])

  return (
    <div className="user-reports">
      <div className="page-header">
        <h3>All { SelectedTypes.reports === selected ? SelectedTypes.reports : SelectedTypes.requests }</h3>
        { selected === SelectedTypes.requests && (
          <div style={{marginTop: "-35px", position: "relative", display: "flex"}}>
            <Pagination page={paginationRequests.page} pages={paginationRequests.pages} paginatorSize="md" total={paginationRequests.total} size={paginationRequests.size} handlePageChange={onPageRequestsChanged} />
          </div>
        ) }

        { selected === SelectedTypes.reports && (
          <div style={{marginTop: "-35px", position: "relative", display: "flex"}}>
            <Pagination page={paginationReports.page} pages={paginationReports.pages} paginatorSize="md" total={paginationReports.total} size={paginationReports.size} handlePageChange={onPageReportsChanged} />
          </div>
        ) }

        <Button color={ selected === SelectedTypes.requests ? 'primary' : 'white' } onClick={() => setSelected(SelectedTypes.requests)} className="mr-2"><Upload size={17} className="mr-2" />Requests</Button>
        <Button color={ selected === SelectedTypes.reports ? 'primary' : 'white' } onClick={() => setSelected(SelectedTypes.reports)} className="mr-2"><Download size={17} className="mr-2" />Reports</Button>


        {/* <Button 
          color="primary"
          onClick={() => setModalNewReport(true)}
        >
          <Upload size={17} className="mr-2" /> Upload Report
        </Button> */}
      </div>



      { loading ? (
        <Loader className="fa-spin" />
      ) : (
        <>

          <Table responsive hover>
            <thead>
              <tr>
                  <th>Report Type</th>
                  <th>Request Date</th>
                  <th>Requested By</th>
                  <th className="actions d-flex justify-content-end">
                    Actions
                  </th>
              </tr>
            </thead>
            <tbody>
              { selected === SelectedTypes.requests ? (
                requests.map((report) => <ReportRow key={report.id} report={report} onClick={() => selectReport(report)}/>)
              ) : (
                reports.map((report) => <ReportRow key={report.id} report={report} onClick={() => selectReport(report)}/>)
              )
              }
            </tbody>
          </Table>
        </>
      ) }


      <span className="action-button-group">
          <Button 
              className="btn-circle btn-adder btn-secondary"
              onClick={() => setModalNewReport(true)}
          >
              <Plus size={28} />
          </Button>
      </span>

      <Modal isOpen={modal}>
        <ModalHeader>
            <Upload className="color-bg-blue mr-2"  size={24}/>
            Upload New Report For { report?.requested_by.name }
            <X className="float-right" onClick={toggleModal} />
        </ModalHeader>
        <ModalBody>
            <Form onSubmit={uploadReport}>
              <FormGroup className="mb-3">
                <Label>Report Type</Label>
                <Input
                  placeholder="Report Type"
                  value={report?.report_type}
                  name="name"
                  disabled={true}
                  style={{ width: '100%', padding: '8px' }}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label>Assign Report</Label>
                <Input
                  placeholder="Assign Report"
                  value={`${report?.requested_by.name}`}
                  name="assign"
                  disabled={true}
                  style={{ width: '100%', padding: '8px' }}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label>Upload Report</Label><br/>

                <InputGroup>
                  <Input
                    type="file"
                    accept={REPORTS_ALLOWED_FILE_EXTENSIONS.join(", ")}
                    onChange={selectReportFile}
                    style={{ padding: '8px', borderStyle: "dashed", borderColor: "#cfdbea" }} 
                  />
                  <InputGroupAddon addonType="append">
                    <Upload className="color-bg-blue"  size={16} style={{ marginLeft: '-29px', marginTop: '15px', zIndex: 100 }} />
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup className="mb-3">
                <Label>Description</Label>
                <Input 
                  type="textarea" 
                  rows={6} 
                  value={report?.description}
                  name="description" 
                  disabled={true}
                  placeholder="Description"
                  style={{ width: '100%', padding: '8px' }} />
              </FormGroup>

            </Form>

        </ModalBody>
        <ModalFooter>
            <Button 
              className="color-white mr-2" color="primary" 
              onClick={uploadReport}
              disabled={uploading || (reportFile === undefined)}
            >
                <FileText size={18} className="mr-2" />
                { uploading ? 'Uploading Report' : 'Upload Report' }
            </Button>

        </ModalFooter>
      </Modal>

      <Modal isOpen={modalNewReport}>
        <ModalHeader>
            <Upload className="color-bg-blue mr-2"  size={24}/>
            Upload New Report 
            <X className="float-right" onClick={() => setModalNewReport(false)} />
        </ModalHeader>
        <ModalBody>
            <Form>
              <FormGroup className="mb-3">
                <Label>Report Type</Label>
                <Input
                  placeholder="Report Type"
                  value={reportType}
                  style={{ width: '100%', padding: '8px' }}
                  onChange={(e) => setReportType(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label>Select Company</Label>
                <Input placeholder="Select Company" value={reportRequestedBy} style={{ width: "100%" }} type="select" onChange={(e) => setRequestedBy(e.target.value)}>
                    <option key={""} value={""}>Select</option>
                    {companies.map(item => <option key={item.id} value={item.id}>{`${item.name} (${item.email})`}</option>)}
                </Input>
              </FormGroup>

              <FormGroup className="mb-3">
                <Label>Upload Report</Label><br/>

                <InputGroup>
                  <Input
                    type="file"
                    accept={REPORTS_ALLOWED_FILE_EXTENSIONS.join(", ")}
                    onChange={selectReportNewFile}
                    style={{ padding: '8px', borderStyle: "dashed", borderColor: "#cfdbea" }} 
                  />
                  <InputGroupAddon addonType="append">
                    <Upload className="color-bg-blue"  size={16} style={{ marginLeft: '-29px', marginTop: '15px', zIndex: 100 }} />
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup className="mb-3">
                <Label>Description</Label>
                <Input 
                  type="textarea" 
                  rows={6} 
                  value={reportDescription}
                  onChange={(e) => setReportDescription(e.target.value)}
                  name="description" 
                  placeholder="Description"
                  style={{ width: '100%', padding: '8px' }} />
              </FormGroup>

            </Form>

        </ModalBody>
        <ModalFooter>
            <Button 
              className="color-white mr-2" color="primary" 
              onClick={createNewReport}
              disabled={adding || (reportNewFile === undefined)}
            >
                <FileText size={18} className="mr-2" />
                { adding ? 'Adding Report' : 'Add Report' }
            </Button>

        </ModalFooter>
      </Modal>

    </div>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    reports: state.admin.reports.list,
    requests: state.admin.requests.list,
    paginationRequests: state.admin.requests.pagination,
    paginationReports: state.admin.reports.pagination,
    companies: state.company.companies,
  }
}

const mapDispatchToProps = {
  getAdminReportRequests: () => adminService.getAdminReportRequests(),
  paginateReportRequests: (page: number) => (dispatch: Dispatch) => {
    dispatch(paginateAdminReportRequests(page))
  },
  getAdminReports: () => adminService.getAdminReports(),
  paginateReports: (page: number) => (dispatch: Dispatch) => {
    dispatch(paginateAdminReports(page))
  },
  uploadReportFile: (reportId: string, reportFile: File) => reportService.uploadReportFile(reportId, reportFile),
  getCompanies: () => companyService.getCompanies(),
  addReport: (reportType: string, reportFile: File, reportDescription: string, requestedBy?: string) => reportService.addReport(reportType, reportFile, reportDescription, requestedBy)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTable);