import { IReport } from "../../interfaces/report";
import { IPagination } from "../../interfaces/pagination";
import { ActionType } from "../actions/action";
import { recommendations, report } from "../actions/actionContants";
import { ITEMS_PER_PAGE } from "../../utils/constants";
import { IRecommendation } from "../../interfaces/recommendation";

export interface IRecommendationState {
	all: IRecommendation[];
}

const INIT_STATE: IRecommendationState = {
	all: []
};

export default function (state: IRecommendationState = INIT_STATE, action: ActionType) {
	switch (action.type) {
		case recommendations.GET_ALL_RECOMMENDATIONS_RESPONSE:
			return {
				...state,
				all: action.payload
			};
	}

	return state;
}
