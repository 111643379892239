import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IPersona } from "../../../interfaces/persona";
import PersonaItem from "../components/persona.item";
import Button from "../../../components/common/button";
import { Search } from "react-feather";
import { RouteComponentProps } from "react-router-dom";
import { IApplicationState } from "../../../redux/reducers";
import personaService from "../../../services/personaService";
import Loader from "../../../components/loader";

interface IProps extends RouteComponentProps {
    all: IPersona[];
    userId: any;
    getAllPersonas: (userId: string) => Promise<any>;
}

const Personas: React.FC<IProps> = ({ all, userId, getAllPersonas }) => {

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        if (userId) {
            getAllPersonas(userId).finally(() => {
                setLoading(false)
            })
        }
    }, [])

    return (


        <Loader isLoading={loading}>
            <div className="personas">
                {all.map((persona) => {
                    return <PersonaItem mini={false} persona={persona} key={persona.id} />
                })}
            </div>
        </Loader>

    )
}

const mapStateToProps = (state: IApplicationState) => ({
    all: state.persona.all,
    userId: state.user.user_id,
})

const mapDispatchToProps = {
    getAllPersonas: (userId: string) => personaService.getAllPersonas(userId)
}


export default connect(mapStateToProps, mapDispatchToProps)(Personas);