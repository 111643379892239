import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DollarSign } from "react-feather";

import { Button, Card, Col, Row } from "reactstrap";
import { IApplicationState } from "../../../redux/reducers";
import userService from "../../../services/userService";

import ProductItem from "../../products/components/productItem";
import IngredientItem from "../../ingredients/components/ingredientItem";

import { routes } from "../../routes";

import ingredientService from "../../../services/ingredientService";
import productService from "../../../services/productService";
import config from "../../../utils/config";

import DashboardWhitePaper from "./dashboardWhitePaper";
import { INotification } from "../../../interfaces/notification";
import reportService from "../../../services/reportService";
import recommendationService from "../../../services/recommendationService";
import { IRecommendation } from "../../../interfaces/recommendation";
import { PRODUCT_TYPE } from "../../../interfaces/products";
import Loader from "../../../components/loader";
import DashboardProductAction from "./dashboardProductAction";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { USER_ROLES } from "../../../interfaces/roles";
import dashboardService from "../../../services/dashboardService";

const NewlineText = (props: any) => {
	const text = props.text;
	const newText = text.split("\n").map((str: string, index: number) => (
		<p key={index} className="split-text">
			{str}
		</p>
	));
	return newText;
};

const ActionCard = (props: any) => {
	const image = require(`../../../assets/images/dashboard/${props.backgroundImageName}.png`);

	return (
		<Card className="wrapper__options" onClick={props.onPress}>
			{props.title ? (
				<>
					<div className="wrapper__container">
						<div className="content__body">
							<div className="options__heading">{props.title}</div>
							<div className="options__content">
								<NewlineText text={props.descriptions} />
							</div>
						</div>

						<div className="options__footer">
							<div className="options__link">{props.buttonText}</div>
							<div className={"card_image"}>
								<img
									className={props.image ? "dynamic_image" : ""}
									src={
										props.name
											? config.api.endpoints.ingredients.ingredientImage(props.name)
											: props.image
									}
								/>
							</div>
						</div>
					</div>
					<div className={"bottom-image"}>{<img src={image} />}</div>
				</>
			) : (
				<>
					<div className="empty__container">
						<div className="options__heading">No Recommendations Found</div>
					</div>
				</>
			)}
		</Card>
	);
};

const PortfolioScoreCard = (props: any) => {
	const [activeTab, setActiveTab] = useState(0);
	const image = require(`../../../assets/images/dashboard/${props.backgroundImageName}.png`);

	const percentage = () => {
		return activeTab === 0
			? Number(Number(props.score?.nutrition_score || 0).toFixed(0))
			: Number(Number(props.score?.sustainability_score || 0).toFixed(0));
	};

	return (
		<Card className="portfolio__options">
			{props.title ? (
				<>
					<div className="wrapper__container">
						<div className="content__body">
							<div className="options__heading">{props.title}</div>
							<div className="portfolio__content">
								<div>
									{/* <div
										style={{ cursor: "pointer" }}
										onClick={() => {
											setActiveTab(0);
										}}
									>
										<DollarSign color={activeTab === 0 ? "#53CE7C" : "#7F8991"} />
										<span
											className="pl-2"
											style={{
												fontSize: "18px",
												fontWeight: 700,
												color: activeTab === 0 ? "#53CE7C" : "#7F8991"
											}}
										>
											Cost
										</span>
									</div> */}
									<div
										className="pt-3"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setActiveTab(0);
										}}
									>
										<svg
											width="21"
											height="19"
											viewBox="0 0 21 19"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.4939 8.87277L6.80385 10.6331L5.3122 9.7733L3.82056 10.628L4.13536 8.87277L2.90039 7.64155L4.58575 7.41769L5.31705 5.7998L6.0435 7.41769L7.73371 7.64664L6.4939 8.87277Z"
												stroke={activeTab === 0 ? "#53CE7C" : "#7F8991"}
												stroke-width="1.25"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<circle
												cx="7.24997"
												cy="7.24997"
												r="6.24997"
												stroke={activeTab === 0 ? "#53CE7C" : "#7F8991"}
												stroke-width="2"
											/>
											<circle
												cx="13.0517"
												cy="11.1167"
												r="6.24997"
												fill="white"
												stroke={activeTab === 0 ? "#53CE7C" : "#7F8991"}
												stroke-width="2"
											/>
											<path
												d="M14.2278 12.7395L14.5377 14.4998L13.0461 13.64L11.5544 14.4947L11.8692 12.7395L10.6343 11.5083L12.3196 11.2844L13.0509 9.6665L13.7774 11.2844L15.4676 11.5133L14.2278 12.7395Z"
												stroke={activeTab === 0 ? "#53CE7C" : "#7F8991"}
												stroke-width="1.25"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>

										<span
											className="pl-2"
											style={{
												fontSize: "18px",
												fontWeight: 700,
												color: activeTab === 0 ? "#53CE7C" : "grey"
											}}
										>
											Nutrition
										</span>
									</div>
									<div
										className="pt-3"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setActiveTab(1);
										}}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M23.1999 3.1407C19.3484 2.58042 15.6419 2.99918 12.4743 4.44309C12.1756 2.86781 12.3156 1.3485 12.8745 0C7.18117 1.44304 2.63252 4.80849 0.581934 9.75821C-1.02844 13.6457 0.817994 18.1061 4.70718 19.7174C5.05663 19.8618 5.41102 19.9694 5.76627 20.0593C5.98348 21.1184 6.26333 22.169 6.64613 23.2C7.44328 23.6741 8.1879 23.2 8.6973 23.2C9.20669 23.2 8.01116 21.2666 7.77916 20.2878C8.6683 20.2681 9.54177 20.097 10.361 19.7818C13.1685 21.0505 16.5821 20.5369 18.8885 18.2317C20.6433 16.4758 21.5014 13.8211 21.0438 10.6871C20.5867 7.55073 21.4448 4.8952 23.1999 3.1407ZM7.62139 18.3666C7.55498 18.3666 7.49061 18.3495 7.42449 18.3468C6.99877 15.0266 7.45291 11.7047 8.69991 8.7L6.91437 7.95905C5.58443 11.1638 5.10768 14.5687 5.43886 17.9278C2.54639 16.7243 1.1686 13.3936 2.36804 10.4986C3.76845 7.11805 6.56058 4.47673 10.3709 2.86723C10.3671 4.82502 10.9523 6.84632 12.1097 8.787C13.3588 10.8814 13.6325 13.0352 12.88 14.8515C11.9943 16.9865 9.93095 18.3666 7.62139 18.3666ZM19.1303 10.9661C19.4836 13.3794 18.9123 15.4732 17.5217 16.8626C16.2048 18.1786 14.3717 18.7346 12.5932 18.4457C13.471 17.6923 14.1934 16.7324 14.6661 15.591C15.6155 13.2982 15.3928 10.5168 13.77 7.79694C13.4765 7.30365 13.2442 6.8092 13.0403 6.31533C15.03 5.3592 17.3091 4.8488 19.7898 4.8314C19.037 6.63868 18.8047 8.73016 19.1303 10.9661Z"
												fill={activeTab === 1 ? "#53CE7C" : "#7F8991"}
											/>
										</svg>
										<span
											className="pl-2"
											style={{
												fontSize: "18px",
												cursor: "pointer",
												fontWeight: 700,
												color: activeTab === 1 ? "#53CE7C" : "#7F8991"
											}}
										>
											Sustainability
										</span>
									</div>
								</div>
								<div style={{ width: 100, height: 100 }}>
									<CircularProgressbar
										value={percentage()}
										text={`${
											activeTab === 0
												? Number(props.score?.nutrition_score || 0).toFixed(0)
												: Number(props.score?.sustainability_score || 0).toFixed(0)
										}`}
										styles={buildStyles({
											textSize: "26px",
											pathColor: `#53CE7C`,
											textColor: "#000",
											trailColor: "#EBEBEC",
											backgroundColor: "#fff"
										})}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={"bottom-image"}>{<img src={image} />}</div>
				</>
			) : (
				<>
					<div className="empty__container">
						<div className="options__heading">No Recommendations Found</div>
					</div>
				</>
			)}
		</Card>
	);
};

interface IProps extends RouteComponentProps {
	subscription_type: string | undefined;
	userName: string | undefined;
	role: any;
	notificationNumber: number;
	productsNumber: number;
	viewedIngredients: Array<any>;
	allIngredientsNumber: number;
	viewedProducts: Array<any>;
	recommendations: Array<IRecommendation>;
	getUserProfile: () => Promise<any>;
	getViewedIngredients: () => Promise<any>;
	getAllIngredient: () => Promise<any>;
	getActiveProducts: () => Promise<any>;
	getViewedProducts: () => Promise<any>;
	getWhitepaper: () => Promise<any>;
	getAllRecommendations: () => Promise<any>;
	addIngredientToPortfolio: (id: string) => Promise<any>;
	getIngredientStatus: (id: string) => Promise<any>;
	getPortfolioScore: () => Promise<any>;
}

const Dashboard: React.FC<IProps> = ({ ...props }) => {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [showProductActions, setShowProductActions] = useState(false);
	const [groupedWhitePapers, setGroupedWhitePapers] = useState();
	const [allWhitePapers, setAllWhitePapers] = useState();
	const [score, setScore] = useState();

	const projectImg = require("../../../assets/images/dashboard/product.png");
	const analyticsImg = require("../../../assets/images/dashboard/analytics.png");
	const ingredientImg = require("../../../assets/images/dashboard/ingredients.png");
	const inviteUser = require("../../../assets/images/dashboard/invite_user.png");

	const onAddIngredientToPortfolioHandler = (id: string) => {
		// setAdding(true);
		props.addIngredientToPortfolio(id).finally(() => {
			// setAdding(false);
			// setStatusLoading(true);
			props.getIngredientStatus(id).finally(() => {
				// (false);
			});
		});
	};

	useEffect(() => {
		try {
			setIsLoading(true);
			Promise.all([
				props.getUserProfile(),
				props.getViewedIngredients(),
				props.getAllIngredient(),
				props.getActiveProducts(),
				props.getViewedProducts(),
				props.getAllRecommendations()
			]).finally(() => {
				setIsLoading(false);
			});
		} catch {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		try {
			setIsLoading(true);
			props.getWhitepaper().then((response: any) => {
				if (response?.data) {
					setAllWhitePapers(response.data);
					const grouped = response.data.reduce(function (r: any, a: any) {
						r[a.tag] = r[a.tag] || [];
						r[a.tag].push(a);
						return r;
					}, Object.create(null));
					setGroupedWhitePapers(grouped);
				}
			});
		} catch {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		try {
			setIsLoading(true);
			props.getPortfolioScore().then((response: any) => {
				setScore(response);
			});
		} catch (e) {
			setIsLoading(false);
		}
	}, []);

	return (
		<>
			{!showProductActions ? (
				<div className="dashboard-wrapper">
					<div className="welcome-wrapper">
						<h2>
							Welcome back, {props.userName}!{" "}
							{Boolean(props.notificationNumber) && (
								<b>
									You have {props.notificationNumber}{" "}
									{props.notificationNumber > 1 ? "opportunities" : "opportunity"}.
								</b>
							)}
						</h2>
					</div>
					<div className="wrapper">
						<ActionCard
							backgroundImageName={"ingredient_recommendation_background"}
							title={
								props.recommendations?.[0]?.recommended_ingredients?.[0]?.jf_display_name
									? "Ingredient Recommendation"
									: undefined
							}
							name={props.recommendations?.[0]?.recommended_ingredients?.[0]?.jf_display_name}
							descriptions={`Swap ${props.recommendations?.[0]?.ingredient.jf_display_name} for \n${props.recommendations?.[0]?.recommended_ingredients?.[0]?.jf_display_name} in ${props.recommendations?.[0]?.product.name}`}
							buttonText="View Swap >"
							onPress={() => {
								if (props.recommendations?.[0]?.product?.id) {
									props.history.push(
										routes.PRODUCTS +
											`/${props.recommendations?.[0]?.product?.id}?recommendationId=${props.recommendations?.[0].id}`
									);
								}
							}}
						/>
						<div className="wrapper__options-big">
							<ActionCard
								image={projectImg}
								backgroundImageName={"background"}
								title="Add New Product"
								descriptions={"Launch your next product \nwith recommendations"}
								buttonText="Add >"
								onPress={() => {
									setShowProductActions(true);
								}}
							/>

							<PortfolioScoreCard
								image={analyticsImg}
								backgroundImageName={"portfolio_score"}
								title="Portfolio Score"
								descriptions={"Review analytics and \ncreate report to stay \non track"}
								score={score}
								// onPress={() => {
								// 	props.history.push(routes.ANALYSIS);
								// }}
							/>
						</div>
					</div>
					<div className="wrapper margin-top">
						<ActionCard
							image={analyticsImg}
							backgroundImageName={"analytics_background"}
							title="Analytics"
							descriptions={"Review analytics and \ncreate report to stay \non track"}
							buttonText="Review  >"
							onPress={() => {
								props.history.push(routes.ANALYSIS);
							}}
						/>
						<ActionCard
							image={ingredientImg}
							backgroundImageName={"ingredient_insights"}
							title="Get Ingredient Insights"
							descriptions={"Star ingredients \nto get weekly email \ninsights"}
							buttonText="Ingredients >"
							onPress={() => {
								props.history.push(routes.INGREDIENTS);
							}}
						/>

						<ActionCard
							image={inviteUser}
							backgroundImageName={"invite_teammate"}
							title="Invite Teammates"
							descriptions={"Onboard your team for \nbetter collaboration"}
							buttonText="Invite >"
							onPress={() => {
								props.history.push(routes.COMPANY + `?tab=users`);
							}}
						/>
					</div>
					<div className="wrapper margin-top">
						<DashboardWhitePaper
							allWhitePapers={allWhitePapers}
							groupedWhitePapers={groupedWhitePapers}
						/>
					</div>

					<div className="recommendation-section">
						<div className="recommendation-section__comment">
							<div className="comment__content">Dive back into your recent viewed.</div>
							<div
								className="comment__link"
								onClick={() => {
									props.history.push(routes.INGREDIENTS);
								}}
							>
								Search {props.allIngredientsNumber} ingredients &gt;&gt;
							</div>
						</div>

						<div className="content ingredient-section__content">
							<Loader isLoading={Boolean(isLoading)}>
								<Col xl="12" className="all-ingredients-list col-lg-12 scroll-y">
									{props.viewedIngredients.map((item: any) => (
										<IngredientItem
											manufacturer={item.manufacturer}
											mini={false}
											key={item.id}
											rank={item.rank}
											id={item.id}
											name={item.jf_display_name}
											suitability={item.suitability_score || 0}
											onClick={() => {
												props.history.push(routes.INGREDIENTS + `/${item.id}`);
											}}
											onBookmarkHandler={
												props.role === USER_ROLES.COMPANY_USER ||
												props.role === USER_ROLES.COMPANY_ADMIN
													? () => {
															// question
															// onAddIngredientToPortfolioHandler;
													  }
													: null
											}
										/>
									))}
								</Col>
							</Loader>
						</div>
					</div>
					<div className="d-flex  justify-content-center ">
						<Button
							className="button-primary button-style btn-lg"
							color="primary"
							outline
							onClick={() => {
								props.history.push(routes.INGREDIENTS);
							}}
						>
							View more
						</Button>
					</div>
					<div className="view-more"></div>
					<div className="products-section">
						<div className="products-section__comment">
							<div className="comment__content">Dive back in to your products.</div>
							<div
								className="comment__link"
								onClick={() => {
									props.history.push(routes.PRODUCTS);
								}}
							>
								See all {props.productsNumber} products &gt;&gt;{" "}
							</div>
						</div>

						<div className="content products-section__content">
							<Loader type="spin" isLoading={Boolean(isLoading)}>
								<Row>
									<Col xl="11" className="concept-products-list col-md-12">
										{props.viewedProducts.map((item: any) => (
											<ProductItem product={item} productType={PRODUCT_TYPE.CONCEPT} />
										))}
									</Col>
								</Row>
							</Loader>
						</div>
						<div className="d-flex  justify-content-center mt-4 mb-5">
							<Button
								className="button-primary button-style btn-lg"
								color="primary"
								outline
								onClick={() => {
									props.history.push(routes.PRODUCTS);
								}}
							>
								View all products
							</Button>
						</div>
					</div>
				</div>
			) : (
				<DashboardProductAction
					{...props}
					onClose={() => {
						setShowProductActions(false);
					}}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	subscription_type: state.user.profile?.company.subscription_type,
	userName: state.user.profile?.first_name,
	role: state.user.role,
	notificationNumber:
		state.notification?.all?.filter(
			(n: INotification) => !n.is_read && n.message !== "New Ingredient Added"
		).length || 0,
	viewedIngredients: state.ingredient?.recentlyViewed || [],
	allIngredientsNumber: state.ingredient?.ingredients?.pagination?.total || 0,
	productsNumber: state.product?.active?.pagination?.total,
	viewedProducts: state.product.recentlyViewed,
	recommendations: state.recommendations.all
});

const mapDispatchToProps = {
	getUserProfile: () => userService.getUserProfile(),
	getViewedIngredients: () => ingredientService.getRecentlyViewed(),
	getAllIngredient: () => ingredientService.getIngredients(),
	getActiveProducts: () => productService.getActiveProducts(),
	getViewedProducts: () => productService.getRecentlyViewed(),
	getWhitepaper: () => reportService.getWhitepaper(),
	getIngredientStatus: (id: string) => ingredientService.getIngredientStatus(id),
	addIngredientToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	getAllRecommendations: () => recommendationService.getAllRecommendations(),
	getPortfolioScore: () => dashboardService.getPortfolioScore()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
