import React, { useCallback, useEffect, useState } from "react";
import { Box, ShoppingCart } from "react-feather";
import { Col, Container, Row } from "reactstrap";
import { IProduct } from "../../../../../interfaces/products";

interface IProps {
	currentProduct: IProduct;
	comparisonProduct: IProduct;
	isMain?: boolean;
}

const CostComparison: React.FC<IProps> = ({
	currentProduct,
	comparisonProduct,
	isMain = false,
	...props
}) => {
	const [marginDiff, setMarginDiff] = useState<number>();

	const formatCost = (value: number | undefined) => {
		return value ? "$" + value?.toFixed(2) : 0;
	};

	const getMarginDiff = useCallback(() => {
		const diff = currentProduct.cost_margin - comparisonProduct.cost_margin;
		setMarginDiff(diff);
	}, [currentProduct, comparisonProduct]);

	const formatMarginDiff = () => {
		if (marginDiff == undefined || isMain) return currentProduct.cost_margin + "%";

		const diffSign = Math.sign(marginDiff);
		switch (diffSign) {
			case 0:
				return currentProduct.cost_margin.toFixed(0) + "%";
			case 1:
				return (
					<span style={{ color: "#0ed7ac" }}>
						{`
							${currentProduct.cost_margin} (+ ${marginDiff.toFixed(0)}%)
						`}
					</span>
				);
			case -1:
				return (
					<span style={{ color: "#e85a73" }}>
						{`
							${currentProduct.cost_margin} (+ ${marginDiff.toFixed(0)}%)
						`}
					</span>
				);
			default:
				return currentProduct.cost_margin + "%";
		}
	};

	useEffect(() => {
		getMarginDiff();
	}, [getMarginDiff]);

	return (
		<Container className={"float-bottom"}>
			<Col className={"cost-comparison"}>
				<Row className={"header"}>
					<div>
						<span>Cost</span>
					</div>
					<div>
						<span>Margin:</span>
						<span>{formatMarginDiff()}</span>
					</div>
				</Row>
				<Row className={"cost-card"}>
					<div className="cost-row">
						<Box color={"#f4a850"} />
						<span>Product Cost</span>
						<span>{formatCost(currentProduct?.cost)}</span>
					</div>
					<div className="cost-row">
						<ShoppingCart color={"#3f65f1"} />
						<span>Retail Cost</span>
						<span>{`${formatCost(currentProduct?.markup)}`}</span>
					</div>
				</Row>
			</Col>
		</Container>
	);
};

export default CostComparison;
