import React, { useEffect, useRef, useState } from "react";
import Slider from "../../../components/slider";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import IngredientItem from "./ingredientItem";
import { History } from "history";
import { routes } from "../../routes";
import { IIngredient } from "../../../interfaces/ingredient";
import { IApplicationState } from "../../../redux/reducers";
import ingredientService from "../../../services/ingredientService";
import { Loader } from "react-feather";
import RecentItem from "../../../components/common/recentlyViewed/recentItem";
import { useContainerDimensions } from "../../../utils/hooks";

interface IProps extends RouteComponentProps {
	ingredients: IIngredient[];
	history: History;
	getStarredIngredients: () => Promise<any>;
}
const StarredIngredients: React.FC<IProps> = (props) => {
	const [starredLoading, setStarredLoading] = useState<boolean>(false);
	const sliderWidthRef = useRef<HTMLDivElement>(null);
	const { width: sliderWidth } = useContainerDimensions(sliderWidthRef);

	useEffect(() => {
		setStarredLoading(true);
		props.getStarredIngredients().finally(() => {
			setStarredLoading(false);
		});
	}, []);

	function title() {
		return (
			<span className="title">
				<h4 className="mr-2 font-weight-semibold">Starred Ingredients </h4>
				{starredLoading ? (
					<Loader className="fa-spin" />
				) : (
					<h4 className="count color-bg-blue font-weight-normal">{props.ingredients.length}</h4>
				)}
			</span>
		);
	}

	return (
		<div className="starred-ingredients" ref={sliderWidthRef}>
			<Slider
				title={title}
				itemPerPage={Math.floor(sliderWidth / 150)}
				loading={starredLoading}
				items={props.ingredients}
				// render={(item: any) => <IngredientItem rank={item.rank} id={item.id} name={item.name} suitability={item.suitability_score} manufacturer={item.manufacturer} onClick={() => props.history.push(routes.INGREDIENTS + `/${item.id}`)} />}
				render={(item: any) => (
					<RecentItem
						item={item}
						type={"ingredients"}
						starred
						onClick={() => props.history.push(routes.INGREDIENTS + `/${item.id}`)}
					/>
				)}
				control={true}
			/>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	ingredients: state.ingredient.starred
});

const mapDispatchToProps = {
	getStarredIngredients: () => ingredientService.getStarredIngredients()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StarredIngredients));
