import { IPendingUserRequestAction, IPendingUserResponseAction, IAdminResponseAction, ISelectProductAction, IAdminProducPagination, IAdminReportRequestAction, IAdminReportRequestPagination, IAdminReportsAction, IAdminReportsPagination, IAdminEmailScheduleReponseAction, IAllUsersAction } from "./adminAction";
import { admin } from "../actionContants";
import {
	IPendingUserRequest,
	IPendingUserResponse,
	IFetchAdminProductResponse,
	IFetchAdminReportRequestsResponse,
	IFetchAdminReportsResponse,
	IEmailSchedule
} from "../../../interfaces/admin";
import { IProduct } from "../../../interfaces/products";
import { IUser } from "../../../interfaces/user";

export function pendingUserRequestAction(
	payload: IPendingUserRequest,
	resolve?: Function,
	reject?: Function
): IPendingUserRequestAction {
	return {
		type: admin.PENDING_USER_REQUEST,
		payload,
		resolve,
		reject
	};
}

export function pendingUserResponseAction(
	payload: IPendingUserResponse
): IPendingUserResponseAction {
	return {
		type: admin.PENDING_USER_RESPONSE,
		payload
	};
}

export function fetchAdminProductResponseAction(
	payload: IFetchAdminProductResponse
): IAdminResponseAction {
	return {
		type: admin.ADMIN_PRODUCTS_RESPONSE,
		payload
	};
}

export function selectProductAction(payload: IProduct): ISelectProductAction {
	return {
		type: admin.SELECT_PRODUCT,
		payload
	};
}

export function paginateAdminProducts(page: number): IAdminProducPagination {
	return {
		type: admin.PAGINATE_ADMIN_PRODUCT,
		payload: page
	};
}

export function fetchAdminReportRequestsAction(
	payload: IFetchAdminReportRequestsResponse
): IAdminReportRequestAction {
	return {
		type: admin.ADMIN_REPORT_REQUESTS_RESPONSE,
		payload
	};
}

export function paginateAdminReportRequests(
	page: number
): IAdminReportRequestPagination {
	return {
		type: admin.PAGINATE_ADMIN_REPORT_REQUEST,
		payload: page
	};
}

export function fetchAdminReportsAction(
	payload: IFetchAdminReportsResponse
): IAdminReportsAction {
	return {
		type: admin.ADMIN_REPORTS_RESPONSE,
		payload
	};
}

export function paginateAdminReports(page: number): IAdminReportsPagination {
	return {
		type: admin.PAGINATE_ADMIN_REPORTS,
		payload: page
	};
}

export function fetchEmailScheduleResponse(payload: IEmailSchedule) : IAdminEmailScheduleReponseAction {
    return {
        type: admin.EMAIL_SCHEDULE_RESPONSE,
        payload
    }
}

export function allUsersResponseAction(payload: IUser[]) : IAllUsersAction {
    return {
        type: admin.ALL_USER_RESPONSE,
        payload
    }
}
