import React, { MouseEventHandler } from "react";
import Button from "../../../components/common/button";
import { IReport } from "../../../interfaces/report";
import { FileText, Download, Upload, Eye } from "react-feather";
import moment from "moment";
import { Link } from "react-router-dom";


interface IProps {
  report: IReport,
  onClick?: MouseEventHandler
}

const ReportRow: React.FC<IProps> =  ({
  report, onClick
}) => {
  return (
    <tr>
      <td className="w-20">{ report.report_type || "Report Type" }</td>
      <td className="w-20">{ moment(report.request_date).format('MMM DD YYYY HH:mm a') }</td>
      <td className="w-30">{ report.requested_by.name }</td>
      <td className="actions d-flex justify-content-end">
        <span className={`${report.status.toLowerCase()} mr-2`}>
            <FileText size={17} className="mr-2" />
            { report.status.toLowerCase() }
        </span>
        { report.status === "UPLOADED" ? (
          <Link to={`${report.report_uri}`} target="_blank">
            <Button color="primary"><Download size={17} className="mr-2" />Download</Button>
          </Link>
        ) : (
          <Button outline color="primary" onClick={onClick}><Upload size={17} className="mr-2" />Upload Report</Button>
        )}
      </td>
    </tr>
  )
 
}

export default ReportRow;