export const BEYOND_INGREDIENTS_SAMPLE: { [index: string]: any } = {
	macro_nutrients: {
		protein: {
			unit: "g",
			value: 0.52
		},
		carbohydrates: {
			sub_nutrients: {
				dietary_fiber: {
					unit: "g",
					value: 2.61
				},
				total_sugars: {
					unit: "g",
					value: 17.4
				},
				added_sugars: {
					unit: "g",
					value: 2.49
				}
			}
		},
		fiber: {
			sub_nutrients: {
				dietary_fiber: {
					unit: "g",
					value: 8.7
				},
				soluble_fiber: {
					unit: "g",
					value: 7.1
				},
				insoluble_fiber: {
					unit: "g",
					value: 1.6
				}
			}
		},
		sugar: {
			sub_nutrients: {
				added: {
					unit: "g",
					value: 8.3
				},
				monosaccharides: {
					unit: "g",
					value: 49.7
				}
			}
		},
		galactose: {
			unit: "g",
			value: 0
		},
		glucose: {
			unit: "g",
			value: 18.1
		},
		fructose: {
			unit: "g",
			value: 31.6
		},
		disaccharides: {
			unit: "g",
			value: 8.3
		},
		lactose: {
			unit: "g",
			value: 0
		},
		sucrose: {
			unit: "g",
			value: 8.2
		},
		maltose: {
			unit: "g",
			value: 0
		},
		other_carbs: {
			unit: "g",
			value: 1.8
		},
		fat: {
			sub_nutrients: {
				saturated_fat: {
					unit: "g",
					value: 0.06
				},
				trans_fat: {
					unit: "g",
					value: 0
				},
				polyunsaturated_fat: {
					unit: "g",
					value: 0.07
				},
				monounsaturated_fat: {
					unit: "g",
					value: 0.6
				}
			}
		},
		cholesterol: {
			unit: "mg",
			value: 0
		},
		water: {
			unit: "g",
			value: 15.0
		},
		ash: {
			unit: "g",
			value: 2.4
		},
		sodium: {
			unit: "mg",
			value: 0
		}
	},
	micro_nutrients: {
		vitamin_d: {
			unit: "mg",
			value: 0.5
		},
		calcium: {
			unit: "mg",
			value: 43.1
		},
		iron: {
			unit: "mg",
			value: 0.1
		},
		potassium: {
			unit: "mg",
			value: 415.96
		}
	},
	calories: {
		unit: "kJ",
		value: 133.88
	}
};

export const CHOCOLATE_CHIPS: { [index: string]: any } = {
	macro_nutrients: {
		protein: {
			unit: "g",
			value: 1.0
		},
		carbohydrates: {
			sub_nutrients: {
				dietary_fiber: {
					unit: "g",
					value: 0.0
				},
				total_sugars: {
					unit: "g",
					value: 8.0
				},
				added_sugars: {
					unit: "g",
					value: 0.0
				},
				other_carbs: {
					unit: "g",
					value: 1
				}
			}
		},
		fiber: {
			sub_nutrients: {
				dietary_fiber: {
					unit: "g",
					value: 0.0
				},
				soluble_fiber: {
					unit: "g",
					value: 0.0
				},
				insoluble_fiber: {
					unit: "g",
					value: 0.0
				}
			}
		},
		sugar: {
			sub_nutrients: {
				added: {
					unit: "g",
					value: 9.0
				},
				monosaccharides: {
					unit: "g",
					value: 0.0
				}
			}
		},
		galactose: {
			unit: "g",
			value: 0
		},
		glucose: {
			unit: "g",
			value: 0.0
		},
		fructose: {
			unit: "g",
			value: 0.0
		},
		disaccharides: {
			unit: "g",
			value: 0.0
		},
		lactose: {
			unit: "g",
			value: 0
		},
		sucrose: {
			unit: "g",
			value: 0.0
		},
		maltose: {
			unit: "g",
			value: 0
		},
		other_carbs: {
			unit: "g",
			value: 1.0
		},
		fat: {
			sub_nutrients: {
				saturated_fat: {
					unit: "g",
					value: 2.5
				},
				trans_fat: {
					unit: "g",
					value: 0.0
				},
				polyunsaturated_fat: {
					unit: "g",
					value: 1.5
				},
				monounsaturated_fat: {
					unit: "g",
					value: 0.0
				}
			}
		},
		cholesterol: {
			unit: "mg",
			value: 5.0
		},
		water: {
			unit: "g",
			value: 0.0
		},
		ash: {
			unit: "g",
			value: 2.4
		},
		sodium: {
			unit: "mg",
			value: 10
		}
	},
	micro_nutrients: {
		vitamin_d: {
			unit: "mg",
			value: 0.5
		},
		calcium: {
			unit: "mg",
			value: 20.0
		},
		iron: {
			unit: "mg",
			value: 0.1
		},
		potassium: {
			unit: "mg",
			value: 40
		}
	},
	calories: {
		unit: "kJ",
		value: 70.0
	}
};

export const NUTRIENT_ZEROS: { [index: string]: any } = {
	macro_nutrients: {
		protein: {
			unit: "g",
			value: 0
		},
		carbohydrates: {
			sub_nutrients: {
				dietary_fiber: {
					unit: "g",
					value: 0
				},
				total_sugars: {
					unit: "g",
					value: 0
				},
				added_sugars: {
					unit: "g",
					value: 0
				}
			}
		},
		fiber: {
			sub_nutrients: {
				dietary_fiber: {
					unit: "g",
					value: 0
				},
				soluble_fiber: {
					unit: "g",
					value: 0
				},
				insoluble_fiber: {
					unit: "g",
					value: 0
				}
			}
		},
		sugar: {
			sub_nutrients: {
				added: {
					unit: "g",
					value: 0
				},
				monosaccharides: {
					unit: "g",
					value: 0
				}
			}
		},
		galactose: {
			unit: "g",
			value: 0
		},
		glucose: {
			unit: "g",
			value: 0
		},
		fructose: {
			unit: "g",
			value: 0
		},
		disaccharides: {
			unit: "g",
			value: 0
		},
		lactose: {
			unit: "g",
			value: 0
		},
		sucrose: {
			unit: "g",
			value: 0
		},
		maltose: {
			unit: "g",
			value: 0
		},
		other_carbs: {
			unit: "g",
			value: 0
		},
		fat: {
			sub_nutrients: {
				saturated_fat: {
					unit: "g",
					value: 0
				},
				trans_fat: {
					unit: "g",
					value: 0
				},
				polyunsaturated_fat: {
					unit: "g",
					value: 0
				},
				monounsaturated_fat: {
					unit: "g",
					value: 0
				}
			}
		},
		cholesterol: {
			unit: "mg",
			value: 0
		},
		water: {
			unit: "g",
			value: 0
		},
		ash: {
			unit: "g",
			value: 0
		},
		sodium: {
			unit: "mg",
			value: 0
		}
	},
	micro_nutrients: {
		vitamin_d: {
			unit: "mg",
			value: 0
		},
		calcium: {
			unit: "mg",
			value: 0
		},
		iron: {
			unit: "mg",
			value: 0
		},
		potassium: {
			unit: "mg",
			value: 0
		}
	},
	calories: {
		unit: "kJ",
		value: 0
	}
};

// Updated as of May 5, 2020
// source: https://www.fda.gov/food/new-nutrition-facts-label/daily-value-new-nutrition-and-supplement-facts-labels
export const DAILY_NUTRIENT_VALUES: { [index: string]: any } = {
	cholesterol: {
		unit: "mg",
		value: 300
	},
	iodine: {
		unit: "mcg",
		value: 150
	},
	iron: {
		unit: "mg",
		value: 18
	},
	protein: {
		unit: "g",
		value: 50
	},
	saturated_fat: {
		unit: "g",
		value: 20
	},
	added_sugars: {
		unit: "g",
		value: 50
	},
	choline: {
		unit: "mg",
		value: 550
	},
	biotin: {
		unit: "mcg",
		value: 30
	},
	chloride: {
		unit: "mg",
		value: 2300
	},
	chromium: {
		unit: "mcg",
		value: 35
	},
	copper: {
		unit: "mg",
		value: 0.9
	},
	folic_acid: {
		unit: "mcg",
		value: 400
	},
	molybdenum: {
		unit: "mcg",
		value: 45
	},
	niacin: {
		unit: "mg",
		value: 16
	},
	pantothenic_acid: {
		unit: "mg",
		value: 5
	},
	riboflavin: {
		unit: "mg",
		value: 1.3
	},
	selenium: {
		unit: "mcg",
		value: 55
	},
	sodium: {
		unit: "mg",
		value: 2300
	},
	thiamin: {
		unit: "mg",
		value: 1.2
	},
	carbohydrates: {
		unit: "g",
		value: 275
	},
	vitamin_a: {
		unit: "mcg",
		value: 900
	},
	vitamin_b6: {
		unit: "mg",
		value: 1.7
	},
	vitamin_b12: {
		unit: "mcg",
		value: 2.4
	},
	vitamin_e: {
		unit: "mg",
		value: 15
	},
	zinc: {
		unit: "mg",
		value: 11
	},
	calcium: {
		unit: "mg",
		value: 1300
	},
	dietary_fiber: {
		unit: "g",
		value: 28
	},
	fat: {
		unit: "g",
		value: 78
	},
	magnesium: {
		unit: "mg",
		value: 420
	},
	manganese: {
		unit: "mg",
		value: 420
	},
	phosphorus: {
		unit: "mg",
		value: 1250
	},
	potassium: {
		unit: "mg",
		value: 4700
	},
	vitamin_c: {
		unit: "mg",
		value: 90
	},
	vitamin_d: {
		unit: "mcg",
		value: 20
	},
	vitamin_k: {
		unit: "mcg",
		value: 120
	}
};
