import moment from "moment";
import React, { useState } from "react";
import { Loader } from "react-feather";
import { connect } from "react-redux";
import { CustomInput } from "reactstrap";
import { IProduct } from "../../interfaces/products";
import productService from "../../services/productService";
import { renderFeatureBox, renderNegativeFeatureBox } from "./utils";

interface IProps {
	version: IProduct;
	setCurrentVersion: (productId: string) => Promise<any>;
}

const VersionRow: React.FC<IProps> = ({ version, setCurrentVersion, ...props }) => {
	const [loading, setLoading] = useState<boolean>();

	const onChangeCurrentVersion = (version_id: string) => {
		setLoading(true);
		setCurrentVersion(version_id).finally(() => {
			setLoading(false);
		});
	};

	const handleVersionChange = () => {
		if (loading || !version.id) return;
		onChangeCurrentVersion(version.id);
	};
	return (
		<tr className="font-weight-semibold" key={version.id}>
			<td onClick={handleVersionChange}>
				{!loading ? (
					<CustomInput type="radio" label={version.version} />
				) : (
					<Loader className={"fa-spin"} />
				)}
			</td>
			<td>{moment(version.insertion_date).format("MM/DD/YY")}</td>
			<td>{renderFeatureBox("Nutrition", version.optimized_feature?.nutrition)}</td>
			<td>{renderNegativeFeatureBox("Supply Chain", version.optimized_feature?.supply_chain)}</td>
			<td>{renderNegativeFeatureBox("Cost", version.optimized_feature?.cost)}</td>
			<td>{renderFeatureBox("Sustainability", version.optimized_feature?.sustainability)}</td>
			<td>{renderFeatureBox("Popularity", version.optimized_feature?.popularity)}</td>
			{/* <td>{renderFeatureBox("Taste", version.optimized_feature?.taste)}</td>
			<td>{renderFeatureBox("Texture", version.optimized_feature?.texture)}</td> */}
		</tr>
	);
};

const mapDispatchToProps = {
	setCurrentVersion: (productId: string) => productService.setCurrentVersion(productId)
};

export default connect(null, mapDispatchToProps)(VersionRow);
