import {
	IReport,
	ITimeSavedData,
	IProductChangesData
} from "../../interfaces/analytics";
import { ActionType } from "../actions/action";
import { analytics } from "../actions/actionContants";

export interface IAnalyticsState {
	reports: IReport[];
	time_saved: ITimeSavedData[];
	time_saved_filter: number;
	product_changes: IProductChangesData[];
	product_changes_filter: number;
}

const INIT_STATE: IAnalyticsState = {
	reports: [],
	time_saved: [],
	time_saved_filter: 6,
	product_changes: [],
	product_changes_filter: 6
};

export default function (
	state: IAnalyticsState = INIT_STATE,
	action: ActionType
) {
	switch (action.type) {
		case analytics.GET_TIME_SAVED:
			return {
				...state,
				...action.payload
			};
		case analytics.FILTER_TIME_SAVED:
			return {
				...state,
				time_saved_filter: action.payload
			};
		case analytics.GET_PRODUCT_CHANGES:
			return {
				...state,
				...action.payload
			};
		case analytics.FILTER_PRODUCT_CHANGES:
			return {
				...state,
				product_changes_filter: action.payload
			};
	}

	return state;
}
