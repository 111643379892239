import React from "react";
import SuitabilityBar from "../../../components/suitabilityBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import { IPersona } from "../../../interfaces/persona";
import { Mic, MapPin, Check } from "react-feather";
import { HexToRgba } from "../../../utils/color";
import Preference from "./preference";
import ProgressGradient from "../../../components/common/progressGradient";
import ReactImageFallback from "react-image-fallback";

interface IProps {
	mini?: boolean;
	persona?: IPersona;
}

const PersonaItem: React.FC<IProps> = ({ mini, persona }) => {
	return (
		<div className={`persona ${mini ? "mini" : ""}`}>
			{!mini && (
				<>
					<div
						className="top-gradient d-flex justify-content-end align-items-center"
						style={{
							backgroundImage: `linear-gradient(to bottom, ${HexToRgba(
								persona?.card_color || "",
								0.7
							)}, ${persona?.card_color})`
						}}
					>
						{/* <MapPin className="fa fa-map-marker-alt color-white mr-3" /> */}
					</div>
				</>
			)}
			<div className="d-flex flex-column flex-grow-1 position-relative">
				{persona?.image_url ? (
					<ReactImageFallback
						src={persona.image_url}
						alt=""
						fallbackImage={
							"https://w0.pngwave.com/png/129/94/computer-icons-avatar-icon-design-male-teacher-png-clip-art-thumbnail.png"
						}
					/>
				) : (
					<img
						src="https://w0.pngwave.com/png/129/94/computer-icons-avatar-icon-design-male-teacher-png-clip-art-thumbnail.png"
						alt=""
					/>
				)}
				<div className="product-info flex-grow-1">
					<h4 className="mb-0">{persona?.persona_number}</h4>
					<div className="stats mt-3 mb-2">
						<div>
							<p className="type">Age: </p>
							<p className="value">{persona?.age}</p>
						</div>
						<div>
							<p className="type">Code: </p>
							<p className="value code">{persona?.persona_code}</p>
						</div>
						{!mini && (
							<>
								<div>
									<p className="type">Ethnicity: </p>
									<p className="value">{persona?.race}</p>
								</div>
								<div className="mt-2">
									<p className="type">Disposible Income: </p>
									<p className="value">{persona?.income}</p>
								</div>
							</>
						)}
					</div>
					{!mini && (
						<PerfectScrollbar className="pb-4">
							<div className="portfolio-preference-list">
								{persona?.top_preferences.map((preference, index) => {
									return <Preference preference={preference} key={index} />;
								})}
							</div>
						</PerfectScrollbar>
					)}
				</div>
				{!mini && (
					<div
						style={{
							display: "flex",
							background: "var(--very-light-blue)",
							marginLeft: 24,
							width: "80%",
							borderRadius: 6,
							height: 40,
							justifyContent: "space-around",
							alignItems: "center",
							padding: 5
						}}
					>
						<span className="d-flex align-items-center">
							<Check className={`color-bg-${persona?.online ? "green" : "yellow"} mr-2`} />
							<p className="mb-0">Online</p>
						</span>
						<span className="d-flex align-items-center">
							<Check className={`color-bg-${persona?.supermarket ? "green" : "yellow"} mr-2`} />
							<p className="mb-0">Supermarket</p>
						</span>
					</div>
				)}
				{!mini && (
					<div style={{ padding: "15px 24px 24px 24px" }}>
						<ProgressGradient
							color="#f4a850"
							title={"Persona"}
							height={6}
							value={persona?.suitability_score || 0}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default PersonaItem;
