import React, { useState } from "react";
import ReactImageFallback from "react-image-fallback";
import { Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import config from "../../../utils/config";
import { ImageSkeleton } from "../../../utils/ghostUI";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";

const TabElement = ({ data }: any) => {
	const [showElementNumber, setShowElementNumber] = useState(0);
	const image = require("../../../assets/images/dashboard/ingredients.png");

	return (
		<div className="options-body">
			<div className="d-flex flex-row align-items-center">
				<div
					className="left-arrow"
					onClick={() => {
						setShowElementNumber((number: number) => {
							return number === 0 ? number : --number;
						});
					}}
				>
					<svg
						width="12"
						height="20"
						viewBox="0 0 12 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M11 18.88L1 10.0772L11 1.27441"
							stroke="#041928"
							strokeOpacity="0.5"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="pl-3 flex-fill">
					<div className="content__body">
						<div className="options-body__heading">{data?.[showElementNumber]?.name}</div>
						<div className="options__content wite_paper">
							{data?.[showElementNumber]?.description}
							{/* <NewlineText text={props.descriptions} /> */}
						</div>
					</div>

					<div className="options--body__footer">
						<a
							target="_blank"
							href={`${data?.[showElementNumber]?.pdf_link}`}
							className="options__link"
						>
							Read More &gt;
						</a>
						<div className={"option__img"}>
							<img src={config.api.endpoints.ingredients.ingredientImage(data.image)} />
						</div>
					</div>
				</div>

				<div
					className="right-arrow"
					onClick={() => {
						setShowElementNumber((number: number) => {
							return number === data.length - 1 ? number : ++number;
						});
					}}
				>
					<svg
						width="12"
						height="21"
						viewBox="0 0 12 21"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1 19.5212L11 10.7183L1 1.91553"
							stroke="#141C3F"
							strokeOpacity="0.5"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};

const DashboardWhitePaper = (props: any) => {
	const [activeTab, setActiveTab] = useState("0");
	const image = require("../../../assets/images/dashboard/scoup_background.png");

	return (
		// <div className="wrapper__options">
		<Card className="wrapper__options reset_padding_left justify-content-between">
			<Nav tabs>
				{Boolean(props.allWhitePapers?.length) && (
					<NavItem>
						<NavLink
							className={activeTab === "0" ? "nav-active-item" : ""}
							onClick={() => {
								setActiveTab("0");
							}}
						>
							All
						</NavLink>
					</NavItem>
				)}
				{Boolean(props.groupedWhitePapers) &&
					Object.keys(props.groupedWhitePapers).map((key: string, index: number) => (
						<NavItem>
							<NavLink
								className={activeTab === (index + 1).toString() ? "nav-active-item" : ""}
								onClick={() => {
									setActiveTab((index + 1).toString());
								}}
							>
								{key}
							</NavLink>
						</NavItem>
					))}
			</Nav>
			<TabContent activeTab={activeTab}>
				{Boolean(props.allWhitePapers?.length) && (
					<TabPane tabId="0">
						<TabElement data={props.allWhitePapers} />
					</TabPane>
				)}
				{Boolean(props.groupedWhitePapers) &&
					Object.keys(props.groupedWhitePapers).map((key: string, index: number) => (
						<TabPane tabId={(index + 1).toString()}>
							<TabElement data={props.groupedWhitePapers[key]} />
						</TabPane>
					))}
			</TabContent>
			<div className={"bottom-image"}>{<img src={image} />}</div>
		</Card>
		// </div>
	);
};

export default DashboardWhitePaper;
