import React, { useState } from "react";
import RegisterForm from "./form/registerForm";
import { History } from "history";
import RegisterSuccess from "./success/success";
interface IProps {
	history: History;
}

const Register: React.FC<IProps> = (props) => {
	const [modal, setModal] = useState(false);
	return (
		<div className="register">
			{!modal && <RegisterForm setModal={setModal} />}
			{modal && <RegisterSuccess {...props}></RegisterSuccess>}
		</div>
	);
};

export default Register;
