import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../routes/routes';
import crownIcon from '@iconify-icons/mdi/crown';
import { Icon, InlineIcon } from '@iconify/react';

interface IProps { 
    message?: string;
}
const Upgrade: React.FC<IProps> = (props) => {
    return (
        <div className='upgrade-prompt'>
                <Link to={routes.UPGRADE}> 
                    <span>
                        <Icon style={{ marginRight: 'auto', color: '#fff'}}icon={crownIcon}/> Upgrade <br/>
                    </span>
                {/* { props.message } */}
                </Link>
        </div>
    )
}

export default Upgrade;