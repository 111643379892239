import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../components/common/button";

import { 
    Table, 
    Modal, 
    ModalHeader, 
    ModalFooter, 
    ModalBody,
    CustomInput, 
    Label,
  } from "reactstrap";
import Switch from "react-switch";
import { IApplicationState } from "../../redux/reducers";
import companyService from "../../services/companyService";
import integrationService from "../../services/integrationService";
import { ICompany } from "../../interfaces/company";
import { IIntegration } from "../../interfaces/integration";
import { Edit, X, Repeat } from "react-feather";
import { integration } from "../../redux/actions/actionContants";

interface IProps {
    companies: ICompany[];
    getCompanies: () => Promise<any>;
    integrations: IIntegration[];
    getIntegrations: () => Promise<any>;
    updateCompanyIntegrations: (company: ICompany, integrations: IIntegration[]) => Promise<any>;
}

const INIT_COMPANY = {
    id: '',
    email: '',
    name: '',
    company_goal: '',
    company_objective: '',
    integrations: [],
    plant_based: ''
};

const IntegrationsTable: React.FC<IProps> = ({ companies, getCompanies, integrations, getIntegrations, updateCompanyIntegrations }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [company, setCompany] = useState<ICompany>();

    const toggleModal = () => {
        setModal(!modal)
    }

    const selectCompany = (company: ICompany) => {
        setCompany(company)
        setModal(true)
    }

    useEffect(() => {
        setLoading(true)
        Promise.all([getCompanies(), getIntegrations()]).finally(() => {
            setLoading(false)
        })
    }, [])

    const onChange = (integration: IIntegration, checked: Boolean) => {
        let lists: IIntegration[] = (company?.integrations || [])
        const filtered = lists.filter(i => i.id === integration.id)
        let flag = false;
        if (filtered.length > 0 && !checked) {
            flag = true
            lists = lists.filter(i => i.id !== integration.id)
        }
        if (filtered.length === 0 && checked) {
            flag = true
            lists.push(integration)
        }
        if (flag) {
            const newState = company || INIT_COMPANY;
            newState.integrations = lists;
            setCompany(newState)
            updateCompanyIntegrations(company || INIT_COMPANY, lists)
        }
    }

    const isChecked = (id: string) => {
        return (company?.integrations || []).filter(i => i.id === id).length > 0
    }

    return (
        <div className="user-requests">

            <Table responsive hover>
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th>Email</th>
                        <th>Integrations</th>
                        <th className="actions d-flex justify-content-end">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        companies.map((company: ICompany) => (

                            <tr key={company.id}>
                                <td className="w-20" style={{ verticalAlign: "middle" }}>{company.name}</td>
                                <td className="w-20" style={{ verticalAlign: "middle" }}>{company.email}</td>
                                <td className="w-40" style={{ verticalAlign: "middle" }}>{company.integrations?.map((integration: IIntegration) => {
                                    return (
                                        <img src={integration.thumbnail} style={{ height: '35px', marginRight: 10 }} />
                                    )
                                })}</td>
                                <td className="w-20">
                                    <div className="actions">
                                      <Button outline color="primary" onClick={() => selectCompany(company)} className="btn-block"><Edit size={16} className="mr-2" />Edit</Button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </Table>


            <Modal isOpen={modal} size={'lg'}>
                <ModalHeader>
                    <Repeat className="color-bg-blue mr-2"  size={24}/>
                    { company?.name }
                    <X className="float-right" onClick={toggleModal} />
                </ModalHeader>
                <ModalBody>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>Integrations:</th>
                                <th></th>
                                <th className="actions d-flex justify-content-end">status:</th>
                            </tr>
                        </thead>
                        <tbody>
                            { integrations.map((integration: IIntegration) => {
                                return (
                                    <tr key={integration.id}>
                                        <td className="w-20">
                                            {
                                                <img src={integration.thumbnail} style={{ height: '35px' }} />
                                            }
                                        </td>
                                        <td className="w-60" style={{ verticalAlign: "middle" }}>{ integration.name }</td>
                                        <td className="w-20 text-right" style={{ verticalAlign: "middle" }}>
                                            {/* <Label> */}
                                                <Switch
                                                    onChange={checked => onChange(integration, checked)}
                                                    checked={isChecked(integration.id)}
                                                    onColor="#3F65F1"
                                                    onHandleColor="#2693e6"
                                                    handleDiameter={21}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    height={20}
                                                    width={40}
                                                />


                                            {/* </Label> */}
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>

                </ModalBody>
                <ModalFooter>
                    {/* <Button 
                    className="color-white mr-2" color="primary" 
                    onClick={() => {}}
                    disabled={true}
                    >
                        Save
                    </Button> */}

                </ModalFooter>
            </Modal>


        </div>
    )
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        companies: state.company.companies,
        integrations: state.integration.integrations,
    }
}

const mapDispatchToProps = ({
    getCompanies: () => companyService.getCompanies(),
    updateCompanyIntegrations: (company: ICompany, integrations: IIntegration[]) => companyService.updateCompanyIntegrations(company, integrations),
    getIntegrations: () => integrationService.getIntegrations()
})



export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsTable);