import { Api } from "./api";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import config from "../utils/config";

export class CommonService extends Api {
	async getAllMarkets() {
		try {
			const response = await this.http.get(config.api.endpoints.common.markets);
			return response.data;
		} catch (error) {
			this.handleError(error, "Error Retrieving Markets");
		}
	}
}

export default new CommonService();
