import React, { useEffect, useRef, useState } from "react";
import Slider from "../../slider";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import IngredientItem from "../../../routes/ingredients/components/ingredientItem";
import ProductItem from "../../../routes/products/components/productItem";
import { History } from "history";
import { routes } from "../../../routes/routes";
import { IIngredient } from "../../../interfaces/ingredient";
import { IApplicationState } from "../../../redux/reducers";
import ingredientService from "../../../services/ingredientService";
import { Loader } from "react-feather";
import { IProduct } from "../../../interfaces/products";
import productService from "../../../services/productService";
import { IPaginateList } from "../../../interfaces/pagination";
import RecentItem from "./recentItem";
import { useContainerDimensions } from "../../../utils/hooks";
import { uniqBy } from "../../../utils/array";
import { IBrand } from "../../../interfaces/company";

interface IProps extends RouteComponentProps {
	recentProducts: any[];
	recentIngredients: any[];
	history: History;
	type: string;
	title?: string;
	selected_brand: IBrand;
	getRecentProducts: () => Promise<any>;
	getRecentIngredients: () => Promise<any>;
}
const RecentlyViewed: React.FC<IProps> = ({ recentProducts, recentIngredients, ...props }) => {
	const sliderWidthRef = useRef<HTMLDivElement>(null);
	const { width: sliderWidth } = useContainerDimensions(sliderWidthRef);
	const [loading, setLoading] = useState<boolean>(false);
	const [recentItems, setRecentItems] = useState<any[]>([]);

	useEffect(() => {
		setLoading(true);
		switch (props.type) {
			case "products":
				props.getRecentProducts().then((res: any) => {
					setRecentItems(uniqBy(res, (i: any) => i.id));
					setLoading(false);
				});
				return;
			case "ingredients":
				props.getRecentIngredients().then((res: any) => {
					setRecentItems(uniqBy(res, (i: any) => i.id));
					setLoading(false);
				});
				return;
		}
	}, []);

	useEffect(() => {
		switch (props.type) {
			case "products":
				setRecentItems(recentProducts);
				return;
			case "ingredients":
				setRecentItems(recentIngredients);
				return;
		}
	}, [recentProducts, recentIngredients]);

	useEffect(() => {
		if (props.type != "products") return; // Filter by brand only available for recent products
		setLoading(true);
		props.getRecentProducts().then((res: any) => {
			setRecentItems(uniqBy(res, (i: any) => i.id));
			setLoading(false);
		});
	}, [props.selected_brand]);

	function title() {
		return (
			<span className="title">
				<h4 className="mr-2 font-weight-semibold">
					{props.title ? props.title : "Recently Viewed"}
				</h4>
				{loading ? <Loader className="fa-spin" /> : <></>}
			</span>
		);
	}

	return (
		<div className="starred-ingredients" ref={sliderWidthRef}>
			<Slider
				title={title}
				// itemPerPage={props.type == 'ingredients' ? 4 : 8}
				itemPerPage={Math.floor(sliderWidth / 150)}
				loading={loading}
				items={recentItems}
				render={(item: any) => {
					return props.type == "ingredients" ? (
						<RecentItem
							item={item}
							type={props.type}
							onClick={() => props.history.push(routes.INGREDIENTS + `/${item.id}`)}
						/>
					) : (
						<RecentItem
							item={item}
							type={props.type}
							onClick={() => props.history.push(routes.PRODUCTS + `/${item.id}`)}
						/>
					);
				}}
				control={true}
			/>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	recentProducts: state.product.recentlyViewed,
	recentIngredients: state.ingredient.recentlyViewed,
	selected_brand: state.user.selected_brand
});

const mapDispatchToProps = {
	getRecentProducts: () => productService.getRecentlyViewed(),
	getRecentIngredients: () => ingredientService.getRecentlyViewed()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecentlyViewed));
