import React, { useState } from "react";
import StarRating from "react-svg-star-rating";
import { Button, Col, Row } from "reactstrap";

interface IProps {
	rate: number;
	onSubmit: (starNumber: number) => void;
}

const RecommendationRate: React.FC<IProps> = ({ onSubmit, rate }) => {
	const [starNumber, setStarNumber] = useState<number>(0);
	return (
		<>
			<Row className="flex-column ml-5 mt-5 align-items-center">
				<span
					className="mb-1"
					style={{
						fontFamily: "Biotif",
						fontWeight: 600,
						color: "#041928",
						fontSize: "16px",
						textAlign: "center",
						paddingTop: "70px",
						paddingBottom: "70px"
					}}
				>
					{"Rate this recommendation"}
				</span>
				<StarRating
					containerClassName={"start-container"}
					size={50}
					unit="full"
					initialRating={rate || 0}
					emptyColor="#FFFFFF"
					activeColor="#FFBC10"
					hoverColor="#FFBC10"
					handleOnClick={setStarNumber}
				/>

				<Button
					style={{ marginTop: "65px" }}
					className={"recommendation-btn-success btn-success"}
					onClick={() => {
						onSubmit(starNumber);
					}}
				>
					Submit
				</Button>
			</Row>
		</>
	);
};

export default RecommendationRate;
