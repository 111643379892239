import React, { useEffect, useState } from "react";
import { Label, Row, Col } from "reactstrap";
import Select, { OptionsType } from "react-select";
import { Box, X } from "react-feather";
import Button from "../../../../components/common/button";
import commonService, { CommonService } from "../../../../services/commonService";
import { uniqBy } from "../../../../utils/array";
import companyService from "../../../../services/companyService";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../redux/reducers";
import userService from "../../../../services/userService";
import { ICompany } from "../../../../interfaces/company";
import { idText } from "typescript";

interface IProps {
	company: any | undefined;
	getUserProfile: () => Promise<any>;
	saveMarkets: (markets: { id: string }[]) => Promise<any>;
	removeMarket: (id: string) => Promise<any>;
}
const Markets: React.FC<IProps> = ({ company, getUserProfile, saveMarkets, removeMarket }) => {
	const [markets, setMarkets] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>();
	const [selected, setSelected] = useState<string[]>([]);

	useEffect(() => {
		setLoading(true);
		Promise.all([
			commonService.getAllMarkets().then((res: any) => {
				setMarkets(res);
			}),
			getUserProfile()
		]).finally(() => {
			setLoading(false);
			setSelected(company.markets || []);
		});
	}, []);

	const onSelectTypes = (option: any) => {
		const updated = selected.concat(option.value.id);
		setSelected(updated);
	};

	const marketOptions = () => {
		return markets
			.filter((m: any) => !selected.includes(m.id))
			?.map((market: any) => {
				return {
					label: market.name,
					value: market
				};
			});
	};

	const onSave = () => {
		setLoading(true);
		const formatted = selected.map((id: string) => ({ id: id }));
		saveMarkets(formatted)
			.then(
				getUserProfile // Update user profile in store
			)
			.finally(() => {
				setLoading(false);
			});
	};

	const onRemoveMarket = (market: any) => {
		setSelected(selected.filter((id: any) => id != market.id));
		setLoading(true);
		removeMarket(market.id)
			.then(getUserProfile)
			.finally(() => {
				setLoading(false);
			});
	};

	const renderedCompanyMarkets = () => {
		const companyMarkets = markets.filter((m: any) => selected.includes(m.id));
		return companyMarkets.map((m: any) => {
			return (
				<span className="market-tag">
					<span className="remove-pref" onClick={() => onRemoveMarket(m)}>
						<X size={12} />
					</span>
					{m.name}
				</span>
			);
		});
	};

	return (
		<>
			<div
				className={"market-pref"}
				style={{ borderBottom: "1px dashed #cedbe9", paddingBottom: 15, paddingTop: 11 }}
			>
				<Row style={{ marginBottom: 12 }}>
					<Col>
						{/* <Label for="Markets" className="color-battleship-grey">Ingredient Supplier</Label> */}
						<Select
							styles={{
								menu: (provided) => ({
									...provided,
									zIndex: 11
								})
							}}
							name="Markets"
							options={marketOptions()}
							isDisabled={loading}
							onChange={onSelectTypes}
							isSearchable={true}
							controlShouldRenderValue={false}
						/>
					</Col>
				</Row>
				<Row className={"mt-3"} style={{ paddingLeft: 15 }}>
					{renderedCompanyMarkets()}
				</Row>
			</div>
			<div style={{ paddingTop: 17 }}>
				<p>
					These preferences will help tailor our product suggestions and filter your <br />
					live searching whilst using the app.
				</p>
				<Button
					className="btn btn-primary color-white ls-5 font-weight-semibold mt-3"
					type="submit"
					onClick={onSave}
				>
					Save account settings
				</Button>
			</div>
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	company: state.user.profile?.company
});

const mapDispatchToProps = {
	getUserProfile: () => userService.getUserProfile(),
	saveMarkets: (markets: { id: string }[]) => companyService.updateCompanyMarkets(markets),
	removeMarket: (id: string) => companyService.removeCompanyMarket(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(Markets);
