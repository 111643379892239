import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PublicRoute from "../components/hoc/PublicRoute";
import PrivateRoute from "../components/hoc/PrivateRoute";
import App from "./app";
import { ConnectedRouter } from "connected-react-router";
import history from "../utils/history";
import Auth from "./auth"
import Design from "./design";
import { connect } from "react-redux";
import { IApplicationState } from "../redux/reducers";
import { USER_ROLES } from "../interfaces/roles";


const Router: React.FC<{ role: string }> = (props) => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <PublicRoute path="/auth" component={Auth} />
                <PrivateRoute path="/app" component={App} />
                <Route path="/design" component={Design} />
                <Redirect exact path="/" to={props.role === USER_ROLES.ADMIN ? "/app/admin" : "/app"} />
                <Route component={() => <div>Not found</div>} />
            </Switch>
        </ConnectedRouter>
    )
}

const mapStateToProps = (state: IApplicationState) => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(Router);