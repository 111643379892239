import React, { useEffect, useState } from "react";
import EmailScheduleForm from "./components/forms/emailScheduleForm/EmailScheduleForm";
import { connect, useSelector } from "react-redux";
import adminService from "../../services/adminService";
import { IEmailSchedule } from "../../interfaces/admin";
import Button from "../../components/common/button";
import Loader from "../../components/loader";
import { IApplicationState } from "../../redux/reducers";


interface IProps {
    update: (schedule: IEmailSchedule) => Promise<any>;
    fetchEmailSchedule: () => Promise<any>;
    emailSchedule: IEmailSchedule;

}
const EmailSchedule: React.FC<IProps> = (
    props
) => {
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        setLoading(true);
        props.fetchEmailSchedule().finally(() => {
            setLoading(false)
        })
    }, [])
    return (
        <div style={{ padding: "10px 0" }}>

            <div className="email-schedule">
                <Loader isLoading={loading}>
                    <EmailScheduleForm update={props.update} emailSchedule={props.emailSchedule} />
                </Loader>
            </div>

        </div>
    )
}

const mapStateToProps = (state: IApplicationState) => ({
    emailSchedule: state.admin.emailSchedule
})

const mapDispatchToProps = {
    update: (schedule: IEmailSchedule) => adminService.updateEmailSchedule(schedule),
    fetchEmailSchedule: () => adminService.getEmailSchedule()
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSchedule);