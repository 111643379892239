import React from "react";
import StripeClimateBadge from "../../assets/images/stripe-climate.png";

const StripeClimate: React.FC = ({ ...props }) => {
	return (
		<div
			className={"mt-2"}
			style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}
		>
			<img src={StripeClimateBadge} width={40} height={40} className={"mr-3"} />
			<span>
				Journey Foods will contribute{" "}
				<strong style={{ fontStyle: "bold" }}>1% of your purchase</strong> to remove CO<sub>2</sub>{" "}
				from the atmosphere.
			</span>
		</div>
	);
};

export default StripeClimate;
