export const CurrencyFormat = (value: number = 0, currency: string): string => {
	return Intl.NumberFormat("en-US", {
		style: "currency",
		currency: currency
	}).format(value / 100);
};

export const currencyFormatCentsToDollars = (value: number, currency: string): string => {
	return Intl.NumberFormat("en-US", {
		style: "currency",
		currency: currency
	}).format(value / 100);
};
