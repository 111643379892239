import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "../../redux/reducers";
import { INotification } from "../../interfaces/notification";
import notificationService from "../../services/notificationService";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { Loader, ChevronDown, Sliders, Star, X } from "react-feather";
import NotificationTile from "./components/notificationTile";
import { DEMO_NOTIFICATIONS } from "./demo-notifications";
import { IUser } from "../../interfaces/user";
import Upgrade from "../../components/common/upgrade";
import NotificationModal from "./components/notificationModal";
import recommendationService from "../../services/recommendationService";
import { IRecommendation } from "../../interfaces/recommendation";
import { getNotificationIcon } from "../../utils/notification";
import { generateNotificationDetails } from "./components/utils/notification";

interface IProps {
	notifications: INotification[];
	recommendations: IRecommendation[];
	getAllNotifications: () => Promise<any>;
	getAllRecommendations: () => Promise<any>;
	profile?: IUser | undefined;
}

const Notifications: React.FC<IProps> = ({
	notifications,
	recommendations,
	getAllRecommendations,
	getAllNotifications,
	profile
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [notification, setNotification] = useState<INotification>();
	const [modal, toggleModal] = useState<boolean>(false);
	const [filterMenu, toggleFilterMenu] = useState<string>();
	const [filters, setFilters] = useState<string[]>([]);
	const [timeFilter, setTimeFilter] = useState<string | undefined>(undefined);

	useEffect(() => {
		setLoading(true);
		Promise.all([getAllNotifications(), getAllRecommendations()]).finally(() => {
			setLoading(false);
		});
	}, []);

	const handleViewNotification = (notification: INotification) => {
		setNotification(notification);
		toggleModal(true);
	};

	const handleFilterChange = (filter: string, type: string) => {
		switch (type) {
			case "type":
				filter = filter.toLowerCase();
				!filters?.includes(filter)
					? setFilters(filters?.concat(filter))
					: setFilters(filters?.filter((f) => f != filter));
				break;
			case "date":
				timeFilter != filter ? setTimeFilter(filter) : setTimeFilter(undefined);
				break;
		}
	};

	const filterByDate = (notifications: any[] | INotification[]) => {
		const daysApart = (date: Date) => {
			const diffInMs = Math.abs(new Date().getTime() - date.getTime());
			return diffInMs / (1000 * 60 * 60 * 24);
		};
		switch (timeFilter) {
			case "Today":
				return notifications.filter((n) => {
					let diff = daysApart(new Date(n.created_at));
					return diff <= 1;
				});
			case "Last Week":
				return notifications.filter((n) => {
					let diff = daysApart(new Date(n.created_at));
					return diff <= 7;
				});
			case "Last Month":
				return notifications.filter((n) => {
					let diff = daysApart(new Date(n.created_at));
					return diff <= 30;
				});
			default:
				return notifications;
		}
	};

	const filterNotifications = (notifications: any[] | INotification[]) => {
		let filtered = sortByDate(notifications);
		if (filters.length > 0) {
			filtered = filterByType(filtered);
		}
		if (timeFilter != undefined) {
			filtered = filterByDate(filtered);
		}
		return filtered;
	};

	const filterByType = (notifications: any[] | INotification[]) => {
		return filters.length > 0
			? notifications.filter((n) => filters.includes(n.message_type.toLowerCase()))
			: notifications;
	};

	const sortByDate = (notifications: any[]) => {
		return notifications.sort(function (a, b) {
			return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
		});
	};

	return (
		<div>
			<span className="notification-page-header">
				<h4 className="page-title">All Notifications</h4>
			</span>
			<span className={"filters"}>
				<span className={"filter-title"}>
					<Sliders color={"#657280"} size={24} /> Filters:{" "}
				</span>
				{/* Type Filter */}
				<div
					onMouseOver={() => toggleFilterMenu("type")}
					onMouseLeave={() => toggleFilterMenu(undefined)}
					className="filter"
				>
					<span className={!(filters.length > 0) ? "filter-menu" : "filter-menu active"}>
						Type
						<ChevronDown size={16} />
					</span>
					{filterMenu == "type" ? (
						<ul className="list-filters">
							<li
								className={filters?.includes("recommendation") ? "active" : ""}
								onClick={(e) => handleFilterChange(e.currentTarget.innerHTML, "type")}
							>
								Recommendation
							</li>
							<li
								className={filters?.includes("ingredient") ? "active" : ""}
								onClick={(e) => handleFilterChange(e.currentTarget.innerHTML, "type")}
							>
								Ingredient
							</li>
							<li
								className={filters?.includes("product") ? "active" : ""}
								onClick={(e) => handleFilterChange(e.currentTarget.innerHTML, "type")}
							>
								Product
							</li>
							<li
								className={filters?.includes("regulation") ? "active" : ""}
								onClick={(e) => handleFilterChange(e.currentTarget.innerHTML, "type")}
							>
								Regulation
							</li>
						</ul>
					) : (
						""
					)}
				</div>
				{/* Time Filter */}
				<div
					onMouseOver={() => toggleFilterMenu("created_on")}
					onMouseLeave={() => toggleFilterMenu(undefined)}
					className="filter"
				>
					<span className={timeFilter == undefined ? "filter-menu" : "filter-menu active"}>
						Created On
						<ChevronDown size={16} />
					</span>
					{filterMenu == "created_on" ? (
						<ul className="list-filters">
							<li
								className={timeFilter === "Today" ? "active" : ""}
								onClick={() => handleFilterChange("Today", "date")}
							>
								Today
							</li>
							<li
								className={timeFilter === "Last Week" ? "active" : ""}
								onClick={() => handleFilterChange("Last Week", "date")}
							>
								Last Week
							</li>
							<li
								className={timeFilter === "Last Month" ? "active" : ""}
								onClick={() => handleFilterChange("Last Month", "date")}
							>
								Last Month
							</li>
						</ul>
					) : (
						""
					)}
				</div>
				{/* Market Filter */}
				{/* <div className='filter' onMouseOver={() => toggleFilterMenu('market')} onMouseLeave={() => toggleFilterMenu(undefined)}>
                        <span className={'filter-menu'}>Market<ChevronDown size={16}/></span>
                        { filterMenu == 'market' ? 
                        <ul className='list-filters'>
                            <li className={timeFilter === 'Today' ? 'active' : ''} onClick={()=>{}}>USA</li>
                            <li className={timeFilter === 'Last Week' ? 'active' : ''} onClick={()=>{}}>CAN</li>
                            <li className={timeFilter === 'Last Month' ? 'active' : ''} onClick={()=>{}}>CHN</li>
                        </ul> : '' } 
                    </div> */}
				{/* Brand Filter */}
				{/* <div onMouseOver={() => toggleFilterMenu('brand')} onMouseLeave={() => toggleFilterMenu(undefined)} className='filter'>
                        <span  className={'filter-menu'}>Brand<ChevronDown size={16}/></span>
                        { filterMenu == 'brand' ? 
                        <ul className='list-filters'>
                            <li className={timeFilter === 'Today' ? 'active' : ''} onClick={()=>{}}>Journey Bites</li>
                            <li className={timeFilter === 'Last Week' ? 'active' : ''} onClick={()=>{}}>Journey Fruits</li>
                            <li className={timeFilter === 'Last Month' ? 'active' : ''} onClick={()=>{}}>Concept Brand</li>
                        </ul> : '' } 
                    </div> */}
			</span>
			{loading ? (
				<Loader className="fa-spin mr-2" />
			) : (
				<Table className={"clean-table"} responsive hover>
					<colgroup>
						<col />
						<col />
						<col />
						<col />
						<col className="progress-col" />
						<col />
					</colgroup>
					<thead>
						<tr>
							<th></th>
							<th>Date</th>
							<th>Type</th>
							<th>Market</th>
							<th>Brand</th>
							<th>Description</th>
							<th>Progress</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{/* TEMPORARILY FILTER 'New Ingredient Added' notifications */}
						{/* {notifications.map((notification) => <NotificationTile onView={handleViewNotification} notification={notification}/>)} */}
						{filterNotifications(
							notifications
								// .concat(DEMO_NOTIFICATIONS)
								.filter((n: INotification) => n.message !== "New Ingredient Added")
						).map((notification) => (
							<NotificationTile onView={handleViewNotification} notification={notification} />
						))}
					</tbody>
				</Table>
			)}
			{notification && (
				<NotificationModal
					isOpen={modal}
					notification={notification}
					toggleModal={() => toggleModal(false)}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	notifications: state.notification.all,
	profile: state.user.profile,
	recommendations: state.recommendations.all
});

const mapDispatchToProps = {
	getAllNotifications: () => notificationService.getAllNotifications(),
	getAllRecommendations: () => recommendationService.getAllRecommendations()
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
