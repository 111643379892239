import React, { useEffect } from "react";
import { Package } from "react-feather";
import { X } from "react-feather";
import { isObject } from "util";

interface IProps {
	isOpen: boolean;
	toggleForm: () => void;
	title: string;
	reset?: () => void;
}

const SideForm: React.FC<IProps> = (props) => {
	useEffect(() => {
		if (props.reset) {
			props.reset();
		}
	}, [props.isOpen]);

	const getFormIcon = () => {
		if (props.title == "Edit Ingredient" || props.title == "Add Ingredient")
			return <i className="mr-3 icon-nav-ingredients" style={{ color: "#f4a850", fontSize: 36 }} />;
		if (props.title == "Edit Product" || props.title == "Add Product")
			return <Package size={46} className="color-bg-blue mr-3" />;

		return <Package size={46} className="color-bg-blue mr-3" />;
	};

	return (
		<div className={`side-form ${props.isOpen ? "show" : ""}`}>
			<div className="overlay" onClick={props.toggleForm} />

			<div className="content">
				<div className="title">
					<div className="d-flex align-items-center">
						{getFormIcon()}
						<h3 className="mb-0">{props.title}</h3>
					</div>

					<div onClick={props.toggleForm}>
						{" "}
						<X />
					</div>
				</div>
				{props.children}
			</div>
		</div>
	);
};

export default SideForm;
