import React from "react";
import { Edit, Trash } from "react-feather";
import { Button, Table } from "reactstrap";
import { IBrand } from "../../../../../interfaces/company";

interface IProps {
	handleBrandRemoveSelect: (brand: IBrand) => void;
	handleBrandEditSelect: (brand: IBrand) => void;
	brands: IBrand[];
}
export const BrandTable: React.FC<IProps> = ({
	brands,
	handleBrandEditSelect,
	handleBrandRemoveSelect,
	...props
}) => {
	return (
		<Table className="company-brand-list">
			<colgroup>
				<col />
				<col className="small-right" />
				<col className="small-right" />
			</colgroup>
			<thead>
				<tr>
					<th>Brand</th>
					<th align="right"></th>
					<th align="right"></th>
				</tr>
			</thead>
			<tbody>
				{brands.map((item) => (
					<tr>
						<td>{item.name}</td>
						<td align="right">
							<Button
								className="edit-btn-success"
								style={{
									display: "flex",
									alignItems: "center",
									backgroundColor: "white",
									border: "1px solid #cedbe9",
									boxShadow: "none"
								}}
								onClick={() => {
									handleBrandEditSelect(item);
								}}
							>
								<Edit style={{ height: 16, marginRight: 5, color: "#3f65f1" }} />
								Edit
							</Button>
						</td>
						<td align="right">
							<Button
								className="remove-brand"
								onClick={() => {
									handleBrandRemoveSelect(item);
								}}
							>
								<Trash />
							</Button>
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default BrandTable;
