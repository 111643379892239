import React, { useEffect, useState } from "react";
import { ArrowRight, Check, DollarSign, Heart, Loader, Users } from "react-feather";
import { Button, Table, Tooltip } from "reactstrap";
import SustainabilityScore from "../../../assets/images/sustainability-scale-icon.svg";
import SuitabilityIcon from "../../../assets/images/suitability-score-icon2.svg";
import config from "../../../utils/config";
import { IRecommendation } from "../../../interfaces/recommendation";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "../../routes";
import { IIngredient, ISearchIngredient } from "../../../interfaces/ingredient";
import { toCamelCase, truncate } from "../../../utils/string";
import ReactImageFallback from "react-image-fallback";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import ingredientService from "../../../services/ingredientService";
import { connect } from "react-redux";
import { render } from "react-dom";

interface IRecommendedIngredientValue {
	ingredient: IIngredient;
	percent_diff: {
		persona: number;
		preference: number;
		cost: number;
		sustainability: number;
	};
}
interface IProps extends RouteComponentProps {
	recommendation: IRecommendation;
	getIngredientById: (id: string) => Promise<any>;
}

const RecommendedIngredientsTable: React.FC<IProps> = ({
	recommendation,
	getIngredientById,
	...props
}) => {
	const [tooltip, setToolTip] = useState<string>("");
	const [productIngredient, setProductIngredient] = useState<IIngredient>();
	const [recommendedIngredientValues, setRecommendedIngredientValues] = useState<
		IRecommendedIngredientValue[]
	>();
	const [recommendationValuesLoading, setRecommendationValuesLoading] = useState<boolean>();

	useEffect(() => {
		setRecommendationValuesLoading(true);
		if (recommendation.ingredient) {
			getIngredientById(recommendation.ingredient.id).then((res: any) => {
				setProductIngredient(res);
			});
		}
	}, []);

	useEffect(() => {
		getRecommendedIngredientDetails().then((res: IIngredient[] | undefined) => {
			if (res) {
				const recommendedValues = calculateRecommendationValues(res);
				setRecommendedIngredientValues(recommendedValues || []);
				setRecommendationValuesLoading(false);
			}
		});
	}, [productIngredient]);

	const getRecommendedIngredientDetails = async () => {
		const recommended = recommendation.recommended_ingredients;
		let recommendedDetails: IIngredient[] = [];
		if (productIngredient && recommended) {
			var results: IIngredient[] = await Promise.all(
				recommended.map(
					async (item: ISearchIngredient): Promise<IIngredient> => {
						const ingredient = await getIngredientById(item.id);
						return ingredient;
					}
				)
			);
			return results;
		}
	};

	const calculateRecommendationValues = (recommendedIngredientDetails: IIngredient[]) => {
		if (productIngredient) {
			return recommendedIngredientDetails.map((i: IIngredient) => ({
				ingredient: i,
				percent_diff: {
					persona: calculatePercentDiff(0, 0),
					preference: calculatePercentDiff(
						productIngredient.suitability_score,
						i.suitability_score
					),
					cost: calculatePercentDiff(
						parseFloat(productIngredient?.price || "0"),
						parseFloat(i?.price || "0")
					),
					sustainability: calculatePercentDiff(
						productIngredient.sustainability_score,
						i.sustainability_score
					)
				}
			}));
		}
	};

	const calculatePercentDiff = (curr: number = 0, recommended: number = 0) => {
		if (curr == 0 || recommended == 0) return 0;
		const percentDiff = parseFloat((((recommended - curr) / curr) * 100).toFixed(1));
		return percentDiff;
	};

	const renderPercentDiff = (percent_diff: number, type: string) => {
		let color = "#cedbe0";
		if (percent_diff != 0) {
			switch (type) {
				case "cost":
					percent_diff > 0 ? (color = "#e85a73") : (color = "#0ed7ac");
					break;
				default:
					percent_diff > 0 ? (color = "#0ed7ac") : (color = "#e85a73");
					break;
			}
		}
		return (
			<span style={{ color: color }}>
				{percent_diff != 0 && typeof percent_diff == "number" ? percent_diff + "%" : "-"}
			</span>
		);
	};

	return (
		<>
			<h6>Recommended Ingredients:</h6>
			{recommendationValuesLoading && !recommendedIngredientValues ? (
				<div
					className={"d-flex align-items-center justify-content-center"}
					style={{ width: "100%", height: 200, maxHeight: 200 }}
				>
					<Loader className="fa-spin" />
				</div>
			) : (
				<Table className="recommended-ingredients">
					<colgroup>
						<col className="img" />
						<col className="name" />
						<col className="score" />
						<col className="score" />
						<col className="score" />
						<col className="view" />
					</colgroup>
					<thead>
						<tr>
							<td></td>
							<td></td>
							<td>
								<Tooltip
									className="simple-tooltip"
									isOpen={tooltip == "cost"}
									placement="top"
									target="cost-icon"
									toggle={() => setToolTip(tooltip == "cost" ? "" : "cost")}
								>
									Improvement to product cost
								</Tooltip>
								<DollarSign id={"cost-icon"} color={"#0ed7ac"} />
							</td>
							<td>
								<Tooltip
									className="simple-tooltip"
									isOpen={tooltip == "sustainability"}
									placement="top"
									target="sustainability-icon"
									toggle={() => setToolTip(tooltip == "sustainability" ? "" : "sustainability")}
								>
									Improvement to product sustainability
								</Tooltip>
								<img id={"sustainability-icon"} src={SustainabilityScore} />
							</td>
							<td>
								<Tooltip
									className="simple-tooltip"
									isOpen={tooltip == "nutrition"}
									placement="top"
									target="nutrition-icon"
									toggle={() => setToolTip(tooltip == "nutrition" ? "" : "nutrition")}
								>
									Improvement to product nutritional content
								</Tooltip>
								<Heart id={"nutrition-icon"} color={"#e85a73"} />
							</td>
						</tr>
					</thead>
					<tbody>
						{recommendedIngredientValues?.map((rec: IRecommendedIngredientValue, index: number) => {
							return (
								<tr key={index}>
									<td>
										{/* <img
										src={config.api.endpoints.ingredients.ingredientImage(rec.jf_display_name)}
									/> */}
										<ReactImageFallback
											src={config.api.endpoints.ingredients.ingredientImage(
												rec.ingredient.jf_display_name || ""
											)}
											fallbackImage={getIngredientImagePlaceholder()}
											alt="Ingredient Image"
										/>
									</td>
									<td className="rec-ingredient-name">
										{toCamelCase(truncate(rec.ingredient.jf_display_name || "", 30))}
									</td>
									<td>{renderPercentDiff(rec.percent_diff.cost, "cost")}</td>
									<td>{renderPercentDiff(rec.percent_diff.sustainability, "sustainability")}</td>
									<td>{renderPercentDiff(rec.percent_diff.persona, "persona")}</td>
									<td>
										<Button
											className="std-view-btn"
											onClick={(e) => {
												e.preventDefault();
												props.history.push(routes.INGREDIENTS + "/" + rec.ingredient.id);
											}}
										>
											View <ArrowRight size={18} />
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			)}
		</>
	);
};

const mapDispatchToProps = {
	getIngredientById: (id: string) => ingredientService.getIngredientById(id)
};

export default connect(null, mapDispatchToProps)(withRouter(RecommendedIngredientsTable));
