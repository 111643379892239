import { ICompany } from "../../interfaces/company";
import { IProductAnalyticsData } from "../../interfaces/dashboard";
import { Action } from "history";
import { ActionType } from "../actions/action";
import { dashboard } from "../actions/actionContants";
import { IProduct, IProductPersonas } from "../../interfaces/products";

export interface IDashboardState {
	active_products_count: number;
	concept_products_count: number;
	star_ingredients_count: number;
	total_ingredients: number;
	total_products: number;
	total_users: number;
	manufacturers: number;
	company_info?: ICompany;
	product_analytics?: IProductAnalyticsData[];
	concept_products: IProduct[];
	active_products: IProduct[];
}

const INIT_STATE: IDashboardState = {
	active_products_count: 0,
	concept_products_count: 0,
	star_ingredients_count: 0,
	total_ingredients: 0,
	total_products: 0,
	total_users: 0,
	manufacturers: 0,
	product_analytics: [],
	active_products: [],
	concept_products: []
};

export default function (
	state: IDashboardState = INIT_STATE,
	action: ActionType
) {
	switch (action.type) {
		case dashboard.GET_DASHBOARD_OVERVIEW_RESPONSE:
			return {
				...state,
				active_products_count: action.payload.active_products_count,
				concept_products_count: action.payload.concept_products_count,
				star_ingredients_count: action.payload.star_ingredients_count,
				total_ingredients: action.payload.total_ingredients,
				total_products: action.payload.total_products,
				total_users: action.payload.total_users,
				manufacturers: action.payload.manufacturers
			};
		case dashboard.GET_DASHBOARD_ACTIVE_PRODUCT_RESPONSE:
			return {
				...state,
				active_products: action.payload
			};
		case dashboard.GET_DASHBOARD_CONCEPT_PRODUCT_RESPONSE:
			return {
				...state,
				concept_products: action.payload
			};
		case dashboard.GET_DASHBOARD_PRODUCT_ANALYTICS_RESPONSE:
			return {
				...state,
				product_analytics: action.payload.product_analytics_data
			};
	}
	return state;
}
