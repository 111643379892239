import React from "react";
import Dashboard from "./dashboard";
import { Route, Switch } from "react-router-dom";
import { routes } from "../routes";

const DashboardRouter: React.FC = () => (

    <Switch >
        <Route path={routes.DASHBOARD} component={Dashboard} />
    </Switch>
)



export default DashboardRouter;