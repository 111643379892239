import React from "react";
import { Table } from "reactstrap";
import { ISearchIngredient } from "../../../interfaces/ingredient";
import { IRecommendation } from "../../../interfaces/recommendation";
import RegulationRow from "./regulationRow";

interface IProps {
	recommendation: IRecommendation;
}

const RegulationTable: React.FC<IProps> = ({ recommendation, ...props }) => {
	return (
		<Table className="clean-table">
			<thead>
				<tr>
					<th>Ingredient</th>
					<th>Claim</th>
					<th>Country</th>
					<th>Regulations</th>
				</tr>
			</thead>
			<tbody>
				{recommendation?.recommended_ingredients?.map((i: ISearchIngredient) => {
					return <RegulationRow ingredient_id={i.id} />;
				})}
			</tbody>
		</Table>
	);
};

export default RegulationTable;
