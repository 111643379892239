import { ICompany } from "./company";

export enum REPORT_STATUSES {
	SUBMITTED = "SUBMITTED",
	UPLOADED = "UPLOADED"
}

export interface IReport {
	id: string;
	report_type: string;
	description: string;
	report_uri: string;
	status: REPORT_STATUSES;
	request_date: string;
	requested_by: ICompany;
}

export interface IReportNew {
	report_type: string;
	description: string;
	report_file: File;
	requested_by: string;
}
