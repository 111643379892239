import React from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import Button from "../../../../components/common/button";
import { History } from "history";

interface IProps {
	history: History;
}

const RegisterSuccess: React.FC<IProps> = (props) => {
	function navigateToLogin() {
		props.history.push(routes.LOGIN);
	}

	return (
		<Card className="register-success">
			<div className="register-success-icon">
				<svg
					width="44"
					height="44"
					viewBox="0 0 44 44"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M40.3333 20.3133V22C40.3311 25.9534 39.0509 29.8002 36.6838 32.9667C34.3166 36.1331 30.9893 38.4495 27.1981 39.5705C23.4069 40.6914 19.355 40.5568 15.6465 39.1867C11.9381 37.8167 8.77186 35.2845 6.62008 31.968C4.46831 28.6514 3.44627 24.7281 3.7064 20.7832C3.96652 16.8384 5.49487 13.0833 8.0635 10.078C10.6321 7.07267 14.1034 4.9782 17.9597 4.10694C21.8159 3.23567 25.8505 3.63429 29.4617 5.24333"
						stroke="#1DD6AE"
						strokeWidth="3.66667"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M40.3333 7.33333L22 25.685L16.5 20.185"
						stroke="#1DD6AE"
						strokeWidth="3.66667"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
			<div className="register-success-header">Registration completed successfully!</div>

			<div className="register-success-text">
				Please check your registered email to verify your account.
				<br /> Once you have verified your account, check back here to login and subscribe!
			</div>

			<Button className="register-success-btn" size="lg" onClick={navigateToLogin}>
				Login
			</Button>
		</Card>
	);
};

export default RegisterSuccess;
