import React from "react";
import Header from "../../../components/common/header";
import { Row, Col, Progress, Button, Form, Input } from "reactstrap";

class IngredientItem extends React.Component {
  render() {
    return (
      <>
        <Header />
        <data className="page-wrapper d-block">
          <div className="page-content container-fluid">
            <div className="labels mt-3">

              {/* products block */}
              <section className="labels__product-block">
                <Row>
                  <Col md="6">
                    <div className="product-block__wrapper custom-shadow border-radius-12 border-dashed position-relative d-flex p-4">
                      <Col xs="4">
                        <img width="184px" height="184px" src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq" alt="Ingredient Image" />
                      </Col>
                      <Col xs="8">
                        <h3 className="mb-3">Product label</h3>

                        <div className="product-type-list">
                          <div className="product-type-item m-0">
                            <i data-feather="mic"></i>
                            <p>Fruit Snack</p>
                          </div>
                        </div>

                        <div className="portfolio-preference-list">
                          <div className="portfolio-preference-item ml-0 mr-2">
                            <i data-feather="mic"></i>
                            <p>Vegan</p>
                          </div>
                          <div className="portfolio-preference-item ml-0 mr-2">
                            <i data-feather="mic"></i>
                            <p>Vegan</p>
                          </div>
                          <div className="portfolio-preference-item ml-0 mr-2">
                            <i data-feather="mic"></i>
                            <p>Vegan</p>
                          </div>
                        </div>

                        <p className="mt-2">
                          To request a custom label for your current or R&D products, please submit a request below and someone from our team will reach out to fulfill your inquiry.
                        </p>

                        <div className="d-flex">
                          <Col md="5" className="pl-0">
                            <h5 className="font-weight-normal">Serving size:</h5>
                            <p className="h5 font-weight-semibold">11 oz</p>
                          </Col>
                          <Col md="6">
                            <h5 className="font-weight-normal">Manufacturer:</h5>
                            <p className="h5 font-weight-semibold">Beyond Ingredients</p>
                          </Col>
                        </div>
                      </Col>
                      <Button color="secondary" className="button-top">Example</Button>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="p-4 bg-very-light-blue border-radius-12 h-100 d-flex flex-column">
                      <p>
                        To request a custom label for your current or R&D products, please submit a request below and someone from our team will reach out to fulfill your inquiry.
                      </p>
                      <Form className="flex-grow-1 d-flex flex-column">
                        <Input type="textarea" name="notes" id="notes" placeholder="Notes..." className="flex-grow-1" />
                        <div className="d-flex justify-content-end">
                          <Button color="primary" className="mt-4">
                            <i className="mr-2"/>
                          Connected
                        </Button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </section>

              {/* products list */}
              <section className="labels__product-list mt-5">
                <Row>
                  <Col xs="12">
                    <h5>Product labels</h5>
                  </Col>
                  <Col md="10">
                    <ul className="list-style-none">
                      <li className="border-bottom py-2 d-flex align-items-center">
                        <h5 className="mb-0 font-weight-normal flex-grow-5">Product label name example</h5>
                        <span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
                        <div className="d-flex">
                          <Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-upload"></i>
                            <p>Upload</p>
                          </Button>
                          <Button color="success ml-2">Save</Button>
                          <Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-eye"></i>
                            <p>View label</p>
                          </Button>
                        </div>
                      </li>
                      <li className="border-bottom py-2 d-flex align-items-center">
                        <h5 className="mb-0 font-weight-normal flex-grow-5">Product label name example</h5>
                        <span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
                        <div className="d-flex">
                          <Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-upload"></i>
                            <p>Upload</p>
                          </Button>
                          <Button color="success ml-2">Save</Button>
                          <Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-eye"></i>
                            <p>View label</p>
                          </Button>
                        </div>
                      </li>
                      <li className="border-bottom py-2 d-flex align-items-center">
                        <h5 className="mb-0 font-weight-normal flex-grow-5">Product label name example</h5>
                        <span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
                        <div className="d-flex">
                          <Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-upload"></i>
                            <p>Upload</p>
                          </Button>
                          <Button color="success ml-2">Save</Button>
                          <Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-eye"></i>
                            <p>View label</p>
                          </Button>
                        </div>
                      </li>
                      <li className="border-bottom py-2 d-flex align-items-center">
                        <h5 className="mb-0 font-weight-normal flex-grow-5">Product label name example</h5>
                        <span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
                        <div className="d-flex">
                          <Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-upload"></i>
                            <p>Upload</p>
                          </Button>
                          <Button color="success ml-2">Save</Button>
                          <Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-eye"></i>
                            <p>View label</p>
                          </Button>
                        </div>
                      </li>
                      <li className="border-bottom py-2 d-flex align-items-center">
                        <h5 className="mb-0 font-weight-normal flex-grow-5">Product label name example</h5>
                        <span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
                        <div className="d-flex">
                          <Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-upload"></i>
                            <p>Upload</p>
                          </Button>
                          <Button color="success ml-2">Save</Button>
                          <Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
                            <i className="fas fa-eye"></i>
                            <p>View label</p>
                          </Button>
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </section>

              {/* action buttons */}
              <span className="action-button">
                <button className="btn btn-circle btn btn-lg btn-primary"><i className="fa fa-plus fa-lg"></i>
                </button>
              </span>
            </div>
          </div>
        </data >
      </>
    )
  }
}


export default IngredientItem;


