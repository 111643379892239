import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "../routes";
import Packaging from "./packaging";
import PackagingDetail from "./packagingDetail";

const PackagingRouter: React.FC = () => (
  <Switch>
    <Route exact path={routes.PACKAGING} component={Packaging} />
    <Route exact path={routes.PACKAGING_DETAIL} component={PackagingDetail} />
  </Switch>
)

export default PackagingRouter;