import React from "react";
import { ChevronLeft, ChevronRight, PlusCircle, Plus, Loader } from "react-feather";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import "./sliderStyle.scss";
import Upgrade from "../common/upgrade";
import RecentlyViewedLoader from "../../routes/newDashboard/components/recentlyViewed/recentlyViewedLoader";
import { RecentItemSkeleton } from "../../utils/ghostUI";

export const BREAKPOINTS: { [key: number]: { slidesPerPage: number } } = {
	768: {
		slidesPerPage: 1
	},
	1024: {
		slidesPerPage: 2
	},
	1366: {
		slidesPerPage: 3
	},
	1920: {
		slidesPerPage: 4
	}
};

export interface ISliderProps {
	title: React.ComponentType<any>;
	items: any[];
	render: React.ComponentType<any>;
	itemPerPage?: number;
	containerClassname?: string;
	itemClassname?: string;
	control?: boolean;
	loading?: boolean;
	paginate?: Function;
	access?: string;
}

const Slider: React.FC<ISliderProps> = ({
	title: Title,
	items,
	render: Render,
	containerClassname,
	loading,
	itemPerPage,
	access
}) => {
	items = items || [];

	return (
		<div className={`${containerClassname || ""} slider-list`}>
			<div className="slider-title">
				<Title />
			</div>
			{access && access == "supplier" ? (
				<Upgrade />
			) : (
				<Carousel
					arrowLeft={!items.length ? <></> : <ChevronLeft />}
					arrowRight={!items.length ? <></> : <ChevronRight />}
					addArrowClickHandler={!loading}
					slidesPerPage={itemPerPage}
				>
					{loading
						? new Array(8).fill(0).map((item, index: number) => {
								return <RecentItemSkeleton key={index} />;
						  })
						: items.map((item, index: number) => {
								return <Render {...item} key={item.id + index} />;
						  })}
				</Carousel>
			)}
		</div>
	);
};

export default Slider;
