import React, { useState } from "react";
import { Card } from "reactstrap";
import ForgotPasswordForm from "./forms/forgotPasswordForm";
import { routes } from "../../routes";
import { Link } from "react-router-dom";
import PasswordInstruct from "./instruct/instruct";
import { History } from "history";

interface IProps {
	history: History;
}

const ForgotPassword: React.FC<IProps> = (props) => {
	const [notification, setNotification] = useState(false);
	const [email, setEmail] = useState("");

	return (
		<>
			{!notification ? (
				<Card className="forget-password">
					<div className="forget-password-icon">
						<svg
							width="44"
							height="44"
							viewBox="0 0 44 44"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#clip0_1304_8390)">
								<path
									d="M22 40.3334C32.1252 40.3334 40.3333 32.1253 40.3333 22.0001C40.3333 11.8749 32.1252 3.66675 22 3.66675C11.8747 3.66675 3.66663 11.8749 3.66663 22.0001C3.66663 32.1253 11.8747 40.3334 22 40.3334Z"
									stroke="#767676"
									strokeWidth="3.67"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M22 14.6667V22.0001"
									stroke="#767676"
									strokeWidth="3.67"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M22 29.3333H22.0183"
									stroke="#767676"
									strokeWidth="3.67"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
							<defs>
								<clipPath id="clip0_1304_8390">
									<rect width="44" height="44" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>
					<div className="forget-password-header">Forgot your password?</div>
					<ForgotPasswordForm setNotification={setNotification} setEmail={setEmail} />
					<div className="forget-password-action">
						<Link to={routes.LOGIN}>Back to login</Link>
					</div>
				</Card>
			) : (
				<div className="forget-password-notification">
					<PasswordInstruct email={email} {...props} />
				</div>
			)}
		</>
	);
};

export default ForgotPassword;
