import React, { useState } from "react";
import {
    Input,
    Modal, 
    ModalHeader, 
    ModalFooter, 
    ModalBody,
    Form, 
    FormGroup, 
    Label,
    InputGroup,
    Button as ReactstrapButton,
    ButtonGroup,
    Row,
    Col,
} from 'reactstrap';
import { ICompany } from "../../../interfaces/company";
import { COMPANY_PERMISSIONS } from "../../../interfaces/permissions";
import { IUser } from "../../../interfaces/user";

interface IProps {
    company: ICompany;
    permission: COMPANY_PERMISSIONS;
    handlePermissionChange: any;
    companyHasPermission: any;
}

const PermissionToggle: React.FC<IProps> = (props) => {
    const [updating, setUpdating] = useState<boolean>(false)

    function formatPermissionName(name:string) {
        return name.replace('_',' ').toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }
    
    return (
        <FormGroup className="mb-3">
            <Label>{formatPermissionName(props.permission)}</Label>
            <ButtonGroup className="d-flex" disabled={updating}>
                <ReactstrapButton className={'btn-permission-toggle'} color="primary" onClick={() => props.handlePermissionChange(props.company, props.permission, 'disable')} active={!props.companyHasPermission(props.company, props.permission)}>disabled</ReactstrapButton>
                <ReactstrapButton className={'btn-permission-toggle'} color="primary" onClick={() => props.handlePermissionChange(props.company, props.permission, 'enable')} active={props.companyHasPermission(props.company, props.permission)}>enabled</ReactstrapButton>
            </ButtonGroup>
        </FormGroup>
    );
}

export default PermissionToggle;