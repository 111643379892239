import React, { useState } from "react";
import { Plus } from "react-feather";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, Input } from "reactstrap";
import userService from "../../../../../services/userService";

interface IProps {
	addBrand: (name: string) => Promise<any>;
	title: string;
	style?: React.CSSProperties;
	className?: string;
}
const CreateCard: React.FC<IProps> = ({ addBrand, title, style, className = "", ...props }) => {
	const [isAddLoading, setAddLoading] = useState<boolean>(false);
	const [newBrandText, setBrandText] = useState<string>("");

	function createBrand() {
		if (newBrandText) {
			setAddLoading(true);
			addBrand(newBrandText).finally(() => {
				setAddLoading(false);
				setBrandText("");
			});
		}
	}

	return (
		<Card
			className={className}
			style={{ ...style, boxShadow: "0 5px 16px 0 rgba(57, 57, 57, 0.15)" }}
		>
			<CardHeader style={{ backgroundColor: "#e1ecf3", display: "flex", alignItems: "center" }}>
				<div style={{ marginRight: 14 }}>
					<Plus style={{ color: "#3f65f1" }} />
				</div>
				<div style={{ fontSize: 18, fontWeight: 600 }}>Add New {title}:</div>
			</CardHeader>
			<CardBody>
				<div className="d-flex" style={{ alignItems: "center", marginBottom: 14 }}>
					<Input
						className="w-100 px-3 py-2 font-16"
						label="Brand Name"
						name="brand_name"
						placeholder={`Example ${title}`}
						value={newBrandText}
						onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
							setBrandText(e.target.value)
						}
						style={{ borderRadius: 6, marginRight: 5 }}
					/>

					<Button
						disabled={!newBrandText || isAddLoading}
						className="color-white"
						color={"success"}
						loading={isAddLoading}
						onClick={createBrand}
						style={{ paddingTop: 9, paddingBottom: 9 }}
					>
						Create {title}
					</Button>
				</div>
			</CardBody>
		</Card>
	);
};

export default CreateCard;
