import React from "react";
import { OptionTypeBase, OptionProps, OptionsType, ValueType } from "react-select";
import { FormikValues, FormikErrors, FormikTouched, ErrorMessage } from "formik";
import Select from "react-select";
import CreateSelect from "react-select/creatable";
import { defaultStyles as ReactSelectStyles } from "../common/customSelect/customStyles";

export interface IOption {
	label: string;
	value: any;
}

interface ISelectProps {
	options: IOption[];
	onChange: (...args: any[]) => void;
	onBlur: (...args: any[]) => void;
	onCreate?: (inputValue: string) => void;
	customOnChange?: (option: ValueType<any>) => void;
	disabled?: boolean;
	name: string;
	multi?: boolean;
	value: any;
	errors: FormikErrors<FormikValues>;
	touched: FormikTouched<FormikValues>;
	loading?: boolean;
	isCreatable?: boolean;
	isClearable?: boolean;
	placeholder?: string;
	className?: string;
	classNamePrefix?: string;
	formatOptionLabel?: any;
}

export const SelectField: React.FC<ISelectProps> = ({
	options,
	onBlur,
	onChange,
	multi,
	value,
	name,
	loading,
	isCreatable,
	isClearable,
	onCreate,
	placeholder,
	className,
	classNamePrefix,
	customOnChange,
	disabled = false,
	formatOptionLabel
}) => {
	const handleChange = (value: ValueType<any>) => {
		onChange(name, value);
	};

	const handleBlur = () => {
		onBlur(name, true);
	};

	return (
		<div>
			{isCreatable ? (
				<CreateSelect
					isDisabled={disabled}
					onCreateOption={onCreate}
					isClearable={isClearable}
					isLoading={loading}
					placeholder={placeholder}
					options={options}
					className={className}
					id={name}
					classNamePrefix={classNamePrefix}
					isMulti={multi}
					onChange={handleChange}
					onBlur={handleBlur}
					value={value}
					style={{ width: "60px" }}
					styles={ReactSelectStyles(false, undefined, { border: "2px solid rgb(206, 219, 233)" })}
					formatOptionLabel={formatOptionLabel}
				/>
			) : (
				<Select
					isDisabled={disabled}
					onCreateOption={onCreate}
					isClearable={isClearable}
					isLoading={loading}
					placeholder={placeholder}
					options={options}
					id={name}
					classNamePrefix={classNamePrefix}
					isMulti={multi}
					onChange={customOnChange || handleChange}
					onBlur={handleBlur}
					value={value}
					style={{ width: "60px" }}
					styles={ReactSelectStyles(false, undefined, {
						border: "2px solid rgb(206, 219, 233)",
						paddingTop: 2,
						paddingBottom: 2,
						borderRadius: 6
					})}
					formatOptionLabel={formatOptionLabel}
				/>
			)}
			<ErrorMessage name={name} />
		</div>
	);
};
