import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "../routes";
import Products from "./products";
import ProductDetail from "./productDetail/productDetail";



const ProductRouter: React.FC = () => (
    <Switch>
        <Route exact path={routes.PRODUCTS} component={Products} />
        <Route exact path={routes.PRODUCT_DETAIL} component={ProductDetail} />
    </Switch>
)


export default ProductRouter;