import { INotification } from "../../interfaces/notification";
import { ActionType } from "../actions/action";
import { notification } from "../actions/actionContants";

export interface INotificationState {
	all: INotification[];
}

const INIT_STATE: INotificationState = {
	all: []
};

export default function (
	state: INotificationState = INIT_STATE,
	action: ActionType
) {
	switch (action.type) {
		case notification.GET_ALL_NOTIFICATIONS_RESPONSE:
			return {
				...state,
				all: action.payload
			};
	}

	return state;
}
