import { IGetAllPackagesResponse, ISelectPackage } from "./packaging.d";
import { packaging } from "../actionContants";

export function getAllPackagesAction(payload: any): IGetAllPackagesResponse {
	return {
		type: packaging.GET_ALL_PACKAGES_RESPONSE,
		payload
	};
}

export function selectPackageAction(payload: any): ISelectPackage {
	return {
		type: packaging.SELECT_PACKAGE,
		payload
	};
}
