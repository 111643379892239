import React from "react";
import { Switch, Route, NavLink, RouteComponentProps } from "react-router-dom";
import { routes } from "../routes";
import Login from "./login";
import Register from "./register";
import { IApplicationState } from "../../redux/reducers";
import { connect } from "react-redux";
import JF_LOGO_NEW from "../../assets/images/JF Horizontal Color.png";
import RecoveryPassword from "./recoveryPassword";
import ForgotPassword from "./forgotPassword";
interface IProps extends RouteComponentProps {
	isLoggedIn: boolean;
	location: any;
}

const AuthRouter: React.FC<RouteComponentProps> = (props) => (
	<div className="auth-container">
		<div className="image-container">
			<div className="logo">
				<img src={JF_LOGO_NEW} alt="Journey Foods Logo" width={309} />
			</div>
			<div className="alpha">
				<svg
					width="100%"
					height="100%"
					viewBox="0 0 615 588"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M-0.00928363 7.67191C117.556 39.2999 326.377 156.732 327.195 355.359C327.839 511.718 148.753 515.419 107.802 414.805C66.85 314.191 133.936 239.81 213.214 239.483C326.717 239.016 350 323.5 391.5 424C459.602 588.921 550.5 601 614.5 563.5"
						stroke="url(#paint0_linear_1267_452)"
						strokeWidth="12"
						strokeLinecap="square"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_1267_452"
							x1="615"
							y1="583"
							x2="-2.46904"
							y2="7.64984"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#67D05D" />
							<stop offset="0.302083" stopColor="#1DD6AE" />
							<stop offset="0.651042" stopColor="#17C9DC" />
							<stop offset="1" stopColor="#C747F4" />
						</linearGradient>
					</defs>
				</svg>
			</div>
		</div>
		<div className="auth-container">
			<header className="content-header">
				{props.location?.pathname === routes.LOGIN && (
					<>
						<span className="header-text">Need an account ? </span>
						<NavLink className="header-link" to={routes.REGISTER} activeClassName="active">
							Create account
						</NavLink>
					</>
				)}
				{props.location?.pathname === routes.REGISTER && (
					<>
						<span className="header-text">Already have a account ? </span>
						<NavLink className="header-link" to={routes.LOGIN} activeClassName="active">
							Login
						</NavLink>
					</>
				)}
			</header>
			<div className="logo-rightside">
				<img src={JF_LOGO_NEW} alt="Journey Foods Logo" width={309} />
			</div>
			<div className="content">
				<Switch>
					<Route exact path={routes.LOGIN} render={(props) => <Login {...props}></Login>} />
					<Route exact path={routes.REGISTER} component={Register} />
					<Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
					<Route exact path={routes.RECOVERY_PASSWORD} component={RecoveryPassword} />
				</Switch>
			</div>
		</div>
	</div>
);

const mapStateToProps = (state: IApplicationState) => ({
	isLoggedIn: state.user.isLoggedIn
});

export default connect(mapStateToProps, null)(AuthRouter);
