import { ISubscriptionDetails } from "../routes/account/billing/subscription/subscription";
import { currencyFormatCentsToDollars } from "./currency";

export const getSubscriptionPrice = (subscriptionDetails: ISubscriptionDetails) => {
	console.log(subscriptionDetails);
	const {
		subscription: {
			plan: { interval, amount }
		}
	} =
		subscriptionDetails && // 👈 null and undefined check
		Object.keys(subscriptionDetails).length > 0 &&
		Object.getPrototypeOf(subscriptionDetails) === Object.prototype
			? subscriptionDetails
			: {
					subscription: {
						plan: {
							interval: "",
							amount: 0
						}
					}
			  };
	return {
		price: currencyFormatCentsToDollars(amount || 0, "USD"),
		interval: interval || "month"
	};
};

export const isEmptyObject = (value: Object) => {
	return Object.keys(value).length === 0 && value.constructor === Object;
};
