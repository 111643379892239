import React, { useEffect, useState } from "react";
import { structurePreferences } from "../../../../utils/structurePreferences";
import { Box, ChevronLeft, ChevronRight } from "react-feather";

interface IProps {
	options: any[];
	currentlySelected?: any[];
	onSelectChange: (pref: any) => void; //Call back to update state based on selection change
}

const HierarchySelect: React.FC<IProps> = (props) => {
	const [selectedOptions, setSelectedOptions] = useState<any>({});
	const [options, setOptions] = useState<any>([]);

	useEffect(() => {
		setOptions(structurePreferences(props.options));
	}, [props.options]);

	const handleOptionsChange = (selected: any) => {
		setSelectedOptions({ ...selected });
	};

	return (
		<>
			{options.length > 0 ? (
				<OptionsList
					options={options}
					onChange={(selected: any) => handleOptionsChange(selected)}
					selectedOptions={selectedOptions}
					isFirst={true}
					onSelectChange={props.onSelectChange}
					currentlySelected={props.currentlySelected}
				/>
			) : (
				<></>
			)}
		</>
	);
};

interface IOptionsProps {
	options: any;
	selectedOptions: any;
	onChange: any;
	isFirst: any;
	onSelectChange: (pref: any) => void;
	currentlySelected?: any[];
}
const OptionsList: React.FC<IOptionsProps> = ({
	options,
	selectedOptions,
	onChange,
	isFirst,
	onSelectChange,
	...props
}) => {
	const handleCheckboxClicked = (selectedOption: any) => {
		let additionalText = `${
			selectedOption.id
		} is already selected, to deselect it we'll remove it from the current selectedOptions structure\n\n${JSON.stringify(
			selectedOptions,
			null,
			3
		)}`;
		if (selectedOptions[selectedOption.id]) {
			delete selectedOptions[selectedOption.id];
		} else {
			selectedOptions[selectedOption.id] = selectedOption.jf_id ? { id: selectedOption.jf_id } : {};
			additionalText = `${selectedOption.id} is not currently selected, to select it we'll add "${
				selectedOption.id
			}: {}" to the current selectedOptions structure\n\n${JSON.stringify(
				selectedOptions,
				null,
				3
			)}`;
		}
		if (selectedOption.jf_id) onSelectChange(selectedOption); // Callback if item and not just category
		onChange(selectedOptions);
	};

	const isSelected = (option: any) => {
		if (props.currentlySelected) {
			return props.currentlySelected?.findIndex((p: any) => p.id === option.jf_id) > -1;
		}
		return false;
	};

	const handleSubOptionsListChange = (optionId: string, subSelections: any) => {
		selectedOptions[optionId] = subSelections;
		onChange(selectedOptions);
	};

	return (
		<div>
			{options.map((option: any) => {
				return (
					<ul className={isFirst && "first"} key={option.id}>
						<Checkbox
							// selected={selectedOptions[option.id]}
							selected={isSelected(option)}
							option={option}
							onChange={() => handleCheckboxClicked(option)}
						/>
						{option.subOptions.length > 0 && selectedOptions[option.id] ? (
							<OptionsList
								options={option.subOptions}
								selectedOptions={selectedOptions[option.id]}
								onChange={(subSelections: any) =>
									handleSubOptionsListChange(option.id, subSelections)
								}
								isFirst={false}
								onSelectChange={onSelectChange}
								currentlySelected={props.currentlySelected}
							/>
						) : (
							<></>
						)}
					</ul>
				);
			})}
		</div>
	);
};

interface ICheckboxProps {
	selected: any;
	option: any;
	onChange: any;
}
const Checkbox: React.FC<ICheckboxProps> = ({ selected, option, onChange }) => {
	return (
		<div
			className={"category " + `${selected ? "selected" : ""}`}
			onClick={() => onChange(!selected)}
		>
			<Box
				size={24}
				style={
					selected ? { color: "#fff", marginRight: 10 } : { color: "#3f65f1", marginRight: 10 }
				}
			/>
			{option.name}
			{option.subOptions?.length > 0 ? (
				<>
					{selected ? (
						<ChevronLeft size={16} style={{ marginLeft: 10 }} />
					) : (
						<ChevronRight size={16} style={{ marginLeft: 10 }} />
					)}
				</>
			) : (
				<></>
			)}
			<div></div>
		</div>
	);
};

export default HierarchySelect;
