import React, { useEffect, useState } from "react";
import { SUBSCRIPTION } from "../../../../interfaces/subscription";
import { connect } from "react-redux";
import { Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import SubscriptionCheckoutModal from "../../../../components/checkout/subscriptionModal";
import { IApplicationState } from "../../../../redux/reducers";
import { X } from "react-feather";
import InvoiceTotal from "../components/invoiceTotal";
import { IUser } from "../../../../interfaces/user";
import { toCamelCase } from "../../../../utils/string";
import Upgrade from "../../../../components/common/upgrade";
import { availableSubscriptionPlans } from "../../../../utils/constants";
import { BillingPeriod } from "./components";
import paymentService from "../../../../services/paymentService";
import Stripe from "@stripe/stripe-js";
import { currencyFormatCentsToDollars } from "../../../../utils/currency";
import Loader from "../../../../components/loader";
import { getSubscriptionPrice, isEmptyObject } from "../../../../utils/subscription";

export interface ISubscriptionDetails {
	subscription: any;
	latest_invoice: any;
}

interface IProps {
	active: SUBSCRIPTION;
	profile?: IUser | undefined;
}

const Subscription: React.FC<IProps> = (props) => {
	const [checkoutModal, setCheckoutModal] = useState<boolean>(false);
	const [selectedPlan, setSelectedPlan] = useState<availableSubscriptionPlans>();
	const [subscriptionType, setSubscriptionType] = useState<string>("");
	const [subscriptionDetails, setSubscriptionDetails] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		paymentService
			.getCompanySubscriptionDetails()
			.then((response: any) => {
				console.log(response);
				setSubscriptionDetails(response);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		if (props?.profile?.company?.subscription_type) {
			setSubscriptionType(props.profile.company.subscription_type);
		}
	}, [props.profile]);

	const getSubscriptionDescription = () => {
		const status = subscriptionDetails.subscription?.status;
		return `Monthly charge for ${subscriptionType} tier platform access ${
			status ? "(" + status + ")" : ""
		}`;
	};

	const getPrice = () => {
		return currencyFormatCentsToDollars(subscriptionDetails?.latest_invoice?.subtotal || 0, "USD");
	};

	return (
		<>
			{" "}
			{loading || !subscriptionDetails ? (
				<Loader isLoading={loading} fullScreen />
			) : (
				<>
					{isEmptyObject(subscriptionDetails) ? (
						<div>
							No subscription information found. Please contact{" "}
							<a href="mailto:sales@journeyfoods.com?subject=Missing Subscription Information">
								sales@journeyfoods.com
							</a>{" "}
							if you believe this is a mistake
						</div>
					) : (
						<Col className="subscription invoices">
							<Row className={"mt-3 mb-3"}>
								<BillingPeriod subscriptionDetails={getSubscriptionPrice(subscriptionDetails)} />
							</Row>
							<Row className={"mt-4 mb-3"}>
								<h3>Itemized Subscription</h3>
								<div style={{ marginLeft: "auto", marginRight: 0, justifySelf: "flex-end" }}>
									<Upgrade />
								</div>
							</Row>
							<Row>
								<Table className={"clean-table"}>
									<thead>
										<tr>
											<th> Service </th>
											<th> Type </th>
											<th> Description </th>
											<th> Status </th>
											<th> Price </th>
											<th></th>
										</tr>
									</thead>
									{subscriptionDetails && (
										<tbody>
											<tr>
												<td> {toCamelCase(subscriptionType || "")} Subscription </td>
												<td> Subscription </td>
												<td> {getSubscriptionDescription()}</td>
												<td>
													{" "}
													<span>Paid</span>
												</td>
												<td> {getPrice()} </td>
												<td></td>
											</tr>
										</tbody>
									)}
								</Table>
								{subscriptionDetails && (
									<InvoiceTotal
										subTotal={subscriptionDetails?.latest_invoice?.subtotal}
										total={subscriptionDetails?.latest_invoice?.total}
										tax={subscriptionDetails?.latest_invoice?.tax}
										discount={subscriptionDetails?.latest_invoice?.discount?.coupon?.percent_off}
									/>
								)}
							</Row>
							{selectedPlan && (
								<Modal isOpen={checkoutModal}>
									<ModalHeader>
										<div className={"modal-header-content"}>
											<span>Update Your Subscription</span>
											<X
												onClick={() => setCheckoutModal(false)}
												className={"close-modal"}
												size={24}
											></X>
										</div>
									</ModalHeader>
									<ModalBody>
										<SubscriptionCheckoutModal
											active={props.active}
											subscriptionPlan={selectedPlan}
										/>
									</ModalBody>
								</Modal>
							)}
						</Col>
					)}
				</>
			)}
		</>
	);
};
const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile
});

export default connect(mapStateToProps)(Subscription);
