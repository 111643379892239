import React from "react";
import ReactImageFallback from "react-image-fallback";
import { IProductIngredient } from "../../../../interfaces/products";
import config from "../../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../../utils/ingredient";

interface IProps {
	productIngredient: IProductIngredient;
	totalWeights: number;
	opacity: number;
}

const ProductIngredientItem: React.FC<IProps> = ({
	productIngredient,
	totalWeights,
	opacity = 0.5
}) => {
	return (
		<div className="product-ingredient-item" style={{ opacity }}>
			<ReactImageFallback
				src={config.api.endpoints.ingredients.ingredientImage(
					productIngredient.ingredient.jf_display_name
				)}
				className="ingredient-image border-radius-6"
				fallbackImage={getIngredientImagePlaceholder()}
			/>
			<div className="ingredient-name">{productIngredient.ingredient.jf_display_name}</div>
			<div className="ingredient-percentage">
				<span>
					{totalWeights != 0
						? `${((productIngredient.weight / totalWeights) * 100).toFixed(1)}%`
						: `${productIngredient.percentage ? productIngredient.percentage.toFixed(1) : 0}%`}
				</span>
			</div>
		</div>
	);
};

export default ProductIngredientItem;
