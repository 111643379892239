import { ESRCH } from "constants";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Input } from "reactstrap";
import Button from "../../../../components/common/button";
import userService from "../../../../services/userService";

interface IProps {
	updatePhoneNumber: (number: string) => void;
	passwordVerifiedCallback: (requiresMFASignIn: boolean, mfaResolver?: any) => void;
	verifyPassword: (password: string) => Promise<void>;
}

const PasswordVerification: React.FC<IProps> = (props) => {
	const [password, setPassword] = useState<string>("");
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		if (error) setError(null);
		setPassword(value);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setLoading(true);
		props
			.verifyPassword(password)
			.then((res: any) => {
				if (res?.a?.mfaInfo?.[0]?.phoneInfo) {
					props.updatePhoneNumber(res?.a?.mfaInfo?.[0]?.phoneInfo);
				}
				if (res && res?.code === "auth/multi-factor-auth-required")
					return props.passwordVerifiedCallback(true, res.resolver);
				if (res) return props.passwordVerifiedCallback(false);
				setError("Password is incorrect");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Form>
			<h4 className={"mb-4"}>Please verfiy your password</h4>
			<FormGroup className={"mb-4"}>
				<Input
					type="password"
					placeholder="Confirm password"
					onChange={handleChange}
					className="w-100 px-3 py-2 font-16"
					style={{ borderRadius: "6px" }}
				/>
				{error && <small className="text-danger">{error}</small>}
			</FormGroup>
			<Button
				type="submit"
				className="btn-green color-white"
				style={{ color: "white" }}
				loading={loading}
				disabled={password === "" || error != null || loading}
				onClick={handleSubmit}
			>
				Confirm
			</Button>
		</Form>
	);
};

const mapDispatchToProps = (dispatch: any) => ({
	verifyPassword: (password: string, resolve?: Function, reject?: Function) =>
		dispatch(userService.verfiyPassword(password, resolve, reject))
});

export default connect(null, mapDispatchToProps)(PasswordVerification);
