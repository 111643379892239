import React, { useEffect, useState } from "react";
import { ArrowLeft, Loader } from "react-feather";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { IIngredient, ISearchIngredient } from "../../../interfaces/ingredient";
import { IRecommendation } from "../../../interfaces/recommendation";
import ingredientService from "../../../services/ingredientService";
import RegulationTable from "../regulationTable/regulationTable";

interface IProps extends RouteComponentProps {
	recommendation?: IRecommendation;
	getIngredientById: (id: string) => Promise<any>;
	navBackCallback: () => void;
}

const RecommendationActions: React.FC<IProps> = ({
	recommendation,
	getIngredientById,
	navBackCallback,
	...props
}) => {
	const mapRecommendedIngredientToSuppliers = () => {
		const suppliers = ["Global Resources Direct", "Cisco", "Avatar"];

		if (recommendation && recommendation?.recommended_ingredients) {
			return recommendation.recommended_ingredients.map((i: ISearchIngredient, index: number) => {
				return (
					<li key={i?.id}>
						<i className="icon-nav-ingredients"></i>
						<span>
							{i?.jf_display_name} from{" "}
							<a>{suppliers[index > suppliers?.length - 1 ? 0 : index]}</a>
						</span>
					</li>
				);
			});
		}
		return;
	};

	return (
		<Container className={"recommendation-actions"}>
			<div className="nav-back">
				<ArrowLeft onClick={navBackCallback} />
			</div>
			<Col className="actions-body">
				{/* Common Usage Info. */}
				<Row className={"action-section"}>
					<h6 className={"mb-2"}>Regulations</h6>
					{recommendation && <RegulationTable recommendation={recommendation} />}
				</Row>

				{/* Discovery Info/Actions */}
				{/* <Row className={"action-section"}>
					<h6 className={"mb-2"}>Suppliers</h6>
					<ul>{mapRecommendedIngredientToSuppliers()}</ul>
				</Row> */}

				{/* Discovery Info/Actions */}
				<Row className={"action-section"}>
					<h6 className={"mb-2"}>Manufacturers</h6>
					<p>
						No manufacturing accomodations determined. Follow standard manufacturing procedures.
					</p>
				</Row>
			</Col>
		</Container>
	);
};

const mapDispatchToProps = {
	getIngredientById: (id: string) => ingredientService.getIngredientById(id)
};

export default connect(null, mapDispatchToProps)(withRouter(RecommendationActions));
