import React from "react";
import "./loader.scss";
import { Spinner } from "reactstrap";
import { Loader as LoaderIcon } from "react-feather";


export const Loader: React.FC<{ isLoading: boolean; type?: "grow" | "spin", fullScreen?:boolean }> = ({ isLoading, children, type, fullScreen }) => {
    return (

        <div className={`loader ${fullScreen ? 'full' : ''}`}>
            {
                isLoading && <div className="loader__overlay">
                    <LoaderIcon className="fa-spin" size={30}/>
                </div>
            }
            {children}
        </div>
    )
}
