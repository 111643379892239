import React, { useState } from "react";
import { connect } from "react-redux";
import "../../../../../assets/scss/pages/integration/_csv-integration.scss";
import { CheckCircle, Download, Info, Loader, RefreshCcw, UploadCloud, X } from "react-feather";
import Button from "../../../../../components/common/button";
import integrationService from "../../../../../services/integrationService";
import { Input, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import Select from "react-select";
import firebase from "../../../../../utils/firebase";
import axios from "axios";
import fileDownload from "js-file-download";
import {
	FoodProductTemplateDescriptions,
	ProductRecipeTemplateDescriptions,
	IngredientTemplateDescriptions
} from "../../utils/templateDescriptions";
import images from "../../../images";
import { validateGoogleSheetsLink } from "../../../../../utils/string";
import { REQUEST_FILE_TYPES } from "../../../../../components/checkout/uploadRequestModal";

interface IProps {
	uploadCSVFile: (file: File, type: string) => Promise<any>;
	requestDataUpload: (
		file: File,
		type: string,
		description: string,
		dataType: REQUEST_FILE_TYPES
	) => Promise<any>;
}

const GoogleSheets: React.FC<IProps> = ({ uploadCSVFile, requestDataUpload }) => {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [link, setLink] = useState<string>();
	// Keep track of files user wants to download
	const [xlsxDownloads, setXLSXDownloads] = useState<
		{ label: string; value: firebase.storage.Reference }[]
	>();
	const [uploading, setUploading] = useState<boolean>();
	const [fileType, setFileType] = useState<any>();
	const [fileName, setFileName] = useState<string>();
	const [uploaded, setUploaded] = useState<boolean>();
	const [linkError, setLinkError] = useState<boolean>();

	const firebaseStorage = firebase.storage();
	const xlsxFileRef = {
		products: firebaseStorage.refFromURL(
			"gs://journeyfoods-io.appspot.com/Integration Templates/XLSX Templates/Food Product Template (XLSX).xlsx"
		),
		ingredients: firebaseStorage.refFromURL(
			"gs://journeyfoods-io.appspot.com/Integration Templates/XLSX Templates/Ingredient Manufacturer Template (XLSX).xlsx"
		),
		product_ingredients: firebaseStorage.refFromURL(
			"gs://journeyfoods-io.appspot.com/Integration Templates/XLSX Templates/Product Recipe Template (XLSX).xlsx"
		)
	};

	const downloadTemplateFiles = (
		storageRef: { label: string; value: firebase.storage.Reference }[]
	) => {
		if (storageRef != undefined) {
			for (let i = 0; i < storageRef.length; i++) {
				let ref = storageRef[i];
				ref.value
					.getDownloadURL()
					.then((url) => {
						axios
							.get(url, {
								responseType: "blob"
							})
							.then((res) => {
								fileDownload(res.data, ref.label);
							});
					})
					.catch((error) => {
						error("Error Downloading Files");
					});
			}
		}
	};

	const onDownloadSelectChange = (options: any, type: string) => {
		switch (type) {
			case "xlsx":
				setXLSXDownloads(options);
		}
	};

	const reset = () => {
		setUploading(undefined);
		setUploaded(false);
		setFileType(null);
		setFileName("");
		setLink("");
	};

	const onFileTypeChange = (e: any) => {
		e.value ? setFileType(e) : setFileType(undefined);
	};

	const onShareLinkChange = (link: string | undefined) => {
		if (link) {
			const isValid = validateGoogleSheetsLink(link);
			setLinkError(!isValid);
		}
		setLink(link);
	};

	const onFileNameChange = (name: string | undefined) => {
		setFileName(name);
	};

	const processUpload = () => {
		setUploading(true);
		if (link && fileType) {
			integrationService
				.uploadGoogleSheet(link, fileName || "Google Sheet", fileType.value)
				.then((res: any) => {
					if (res.status == 200 || res.status == 201) {
						setUploaded(true);
					}
				})
				.finally(() => {
					setUploading(false);
				});
		}
	};

	return (
		<div>
			<h3 className={"pt-3 integration-header"}>Import Data from Google Sheets</h3>
			<div className={"csv-integration-container"}>
				<div className="integration-actions">
					<div className="subsection-links">
						<h5>Table of Contents</h5>
						<ul className={"main-list"}>
							<li>
								<a href="#introduction">- Introduction</a>
							</li>
							<li>
								<a href="#correct-template-file">- Using the Correct Template</a>
							</li>
							<li>
								<a href="#template-field-descriptions">- Template Field Descriptions</a>
							</li>
							<ul className={"sub-list"}>
								<li>
									<a href="#food-product-fields">- Food Product Fields</a>
								</li>
								<li>
									<a href="#product-recipe-fields">- Product Recipe Fields</a>
								</li>
								<li>
									<a href="#ingredient-manufacturer-fields">- Ingredient Manufacturer Fields</a>
								</li>
							</ul>
						</ul>
					</div>
					<div className="template-downloads">
						<h5>Journey Foods Template Downloads:</h5>
						<div className="download-selection">
							<h6>Excel Templates (.xlsx)</h6>
							<Select
								name="Choose XLSX File Templates"
								isClearable={true}
								isMulti={true}
								className={"download-select"}
								hideSelectedOptions={true}
								placeholder="Select Excel Templates"
								options={[
									{ label: "Food Product Template.xlsx", value: xlsxFileRef.products },
									{
										label: "Ingredient Manufacturer Template.xlsx",
										value: xlsxFileRef.ingredients
									},
									{ label: "Product Recipe Template.xlsx", value: xlsxFileRef.product_ingredients }
								]}
								onChange={(option) => onDownloadSelectChange(option, "xlsx")}
								isCreatable={false}
							/>
							<Button
								onClick={() =>
									xlsxDownloads != undefined ? downloadTemplateFiles(xlsxDownloads) : null
								}
								color={"secondary"}
								outline={false}
								className={`mt-3 download-btn`}
							>
								<div>
									<Download size={16} />
									<span className={"ml-3"}>Download XLSX Templates</span>
								</div>
							</Button>
						</div>
					</div>

					<Button onClick={() => setModalOpen(true)} outline={false} className={`mt-5 manage-btn`}>
						<div>
							<UploadCloud size={16} color={"#fff"} />
							<span className={"ml-3"}>Upload a Google Sheets File</span>
						</div>
					</Button>
				</div>

				<div className={"instructions-container"}>
					<a id="introduction"></a>
					<h4>Introduction</h4>
					<p>
						Journey Foods allows users to import formulation data from a variety of file types
						including, Excel Files (XLS, XLSX, CSV, TXT). This guide/page/tutorial shows you how to
						import data from an Google Sheets file by following the instructions/prompts. By using a
						Google Sheets file, you can import or export a large number of products and their
						details at one time. This can be helpful if you want to exchange product information
						between Journey Foods and another system.
					</p>
					<a id="correct-template-file"></a>
					<h4>Using the Correct Template File</h4>
					<p>
						Depending on the type of data you want to upload, it is imperative that you use the
						correct template for your data. Download the necessary XLSX template files from the drop
						down on the left and import them into Google Sheets.
					</p>
					<p>
						The following questions outline the template choice(s) that will be best suited for your
						data.
					</p>
					<ul>
						<li>
							<strong>Are you a food company?</strong> <br /> Use the Food Products template
						</li>
						<li>
							<strong>Are you an ingredients company?</strong> <br /> Use the Ingredients
							Manufacturer template
						</li>
						<li>
							<strong>
								Are you are producing ingredients and/or have your own collection of ingredient
								infromation and are using them in food products?
							</strong>{" "}
							<br />
							Use the Ingredients and Product Recipe template in conjunction
						</li>
					</ul>
					<p>
						* Template file(s) can be downloaded using the drop down on the left hand side of the
						page
					</p>

					<p>
						Please refer to the <b>template field descriptions</b> for fine grained formatting info
						for each field in your dataset{" "}
					</p>
					<a id="template-field-descriptions"></a>
					<a id="food-product-fields"></a>
					<h4>Food Product Template Field Descriptions</h4>
					<div className={"template-description"}>
						<Table striped>
							<thead>
								<tr>
									<th>Column Header</th>
									<th>Description</th>
									<th>Data Requirements</th>
								</tr>
							</thead>
							<tbody>
								{FoodProductTemplateDescriptions.map((field, index: number) => {
									return (
										<tr key={index}>
											<td>{field.column_header}</td>
											<td>{field.description}</td>
											<td>{field.data_requirements}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<a id="product-recipe-fields"></a>
					<h4>Product Recipe Template Field Descriptions</h4>
					<div className={"template-description"}>
						<Table striped>
							<thead>
								<tr>
									<th>Column Header</th>
									<th>Description</th>
									<th>Data Requirements</th>
								</tr>
							</thead>
							<tbody>
								{ProductRecipeTemplateDescriptions.map((field, index: number) => {
									return (
										<tr key={index}>
											<td>{field.column_header}</td>
											<td>{field.description}</td>
											<td>{field.data_requirements}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<a id="ingredient-manufacturer-fields"></a>
					<h4>Ingredient Manufacturer Template Field Descriptions</h4>
					<p>
						* Some template fields are not described here as they are self-explanatory. Please
						contact <a>help@journeyfoods.com</a> with any issues.
					</p>
					<div className={"template-description"}>
						<Table striped>
							<thead>
								<tr>
									<th>Column Header</th>
									<th>Description</th>
									<th>Data Requirements</th>
								</tr>
							</thead>
							<tbody>
								{IngredientTemplateDescriptions.map((field, index: number) => {
									return (
										<tr key={index}>
											<td>{field.column_header}</td>
											<td>{field.description}</td>
											<td>{field.data_requirements}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<p>
						Once you have exported your populated template file, it's time to upload it! Click on
						the 'Upload a Google Sheets File' button to upload your file.
					</p>
				</div>

				<Modal isOpen={modalOpen} size="md">
					<ModalHeader>
						<img style={{ marginRight: 15 }} height={30} src={images.google_sheets.logo} />
						Upload a Google Sheets File
						<X className={"close-modal"} size={24} onClick={() => setModalOpen(false)} />
					</ModalHeader>
					<ModalBody>
						<div className={"upload-body"}>
							<h5>Upload Steps</h5>
							<ol>
								<li>Open your Google Sheets file</li>
								<li>Click Share in the top right hand corner of your browser</li>
								<li>Add 'data@journeyfoods.com' to the list of shared users</li>
								<li>Copy the share link</li>
							</ol>

							<h6> File Type </h6>
							<Select
								id="fileType"
								name="fileType"
								defaultValue={fileType}
								value={fileType || undefined}
								styles={{
									container: (provided, state) => ({
										...provided,
										borderRadius: 6
									}),
									control: (provided, state) => ({
										...provided,
										borderRadius: 6
									})
								}}
								options={[
									{ value: "product", label: "Products" },
									{ value: "ingredient", label: "Ingredients" },
									{ value: "product_ingredients", label: "Product Ingredients" }
								]}
								onChange={(e) => onFileTypeChange(e)}
							/>

							<h6 className={"mt-2"}>
								File Name{" "}
								<span style={{ opacity: 0.5, fontSize: 12, marginLeft: ".25rem" }}>*optional</span>
							</h6>
							<Input
								id="fileName"
								name="fileName"
								defaultValue={fileName || ""}
								value={fileName || ""}
								style={{
									width: "100%",
									borderRadius: 6,
									padding: "6px 12px",
									borderWidth: 1
								}}
								onChange={(e) => onFileNameChange(e.target.value)}
							/>
							<h6 className={"mt-2"}>
								<span>Google Sheets Share Link</span>
								{linkError ? (
									<span
										style={{ opacity: 0.75, fontSize: 12, marginLeft: ".25rem", color: "#e85a73" }}
									>
										*Check the format of your link
									</span>
								) : (
									<></>
								)}
							</h6>
							<Input
								id="link"
								name="link"
								defaultValue={link || ""}
								value={link || ""}
								style={{
									width: "100%",
									borderRadius: 6,
									padding: "6px 12px",
									borderWidth: 1
								}}
								onChange={(e) => onShareLinkChange(e.target.value)}
							/>
						</div>
						{uploaded ? (
							<div>
								<span className={"mt-2 mb-2"} style={{ display: "block", color: "#0ed7ac" }}>
									Upload Successful!
								</span>
								<Button
									style={{ backgroundColor: "#3f65f1", color: "#fff", borderColor: "#3f65f1" }}
									onClick={() => reset()}
								>
									Reset
									<RefreshCcw size={18} className={"ml-1"} />
								</Button>
							</div>
						) : (
							<Button
								disabled={uploading || !link || !fileType || linkError}
								className={`mt-4 ${uploading ? "upload-btn loading" : "upload-btn"}`}
								style={{ backgroundColor: "#0ed7ac", color: "#fff", borderColor: "#0ed7ac" }}
								onClick={() => processUpload()}
							>
								{uploading ? "Uploading..." : "Upload File"}
								{uploading ? (
									<Loader className="fa-spin ml-1" />
								) : (
									<CheckCircle className={"ml-1"} size={18} />
								)}
							</Button>
						)}
					</ModalBody>
				</Modal>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	uploadCSVFile: (file: File, type: string) => integrationService.uploadTabularDataFile(file, type),
	requestDataUpload: (file: File, description: string) =>
		integrationService.requestDataUpload(file, description)
};

export default connect(null, mapDispatchToProps)(GoogleSheets);
