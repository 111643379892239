import { integration } from "../actionContants";
import {
	IGetIntegrationsResponse,
	IGetAllUploadRequestsResponse
} from "./integrations.d";
import { IIntegration } from "../../../interfaces/integration";

export function GetIntegrationsAction(
	payload: IIntegration[]
): IGetIntegrationsResponse {
	return {
		type: integration.GET_INTEGRATIONS_RESPONSE,
		payload
	};
}

export function getAllUploadRequestsAction(
	payload: any[]
): IGetAllUploadRequestsResponse {
	return {
		type: integration.GET_ALL_REQUESTS_RESPONSE,
		payload
	};
}
