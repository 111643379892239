import { IPaginateList } from "../../interfaces/pagination";
import { IProduct } from "../../interfaces/products";
import { search } from "../../redux/actions/actionContants";

export interface ISearchState {
	result: any;
	term?: string;
	filter: any | undefined;
	paginated_results: IPaginateList<any[]>;
	portfolio_products: IProduct[];
	loading: boolean;
}

const INIT_STATE: ISearchState = {
	result: [],
	term: "",
	filter: undefined,
	paginated_results: {
		list: [],
		pagination: {
			page: 1,
			pages: 0,
			size: 10,
			total: 0
		}
	},
	portfolio_products: [],
	loading: false
};

export interface ISearchResultPayload {
	result: any[];
}

export default function (state: ISearchState = INIT_STATE, action: any) {
	switch (action.type) {
		case search.SEARCH_FILTER_RESPONSE:
			return { ...state, result: action.payload, term: action.term };
		case search.SET_SEARCH_FILTER:
			return { ...state, filter: action.filter };
		case search.PAGINATE_SEARCH_RESULTS:
			return {
				...state,
				paginated_results: action.payload
			};
		case search.UPDATE_PAGINATION:
			return {
				...state,
				paginated_results: {
					...state.paginated_results,
					pagination: {
						...state.paginated_results.pagination,
						page: action.payload
					}
				}
			};
		case search.SET_SEARCH_TERM:
			return {
				...state,
				term: action.term
			};
		case search.SET_SEARCH_LOADING:
			return {
				...state,
				loading: action.loading
			};
		case search.PORTFOLIO_PRODUCTS_SEARCH_RESPONSE:
			return {
				...state,
				portfolio_products: action.payload
			};
		default:
			return state;
	}
}
