import { relative } from "path";
import React from "react";

interface IProps {
	color: string;
	title?: string;
	value: number;
	height?: number;
	className?: string;
	actualValue?: number;
	decimalPoints?: number;
}

const ProgressGradient: React.FC<IProps> = ({
	color,
	title,
	value,
	height,
	actualValue,
	decimalPoints = 0,
	...props
}) => {
	return (
		<div className={props.className || ""} style={{ flex: 1 }}>
			{title && (
				<span style={{ fontSize: 14, fontWeight: 600, color: "#273f52" }}>{`${title} score:`}</span>
			)}
			<div
				data-tooltip={actualValue?.toFixed(decimalPoints) || value.toFixed(decimalPoints)}
				style={{
					width: `100%`,
					background: `linear-gradient(to right, ${color}80, ${color})`,
					marginTop: 10,
					borderRadius: "2px",
					position: "relative",
					height: height || 12
				}}
			>
				<div
					style={{
						width: `${100 - value}%`,
						boxShadow: "inset 0 0.1rem 0.1rem rgb(0, 0, 0, .1)",
						background: "#f6f9fc",
						height: height || 12,
						position: "absolute",
						right: 0,
						borderRadius: "0 2px 2px 0"
					}}
				></div>
			</div>
		</div>
	);
};

export default ProgressGradient;
