import React, { useEffect, useState } from "react";
import { withFormik, InjectedFormikProps } from "formik";

import { Location, History } from "history";

import { Form } from "reactstrap";
import { connect } from "react-redux";

import Button from "../../../../components/common/button";
import userService from "../../../../services/userService";
import RecoveryPasswordFormElements, {
	IRecoveryPasswordFormElementsValues
} from "./recoveryPasswordFormElements";
import { RecoveryPasswordValidation } from "../../../../validations/recoveryPaswordValidation";
import { routes } from "../../../routes";

interface IRecoveryPasswordFormProps {
	onValidateForm: Function;
	location: Location;
	history: History;
	recoveryPasswordWithFirebase: (request: any) => Promise<any>;
}

// todo
interface IProps {
	// location: Location;
	// onValidateForm: Function;
	// isValid: boolean;
	// handleSubmit: Function;
	// // onSubmit: Function;
}

const RecoveryPasswordForm: React.FC<any> = (props) => {
	useEffect(() => {
		props.onValidateForm(props.isValid && Object.keys(props.touched).length);
	}, [props.isValid]);

	return (
		<Form onSubmit={props.handleSubmit}>
			<RecoveryPasswordFormElements {...props} />

			<div className="btn-container">
				<Button
					type="submit"
					className="color-whit"
					size="lg"
					style={{ color: "white" }}
					loading={props.isSubmitting}
					disabled={!props.isValid}
				>
					Change password
				</Button>
			</div>
		</Form>
	);
};

const WrappedForm = withFormik<IRecoveryPasswordFormProps, IRecoveryPasswordFormElementsValues>({
	enableReinitialize: true,
	validationSchema: RecoveryPasswordValidation,
	mapPropsToValues: () => ({
		password: "",
		confirmPassword: ""
	}),

	handleSubmit: async ({ password }, { props, setSubmitting }) => {
		const query = new URLSearchParams(props.location.search);
		const oobCode = query.get("oobCode");
		try {
			setSubmitting(true);
			await props.recoveryPasswordWithFirebase({ code: oobCode, password });
			props.history.push(routes.LOGIN);
		} catch (error) {
			console.log("err");
		} finally {
			setSubmitting(false);
		}
	}
});

const mapDispatchToProps = (dispatch: any) => ({
	recoveryPasswordWithFirebase: ({ code, password }: any) =>
		dispatch(userService.recoveryPasswordWithFirebase(code, password))
});

export default connect(null, mapDispatchToProps)(WrappedForm(RecoveryPasswordForm));
