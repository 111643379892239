import * as React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationState } from '../../redux/reducers';
import { USER_ROLES } from '../../interfaces/roles';
import { routes } from '../../routes/routes';
import { IDynamicRouteSegments } from './PrivateRoute';


interface PublicRoutePropType extends RouteProps {
  isLoggedIn: boolean;
  component: React.ComponentType<RouteComponentProps<{}>>;
  role: string;
}

const PublicRoute: React.FC<PublicRoutePropType> = ({ component: Component, isLoggedIn, role, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<{}>) =>
        !isLoggedIn ? <Component {...props} /> : <Redirect to={role === USER_ROLES.ADMIN ? "/app/admin" : "/"} />
        // false ? <Component {...props} /> : <Redirect to={role === USER_ROLES.ADMIN ? "/app/admin" : "/"} />
      }
    />
  )
};


const mapStateToProps = (state: IApplicationState) => ({
  isLoggedIn: state.user.isLoggedIn,
  role: state.user.role
})

export default connect(mapStateToProps)(PublicRoute);