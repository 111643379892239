import React, { useState } from "react";
import { INotification } from "../../../interfaces/notification";
import { Box, ArrowRight, AlertTriangle, AlertCircle, DollarSign, Send } from "react-feather";
import notificationService from "../../../services/notificationService";
import { connect } from "react-redux";
import { IApplicationState } from "../../../redux/reducers";
import { getAllNotificationsAction } from "../../../redux/actions/notifications/notifications";
import SuitabilityBar from "../../../components/suitabilityBar";
import { Button } from "reactstrap";
import { formatNotificationDate } from "../../../utils/string";
import { getNotificationIcon } from "../../../utils/notification";

interface IProps {
	setAsRead: (id: string) => Promise<any>;
	getAllNotifications: () => Promise<any>;
	onView: (notificatin: INotification) => void;
	notification: INotification;
}

const NotificationTile: React.FC<IProps> = ({
	notification,
	setAsRead,
	getAllNotifications,
	onView
}) => {
	const [read, setRead] = useState<boolean>(notification.is_read);

	function setNotificationAsRead(notificationId: string) {
		setAsRead(notificationId);
		setRead(true);
		getAllNotifications(); // Call in order to update global state when a notification is read
	}

	const handleView = (notification: INotification) => {
		onView(notification);
		if (!notification.is_read) setNotificationAsRead(notification.id);
	};

	return (
		<tr key={notification.id} className={!read ? "notification-unread" : ""}>
			<td className="icon">
				{!read ? <div className={"notification-page-bubble"}></div> : <></>}
				{getNotificationIcon(notification.message_type)}
			</td>
			<td className="date">
				{notification?.created_at ? formatNotificationDate(new Date(notification.created_at)) : ""}
			</td>
			<td className="title">
				{notification?.message_type
					? notification?.message_type.charAt(0).toUpperCase() + notification?.message_type.slice(1)
					: "Notification"}
			</td>
			<td>...</td> {/* Market */}
			<td>...</td> {/* Brand */}
			<td className="description">{notification.message}</td>
			<td className="notification-progress">
				<SuitabilityBar
					title={" "}
					type={read ? "success" : "warning"}
					className="persona-suitability"
					value={read ? 100 : 25}
				/>
			</td>
			<td className="action">
				<Button
					color="secondary"
					outline
					className="color-battleship-grey"
					onClick={() => handleView(notification)}
				>
					<ArrowRight size={18} className="mr-2" />
					{notification.is_read ? "Viewed" : "On Review"}
				</Button>
			</td>
		</tr>
	);
};

const mapStateToProps = (state: IApplicationState, props: any) => ({});

const mapDispatchToProps = {
	setAsRead: (id: string) => notificationService.setNotificationAsRead(id),
	getAllNotifications: () => notificationService.getAllNotifications()
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationTile);
