import React, { useState } from "react";
import { X } from "react-feather";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { INotification } from "../../../../interfaces/notification";
import notificationService from "../../../../services/notificationService";
import { getNotificationIcon } from "../../../../utils/notification";
import { toCamelCase } from "../../../../utils/string";

interface IProps {
	notification: INotification;
	markAsRead: (id: string) => Promise<any>;
	getAllNotifications: () => Promise<any>;
}

const NotificationDropdownTile: React.FC<IProps> = ({ notification, ...props }) => {
	const [isRead, setIsRead] = useState<boolean>(notification.is_read);

	const markAsRead = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setIsRead(true);
		props.markAsRead(notification.id).then(() => props.getAllNotifications());
	};

	return (
		<>
			{isRead ? (
				<></>
			) : (
				<DropdownItem toggle={false} key={notification.id} tag={Link} to="/app/notifications">
					<div className="dropdown__notification color-battleship-grey">
						{getNotificationIcon(notification?.message_type)}
						<div className="notification__detail">
							<span className="notification__title">{toCamelCase(notification.message_type)}</span>
							<span className="notification__description">{notification.message}</span>
						</div>
						<X size={23} onClick={markAsRead} />
					</div>
				</DropdownItem>
			)}
		</>
	);
};

const mapDispatchToProps = {
	markAsRead: (id: string) => notificationService.setNotificationAsRead(id),
	getAllNotifications: () => notificationService.getAllNotifications()
};

export default connect(null, mapDispatchToProps)(NotificationDropdownTile);
