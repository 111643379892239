import { stat } from "fs";
import React, { useEffect, useState } from "react";
import { Loader } from "react-feather";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import { IUser } from "../../interfaces/user";
import { IApplicationState } from "../../redux/reducers";
import userService from "../../services/userService";
import { routes } from "../routes";


interface IProps extends RouteComponentProps {
    profile: IUser | undefined,
    getUserProfile: () => Promise<any>,
    setWalkthroughIncomplete: (id: string, complete: boolean) => Promise<any>;
}

const KnowledgeHub: React.FC<IProps> = (props) => {
    const[loading, setLoading] = useState<boolean>();
    
    useEffect(() => {
        setLoading(true);
        props.getUserProfile().finally(() => {
            setLoading(false);
        })
    }, [])

    const handleStartWalkthrough = () => {
        if(props.profile?.id) {
            setLoading(true);
            props.setWalkthroughIncomplete(props.profile.id, false).finally(() => {
                props.getUserProfile().finally(() => {
                    setLoading(false);
                    props.history.push(routes.DASHBOARD);
                })
            })
        }

    }

    return (
            <div className="knowledge-hub">
                <Row>
                    {/* title */}
                    <Col className={'mb-3'} md="12">
                        <div className={'d-flex align-items-center'}>
                            <h3 style={{marginBottom: 0}} className="font-weight-semibold mr-2">Knowledge hub</h3>
                            <Button 
                                disabled={loading} 
                                onClick={handleStartWalkthrough}
                                className={'justify-self-end float-right'} style={{ backgroundColor: '#3f65f1', borderColor: '#3f65f1', color: '#fff', marginLeft: 'auto', marginRight: 0 }}
                            >
                                { loading ? <Loader size={'18'} className={'fa-spin mr-1'} />: <></> }
                                Take a Tour
                            </Button>
                        </div>
                    </Col>
                    {/* left content */}
                    <Col md="6" xl="5" className="mt-4 color-battleship-grey">
                        <ul className="list-style-none">
                            <li>
                                <h5>Common Name</h5>
                                <p>General/standardized ingredient name (apple, pear, etc.)</p>
                            </li>
                            <li>
                                <h5>Variety Name</h5>
                                <p>Variation of general/standardized ingredient (granny smith apple, bosc pear, etc.)</p>
                            </li>
                            <li>
                                <h5>Subcategory</h5>
                                <p>Name of food subcategory / form factor for sample product - a specific brand's version of a food category</p>
                            </li>
                            <li>
                                <h5>Flavor</h5>
                                <p>AKA Variation - the exact flavor of the product variation these ingredients come from</p>
                            </li>
                            <li>
                                <h5>Journey Code</h5>
                                <p>Numerical code for sample product, with prefix to indicate category</p>
                            </li>
                            <li>
                                <h5>Category Code</h5>
                                <p>List of prefixes used to create Journey Code for each sample product</p>
                            </li>
                            <li>
                                <h5>Base Ingredients</h5>
                                <p>List of potential ingredients that make up the "texture base" for each sample product</p>
                            </li>
                            <li>
                                <h5>Ingredient Code</h5>
                                <p>Numerical code, unique identifier to track thousands of products</p>
                            </li>
                        </ul>
                    </Col>

                    {/* right content */}
                    <Col md="6" xl="5" className="mt-4 color-battleship-grey">
                        <ul className="list-style-none">
                            <li>
                                <h5>Dashboard</h5>
                                <p>Dashboard where user can edit and manage products. Features included on the profile include subscription upgrade, links to edit and add products, product formulation requests and reports.</p>
                            </li>
                            <li>
                                <h5>Product Page</h5>
                                <p>Page within the user dashboard that shows all existing user products with their name, image, size, and ingredient list.</p>
                            </li>
                            <li>
                                <h5>Product Settings</h5>
                                <p>Product count and product breakdown visualization are featured on page.</p>
                            </li>
                            <li>
                                <h5>Nutrition Page</h5>
                                <p>Option to request a nutrition label also provided</p>
                            </li>
                            <li>
                                <h5>Ingredients Page</h5>
                                <p>A curated list of top 100 ingredients with the option to subscribe to or add ingredients to watchlist.</p>
                            </li>
                            <li>
                                <h5>Explore</h5>
                                <p>A search bar is also featured on this page to search other ingredients in the database.  </p>
                            </li>
                            <li>
                                <h5>Functional Foods</h5>
                                <p>A food containing health-giving additives and having medicinal benefit (Chia, ancient grains, spices and herbs)</p>
                            </li>
                            <li>
                                <h5>Need more?</h5>
                                <p>If you have any questions about this Privacy Policy, please contact us:</p>
                            </li>
                            <li className="color-bg-blue">
                                <p className="mb-0">By email: hello@journeyfoods.com</p>
                                <p>By phone number: (847)859-9833</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
    )
}

const mapStateToProps = (state: IApplicationState) => ({
    profile: state.user.profile,
})

const mapDispatchToProps = ({
    getUserProfile: () => userService.getUserProfile(),
    setWalkthroughIncomplete: (id: string, complete: boolean) => userService.setWalkthroughComplete(id, complete),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(KnowledgeHub));

