import React, { useState } from "react";
import { Activity, ChevronDown, ChevronUp } from "react-feather";
import { Col, Container, Row } from "reactstrap";
import ProgressGradient from "../../../../components/common/progressGradient";
import { IProduct } from "../../../../interfaces/products";
import { NormalToSnakeCase, SnakeCaseToNormal, toCamelCase } from "../../../../utils/string";
import {
	BEYOND_INGREDIENTS_SAMPLE,
	CHOCOLATE_CHIPS,
	DAILY_NUTRIENT_VALUES,
	NUTRIENT_ZEROS
} from "../../exampleData/nutrition";

interface INutrientFieldProps {
	name: string;
	nutrient: { unit: string; value: number };
	isFirst?: boolean;
	subNutrients?: any;
}
const NutrientField: React.FC<INutrientFieldProps> = ({
	name,
	nutrient,
	isFirst,
	subNutrients,
	...props
}) => {
	const nutrientKey = NormalToSnakeCase(name);
	const hasDailyValue = DAILY_NUTRIENT_VALUES.hasOwnProperty(nutrientKey);
	const percentDailyValue = hasDailyValue
		? parseFloat(
				(
					(parseFloat(nutrient.value.toFixed(2)) / DAILY_NUTRIENT_VALUES[nutrientKey].value) *
					100
				).toFixed(2)
		  )
		: 0;
	const [showNutrientBreakdown, setShowNutrientBreakdown] = useState<boolean>();

	return (
		<div
			onClick={() => setShowNutrientBreakdown(!showNutrientBreakdown)}
			className={`nutrient-field ${subNutrients ? "dropdown-style" : ""}`}
		>
			<h5>
				<span>{`${name} ${nutrient.value.toFixed(1)}${nutrient.unit}`}</span>
				{subNutrients &&
					(showNutrientBreakdown ? (
						<ChevronUp className={"ml-2"} size={18} />
					) : (
						<ChevronDown className={"ml-2"} size={18} />
					))}
				{isFirst && <span className="daily-value">% Daily Value</span>}
			</h5>
			<ProgressGradient height={10} color={"#0ed7ac"} value={percentDailyValue} />
			{subNutrients && showNutrientBreakdown && (
				<div className={"sub-nutrients"}>
					{Object.keys(subNutrients).map((key: string) => {
						return (
							<div>
								<h5>
									<span>{`${toCamelCase(SnakeCaseToNormal(key))} ${
										subNutrients[key].value.toFixed(1) + subNutrients[key].unit
									}`}</span>
								</h5>
								<ProgressGradient
									className={"mb-2"}
									height={10}
									color={"#0ed7ac"}
									value={parseFloat(subNutrients[key].value.toFixed(2))}
								/>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

interface IProps {
	product: IProduct;
}

const NutritionalInfo: React.FC<IProps> = ({ product }) => {
	let nutrition =
		product?.brand?.name == "Journey Bites" ? BEYOND_INGREDIENTS_SAMPLE : NUTRIENT_ZEROS;
	if (product?.name == "Chocolate droplet BA") nutrition = CHOCOLATE_CHIPS;

	const reduceSubCategoriesToTotal = (category: any, unit: string) => {
		return {
			unit: unit,
			value: Object.keys(category).reduce(
				(total: number, child: string) => (total += category[child].value),
				0
			)
		};
	};

	let serving: string = "-";
	if (product?.brand?.name == "Journey Bites") serving = "per 30g";
	if (product?.name == "Chocolate droplet BA") serving = "per 14g";

	return (
		<Container className={"product-nutrition"}>
			<Row>
				<Col>
					<Row className={"nutrients-header"}>
						<h5>Macro Nutrients</h5>
						<span>{serving}</span>
					</Row>
					<hr></hr>
					<Row>
						<Col style={{ padding: 0 }}>
							<Row className="total-energy">
								<div>
									<h5>Total Energy</h5>
									<h5>{`${nutrition.calories.value.toFixed(0)} calories`}</h5>
								</div>
								<div
									className={"d-flex align-items-center"}
									style={{ marginRight: 0, marginLeft: "auto" }}
									data-tooltip={`${nutrition.calories.value.toFixed(0)} kcal`}
								>
									<Activity color={"#f4a850"} size={28} />
								</div>
							</Row>
							<NutrientField
								name={"Fat"}
								nutrient={reduceSubCategoriesToTotal(
									nutrition.macro_nutrients.fat.sub_nutrients,
									"g"
								)}
								isFirst
								subNutrients={nutrition.macro_nutrients.fat.sub_nutrients}
							/>
							<NutrientField
								name={"Cholesterol"}
								nutrient={nutrition.macro_nutrients.cholesterol}
							/>
							<NutrientField name={"Sodium"} nutrient={nutrition.macro_nutrients.sodium} />
							<NutrientField
								name={"Carbohydrates"}
								nutrient={reduceSubCategoriesToTotal(
									nutrition.macro_nutrients.carbohydrates.sub_nutrients,
									"g"
								)}
								subNutrients={nutrition.macro_nutrients.carbohydrates.sub_nutrients}
							/>
							<NutrientField name={"Protein"} nutrient={nutrition.macro_nutrients.protein} />
						</Col>
					</Row>
				</Col>
				<Col>
					<Row className={"nutrients-header"}>
						<h5>Micro Nutrients</h5>
						<span>{product?.brand?.name == "Journey Bites" ? "per 30g" : "-"}</span>
					</Row>
					<hr />
					<Row>
						<Col style={{ padding: 0 }}>
							<NutrientField
								name={"Vitamin D"}
								nutrient={nutrition.micro_nutrients.vitamin_d}
								isFirst
							/>
							<NutrientField name={"Calcium"} nutrient={nutrition.micro_nutrients.calcium} />
							<NutrientField name={"Iron"} nutrient={nutrition.micro_nutrients.iron} />
							<NutrientField name={"Potassium"} nutrient={nutrition.micro_nutrients.potassium} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default NutritionalInfo;
