import React from "react";
import { connect } from "react-redux";
import ActiveProducts from "../components/activeProducts";
import ConceptProducts from "../components/conceptProducts";
import SideForm from "../../../components/sideForm";
import AddProductForm from "../productDetail/form/addForm";
import Button from "../../../components/common/button";
import { Plus, Grid, List } from "react-feather";
import { RouteComponentProps } from "react-router-dom";
import ProductList from "../components/productList";
import ProductOverview from "../components/productOverview";
import { IApplicationState } from "../../../redux/reducers";
import { IUser } from "../../../interfaces/user";
import RecentlyViewed from "../../../components/common/recentlyViewed";

interface IProps extends RouteComponentProps<{ asd: "boolean" }, any, { addFormOpen: boolean }> {
	profile?: IUser | undefined;
}

interface IState {
	addFormOpen: boolean;
	display: string;
}

class Products extends React.Component<IProps, IState> {
	state = {
		addFormOpen: false,
		display: "list"
	};

	componentDidMount() {
		if (this.props.history.location.state?.addFormOpen) {
			this.setState({
				addFormOpen: true
			});
		}
	}

	toggleAddForm = () => {
		this.setState(({ addFormOpen }) => ({ addFormOpen: !addFormOpen }));
	};
	navigateToProductDetail(productId: string) {
		this.props.history.push(``);
	}

	render() {
		return (
			<>
				<div className="products">
					<ProductOverview />
					<RecentlyViewed type="products" />

					<div className={"all-products-container"}>
						<div className={"display-options"}>
							<Grid
								size={24}
								className={this.state.display == "grid" ? "active display-icon" : "display-icon"}
								onClick={() => {
									this.setState({ display: "grid" });
								}}
							/>
							<List
								size={24}
								className={this.state.display == "list" ? "active display-icon" : "display-icon"}
								onClick={() => {
									this.setState({ display: "list" });
								}}
							/>
						</div>
						{this.state.display == "list" ? (
							<ProductList viewType={"active"} allProducts={true} />
						) : (
							<>
								<ActiveProducts />
								<div
									style={{
										backgroundColor: "#f4f7f9",
										padding: 65,
										marginTop: 20,
										marginRight: -65,
										marginLeft: -65,
										marginBottom: -45
									}}
								>
									<hr
										style={{
											borderTop: "3px dashed #E1E9F1",
											marginTop: -65,
											marginLeft: -65,
											marginRight: -65
										}}
									/>
									<ConceptProducts />
								</div>
							</>
						)}
					</div>

					{this.props.profile?.company?.subscription_type == "supplier" ? (
						<></>
					) : (
						<span className="action-button-group">
							{/* <Button className="btn-circle btn-search btn-secondary"><Search size={28} /></Button> */}
							<Button className="btn-circle btn-adder btn-secondary" onClick={this.toggleAddForm}>
								<Plus size={28} />
							</Button>
						</span>
					)}
				</div>
				{this.props.profile?.company?.subscription_type == "supplier" ? (
					<> </>
				) : (
					<SideForm
						isOpen={this.state.addFormOpen}
						title="Add Product"
						toggleForm={this.toggleAddForm}
					>
						<AddProductForm toggleForm={this.toggleAddForm} />
					</SideForm>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile
});

export default connect(mapStateToProps, null)(Products);
