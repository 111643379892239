import React from "react";
import { Button, Col, Row } from "reactstrap";
import { ChevronRight } from "react-feather";

interface ISupplierItemProps {
	title: string;
	cost: string;
	sustainability: string;
	nutrition: string;
	onSupplierDetail: () => void;
}

const SustainabilityItem: React.FC<ISupplierItemProps> = ({
	title,
	cost,
	sustainability,
	nutrition,
	onSupplierDetail
}) => {
	return (
		<Row className={"suppliers-content mt-4"}>
			<Col className={"name large"}>{title}</Col>
			<Col className={"text-center"}>
				<span className={"suppliers-content-box"}>{cost}</span>
			</Col>
			<Col className={"text-center"}>
				<span className={"suppliers-content-box"}>{sustainability}</span>
			</Col>
			<Col className={"text-center"}>
				<span className={"suppliers-content-box"}>{nutrition}</span>
			</Col>
			<Col className={"d-flex justify-content-center"}>
				<Button className={"view-button"} size="sm" outline onClick={onSupplierDetail}>
					View <ChevronRight size={14} />
				</Button>
			</Col>
		</Row>
	);
};

interface ISuppliersProps {
	onSupplierDetail: () => void;
}

const RecommendationSuppliers: React.FC<ISuppliersProps> = ({ onSupplierDetail }) => {
	return (
		<>
			<Row className={"suppliers-content mt-3"}>
				<Col className={"suppliers-content-header large"}>Supplier</Col>
				<Col className={"suppliers-content-header"}>Cost</Col>
				<Col className={"suppliers-content-header"}>Sustainability</Col>
				<Col className={"suppliers-content-header"}>Nutrition</Col>
				<Col></Col>
			</Row>
			<SustainabilityItem
				title="ReSourceful"
				cost="+26.9%"
				sustainability="+26.9%"
				nutrition="+26.9%"
				onSupplierDetail={onSupplierDetail}
			></SustainabilityItem>
			<SustainabilityItem
				title="ReSourceful"
				cost="+26.9%"
				sustainability="+26.9%"
				nutrition="+26.9%"
				onSupplierDetail={onSupplierDetail}
			></SustainabilityItem>
			<SustainabilityItem
				title="ReSourceful"
				cost="+26.9%"
				sustainability="+26.9%"
				nutrition="+26.9%"
				onSupplierDetail={onSupplierDetail}
			></SustainabilityItem>
		</>
	);
};

export default RecommendationSuppliers;
