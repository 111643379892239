import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import { Box, CheckCircle, ChevronRight } from "react-feather";
import Button from "../../../components/common/button";
import { analytics } from "firebase";

interface IProps {
	preferences: any;
	onSelectPref: (pref: any) => void;
	selectedPrefs: any;
}

interface IPreference {
	preference_type: string;
	title: string;
	id: string;
}

const OriginalProduct: React.FC<IProps> = ({ preferences, onSelectPref, selectedPrefs }) => {
	const colorPreferred = (category: string, isPreferred: boolean) => {
		if (!isPreferred) {
			return "#fff";
		}
		if (category.toLowerCase() === "categories") {
			return "#3f65f1";
		} else if (category.toLowerCase() === "form") {
			return "#f4a850";
		} else if (category.toLowerCase() === "storage conditions") {
			return "#657280";
		} else {
			return "#3f65f1";
		}
	};

	const getIcon = (category: string, isPreferred: boolean) => {
		if (category.toLowerCase() === "categories") {
			return <Box color={colorPreferred(category, !isPreferred)} />;
		} else if (category.toLowerCase() === "form") {
			return <CheckCircle color={colorPreferred(category, !isPreferred)} />;
		} else if (category.toLowerCase() === "storage conditions") {
			return <ChevronRight color={colorPreferred(category, !isPreferred)} />;
		} else {
			return <Box color={colorPreferred(category, !isPreferred)} />;
		}
	};

	return (
		<>
			{Object.keys(preferences).map((key, index) => (
				<div style={{ borderBottom: "1px dashed #cedbe9", paddingBottom: 15, paddingTop: 11 }}>
					<Label className="color-battleship-grey">{key}</Label>{" "}
					<div>
						{preferences[key].map((s: IPreference) => (
							<>
								{(selectedPrefs.preferences || []).findIndex((p: any) => p.id === s.id) > -1 ? (
									<div
										style={{
											display: "inline-block",
											alignSelf: "center",
											padding: 7,
											borderRadius: 8,
											marginRight: 6,
											marginBottom: 6,
											backgroundColor: colorPreferred(key, true),
											border: `2px solid ${colorPreferred(key, true)}`,
											cursor: "pointer",
											color: "#fff"
										}}
										onClick={() => onSelectPref(s)}
									>
										{getIcon(key, true)} {s.title}
									</div>
								) : (
									<div
										style={{
											display: "inline-block",
											alignSelf: "center",
											padding: 7,
											borderRadius: 8,
											marginRight: 6,
											marginBottom: 6,
											border: "2px solid #cedbe9",
											cursor: "pointer"
										}}
										onClick={() => onSelectPref(s)}
									>
										{getIcon(key, false)} {s.title}
									</div>
								)}
							</>
						))}
					</div>
				</div>
			))}
		</>
	);
};

export default OriginalProduct;
