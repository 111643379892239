import React, { useEffect, useState } from "react";
import SuitabilityBar from "../../../components/suitabilityBar";
import { connect } from "react-redux";
import packagingService, { IPackageFilters } from "../../../services/packagingService";
import { IPackage, IPackagePreference } from "../../../interfaces/package";

import {
	Table,
	Modal,
	ModalFooter,
	Row,
	Col,
	Button,
	Form,
	FormGroup,
	Input,
	Label
} from "reactstrap";
import { ArrowRight, CheckCircle, Box } from "react-feather";
import { RouteComponentProps } from "react-router-dom";
import { IApplicationState } from "../../../redux/reducers";
import packageImagePlaceholder from "../../../assets/images/product-packaging-placeholder.png";
import { routes } from "../../routes";
import { Loader as LoaderIcon } from "react-feather";
import Upgrade from "../../../components/common/upgrade";
import { IUser } from "../../../interfaces/user";
import { packaging } from "../../../redux/actions/actionContants";
import { paginateAction } from "../../../redux/actions/pagination/pagination";
import { Dispatch } from "redux";
import { IPaginateList } from "../../../interfaces/pagination";
import Pagination from "../../../components/pagination";
import PackagingOverview from "../packagingOverview/packagingOverview";
import { selectPackageAction } from "../../../redux/actions/packaging/packaging";
import portfolioService from "../../../services/preferenceService";
import PackageFilters, { IFilterOption } from "./components/packageFilters";
import { formatPackagingFilters } from "../../../utils/packaging";
import { TableSkeleton } from "../../../utils/ghostUI";
import ReactImageFallback from "react-image-fallback";
import { ImageSkeleton } from "../../../utils/ghostUI";

interface IProps extends RouteComponentProps {
	packages: IPaginateList<IPackage[]>;
	userId: any;
	profile: IUser | undefined;
	getAllPackages: (userId: string) => Promise<any>;
	paginate: (page: number) => void;
	setSelectedPackage: (p: IPackage) => Promise<void>;
	getPackagingPreferences: () => Promise<any>;
	getFilteredPackages: (filters?: IPackageFilters) => Promise<any>;
}

const Packaging: React.FC<IProps> = ({
	packages: { list, pagination },
	userId,
	getAllPackages,
	getPackagingPreferences,
	getFilteredPackages,
	paginate,
	...props
}) => {
	const [modal, setModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedPackage, setSelectedPackage] = useState<IPackage>();
	const [viewMore, setViewMore] = useState<boolean>(false);
	const [filterMenu, toggleFilterMenu] = useState<string | undefined>(undefined);
	const [filteredCount, setFilteredCount] = useState<number>();
	// Setting copy of packages for cost demo
	const [packagesWCost, setPackagesWCost] = useState<IPackage[]>();
	const [showAllPackages, setShowAllPackages] = useState<boolean>(false);
	const [allFilterOptions, setAllFilterOptions] = useState<any>();
	const [appliedFilters, setAppliedFilters] = useState<IFilterOption[]>();

	const selectPackage = (p: IPackage) => {
		setViewMore(false);
		setSelectedPackage(p);
		setModal(true);
	};

	useEffect(() => {
		if (userId) {
			setLoading(true);
			if (appliedFilters || showAllPackages) {
				let formatted: IPackageFilters | undefined = formatPackagingFilters(appliedFilters);
				getFilteredPackages(formatted).finally(() => setLoading(false));
				return;
			} else {
				getAllPackages(userId).finally(() => {
					setLoading(false);
				});
			}
		}
	}, []);

	useEffect(() => {
		getPackagingPreferences().then((data) => {
			const temp = data.reduce((d: any, a: any) => {
				d[a.preference_type] = d[a.preference_type] || [];
				d[a.preference_type].push(a);
				return d;
			}, Object.create(null));
			setAllFilterOptions(temp);
		});
	}, []);

	useEffect(() => {
		if (userId) {
			setLoading(true);
			if (appliedFilters || showAllPackages) {
				let formatted: IPackageFilters | undefined = formatPackagingFilters(appliedFilters);
				getFilteredPackages(formatted).finally(() => setLoading(false));
				return;
			}
			getAllPackages(userId).finally(() => {
				setLoading(false);
			});
		}
	}, [pagination.page]);

	useEffect(() => {
		setFilteredCount(list.length);
		setPackageDemoCosts();
	}, [list]);

	useEffect(() => {
		if (!showAllPackages && userId) {
			setLoading(true);
			getAllPackages(userId).finally(() => {
				setLoading(false);
			});
			return;
		}

		const formattedFilters = formatPackagingFilters(appliedFilters);
		setLoading(true);
		getFilteredPackages(formattedFilters).finally(() => setLoading(false));
	}, [appliedFilters, showAllPackages]);

	const onFilterChange = (options: any) => {
		setAppliedFilters(options.map((o: any) => o.value));
	};

	const setPackageDemoCosts = () => {
		const wCost = list;
		wCost.forEach((item, i) => {
			list[i].cost = (
				Math.floor(Math.random() * 100) * (Math.round(Math.random()) ? 1 : -1)
			).toString(); // this will add minus sign in 50% of cases//using index, change the reference in array
		});
		setPackagesWCost(wCost);
	};

	const handlePackageNav = (p: IPackage) => {
		props.setSelectedPackage(p).finally(() => {
			props.history.push(`${routes.PACKAGING}/${p.id}`);
		});
	};

	return (
		<div className="packaging">
			<PackagingOverview />
			<div className={"d-flex align-items-center"}>
				<h4 style={{ marginBottom: 0 }}>Packaging Recommendations</h4>
				<span className="title ml-3">
					<h4 style={{ marginBottom: 0 }} className="count color-bg-blue font-weight-normal">
						{props.profile?.company.subscription_type == "supplier" ? (
							"- Matched Packages"
						) : (
							<>
								{loading ? (
									<LoaderIcon className="fa-spin ml-2" />
								) : (
									pagination.total + (showAllPackages ? " Results" : " Matched Packages")
								)}
							</>
						)}
					</h4>
					<Pagination
						pages={pagination.pages}
						page={pagination.page}
						size={pagination.size}
						total={pagination.total}
						handlePageChange={paginate}
					/>
				</span>
			</div>
			<Form className={"mt-2"}>
				<FormGroup check style={{ width: "max-content" }}>
					<Label check className="d-flex flex-row">
						<Input
							type="checkbox"
							onChange={() => setShowAllPackages(!showAllPackages)}
							checked={showAllPackages}
							disabled={loading}
						/>
						<span style={{ marginTop: 1 }}>Show All Packages</span>
					</Label>
				</FormGroup>
			</Form>
			{allFilterOptions && showAllPackages && (
				<PackageFilters filterOptions={allFilterOptions} onChange={onFilterChange} />
			)}

			<Table className={"package-list"} borderless>
				<thead>
					<tr>
						<th></th>
						<th>Package Name</th>
						<th></th>
						<th>Cost Variance</th>
						<th>Market</th>
						<th>Material</th>
						<th>Packaging Score</th>
						<th></th>
					</tr>
				</thead>
				{props.profile?.company?.subscription_type == "supplier" ? (
					<></>
				) : (
					<>
						{" "}
						{loading ? (
							<TableSkeleton numCells={6} />
						) : (
							<tbody>
								{list.map((p: IPackage) => (
									<tr className="list-row" key={p.id} onClick={() => handlePackageNav(p)}>
										<td>
											<ReactImageFallback
												src={p.image_uri || ""}
												initialImage={<ImageSkeleton className={"table-img-ghost"} />}
												fallbackImage={packageImagePlaceholder}
											/>
										</td>
										<td className="package-name">{p.name}</td>
										<td>
											<span className={`label label-${p.status.toLowerCase()}`}>
												{p.status.toLowerCase()}
											</span>
										</td>
										<td style={{ textAlign: "center" }}>
											<span style={{ color: "#cedbe9", textAlign: "center" }}>
												{/* {Math.sign(parseInt(p.cost)) > 0 ? <ArrowUp style={{marginRight: 5}} size={16} color="#0ed7ac" /> : <ArrowDown style={{marginRight: 5}} size={16} color="#f4a850"/>}
                          {p.cost + "%"} */}
												--
											</span>
										</td>
										<td>{p?.region_available?.join(", ")}</td>
										<td style={{ width: "200px" }}>{p?.material?.join(", ")}</td>
										<td>
											<SuitabilityBar
												title={" "}
												type={"packaging"}
												className="persona-suitability"
												value={p.packaging_score}
											/>
										</td>
										<td>
											<span
												className="action"
												onClick={(e) => {
													e.stopPropagation();
													selectPackage(p);
												}}
											>
												<ArrowRight size={17} color={"#4974f3"} className="mr-1" />
												View
											</span>
										</td>
									</tr>
								))}
							</tbody>
						)}
					</>
				)}
			</Table>

			{props.profile?.company.subscription_type == "supplier" ? (
				<div className={"mt-5"} style={{ width: "100%" }}>
					<Upgrade />
				</div>
			) : (
				<></>
			)}

			{list.length == 0 && !loading ? (
				<div className={"alert"}>
					{showAllPackages ? (
						<span>No Results</span>
					) : (
						<>
							<span>
								Set{" "}
								<a onClick={() => props.history.push(routes.PREFERENCES)}>packaging preferences</a>{" "}
								for your account to start seeing recommendations!
							</span>
							<Button
								size={"lg"}
								color="primary"
								onClick={() => props.history.push(routes.PREFERENCES)}
								className="preferences-btn"
							>
								View Preferences
							</Button>
						</>
					)}
				</div>
			) : (
				<></>
			)}
			<Modal isOpen={modal} className="package-modal">
				<Row className="package-header">
					<span className="cost">{`$ $ $`}</span>
					<span className={`label label-${selectedPackage?.status?.toLowerCase()}`}>
						{selectedPackage?.status?.toLowerCase()}
					</span>
				</Row>

				<Row>
					<div className="img-container">
						<img src={packageImagePlaceholder} />
					</div>
					<Col>
						<h3>{selectedPackage?.name}</h3>
						<Row className="info">
							<Col md={4}>
								<span>Market</span>
								<p>{selectedPackage?.region_available?.join(", ")}</p>
							</Col>

							<Col md={8}>
								<span>Material</span>
								<p>{selectedPackage?.material?.join(", ")}</p>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row className="tags m-3">
					<Col>
						{selectedPackage?.sustainability?.map((s: IPackagePreference, index: number) => {
							return (
								<span key={index} className="tag">
									<CheckCircle size={17} className="mr-1" />
									{s.title}
								</span>
							);
						})}
						{selectedPackage?.filling_process?.map((f: IPackagePreference, index: number) => {
							return (
								<span key={index} className="tag">
									<CheckCircle size={17} className="mr-1" />
									{f.title}
								</span>
							);
						})}
					</Col>
				</Row>

				<Row className="product-types m-3 pt-0 pb-0">
					<Col md={12} className="m-0 p-0">
						<h5 className="">Product Types</h5>
					</Col>
					<Col md={12} className="p-0">
						{selectedPackage?.product_type?.map((pt: string, index: number) => {
							return (
								<React.Fragment key={index}>
									{(index < 3 || viewMore) && (
										<span className="product-type">
											<Box size={17} color={"#3f65f1"} className="mr-1" />
											{pt}
										</span>
									)}
								</React.Fragment>
							);
						})}
					</Col>
					{(selectedPackage?.product_type || []).length > 3 && (
						<label
							style={{
								color: "#3f65f1",
								letterSpacing: "0.5px",
								fontSize: "14px",
								cursor: "pointer"
							}}
							onClick={() => setViewMore(!viewMore)}
						>
							View {viewMore ? "Less" : "More"} »
						</label>
					)}
				</Row>

				<Row className="m-3 pt-0">
					<Col md={12} className="pt-0">
						<SuitabilityBar
							title={"Packaging score"}
							type={"packaging"}
							className="persona-suitability"
							value={selectedPackage?.packaging_score || 0}
						/>
					</Col>
				</Row>

				<ModalFooter>
					<div className="modal-close" onClick={() => setModal(false)}>
						Close
					</div>
				</ModalFooter>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	packages: state.packaging.packaging,
	userId: state.user.user_id,
	profile: state.user.profile
});

const mapDispatchToProps = {
	getAllPackages: (userId: string) => packagingService.getAllPackages(userId),
	getFilteredPackages: (filters?: IPackageFilters) => packagingService.getFilteredPackages(filters),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(packaging.UPDATE_PAGINATION, page));
	},
	setSelectedPackage: (p: IPackage) => async (dispatch: Dispatch) => {
		dispatch(selectPackageAction(p));
	},
	getPackagingPreferences: () => portfolioService.getPackaging()
};

export default connect(mapStateToProps, mapDispatchToProps)(Packaging);
