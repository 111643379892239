import React, { useState } from "react";
import { Lock } from "react-feather";
import Button from "../../../../components/common/button";
import TwoFactorSetupModal from "./twoFactorModal";

interface IProps {}

const TwoFactorSetup: React.FC<IProps> = (props) => {
	const [showSetUpModal, setShowSetUpModal] = useState(false);

	return (
		<div className="pt-3 pb-3">
			<Button
				type="button"
				color="primary"
				className="btn-priamry color-white"
				style={{ color: "white" }}
				onClick={() => setShowSetUpModal(true)}
			>
				<div className={"d-flex align-items-center"}>
					<Lock className="mr-2" size={16} />
					<span>Set up two-factor authentication</span>
				</div>
			</Button>
			<TwoFactorSetupModal isOpen={showSetUpModal} toggle={() => setShowSetUpModal(false)} />
		</div>
	);
};

export default TwoFactorSetup;
