import React from "react";
import Stripe from "@stripe/stripe-js";
import { Col, Row } from "reactstrap";
import { toCamelCase } from "../../../../utils/string";
import { getCreditCardIcon } from "../../../../utils/images";

interface IPaymentMethodProps {
	payment_method: Stripe.PaymentMethod;
	details?: boolean;
}
const PaymentMethod: React.FC<IPaymentMethodProps> = ({ payment_method, details = true }) => {
	const renderBillingDetail = (title: string, detail: string) => {
		return (
			<Col>
				<h5>{title}</h5>
				<p>{detail || "Unavailable"}</p>
			</Col>
		);
	};
	return (
		<Row className={"mt-4"} noGutters>
			<Col className={"col-lg-4"}>
				<Row noGutters>
					<h4>Payment Details</h4>
				</Row>
				<Row
					noGutters
					style={{ backgroundColor: "#e1ecf37e", padding: "15px 20px", borderRadius: "6px" }}
				>
					<Col>
						<Row noGutters>
							{getCreditCardIcon(payment_method.card?.brand || "")}
							<h3 className="ml-3">{`${toCamelCase(payment_method.card?.brand || "")} ending in ${
								payment_method.card?.last4
							}`}</h3>
						</Row>
						<Row noGutters>
							<h4>{payment_method.billing_details.name}</h4>
						</Row>
						<Row noGutters>
							<p>{`Expires on ${payment_method.card?.exp_month}/${payment_method.card?.exp_year}`}</p>
						</Row>
					</Col>
				</Row>
			</Col>
			{details && (
				<Col className={"offset-sm-2 col-lg-4"}>
					<Row noGutters>
						<h4>Billing Details</h4>
					</Row>
					<Row noGutters>
						{renderBillingDetail(
							"Address line 1",
							payment_method.billing_details.address?.line1 || "Unavailable"
						)}
						{renderBillingDetail(
							"City",
							payment_method.billing_details.address?.city || "Unavailable"
						)}
					</Row>
					<Row noGutters>
						{renderBillingDetail(
							"Postal code",
							payment_method.billing_details.address?.postal_code || "Unavailable"
						)}
						{renderBillingDetail(
							"Country",
							payment_method.billing_details.address?.country || "Unavailable"
						)}
					</Row>
				</Col>
			)}
		</Row>
	);
};

export default PaymentMethod;
