import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Row, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Box, Loader as LoaderIcon } from "react-feather";
import { IApplicationState } from "../../../redux/reducers";
import dashboardService from "../../../services/dashboardService";
import { IDashboardState } from "../../../redux/reducers/dashboardReducer";
import AccountNotifications from "../../newDashboard/components/accountNotifications";
import { useContainerDimensions } from "../../../utils/hooks";
import Unmatched from "./unmatched";
import { samplePackageData} from "../../../components/charts/sampleData";
import LineChart from "../../../components/charts/lineChart";
import SustainabilityScore from '../../../assets/images/sustainability-scale-icon.svg'

interface IProps extends RouteComponentProps {

  profile: any;
  dashboard: IDashboardState;
  fetchDashboardOverview: () => Promise<any>;
  getDashboardProductAnalytics: () => Promise<any>;
}

//TODO:
// (1) Refactor Crosshair & associated MarkSeries to prevent repeated conditional of crosshair
//     - XYPlot doesn't response well to rendering both under same <></> or <div></div> parent tag 
const PackagingOverview: React.FC<IProps> = (props) => {

  const [loading, setLoading] = useState(false);

  const chartCardRef = useRef<HTMLDivElement>(null);
  const { width: chartCardWidth } = useContainerDimensions(chartCardRef);
  
  useEffect(() => {
    setLoading(true)
    Promise.all([props.fetchDashboardOverview(), props.getDashboardProductAnalytics()]).finally(() => {
      setLoading(false)
    })
  }, [])


  return (

    <div className="packaging-overview mt-n3">
      <Row className="mb-4">
        <div className={'mt-3 mr-2'}>
          <AccountNotifications mini filterBy={'packaging'} />
        </div>
        <div className={'mt-3 mr-2'}>
          <Unmatched/>
        </div>
        <div className="mt-3">
          <Card className="concept-product mr-1 mb-0">
            <CardBody>
              <div>
                <CardTitle>
                  <h1 className="font-weight-semibold mb-0 color-nile-blue">
                    {props.profile?.company?.subscription_type == 'supplier' ? 
                      <>
                      -
                      </> :
                      <>
                      { 80 }
                      </>
                    }
                  </h1>
                  {loading ? <LoaderIcon className="fa-spin ml-2" color={'#273f52'} /> : <img src={SustainabilityScore} height={28} width={28} style={{marginLeft: 5, color: "#fff"}} />}</CardTitle>
                <CardSubtitle>Sustainability <br /> Improvement</CardSubtitle>
              </div>
              <div ref={chartCardRef} style={{height: 150, width: 400}}>
                    <LineChart noGridLines color={'#273f52'} data={samplePackageData}/>
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
    </div >

  )
}

const mapStateToProps = (state: IApplicationState) => ({
  profile: state.user.profile,
  dashboard: state.dashboard
})

const mapDispatchToProps = {
  fetchDashboardOverview: () => dashboardService.getDashboardOverview(),
  getDashboardProductAnalytics: () => dashboardService.getDashboardProductAnalytics(),
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackagingOverview));