import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Loader } from 'react-feather';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { IInvoice } from '../../interfaces/invoice';
import { IApplicationState } from '../../redux/reducers';
import paymentService from '../../services/paymentService';
import { toCamelCase } from '../../utils/string';

interface IProps {
    adminInvoices: IInvoice[];
    getAllInvoices: () => Promise<any>;
}
const AdminBilling: React.FC<IProps> = ({adminInvoices, getAllInvoices}) => {
    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {
        setLoading(true);
        getAllInvoices().finally(() => {
            setLoading(false);
        })
    }, [])

    return (
        <div className='admin-billing'>
            <div className="page-header">
                <h4>All Invoices</h4>
            </div>
            { loading ? 
                <Loader className="fa-spin" /> :
                <Table className='clean-table'>
                    <thead>
                        <tr key="all-invoices">
                            <th>Company</th>
                            <th>Service</th>
                            <th>Created On</th>
                            <th>Updated On</th>
                            <th>Amount</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        { adminInvoices.map((i: IInvoice) => (
                            <tr key={i.id}>
                                <td>{i.company?.name}</td>
                                <td>{i.meta_data?.service}</td>
                                <td>{moment(i.created_at).format("MM/DD/YY")}</td>
                                <td>{moment(i.updated_at).format("MM/DD/YY")}</td>
                                <td>{i.meta_data?.price}</td>
                                <td>
                                    <span className={i.invoice_status == 'open' ? 'open' : 'paid'}>
                                        {toCamelCase(i.invoice_status)}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            }   
        </div>   
    )
}

const mapStateToProps = (state: IApplicationState) => ({
    adminInvoices: state.billing.admin_invoices,
})

const mapDispatchToProps = ({
    getAllInvoices: () => paymentService.getAllInvoices(),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminBilling);