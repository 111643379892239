import React from 'react';
import { Lock } from 'react-feather';

const PermissionNotice: React.FC = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{width:'max-content', display: 'flex', alignItems: 'center', backgroundColor: '#3f65f1', borderRadius: 12, padding: '6px 12px', color: '#fff'}}>
               <Lock style={{marginRight: 10}}/> Access Resricted to Company Admin
            </div>
        </div>
    )
}

export default PermissionNotice;