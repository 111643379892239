import { IPaginationAction } from "./pagination.d";

export function paginateAction(
	type: string,
	payload: number
): IPaginationAction {
	return {
		type,
		payload
	};
}
