import React from "react";
import { Table } from "reactstrap";

interface IGhostImgProps {
	className?: string;
	style?: React.CSSProperties;
}
export const ImageSkeleton: React.FC<IGhostImgProps> = ({ className, ...props }) => (
	<div className={className || "img-ghost"} style={props.style} />
);

export const RecentItemSkeleton: React.FC = () => (
	<div className="recent-item ghost">
		{<ImageSkeleton className={"recent-img-slider-ghost"} />}
		<h5 className="ghost" style={{ textAlign: "center" }}></h5>
	</div>
);

interface ITableSkeletonProps {
	numCells?: number;
	noAction?: boolean;
	noImg?: boolean;
	numRows?: number;
}

export const TableSkeleton: React.FC<ITableSkeletonProps> = ({
	numCells = 8,
	numRows = 8,
	...props
}) => (
	<tbody className={"ghost"}>
		{new Array(numRows).fill(0).map((item: number, index: number) => (
			<tr key={index}>
				{!props.noImg && (
					<td className={"ghost"}>
						<ImageSkeleton className={"table-img-ghost mr-2 ml-2"} />
					</td>
				)}
				{Array(numCells)
					.fill(0)
					.map(() => (
						<td className={"ghost"}>
							<div className={"content-ghost"}></div>
						</td>
					))}
				{!props.noAction && <td className={"ghost"}></td>}
			</tr>
		))}
	</tbody>
);

interface ISearchTableSkeletonProps {
	numRows?: number;
}
export const SearchTableSkeleton: React.FC<ISearchTableSkeletonProps> = ({
	numRows = 10,
	...props
}) => (
	<tbody className="search-result-list ghost">
		{new Array(numRows).fill(0).map((item, index: number) => (
			<tr>
				<td className={"ghost"}>
					<ImageSkeleton className={"table-img-ghost"} />
				</td>
				{Array(6)
					.fill(0)
					.map((item, index: number) => (
						<td key={index} className={"ghost"}>
							<div className={"content-ghost"}></div>
						</td>
					))}
			</tr>
		))}
	</tbody>
);

export const SearchTableRowsSkeleton: React.FC = () => (
	<>
		{new Array(5).fill(0).map((item, index: number) => (
			<tr>
				<td className={"ghost"}>
					<ImageSkeleton className={"table-img-ghost"} />
				</td>
				{Array(6)
					.fill(0)
					.map(() => (
						<td className={"ghost"}>
							<div className={"content-ghost"}></div>
						</td>
					))}
			</tr>
		))}
	</>
);
