import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { IRoutes, IRoute } from "../../../interfaces/route";
import { ISubmenus } from "../../../interfaces/submenus";
import { connect } from "react-redux";
import { IApplicationState } from "../../../redux/reducers";
import JfLogo from "../../../assets/images/jf-logo.png";
import { Icon } from "@iconify/react";
import crownIcon from "@iconify-icons/mdi/crown";
import { INotification } from "../../../interfaces/notification";
import notificationService from "../../../services/notificationService";
import JF_LOGO_NEW from "../../../assets/images/JF Color Logomark.png";

interface ISidebarProps {
	routes: IRoutes;
	location: any;
	role: string;
	profile: any;
	notifications: INotification[];
	getAllNotifications: () => Promise<any>;
}

interface ISubmenuState {
	name: string;
	routes: ISubmenus;
}

const Sidebar: React.FC<ISidebarProps> = (props) => {
	const INIT_SUBMENU: ISubmenuState = { name: "", routes: [] };
	const [loadingNotifications, setLoadingNotifications] = useState<boolean>();
	const [submenus, setSubmenus] = useState<ISubmenuState>(INIT_SUBMENU);

	useEffect(() => {
		const found = props.routes.find((r) => props.location.pathname.indexOf(r.path) > -1);

		// Only query for notifications on sidebar if they haven't been loaded yet.
		// TODO: Figure out how to keep track of changes to notifications... notification bubbles won't update like this
		if (props.notifications == undefined) {
			setLoadingNotifications(true);
			props.getAllNotifications().finally(() => setLoadingNotifications(false));
		}

		if (found && found.submenus.length > 0) {
			setSubmenus({ name: found.path, routes: found.submenus });
		} else {
			setSubmenus(INIT_SUBMENU);
		}
	}, [props.location]);

	const expandLogo = () => {
		document.getElementById("logobg")?.classList.toggle("expand-logo");
	};

	const activeRoute = (routeName: string) => {
		return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	};

	const getRouteNotificationCount = (name: string) => {
		const routeNotifications = (type: string) => {
			return props.notifications.filter(
				(n: INotification) => n.message_type.toLowerCase() == type.toLowerCase() && !n.is_read
			);
		};

		switch (name) {
			case "Products":
				return routeNotifications("Product").length;
			case "Ingredients":
				return routeNotifications("Ingredient").length;
			case "Packaging":
				return routeNotifications("Packaging").length;
			case "Personas":
				return routeNotifications("Personas").length;
			case "Analytics":
				return routeNotifications("Analytics").length;
			case "Integrations":
				return routeNotifications("Integrations").length;
			case "Account":
				return routeNotifications("Account").length;
			case "Admin":
				return routeNotifications("Admin").length;
			case "Account":
				return routeNotifications("Account").length;
			case "Notifications":
				return props.notifications.filter((n) => !n.is_read).length;
			default:
				return [];
		}
	};

	const getJoyRideId = (name: string) => {
		switch (name) {
			case "Company Settings":
				return "company-settings-joyride";
			case "Dashboard":
				return "dashboard-joyride";
			case "Integrations":
				return "integrations-joyride";
			case "Ingredients":
				return "ingredients-joyride";
			case "Packaging":
				return "packaging-joyride";
			default:
				return "";
		}
	};

	const renderRoute = (route: IRoute) => {
		const companyHasAccess =
			route.permission_name &&
			props.profile != undefined &&
			props.profile.role !== "JF_ADMIN" &&
			props.profile.role !== "JF_STAFF"
				? props.profile?.company[route.permission_name] !== "disable" &&
				  !route.locked_for?.includes(props.profile?.company.subscription_type)
				: true;
		if (!companyHasAccess) return <></>;
		return (
			<>
				{route.includeSpace ? (
					<div
						key={`${route.name}-space`}
						style={{ width: "100%", height: 2, margin: "12px 20px", borderRadius: 6 }}
					>
						<div
							style={{
								width: "100%",
								height: "100%",
								backgroundColor: "#607d8b",
								opacity: "25%",
								justifySelf: "center"
							}}
						/>
					</div>
				) : (
					<></>
				)}
				<li
					className={
						(submenus.name === route.path ? "active" : "") +
						(route ? " active active-pro" : "") +
						" sidebar-item"
					}
					key={route.path}
					// style={route.includeSpace ? {marginTop: 15} : {}}
				>
					{
						// <NavLink to={ !companyHasAccess && route.upgrade_path != undefined ? routes.UPGRADE + route.upgrade_path : route.path} className={`sidebar-link ${route.name.toLowerCase()}`} activeClassName="active">
						<NavLink
							to={route.path}
							id={getJoyRideId(route.name)}
							className={`sidebar-link ${route.name.toLowerCase()}`}
							activeClassName="active"
						>
							{!loadingNotifications ? (
								getRouteNotificationCount(route.name) > 0 ? (
									<div className="notification-bubble"></div>
								) : (
									<></>
								)
							) : (
								<></>
							)}
							{typeof route.icon == "string" ? <i className={route.icon} /> : route.icon}
							{/* Display premium upgrade icon if user's company doesn't have access to feature */}
							{!companyHasAccess ? (
								<Icon color="#f4a850" className="icon-crown" icon={crownIcon} />
							) : (
								""
							)}
							<span className="hide-menu">{route.name}</span>
						</NavLink>
					}
				</li>
			</>
		);
	};

	return (
		<div style={{ display: "flex" }}>
			<aside
				className="left-sidebar pt-3"
				id="sidebarbg"
				data-sidebarbg="skin6"
				onMouseEnter={expandLogo}
				onMouseLeave={expandLogo}
			>
				<a href="/" className="d-flex justify-content-center">
					<b className="logo-icon">
						<img
							src={JF_LOGO_NEW}
							alt="Journey Foods Logo Mark"
							className="dark-logo d-block"
							width={50}
							// height={47}
						/>
					</b>
				</a>
				<div className="scroll-sidebar mt-4 pt-2">
					<PerfectScrollbar className="sidebar-nav">
						<div
							className=" d-flex"
							style={{ height: "calc(100% - 60px)", flexDirection: "column" }}
						>
							<div key={"top-nav"}>
								<Nav className="sidebarnav">
									{props.routes
										.filter((r) => !r.atBottom && !r.inactive)
										.map((route) => {
											if (route.roles && !route.roles.includes(props.role)) {
												return null;
											}
											if (route.redirect || route.name === "Upgrade") {
												return null;
											}

											return renderRoute(route);
										})}
								</Nav>
							</div>
							<div key={"bottom-nav"} style={{ marginTop: "auto" }}>
								<Nav className="sidebarnav">
									{props.routes
										.filter((r) => r.atBottom && !r.inactive)
										.map((route) => {
											if (route.roles && !route.roles.includes(props.role)) {
												return null;
											}
											if (route.redirect) {
												return null;
											}

											return renderRoute(route);
										})}
								</Nav>
							</div>
						</div>
					</PerfectScrollbar>
				</div>
			</aside>
			<div style={{ height: "100%" }}>
				{submenus.name !== "" && (
					<aside className="left-submenu">
						<div style={{ position: "fixed" }}>
							{submenus.routes.map((s, index) => (
								<NavLink
									key={s.name + index}
									to={s.route}
									className="sidebar-link"
									activeClassName="active"
								>
									<div
										id={getJoyRideId(s.name)}
										style={{
											color: "#fff",
											marginBottom: 37,
											opacity: props.location.pathname.indexOf(s.route) > -1 ? 1 : 0.5
										}}
									>
										{s.name}
									</div>
								</NavLink>
							))}
						</div>
					</aside>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	role: state.user.role,
	profile: state.user.profile,
	notifications: state.notification.all || []
});

const mapDispatchToProps = {
	getAllNotifications: () => notificationService.getAllNotifications()
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
