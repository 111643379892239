import React, { useEffect } from "react";
import { Form } from "reactstrap";
import EmailFormElements, { IEmailFormElementsValues } from "./EmailFormElements";
import { withFormik, InjectedFormikProps } from "formik";
import { AdminEmail } from "../../../../../validations/adminEmail";
import adminService from "../../../../../services/adminService";
import { connect } from "react-redux";
import Button from "../../../../../components/common/button";

interface IEmailFormProps {
	testEmail: (email: string, name?: string, companyName?: string) => Promise<any>;
}

const EmailForm: React.FC<any> = (props) => {
	return (
		<Form onSubmit={props.handleSubmit}>
			<EmailFormElements {...props} />
			<div className="d-flex pt-4 justify-content-center">
				<Button
					type="submit"
					className="color-whit"
					size="lg"
					style={{ color: "white" }}
					loading={props.isSubmitting}
					disabled={!props.isValid}
				>
					Submit
				</Button>
			</div>
		</Form>
	);
};

const WrappedForm = withFormik<IEmailFormProps, IEmailFormElementsValues>({
	enableReinitialize: true,
	validationSchema: AdminEmail,
	mapPropsToValues: () => ({
		email: "",
		name: "",
		companyName: ""
	}),

	handleSubmit: async ({ email, name, companyName }, { props, setSubmitting }) => {
		try {
			setSubmitting(true);
			console.log("email", email);

			await props.testEmail(email, name, companyName);
		} catch (error) {
			console.log("err");
		} finally {
			setSubmitting(false);
		}
	}
});

const mapDispatchToProps = (dispatch: any) => ({
	testEmail: (email: string, name?: string, companyName?: string) =>
		dispatch(adminService.emailTest(email, name, companyName))
});

export default connect(null, mapDispatchToProps)(WrappedForm(EmailForm));
