import React from "react";
import { InjectedFormikProps } from "formik";
import Input from "../../../../components/form/Input";
import { Row } from "reactstrap";

export interface IRecoveryPasswordFormElementsValues {
	password: string;
	confirmPassword: string;
}

interface IRecoveryPasswordFormElementsProps {}

const RecoveryPasswordFormElements: React.FC<
	InjectedFormikProps<IRecoveryPasswordFormElementsProps, IRecoveryPasswordFormElementsValues>
> = (props) => {
	const {
		values: { password, confirmPassword },
		handleChange,
		touched,
		handleBlur,
		errors
	} = props;

	return (
		<div className="recovery-form-content">
			<Row>
				<Input
					label="Password"
					name="password"
					placeholder="New password"
					type="password"
					value={password}
					handleChange={handleChange}
					errors={errors}
					touched={touched}
					handleOnBlur={handleBlur}
				/>
			</Row>
			<Row>
				<Input
					label="Confirm password"
					name="confirmPassword"
					placeholder="Confirm new password"
					type="password"
					value={confirmPassword}
					handleChange={handleChange}
					errors={errors}
					handleOnBlur={handleBlur}
					touched={touched}
				/>
			</Row>
		</div>
	);
};

export default RecoveryPasswordFormElements;
