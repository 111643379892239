// export const DefaultNumberFormat = function (n: number): string {
// 	// NOTE: This regex replacement string results in the app not rendering on safari or mobile browsers
// 	// return n.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// 	return n.toString();
// };

export const DefaultNumberFormat = function (
	number: number,
	decimals: number = 0,
	dec_point: string = ".",
	thousands_sep: string = ","
): string {
	// http://kevin.vanzonneveld.net
	let n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
		dec = typeof dec_point === "undefined" ? "." : dec_point,
		toFixedFix = function (n: number, prec: number) {
			// Fix for IE parseFloat(0.55).toFixed(0) = 0;
			var k = Math.pow(10, prec);
			return Math.round(n * k) / k;
		},
		s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}
	if ((s[1] || "").length < prec) {
		s[1] = s[1] || "";
		s[1] += new Array(prec - s[1].length + 1).join("0");
	}
	return s.join(dec);
};
