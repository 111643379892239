import React from "react";
import Header from "../../../components/common/header";
import { Row, Col, Progress, Form, CustomInput, Button, Table } from "reactstrap";

class VersionHistory extends React.Component {
	render() {
		return (
			<>
				<Header />
				<data className="page-wrapper d-block">
					<div className="page-content container-fluid">
						<div className="ingredient-single">
							{/* version history */}
							<section className="version-history">
								<Row>
									<Col md="7">
										<Table className="w-100 current-version" borderless>
											<tr>
												<th colSpan={2}>
													<h4 className="font-weight-semibold mb-1">Version History</h4>
													<span className="font-weight-normal">Current version</span>
												</th>
												<th>
													<i className="fas fa-arrow-up text-success"></i>
												</th>
												<th>
													<i className="fas fa-arrow-down text-danger"></i>
												</th>
												<th>
													<i className="fas fa-arrow-up text-success"></i>
												</th>
												<th>
													<span className="horizantal-rule"></span>
												</th>
												<th>
													<span className="horizantal-rule"></span>
												</th>
												<th>
													<i className="fas fa-arrow-up text-success"></i>
												</th>
											</tr>

											<tr className="font-weight-semibold">
												<td>
													<CustomInput type="checkbox" id="v2.3" label="2.3" />
												</td>
												<td className="shopping-cart">08/05/20</td>
												<td>
													<Button outline color="success">
														Nutrition
													</Button>
												</td>
												<td>
													<Button outline color="success">
														Supply Chain
													</Button>
												</td>
												<td>
													<Button outline color="warning">
														Cost
													</Button>
												</td>
												<td>
													<Button color="secondary">Texture</Button>
												</td>
												<td>
													<Button color="secondary">Taste</Button>
												</td>
												<td>
													<Button outline color="success">
														Popularity
													</Button>
												</td>
											</tr>
										</Table>

										<Table className="version-list" hover borderless>
											<thead>
												<tr className="font-weight-normal">
													<th>Version</th>
													<th>Date</th>
													<th colSpan={6}>Optimised features</th>
												</tr>
											</thead>
											<tbody>
												<tr className="font-weight-semibold">
													<td>
														<CustomInput type="checkbox" id="v2.2" label="2.2" />
													</td>
													<td>08/05/20</td>
													<td>
														<Button outline color="warning">
															Nutrition
														</Button>
													</td>
													<td>
														<Button outline color="success">
															Supply Chain
														</Button>
													</td>
													<td>
														<Button outline color="danger">
															Cost
														</Button>
													</td>
													<td>
														<Button color="secondary">Texture</Button>
													</td>
													<td>
														<Button color="secondary">Taste</Button>
													</td>
													<td>
														<Button outline color="success">
															Popularity
														</Button>
													</td>
												</tr>
												<tr className="font-weight-semibold">
													<td>
														<CustomInput type="checkbox" id="v2.1" label="2.1" />
													</td>
													<td>08/05/20</td>
													<td>
														<Button outline color="warning">
															Nutrition
														</Button>
													</td>
													<td>
														<Button outline color="success">
															Supply Chain
														</Button>
													</td>
													<td>
														<Button outline color="danger">
															Cost
														</Button>
													</td>
													<td>
														<Button color="secondary">Texture</Button>
													</td>
													<td>
														<Button color="secondary">Taste</Button>
													</td>
													<td>
														<Button outline color="success">
															Popularity
														</Button>
													</td>
												</tr>
												<tr className="font-weight-semibold">
													<td>
														<CustomInput type="checkbox" id="v2.0" label="2.0" />
													</td>
													<td>08/05/20</td>
													<td>
														<Button outline color="warning">
															Nutrition
														</Button>
													</td>
													<td>
														<Button outline color="success">
															Supply Chain
														</Button>
													</td>
													<td>
														<Button outline color="danger">
															Cost
														</Button>
													</td>
													<td>
														<Button color="secondary">Texture</Button>
													</td>
													<td>
														<Button color="secondary">Taste</Button>
													</td>
													<td>
														<Button outline color="success">
															Popularity
														</Button>
													</td>
												</tr>
											</tbody>
										</Table>
									</Col>
								</Row>
							</section>
						</div>
					</div>
				</data>
			</>
		);
	}
}

export default VersionHistory;
