import React, { useState } from "react";
import { Info } from "react-feather";
import { Tooltip } from "reactstrap";

interface IProps {
	id: string;
}

const InfoTooltip: React.FC<IProps> = (props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleInfoHover = () => {
		setIsOpen(!isOpen);
	};

	return (
		<span>
			<Info
				id={props.id}
				onMouseEnter={handleInfoHover}
				// onMouseLeave={handleInfoHover}
			/>
			<Tooltip
				className="simple-tooltip"
				isOpen={isOpen}
				placement="right"
				target={props.id}
				toggle={handleInfoHover}
			>
				{props.children}
			</Tooltip>
		</span>
	);
};

export default InfoTooltip;
