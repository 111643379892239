import React from "react";
import { Button, Card, CardImg, Col, Row } from "reactstrap";

interface IProps {
	onNavigateInfo?: () => void;
}

const RecommendationSupplierInfo: React.FC<IProps> = ({ onNavigateInfo }) => {
	return (
		<Card className="ml-3 mt-3 pl-3 pt-3">
			<Row>
				<h5>ReSourceful</h5>
			</Row>

			<Row className="mt-3">
				<Col>
					1234 S Main Street,
					<br /> Chicago, IL USA 23456
				</Col>
				<Col className={"d-flex flex-column"}>
					<span>
						<svg
							className={"mr-2"}
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15.5001 11.69V13.94C15.5009 14.1489 15.4581 14.3556 15.3745 14.547C15.2908 14.7384 15.168 14.9102 15.0141 15.0514C14.8602 15.1926 14.6785 15.3001 14.4806 15.367C14.2828 15.434 14.0731 15.4588 13.8651 15.44C11.5572 15.1892 9.34032 14.4006 7.39257 13.1375C5.58044 11.986 4.04407 10.4496 2.89257 8.63751C1.62506 6.68091 0.836254 4.45326 0.590072 2.13501C0.57133 1.92761 0.595978 1.71858 0.662447 1.52123C0.728916 1.32388 0.83575 1.14253 0.976146 0.988727C1.11654 0.834926 1.28743 0.712044 1.47792 0.627903C1.6684 0.543763 1.87433 0.500208 2.08257 0.500012H4.33257C4.69655 0.496429 5.04942 0.625321 5.32539 0.862661C5.60137 1.1 5.78163 1.4296 5.83257 1.79001C5.92754 2.51006 6.10366 3.21706 6.35757 3.89751C6.45848 4.16595 6.48032 4.4577 6.4205 4.73817C6.36069 5.01865 6.22172 5.27609 6.02007 5.48001L5.06757 6.43251C6.13524 8.31017 7.68991 9.86484 9.56757 10.9325L10.5201 9.98001C10.724 9.77836 10.9814 9.6394 11.2619 9.57958C11.5424 9.51976 11.8341 9.5416 12.1026 9.64251C12.783 9.89642 13.49 10.0725 14.2101 10.1675C14.5744 10.2189 14.9071 10.4024 15.145 10.6831C15.3828 10.9638 15.5092 11.3222 15.5001 11.69Z"
								stroke="#17C9DC"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						+1-555-555-1234
					</span>
					<span>
						<svg
							className={"mr-2"}
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M3 3H15C15.825 3 16.5 3.675 16.5 4.5V13.5C16.5 14.325 15.825 15 15 15H3C2.175 15 1.5 14.325 1.5 13.5V4.5C1.5 3.675 2.175 3 3 3Z"
								stroke="#17C9DC"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M16.5 4.5L9 9.75L1.5 4.5"
								stroke="#17C9DC"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						info@resourceful.co
					</span>
				</Col>
			</Row>
			<Row className="mt-4 mb-4">
				<b>Buckwheat Flour MOQ:</b> 1000 kg
			</Row>
			{/* <Row className={"flex-column"} style={{ marginBottom: "125px" }}>
				<span
					style={{ fontFamily: "Biotif", fontWeight: "bold", color: "#AAAAAA", fontSize: "14px" }}
				>
					About
				</span>
				<span>ReSourceful is a marketplace for recycled and upcycled supplies.</span>
			</Row> */}
		</Card>
	);
};

export default RecommendationSupplierInfo;
