import React, { useEffect, useState } from "react";
import { AlertCircle, CheckCircle, ChevronDown, ChevronUp } from "react-feather";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ProgressGradient from "../../../../../components/common/progressGradient";
import { IProduct } from "../../../../../interfaces/products";
import { IApplicationState } from "../../../../../redux/reducers";
import { uniqBy } from "../../../../../utils/array";
import CostComparison from "./costComparison";
import IngredientComparison from "./ingredientComparison";
import PortfolioScores from "./portfolioScores";

interface IProps {
	isCurrent?: boolean;
	currentProduct: IProduct | null;
	comparisonProduct?: IProduct;
	productVersions: IProduct[];
	productSelectCallback: (p: IProduct) => void;
}

const CompareCard: React.FC<IProps> = ({
	currentProduct,
	isCurrent,
	productVersions,
	comparisonProduct,
	productSelectCallback,
	...props
}) => {
	const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(currentProduct);
	// const [filteredVersions, setFilteredVersions] = useState<IProduct[]>(
	// 	productVersions.filter((p: IProduct) => p.version != currentProduct?.version)
	// );
	const [filteredVersions, setFilteredVersions] = useState<IProduct[]>(productVersions);
	const [showVersions, setShowVersions] = useState<boolean>(false);

	useEffect(() => {
		if (isCurrent) return;
		// Perform action to set versioned product
		if (productVersions.length > 0)
			// Filter out current product version
			setSelectedProduct(filteredVersions[productVersions.length - 2]);
	}, []);

	useEffect(() => {
		if (selectedProduct) productSelectCallback(selectedProduct);
	}, [selectedProduct]);

	const handleShowVersions = () => {
		setShowVersions(!showVersions);
	};

	const handleVersionSelect = (version: number) => {
		const selected = filteredVersions.find((p: IProduct) => p.version == version);
		if (selected) setSelectedProduct(selected);
		setShowVersions(false);
	};

	return selectedProduct ? (
		<Card className={"compare-card"}>
			<CardHeader>
				<h3>{selectedProduct?.name}</h3>
				<div className={"version-select-container"}>
					<div
						className={`version-select-btn ${showVersions ? "active" : ""}`}
						onClick={handleShowVersions}
					>
						<h5>{`v${selectedProduct?.version} ${
							selectedProduct?.version == currentProduct?.version ? "(Current)" : ""
						}`}</h5>
						{!showVersions ? <ChevronDown size={18} /> : <ChevronUp size={18} />}
					</div>
					{showVersions && (
						<div className={"versions"}>
							{uniqBy(filteredVersions, (p: IProduct) => p.version)
								.filter((p: IProduct) => p.version != selectedProduct?.version)
								.map((p: IProduct, index: number) => (
									<div
										key={index}
										className={`version-option`}
										onClick={() => handleVersionSelect(p.version)}
									>
										{`v${p.version}${selectedProduct?.version == p?.version ? "(Current)" : ""}`}
									</div>
								))}
						</div>
					)}
				</div>
			</CardHeader>
			<CardBody>
				<IngredientComparison
					isMain={isCurrent}
					currentIngredients={selectedProduct?.ingredients || []}
					comparisonIngredients={comparisonProduct?.ingredients || []}
				/>
				{/* {!isCurrent && (
					<div className={"mb-3"}>
						<span>Similarity</span>
						<ProgressGradient color={"#0ed7ac"} value={75} />
					</div>
				)} */}
				{selectedProduct && comparisonProduct && (
					<CostComparison
						isMain={isCurrent}
						currentProduct={selectedProduct}
						comparisonProduct={comparisonProduct}
					/>
				)}
				{/* {selectedProduct && <PortfolioScores product={selectedProduct} />}
				<hr className={"divider"} />
				<Row className="metrics">
					<Col>
						<div>Supply Chain Risk</div>
						<div>
							<CheckCircle color="#0ed7ac" />
							<span>4.5/5</span>
						</div>
					</Col>
					<Col>
						<div>Complexity</div>
						<div>
							<AlertCircle color="#f4a850" />
							<span>4.6/5</span>
						</div>
					</Col>
				</Row> */}
			</CardBody>
		</Card>
	) : (
		<Card className={"compare-card no-comparison"}>
			<CardHeader>
				<h3>Oops!</h3>
			</CardHeader>
			<CardBody>
				<p>
					It looks like you haven't created any other product versions. To compare product versions,
					try editing your product and clicking the <span>"Save New Version"</span> button at the
					bottom of the product edit form.
				</p>
			</CardBody>
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	currentProduct: state.product.selected,
	productVersions: state.product.selectedVersions
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompareCard);
