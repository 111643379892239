import React from "react";
import { InjectedFormikProps } from "formik";
import Input from "../../../../components/form/Input";

export interface IForgotPasswordFormElements {
	email: string;
}

const ForgotPasswordFormElements: React.FC<InjectedFormikProps<{}, IForgotPasswordFormElements>> = (
	props
) => {
	const {
		values: { email },
		handleBlur,
		handleChange,
		errors,
		touched
	} = props;

	return (
		<>
			<Input
				errors={errors}
				touched={touched}
				handleChange={handleChange}
				handleOnBlur={handleBlur}
				name="email"
				value={email}
				placeholder="Email address"
			/>
		</>
	);
};

export default ForgotPasswordFormElements;
