import React from "react";
import {withFormik, InjectedFormikProps} from "formik";
import { Form } from "reactstrap";
import { connect } from "react-redux";
import ReportFormElements, { IReportFormElements } from "./reportFormElements";




interface IReportFormProps {
    report: () => null;
}




const ReportForm : React.FC<InjectedFormikProps<IReportFormProps, IReportFormElements>> = (props) =>  {

    return (
        <Form onSubmit={props.handleSubmit} inline>
            <ReportFormElements {...props}/>
        </Form>
    )
}

const WrappedForm = withFormik<IReportFormProps, IReportFormElements> ({
    enableReinitialize: true,
    mapPropsToValues: () => ({
        report: "",
        report_file: "",
        report_name: ""
    }),
    handleSubmit: async ({

    }) => {

    }
})

const mapDispatchToProps = {
    report: () => null
}

export default connect(null, mapDispatchToProps)(WrappedForm(ReportForm));