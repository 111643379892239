import React, { useState } from 'react';
import { Progress } from 'reactstrap';

interface IProps {
    value: number;
    style?: React.CSSProperties;
    className?: string;
    title?: string;
    type?: BarColor;
}

export type BarColor = 'danger' | 'warning' | 'success' | 'persona' | 'sustainability' | 'packaging' | 'preference';

const SuitabilityBar: React.FC<IProps> = ({ value, style, className, title, type }) => {
    const [hover, setHover] = useState<boolean>(false);

    let color:BarColor = 'success';
    if (type === undefined) {
        color = value >= 50 ? 'success' : value >= 25 ? 'warning' : 'danger';
    } else {
        color = type;
    }

    return (
        <div data-tooltip={value} style={style} className={className} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <small className="font-weight-normal color-battleship-grey">{ title || 'Preference'} </small>
            <Progress value={value} className="rounded-pill mt-1" style={{
                boxShadow: 'none',
                backgroundColor: "#E1ECF3",
            }} color={color} />
        </div>
    );
};

export default SuitabilityBar;
