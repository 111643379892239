import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes } from "../routes";
import Tabs from "../../components/tab";
import Analytics from "./analytics";
import Scores from "./scores";
import { Row, Col } from "reactstrap";
import { USER_ROLES } from "../../interfaces/roles";
import Markets from "./markets";

const AnalysisRouter: React.FC = () => (
	<Row className="analysis">
		<Col xl="11" md="12">
			<Tabs
				tabs={[
					{
						name: "Analytics",
						route: routes.ANALYTICS
					},
					// {
					//     name: "Reports",
					//     route: routes.REPORTS,
					//     tabRoles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_USER]
					// },
					{
						name: "Scores",
						route: routes.SCORES
					},
					{
						name: "Markets",
						route: routes.MARKETS
					}
				]}
			/>

			<div className="py-3">
				<Switch>
					<Route exact path={routes.ANALYTICS} component={Analytics} />
					{/* <Route exact path={routes.REPORTS} component={Reports} /> */}
					<Route exact path={routes.SCORES} component={Scores} />
					<Route exact path={routes.MARKETS} component={Markets} />
					<Redirect from={routes.ANALYSIS} to={routes.ANALYTICS} />
				</Switch>
			</div>
		</Col>
	</Row>
);

export default AnalysisRouter;
