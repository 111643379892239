import * as yup from "yup";
import { isRequired } from "./messages";

export const ProductValidation = yup.object().shape({
	name: yup.string().required(isRequired("Product Name")),
	brand: yup.string().nullable(),
	serving_size: yup
		.number()
		.typeError("Serving size must be a number")
		.required(isRequired("Serving Size")),
	cost_margin: yup
		.number()
		.typeError("Cost margin must be a number")
		.required(isRequired("Cost Margin")),
	cost: yup
		.number()
		.typeError("Cost must be a number")
		.required(isRequired("Cost")),
	markup: yup
		.number()
		.typeError("Markup must be a number")
		.required(isRequired("Markup")),
	created_date: yup.string().required(isRequired("Created Date")),
	fulfillment_date: yup.string().required(isRequired("Fulfillment Date")),
	flavor: yup.string(),
	manufacturer: yup.string().nullable(),
	ingredients:
		yup.string() ||
		yup.array().of(
			yup.object().shape({
				ingredient: yup.object().shape({
					jf_display_name: yup.string().required(isRequired("Ingredient Name")),
					id: yup.string().required()
				}),
				weight: yup
					.number()
					.typeError("Weight must be a number")
					.required(isRequired("Weight"))
					.transform(Number),
				unit: yup.string()
			})
		)
});
