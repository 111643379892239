import { loadStripe, StripeError } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import paymentService, { PaymentType } from "../../services/paymentService";
import { IApplicationState } from "../../redux/reducers";
import { connect } from "react-redux";
import { ArrowLeft, Info, Loader } from "react-feather";
import integrationService from "../../services/integrationService";
import { routes } from "../../routes/routes";
import { Link } from "react-router-dom";
import { info } from "../../utils/notification";
import StripeClimate from "./stripeClimate";

/* CheckForm contains Stripe Elements that are validated and accessed inside of a Stripe <Element> component through the exported CheckoutModal class which 
    passes in a Stripe object promise that handles validation and secure payment processing.
*/

interface CheckoutProps {
	processPayment: (
		paymentMethodId: string,
		authorizePaymentCallback?: (pi_client_secret: string) => Promise<any>
	) => Promise<any>;
}

const CheckoutForm: React.FC<CheckoutProps> = ({ processPayment }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState<StripeError | null>(null);
	const [cardComplete, setCardComplete] = useState(false);
	const [processing, setProcessing] = useState(false);

	/*
	 * Returns a promise of either an error or resolved payment intent following card authorization with Stripe
	 * - Required when a card's initial payment attempt 'requires_action' (authentication)
	 *
	 * @param {number} pi_client_secret The payment Stripe client secret for a payment intent with 'requires_action' status
	 * @retunrs {Promise<any>} payment intent with a 'succeeded' status or Stripe error
	 */
	const authorizePaymentCallBack = async (pi_client_secret: string) => {
		try {
			const response = await stripe?.confirmCardPayment(pi_client_secret).then((res: any) => {
				if (res.error) {
					setError(res.error);
					setProcessing(false);
					return res;
				} else if (res.paymentIntent.status == "succeeded") {
					return res.paymentIntent;
				}
			});
			return response;
		} catch (error) {
			return error;
		}
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setProcessing(true);

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		if (error) {
			if (elements != null) {
				elements.getElement("card")?.focus();
			}
			return;
		}

		const card = elements.getElement(CardElement);
		let payment_method: any;
		if (card != null) {
			// Create a payment method; id is used for confirm card payment
			payment_method = await stripe.createPaymentMethod({
				type: "card",
				card: card
			});
		} else {
			setProcessing(false);
		}

		if (payment_method?.error && card != null) {
			setError(payment_method?.error);
			setProcessing(false);
		} else {
			if (payment_method != null) {
				try {
					await processPayment(payment_method.paymentMethod.id, authorizePaymentCallBack).then(
						(res: any) => {
							switch (res?.status) {
								case 500:
									throw Error(
										"Your file was uploaded successfully but we couldn't process your payment. Please check that you have entered the correct card information."
									);
								case 200:
									setProcessing(false);
							}
						}
					);
				} catch (error) {
					setProcessing(false);
				}
			}
		}
	};

	const reset = () => {
		setError(null);
		setProcessing(false);
	};

	return (
		<div>
			<h4 className="payment-header">Data Upload Services: $100.00 (USD)</h4>
			<Form className="checkout-form">
				<FormGroup>
					<Label>Card information</Label>
					<div className="checkout-card-input">
						<CardElement
							onChange={(e) => {
								e.error ? setError(e.error) : setError(null);
								setCardComplete(e.complete);
							}}
						/>
					</div>
				</FormGroup>
				{error ? <div style={{ color: "#e85a73" }}>{error.message}</div> : <></>}
				<div className={"d-flex justify-content-center align-items-center"}>
					<Button
						disabled={processing || !cardComplete}
						className="complete-payment"
						onClick={handleSubmit}
					>
						{processing ? <Loader className="fa-spin mr-2" size={18} color="#fff" /> : <></>}
						{processing ? "Processing Payment" : "Pay Now"}
					</Button>
				</div>
			</Form>
			<StripeClimate />
		</div>
	);
};

export enum REQUEST_FILE_TYPES {
	XLSX = ".xlsx",
	CSV = ".csv"
}

interface IProps {
	company_id: string | undefined;
	dataType: REQUEST_FILE_TYPES;
	file: File | undefined;
	message: string;
	confirmPayment: (id: string) => Promise<any>;
}

const UploadRequestCheckout: React.FC<IProps> = ({ file, message, confirmPayment }) => {
	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST}`);
	const [payLater, setPayLater] = useState<boolean>();
	const [processing, setProcessing] = useState<boolean>();
	const [showOptions, setShowOptions] = useState<boolean>(true);
	const [invoiceCreated, setInvoiceCreated] = useState<boolean>(false);
	const [paymentComplete, setPaymentComplete] = useState<boolean>(false);

	const processDataUploadRequest = async (
		paymentMethodId?: string,
		authorizePaymentCallback?: (pi_client_secret: string) => Promise<any>
	) => {
		setProcessing(true);
		if (file != undefined) {
			try {
				return await integrationService
					.requestDataUpload(file, message)()
					.then(async (res: any) => {
						if (res?.data?.id) {
							if (payLater && !paymentMethodId) {
								return await paymentService
									.makeUploadRequestPayment(PaymentType.LATER, res.data.id)()
									.then((res: any) => {
										if (res.status == 200) {
											setInvoiceCreated(true);
											info("Data Upload Request Successful");
											return res;
										}
									});
							} else {
								return await paymentService
									.makeUploadRequestPayment(PaymentType.NOW, res.data.id, paymentMethodId)()
									.then(async (res: any) => {
										switch (res.status) {
											case 500:
												setProcessing(false);
												return res;
											case 200:
												setPaymentComplete(true);
												info({
													title: "Payment Complete",
													description: "Data Upload Request Successful"
												});
												return res;
											case "requires_action":
												if (authorizePaymentCallback) {
													await authorizePaymentCallback(res.client_secret).then((res: any) => {
														if (res.status == "succeeded") {
															confirmPayment(res.id).then((res: any) => {
																if (res.status == 200) {
																	setPaymentComplete(true);
																	info({
																		title: "Payment Complete",
																		description: "Data Upload Request Successful"
																	});
																}
																return;
															});
														}
													});
												}
												break;
										}
									});
							}
						}
					})
					.finally(() => {
						setProcessing(false);
					});
			} catch (error) {
				throw error;
			}
		}
	};

	const handleOptionSelect = (option: boolean) => {
		setPayLater(option);
		setShowOptions(false);
	};

	const handleGoBack = () => {
		setPayLater(undefined);
		setShowOptions(true);
	};

	return (
		<div className={"checkout-container"}>
			{showOptions ? (
				<div className="csv-checkout-options">
					<Button className="btn-primary" color="primary" onClick={() => handleOptionSelect(false)}>
						{"Pay Now"}
					</Button>
					<Button
						className="btn-secondary"
						color="secondary"
						onClick={() => handleOptionSelect(true)}
					>
						{"Pay Later*"}
					</Button>
					<p>
						{/* {
							"* If choosing to pay later, please note that we will not begin processing your data until you have paid the pending invoice on your account"
						} */}
						{
							"* If you are an ingredient supplier uploading your data within a trial period, please select the 'Pay Later' option. You will not be required to pay an invoice for data importation services during your trial period."
						}
					</p>
				</div>
			) : (
				<></>
			)}

			{!showOptions && !invoiceCreated && !paymentComplete ? (
				<div className="back-btn" onClick={() => handleGoBack()}>
					<ArrowLeft size={24} color={"#2c3e50"} /> Back to options
				</div>
			) : (
				<></>
			)}

			{payLater != undefined && !invoiceCreated && !paymentComplete ? (
				payLater ? (
					<div className="paylater-confirmation">
						<div className={"info-container"}>
							<Info className={"info-icon"} />
							<div>
								<div>
									<h5>Payment Invoice Agreement</h5>
								</div>
								{/* <p>
									If you are choosing to pay for upload services at a later date, please note that
									we will not begin the process of uploading your team's data until you have
									completed the pending charge on your account.
								</p> */}

								{/* <p>
									If you are choosing to pay for upload services at a later date, please note that
									we will not begin the process of uploading your team's data until you have
									completed the pending charge on your account.
								</p> */}

								<p>
									An invoice of $100.00 (USD) will be created for your account. Please note that you
									will not be required to pay this invoice if you have submitted a request during
									your trial period.{" "}
								</p>
							</div>
						</div>
						<Button
							disabled={processing}
							className="confirm"
							onClick={() => processDataUploadRequest()}
						>
							{processing ? <Loader className="fa-spin mr-2" size={18} color="#fff" /> : <></>}
							{processing ? "Processing..." : "Confirm"}
						</Button>
					</div>
				) : (
					<>
						<Elements stripe={stripePromise}>
							<CheckoutForm processPayment={processDataUploadRequest} />
						</Elements>
					</>
				)
			) : (
				<></>
			)}

			{invoiceCreated ? (
				<div>
					<h4>Invoice Created</h4>
					<p>An invoice for the amount of $100 USD was created for your account.</p>
					{/* <p>
						Please pay as soon as possible so that our team can get your team's data uploaded to
						your account! 
					</p> */}
					<p>
						Please note that you will not be required to pay this invoice if you are an ingredient
						supplier uploading your data during a trial period.
					</p>
					<Link to={routes.INVOICES}>
						<Button className="btn-primary" color="primary">
							View Invoice
						</Button>
					</Link>
				</div>
			) : (
				<></>
			)}

			{paymentComplete ? (
				<div>
					<h4>Payment Successful</h4>
					<p>
						Thank you for submitting your request and payment for data uploading services. <br />{" "}
						<br />A Journey Foods team member will get right to work on the process of cleaning and
						uploading your data. Within 48 hours you should recieve a notice that your data was
						successfully uploaded to your account and is available for you and your team to start
						taking full advantage of the Journey Foods platform!
					</p>
					<p>For your records, an invoice has been posted to your account.</p>
					<Link to={routes.INVOICES}>
						<Button className="btn-primary" color="primary">
							View Invoice
						</Button>
					</Link>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	company_id: state.user.profile?.company.id
});

const mapDispatchToProps = {
	confirmPayment: (id: string) => paymentService.confirmPayment(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadRequestCheckout);
