import {
	IDashboardOverviewResponse,
	IDashboardProductAnalyticsResponse
} from "../../../interfaces/dashboard";
import {
	IDashboardResponseAction,
	IDashboardProductAnalyticsAction,
	IDashboardConceptProductResponseAction,
	IDashboardActiveProductResponseAction
} from "./dashboard.d";

import { dashboard } from "../actionContants";
import { IProduct } from "../../../interfaces/products";

export function getDashboardOverviewResponseAction(
	payload: IDashboardOverviewResponse
): IDashboardResponseAction {
	return {
		type: dashboard.GET_DASHBOARD_OVERVIEW_RESPONSE,
		payload
	};
}

export function getDashboardProductAnalyticsAction(
	payload: IDashboardProductAnalyticsResponse
): IDashboardProductAnalyticsAction {
	return {
		type: dashboard.GET_DASHBOARD_PRODUCT_ANALYTICS_RESPONSE,
		payload
	};
}

export function getDashboardActiveProductResponse(
	payload: IProduct[]
): IDashboardActiveProductResponseAction {
	return {
		type: dashboard.GET_DASHBOARD_ACTIVE_PRODUCT_RESPONSE,
		payload
	};
}

export function getDashboardConceptProductResponse(
	payload: IProduct[]
): IDashboardConceptProductResponseAction {
	return {
		type: dashboard.GET_DASHBOARD_CONCEPT_PRODUCT_RESPONSE,
		payload
	};
}
