import React, { useState } from "react";
import { Row, Col, Progress, Button, Form, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Mic, Plus, Upload, Eye, Tag, X, AlertTriangle } from "react-feather";
import under_construction from "../../../assets/images/scientist_lab.png";

const Labels: React.FC = () => {
	const [modal, setModal] = useState<boolean>(true);

	return (
		<div className="page-content container-fluid">
			{/* <div className='blurred-feature'>
                <div className={'under-construction'}>
                    <span>
                        <AlertTriangle size={24} color={'#f4a850'} />
                        Under Construction
                    </span>
                    <p>
                        We are hard at work to bring this feature to you and your team!
                    </p>
                    <img src={under_construction} height={100} width={100}></img>
                    <p>
                        While we work to make label generation the best it can be, please contact our team at<a> hello@journeyfoods.com </a> 
                        if you want personalized help with label creation for your products and ingredients.
                    </p>
                </div>
            </div> */}
			<div className="labels mt-3">
				{/* products block */}
				<section className="labels__product-block">
					<Row>
						<Col md="8" xl="6">
							<div className="product-block__wrapper shadow border-radius-12 border-dashed position-relative d-flex p-4">
								<Col xs="4">
									<img
										width="184px"
										height="184px"
										src="https://www.bbcgoodfood.com/sites/default/files/styles/push_item/public/guide/hub-image/2016/08/health-benefits-of-cinnamon-hub-image-400-400.jpg?itok=LI4YsOpq"
										alt="Ingredient Image"
									/>
								</Col>
								<Col xs="8">
									<h3 className="mb-3 font-weight-semibold color-battleship-grey">Product label</h3>

									<div className="product-type-list mb-2">
										<div className="product-type-item m-0">
											<i className="mr-2 color-bg-blue icon-fruit" />
											<p className="color-battleship-grey">Fruit Snack</p>
										</div>
									</div>

									<div className="portfolio-preference-list">
										<div className="portfolio-preference-item ml-0 mr-2">
											<Mic size={18} color="#f4a850" className="mr-2" />
											<p className="color-battleship-grey">Vegan</p>
										</div>
										<div className="portfolio-preference-item ml-0 mr-2">
											<Mic size={18} color="#f4a850" className="mr-2" />
											<p className="color-battleship-grey">Vegan</p>
										</div>
										<div className="portfolio-preference-item ml-0 mr-2">
											<Mic size={18} color="#f4a850" className="mr-2" />
											<p className="color-battleship-grey">Vegan</p>
										</div>
									</div>

									<p className="mt-2 color-battleship-grey">
										To request a custom label for your current or R&D products, please submit a
										request below and someone from our team will reach out to fulfill your inquiry.
									</p>

									<div className="d-flex">
										<Col md="5" className="pl-0">
											<h5 className="font-weight-normal">Serving size:</h5>
											<p className="h5 font-weight-semibold">11 oz</p>
										</Col>
										<Col md="6">
											<h5 className="font-weight-normal">Manufacturer:</h5>
											<p className="h5 font-weight-semibold">Beyond Ingredients</p>
										</Col>
									</div>
								</Col>
								<Button color="secondary" className="button-top color-white">
									Example
								</Button>
							</div>
						</Col>
						<Col md="4">
							<div className="p-4 bg-very-light-blue border-radius-12 h-100 d-flex flex-column">
								<p className="color-battleship-grey">
									To request a custom label for your current or R&D products, please submit a
									request below and someone from our team will reach out to fulfill your inquiry.
								</p>
								<Form className="flex-grow-1 d-flex flex-column">
									<Input
										type="textarea"
										name="notes"
										id="notes"
										placeholder="Notes..."
										className="flex-grow-1 py-2 px-3"
									/>
									<div className="d-flex justify-content-end">
										<Button color="primary" className="mt-4">
											<Tag className="mr-2" size={18} />
											Submit
										</Button>
									</div>
								</Form>
							</div>
						</Col>
					</Row>
				</section>

				{/* products list */}
				<section className="labels__product-list mt-5">
					<Row>
						<Col xs="12">
							<h4 className="font-weight-semibold">Product labels</h4>
						</Col>
						<Col md="12" xl="10">
							<ul className="list-style-none">
								<li className="border-bottom py-2 d-flex align-items-center">
									<h5 className="mb-0 font-weight-normal flex-grow-5">
										Product label name example
									</h5>
									<span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
									<div className="d-flex">
										<Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
											<Upload className="mr-2 color-bg-blue" size={18} />
											<p>Upload</p>
										</Button>
										<Button color="success" className="color-white ml-2">
											Save
										</Button>
										<Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
											<Eye className="mr-2 color-bg-blue" size={18} />
											<p>View label</p>
										</Button>
									</div>
								</li>
								<li className="border-bottom py-2 d-flex align-items-center">
									<h5 className="mb-0 font-weight-normal flex-grow-5">
										Product label name example
									</h5>
									<span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
									<div className="d-flex">
										<Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
											<Upload className="mr-2 color-bg-blue" size={18} />
											<p>Upload</p>
										</Button>
										<Button color="success" className="color-white ml-2">
											Save
										</Button>
										<Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
											<Eye className="mr-2 color-bg-blue" size={18} />
											<p>View label</p>
										</Button>
									</div>
								</li>
								<li className="border-bottom py-2 d-flex align-items-center">
									<h5 className="mb-0 font-weight-normal flex-grow-5">
										Product label name example
									</h5>
									<span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
									<div className="d-flex">
										<Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
											<Upload className="mr-2 color-bg-blue" size={18} />
											<p>Upload</p>
										</Button>
										<Button color="success" className="color-white ml-2">
											Save
										</Button>
										<Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
											<Eye className="mr-2 color-bg-blue" size={18} />
											<p>View label</p>
										</Button>
									</div>
								</li>
								<li className="border-bottom py-2 d-flex align-items-center">
									<h5 className="mb-0 font-weight-normal flex-grow-5">
										Product label name example
									</h5>
									<span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
									<div className="d-flex">
										<Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
											<Upload className="mr-2 color-bg-blue" size={18} />
											<p>Upload</p>
										</Button>
										<Button color="success" className="color-white ml-2">
											Save
										</Button>
										<Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
											<Eye className="mr-2 color-bg-blue" size={18} />
											<p>View label</p>
										</Button>
									</div>
								</li>
								<li className="border-bottom py-2 d-flex align-items-center">
									<h5 className="mb-0 font-weight-normal flex-grow-5">
										Product label name example
									</h5>
									<span className="h5 mb-0 font-weight-normal flex-grow-1">v1.0</span>
									<div className="d-flex">
										<Button color="secondary" className="m-0 d-flex btn-outline-powderblue">
											<Upload className="mr-2 color-bg-blue" size={18} />
											<p>Upload</p>
										</Button>
										<Button color="success" className="color-white ml-2">
											Save
										</Button>
										<Button color="secondary ml-2" className="m-0 d-flex btn-outline-powderblue">
											<Eye className="mr-2 color-bg-blue" size={18} />
											<p>View label</p>
										</Button>
									</div>
								</li>
							</ul>
						</Col>
					</Row>
				</section>

				{/* action buttons */}
				<span className="action-button-group">
					<Button className="btn-circle btn-adder btn-secondary">
						<Plus size={18} />
					</Button>
				</span>
			</div>
		</div>
	);
};

export default Labels;
