import React from "react";
import { Button as ReactStrapButton, ButtonProps } from "reactstrap";

interface IProps extends ButtonProps {
	loading?: boolean;
	icon?: string;
}

const Button: React.FC<IProps> = ({
	loading,
	children,
	disabled,
	icon,
	className,
	color,
	style,
	...rest
}) => {
	return (
		<ReactStrapButton
			color={color || "success"}
			{...rest}
			className={`btn ${className}`}
			disabled={loading || disabled}
			style={{ boxShadow: "none", ...style }}
		>
			{loading && (
				<i
					className="fa fa-spinner fa-spin"
					style={{
						marginRight: 5
					}}
				/>
			)}
			{icon && <i style={{ marginRight: 7 }} className={icon} />}
			{children}
		</ReactStrapButton>
	);
};

export default Button;
