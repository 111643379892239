import React, { useCallback, useEffect, useState } from "react";
import { ArrowDown, ArrowRight, ArrowUp, Plus } from "react-feather";
import { connect } from "react-redux";
import { Button, Table } from "reactstrap";
import { IProduct } from "../../../interfaces/products";
import { IUser } from "../../../interfaces/user";
import { IApplicationState } from "../../../redux/reducers";
import { IUserState } from "../../../redux/reducers/userReducer";
import adminService from "../../../services/adminService";
import productService from "../../../services/productService";
import userService from "../../../services/userService";
import ProductSnapshot from "./components/productSnapshot";
import CreateRecommendation from "./components/createRecommendation";
import recommendationService, {
	RECOMMENDATION_STATUS
} from "../../../services/recommendationService";
import { IRecommendation } from "../../../interfaces/recommendation";
import { toCamelCase } from "../../../utils/string";
import Loader from "../../../components/loader";
import { Pagination } from "../../../components/pagination/pagination";
import { IPagination } from "../../../interfaces/pagination";

interface IProps {
	getAllUsers: () => Promise<any>;
	getUserProfile: (id: string) => Promise<any>;
	getRecentlyViewed: () => Promise<any>;
	getProductById: (id: string) => Promise<any>;
	getAllRecommendationRequests: (offset?: number, limit?: number) => Promise<any>;
	recentlyViewed: any[];
	selectedProduct: IProduct | null;
	profile: IUser | undefined;
	user: IUserState;
	users: IUser[];
}

const AdminRecommendations: React.FC<IProps> = ({
	getAllUsers,
	getUserProfile,
	getRecentlyViewed,
	getAllRecommendationRequests,
	getProductById,
	users,
	user,
	recentlyViewed,
	selectedProduct,
	profile,
	...props
}) => {
	const [recommendations, setRecommendations] = useState<IRecommendation[]>([]);
	const [loading, setLoading] = useState<boolean>();
	const [selectedRecommendation, setSelectedRecommendation] = useState<IRecommendation>();
	const [sortBy, setSortBy] = useState<RECOMMENDATION_STATUS>(RECOMMENDATION_STATUS.REQUESTED);
	const [modalNewRec, setModalNewRec] = useState<boolean>(false);
	const [modalProductSnapshot, setModalProductSnapshot] = useState<boolean>(false);
	const [paginate, setPaginate] = useState<IPagination>({
		page: 1,
		pages: 0,
		size: 30,
		total: 0
	});

	useEffect(() => {
		setLoading(true);
		Promise.all([
			getAllUsers(),
			getRecentlyViewed(),
			user.user_id ? getUserProfile(user.user_id) : () => {}
		]).finally(() => {
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		setLoading(true);
		try {
			getAllRecommendationRequests(paginate.page, paginate.size).then(
				({ data, meta }: { data: IRecommendation[]; meta: IPagination }) => {
					setRecommendations(data);
					setPaginate(meta);
				}
			);
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	}, [paginate.page]);

	const handleSelectRecommendation = (rec: IRecommendation) => {
		setSelectedRecommendation(rec);
		setModalNewRec(true);
	};

	const onRecommendationCreated = () => {
		getAllRecommendationRequests().then((res: IRecommendation[]) => setRecommendations(res));
	};

	useEffect(() => {
		sortRecommendations();
	}, [sortBy]);

	const sortRecommendations = useCallback(() => {
		const sorted =
			sortBy == RECOMMENDATION_STATUS.RECOMMENDED
				? recommendations.sort((a, b) => (a.status > b.status ? -1 : 1))
				: recommendations.sort((a, b) => (a.status < b.status ? -1 : 1));
		setRecommendations(sorted);
	}, [sortBy, recommendations]);

	const handleStatusSort = () => {
		setSortBy(
			sortBy === RECOMMENDATION_STATUS.REQUESTED
				? RECOMMENDATION_STATUS.RECOMMENDED
				: RECOMMENDATION_STATUS.REQUESTED
		);
	};

	return (
		<div className="user-reports admin-recommendations">
			<div className="page-header">
				<h3>All Recommendations</h3>
				<Pagination
					pages={paginate.pages}
					page={paginate.page}
					size={paginate.size}
					total={paginate.total}
					handlePageChange={(pageNumber: number) => {
						setPaginate((prevState: IPagination) => ({ ...prevState, page: pageNumber }));
					}}
				/>
			</div>

			<Table className="clean-table">
				<thead>
					<tr>
						<th>Company</th>
						<th>Product</th>
						<th>Product Objective</th>
						<th>
							<span>Status</span>
							{/* <span onClick={handleStatusSort}>
								{sortBy == RECOMMENDATION_STATUS.RECOMMENDED ? (
									<ArrowUp size={18} />
								) : (
									<ArrowDown size={18} />
								)}
							</span> */}
						</th>
						<th></th>
					</tr>
				</thead>
				{!loading ? (
					<tbody>
						{recommendations?.map((rec: IRecommendation, index: number) => {
							return (
								<tr key={index}>
									<td>{rec.company?.name}</td>
									<td>{`${rec.product.name}, V${rec.product.version}`}</td>
									<td>
										{rec.objective?.reduce((objectives, obj: string, index: number) => {
											return (
												objectives +
												toCamelCase(obj) +
												(index != rec.objective.length - 1 ? ", " : "")
											);
										}, "")}
									</td>
									<td>
										<span
											className={`status-label ${rec.status.toLowerCase()}`}
											onClick={() => {
												handleSelectRecommendation(rec);
											}}
										>
											{rec.status[0].toUpperCase() +
												rec.status.slice(1, rec.status.length).toLowerCase()}
										</span>
									</td>
									<td>
										<Button
											className="std-view-btn"
											onClick={() => {
												handleSelectRecommendation(rec);
											}}
										>
											View
											<ArrowRight size={20} />
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				) : (
					<Loader isLoading={true} />
				)}
			</Table>

			{!loading ? (
				<span className="action-button-group">
					<Button
						className="btn-circle btn-adder btn-secondary"
						onClick={() => {
							setSelectedRecommendation(undefined);
							setModalNewRec(true);
						}}
					>
						<Plus size={28} />
					</Button>
				</span>
			) : (
				<></>
			)}

			{/* <ProductSnapshot isOpen={modalProductSnapshot} close={() => setModalProductSnapshot(false)} /> */}
			<CreateRecommendation
				recommendation={selectedRecommendation}
				isOpen={modalNewRec}
				close={() => setModalNewRec(false)}
				onCreated={onRecommendationCreated}
			/>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	users: state.admin.users,
	profile: state.user.profile,
	user: state.user,
	recentlyViewed: state.product.recentlyViewed,
	selectedProduct: state.product.selected
});

const mapDispatchToProps = {
	getAllUsers: () => adminService.getAllUsers(),
	getUserProfile: (id: string) => userService.getUserProfile(id),
	getRecentlyViewed: () => productService.getRecentlyViewed(),
	getProductById: (id: string) => productService.getProductById(id),
	getAllRecommendationRequests: (offset?: number, limit?: number) =>
		recommendationService.getAllRecommendationRequests(undefined, offset, limit)
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRecommendations);
