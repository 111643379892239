import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "../routes";
// import Integration from "./integration/components/integrationsOverview/integrationsOverview";
import IntegrationOverview from "./integration/components/integrationsOverview/integrationsOverview";
import CSVIntegration from "./integration/components/csvIntegration";
import XLSXIntegration from "./integration/components/xlsxIntegration";
import GoogleSheets from "./integration/components/googleSheets/googleSheets";

const IntegrationsRouter: React.FC = () => (
	<Switch>
		<Route exact path={routes.CSV_INTEGRATION} component={CSVIntegration} />
		<Route exact path={routes.EXCEL_INTEGRATION} component={XLSXIntegration} />
		<Route exact path={routes.GOOGLE_SHEETS} component={GoogleSheets} />
		<Route path={routes.INTEGRATIONS} component={IntegrationOverview} />
	</Switch>
);

export default IntegrationsRouter;
