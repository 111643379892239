export enum COMPANY_PERMISSIONS {
	PACKAGING = "PACKAGING",
	INGREDIENT_ENGINE = "INGREDIENT_ENGINE",
	PRODUCT_TOOLS = "PRODUCT_TOOLS",
	PACKAGING_ENGINE = "PACKAGING_ENGINE",
	COST_INSIGHTS = "COST_INSIGHTS",
	REGULATORY_INSIGHTS = "REGULATORY_INSIGHTS",
	SUSTAINABILITY_INSIGHTS = "SUSTAINABILITY_INSIGHTS",
	PERSONA_ENGINE = "PERSONA_ENGINE",
	INGREDIENT_DATASHEET = "INGREDIENT_DATASHEET",
	JOURNEY_AI = "JOURNEY_AI",
	CUSTOM_REPORTING = "CUSTOM_REPORTING",
	INTEGRATION_REQUEST = "INTEGRATION_REQUEST",
	LABELS_TOOL = "LABELS_TOOL"
}
