import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { Card, Col, Collapse, Row } from "reactstrap";

export const ExpendableCard: React.FC<{
	id: string;
	title: string;
	info?: string;
	isOpen: boolean;
	onExpend: (cardId: any, expend: boolean) => void;
	infoSuccess: boolean;
	classes?: string;
	headerStyle?: object;
}> = ({ id, title, info, isOpen, children, onExpend, infoSuccess, classes, headerStyle }) => {
	return (
		<Card className={`cost-prediction-card ${classes}`}>
			<Row className={"align-items-center"}>
				<Col style={{ fontWeight: 600, ...headerStyle }}>{title}</Col>
				<Col className={"d-flex justify-content-end"}>
					<span style={{ marginRight: "20px", color: infoSuccess ? "#1DD6AE" : "#FF5959" }}>
						{info}
					</span>
					{isOpen ? (
						<ChevronUp
							style={{ cursor: "pointer" }}
							onClick={() => {
								onExpend(id, false);
							}}
						/>
					) : (
						<ChevronDown
							style={{ cursor: "pointer" }}
							onClick={() => {
								onExpend(id, true);
							}}
						/>
					)}
				</Col>
			</Row>
			<Collapse isOpen={isOpen}>{children}</Collapse>
		</Card>
	);
};
