import React from "react";
import { InjectedFormikProps } from "formik";
import { Input } from "reactstrap";


export interface IReportFormElements {
    report_name: string;
    report: string;
    report_file: string;
}


export interface IReportFormElementProps {

}

const ReportFormElements: React.FC<InjectedFormikProps<IReportFormElementProps, IReportFormElements>> = ({
    values: { report, report_file, report_name },
    handleChange,
    handleBlur,
    touched,
    errors
}) => {


    return (
        <>
            <Input
                label="Report Name"
                name="report_name"
                placeholder="New Report"
                value={report_name}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                handleOnBlur={handleBlur}
            />

            <Input
                label="Assign Report"
                name="username"
                placeholder="Your Username"
                value={report_name}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                handleOn
                Blur={handleBlur}
            />


            <Input
                label="Assign Report"
                name="username"
                placeholder="Your Username"
                value={report_name}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                handleOnBlur={handleBlur}
            />
        </>
    )
}

export default ReportFormElements;