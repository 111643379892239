import { IGetAllNotificationsResponse } from "./notifications.d";
import { notification } from "../actionContants";

export function getAllNotificationsAction(
	payload: any
): IGetAllNotificationsResponse {
	return {
		type: notification.GET_ALL_NOTIFICATIONS_RESPONSE,
		payload
	};
}
