import { IIntegration } from "../../interfaces/integration";
import { ActionType } from "../actions/action";
import { integration } from "../actions/actionContants";

export interface IIntegrationState {
	integrations: IIntegration[];
	requests: any[];
}

const INIT_STATE: IIntegrationState = {
	integrations: [],
	requests: []
};

export default function (
	state: IIntegrationState = INIT_STATE,
	action: ActionType
) {
	switch (action.type) {
		case integration.GET_INTEGRATIONS_RESPONSE:
			return {
				...state,
				integrations: action.payload
			};
		case integration.GET_ALL_REQUESTS_RESPONSE:
			return {
				...state,
				requests: action.payload
			};
		default:
			return state;
	}
}
