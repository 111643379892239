import * as yup from "yup";
import { isRequired } from "./messages";

const isNumberOrNull = yup.number().nullable().typeError("Please input a number");

const allNamedFileFieldsComplete = yup
	.array()
	.of(
		yup.object().shape({
			country: yup.string(),
			link: yup.string(),
			name: yup.string()
		})
	)
	.nullable()
	.required(isRequired("All documents should have a country, name, and link associated with them"));

const allCountryFileFieldsComplete = yup
	.array()
	.of(
		yup.object().shape({
			country: yup.string(),
			datasheet: yup.string()
		})
	)
	.nullable()
	.required(isRequired("All documents should have a country and link associated with them"));

export const IngredientValidation = yup.object().shape({
	name: yup.string().required(isRequired("Ingredient Name")),
	jf_display_name: yup.string().required(isRequired("Ingredient Name")),
	cost: isNumberOrNull,
	df_energy_kcal: isNumberOrNull,
	// allergens: allNamedFileFieldsComplete,
	// certifications: allNamedFileFieldsComplete,
	// datasheets: allCountryFileFieldsComplete,
	df_protein: isNumberOrNull,
	df_carbohydrate: isNumberOrNull,
	df_fiber: isNumberOrNull,
	df_sugar: isNumberOrNull,
	df_calcium: isNumberOrNull,
	df_vitamin_d2_d3: isNumberOrNull,
	df_vitamin_c: isNumberOrNull,
	df_cholesterol: isNumberOrNull,
	df_fatty_acid_total_saturated: isNumberOrNull,
	df_fatty_acid_total_trans: isNumberOrNull,
	df_iron: isNumberOrNull,
	df_potassium: isNumberOrNull,
	df_sodium: isNumberOrNull
});
