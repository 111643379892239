import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Button from "../../components/common/button";
import { Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Pagination from "../../components/pagination";
import adminService from "../../services/adminService";
import { IApplicationState } from "../../redux/reducers";
import { IPagination } from "../../interfaces/pagination";
import EditProductForm from "../products/productDetail/form/editForm";
import { IProduct } from "../../interfaces/products";
import { paginateAdminProducts, fetchAdminProductResponseAction } from "../../redux/actions/admin/adminActions";
import Loader from "../../components/loader";

interface IProps {
    fetchProducts: () => Promise<any>;
    paginate: (page: number) => void;
    selectProduct: (product: IProduct) => void;
    products: any[];
    pagination: IPagination
}

interface IState {

    isFormOpen: boolean;

}

const ProductsTable: React.FC<IProps> = ({
    fetchProducts,
    paginate,
    pagination,
    products,
    selectProduct
}) => {

    const [loading, setLoading] = useState<boolean>(false);

    const [product, setProduct] = useState<IProduct>();
    const [isEditModalOpen, setEditModal] = useState<boolean>(false);

    function toggle() {
        const currentState = isEditModalOpen;
        setEditModal(!currentState);
    }


    function fetch() {
        setLoading(true)
        fetchProducts().finally(() => {
            setLoading(false)
        })
    }

    useEffect(fetch, [
        pagination.page
    ])

    function editProduct(product: IProduct) {
        selectProduct(product);
        setEditModal(true);
    }


    return (
        <>
            <div className="user-requests">
                <Loader isLoading={loading}>
                    <Pagination page={pagination.page} pages={pagination.pages} paginatorSize="md" total={pagination.total} size={pagination.size} handlePageChange={paginate} />
                </Loader>
                <Button onClick={fetch}
                    className="refresh color-white"
                >Refresh</Button>

                <Table responsive hover>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Brand Name</th>
                            <th>Company Name</th>
                            <th>Manufacturer</th>
                            <th>Ingredients</th>
                            <th>Uploaded By</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products.map((item, index) => {
                                    return (
                                        <tr key={item.id} >
                                            <td>{item.name}</td>
                                            <td>{item.brand?.name}</td>
                                            <td>{item.company?.name}</td>
                                            <td>{item.manufacturer?.name}</td>
                                            <td>{Object.keys(item.ingredients || 0).length}</td>
                                            <td>{item.uploaded_by?.name}</td>
                                            <td>
                                                <div className="actions" >
                                                    <Button className="color-white approve" loading={false} icon="fa fa-edit" onClick={() => editProduct(item)} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }

                    </tbody>
                </Table>

            </div>
            <Modal isOpen={isEditModalOpen} size="lg">
                <ModalHeader toggle={toggle}>Edit Product</ModalHeader>
                <ModalBody>
                    <EditProductForm toggleForm={toggle} isAdmin  />
                </ModalBody>
            </Modal>
        </>
    )
}

const mapDispatchToProps = {
    fetchProducts: () => adminService.getAdminProducts(),
    selectProduct: (product: IProduct) => adminService.selectProduct(product),
    paginate: (page: number) => (dispatch: Dispatch) => {
        dispatch(paginateAdminProducts(page))
    }
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        products: state.admin.products.products,
        pagination: state.admin.products.pagination,
        product: state.admin.products.selected
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductsTable);