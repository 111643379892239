import React from "react";
import { Switch,Route } from "react-router-dom";
import IngredientSingle from "./ingredient-item/ingredient-single";
import Integration from "./integration/integration";
import KnowledgeHub from "./knowledge-hub/kh";
import Labels from "./labels/labels";
import VersionHistory from "./product-single/version-history";
import { routes } from "../routes";


const DesignRouter: React.FC = () => (
    <Switch>
        <Route path={routes.INGREDIENT_SINGLE} component={IngredientSingle} />
        <Route path={routes.INTEGRATION} component={Integration} />
        <Route path={routes.KB} component={KnowledgeHub} />
        <Route path={routes.LABELS} component={Labels} />
        <Route path={routes.VERSION_HISTORY} component={VersionHistory} />
    </Switch>
)

export default DesignRouter;