import React, { useRef, useState } from "react";
import { Form } from "reactstrap";
import Button from "../../../../components/common/button";
import LoginVerify from "../verify/loginVerift";
import VerificationCodeFields from "./verificationCodeFields";

interface IVerificationCodeFormState {
	value: string;
	errorMessage: string | null;
	isSubmitting: boolean;
}
interface IProps {
	phoneNumber: string;
	onSubmit: (value: string) => Promise<void>;
	onBack?: () => void;
	showRedirect?: boolean;
}

const VerificationCodeForm: React.FC<IProps> = (props) => {
	const [formState, setFormState] = useState<IVerificationCodeFormState>({
		value: "",
		errorMessage: null,
		isSubmitting: false
	});

	const handleChange = (value: string) => {
		setFormState({
			...formState,
			value
		});
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		await props.onSubmit(formState.value);
	};

	return (
		<LoginVerify
			phoneNumber={props.phoneNumber}
			isSubmitting={formState.isSubmitting}
			showRedirect={props.showRedirect}
			onSubmit={handleSubmit}
			onChange={handleChange}
			onBack={props.onBack}
		></LoginVerify>
	);
};

export default VerificationCodeForm;
