import React, { useEffect, useState } from "react";
import { PlusSquare, X, ArrowLeft } from "react-feather";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { IRecommendation } from "../../../interfaces/recommendation";
import ingredientService from "../../../services/ingredientService";
import { connect, useDispatch } from "react-redux";
import RecommendationAnalysis from "./recommendationAnalysis";
import RecommendationSuppliers from "./recommendationSuppliers";
import RecommendationRate from "./recommendationRate";
import RecommendationSubmitted from "./recomendationSubmitted";
import RecommendationIngredientsInfo from "./recommendationIngredientsinfo";
import {
	IIngredient,
	IRecommendedIngredient,
	ISearchIngredient
} from "../../../interfaces/ingredient";
import { RouteComponentProps, withRouter } from "react-router-dom";

import RecommendationSupplierInfo from "./recomendationSupplierInfo";
import RecommendationPortfolios from "./recommendationPortfolio";
import { routes } from "../../routes";
import productService from "../../../services/productService";

export enum recommendationStep {
	INGREDIENTS_INFO = "INGREDIENTS_INFO",
	ANALYSIS = "ANALYSIS",
	SUPPLIERS = "SUPPLIERS",
	SUPPLIER_INFO = "SUPPLIER_INFO",
	PORTFOLIO = "PORTFOLIO",
	RATE = "RATE",
	SUBMITTED = "SUBMITTED"
}

export enum analysisStep {
	COST_PREDICTION = "COST_PREDICTION",
	SUSTAINABILITY_ANALYSIS = "SUSTAINABILITY_ANALYSIS",
	NUTRITION_ANALYSIS = "NUTRITION_ANALYSIS"
}

export enum recommendationTypes {
	INGREDIENT_RECOMMENDATION = "Ingredient Recommendation",
	PACKAGING_RECOMMENDATION = "Packaging Recommendation",
	SUPPLIER_RECOMMENDATION = "Supplier Recommendation"
}

interface IProps extends RouteComponentProps {
	isOpen: boolean;
	recommendation: IRecommendation;
	similarIngredients: Array<any>;
	getRecommendedIngredients: (
		oldIngredientId: string,
		recommendIngredientId: string
	) => Promise<any>;
	getHarmonized: (
		productId: string,
		firstIngredientId: string,
		secondIngredientId: string
	) => Promise<any>;
	addRateRecommendation: (recommendationId: string, rate: number) => Promise<any>;
	toggle: () => void;
}

const RecommendationModal: React.FC<IProps> = ({
	isOpen,
	recommendation,
	similarIngredients,
	toggle,
	getRecommendedIngredients,
	getHarmonized,
	addRateRecommendation,
	...props
}) => {
	const logo = require("../../../assets/images/recomendation/JourneyAI.png");
	const [expendedCard, setExpendedCard] = useState(recommendationStep.INGREDIENTS_INFO);
	const [rate, setRate] = useState(0);
	const [harmonizations, setHarmonizations] = useState([]);

	const [recommendationNumber, setRecommendationNumber] = useState();

	const [activeRecommendation, setActiveRecommendation] = useState(
		recommendationTypes.INGREDIENT_RECOMMENDATION
	);
	const [expendedAnalysis, setActiveAnalysis] = useState(analysisStep.COST_PREDICTION);
	// const [selectedProductIngredient, setSelectedProductIngredient] = useState<ISearchIngredient>();

	const [oldIngredient, setOldIngredient] = useState<IIngredient>();
	const [recommendNum, setRecommendNum] = useState<number>();
	const [recommendedIngredient, setRecommendedIngredient] = useState<IIngredient>();

	const updateExpendedCards = (tabName: recommendationStep, analysisTabName?: analysisStep) => {
		setExpendedCard(tabName);
		if (analysisTabName) {
			setActiveAnalysis(analysisTabName);
		}
	};

	const updateRate = async (rate: number) => {
		try {
			await addRateRecommendation(recommendation.id, rate);
			setRate(rate);
			updateExpendedCards(recommendationStep.SUBMITTED);
		} catch {}
	};

	useEffect(() => {
		setRate(recommendation?.rating);
	}, [recommendation?.rating]);
	useEffect(() => {
		try {
			getRecommendedIngredients(
				recommendation.ingredient.id,
				recommendation.recommended_ingredients[0]?.id
			).then((res: any) => {
				if (res) {
					setRecommendationNumber(res.hash_number);
					setOldIngredient(res[recommendation.ingredient.id]);
					setRecommendedIngredient(res[recommendation.recommended_ingredients[0]?.id]);
				}
			});
		} catch {
			console.log("errors");
		}
	}, [recommendation.ingredient.id, recommendation.recommended_ingredients[0]?.id]);

	useEffect(() => {
		try {
			//
			// getHarmonized(
			// 	recommendation.product.id,
			// 	"5ef16374b3a431e671847cd8",
			// 	"5ef16374b3a431e671846c91"
			// ).then((res: any) => {
			// 	setHarmonizations(res);
			// 	console.log("getHarmonized", res);
			// });
			getHarmonized(
				recommendation.product.id,
				recommendation.ingredient.id,
				recommendation.recommended_ingredients[0]?.id
			).then((res: any) => {
				setHarmonizations(res);
			});
		} catch {
			console.log("errors");
		}
	}, [recommendation.ingredient.id, recommendation.recommended_ingredients[0]?.id]);

	const setHeaderClass = (recommendation: recommendationTypes) => {
		switch (recommendation) {
			case recommendationTypes.INGREDIENT_RECOMMENDATION: {
				return "ingredient-recommendation";
			}
			case recommendationTypes.PACKAGING_RECOMMENDATION: {
				return "packaging-recommendation";
			}
			case recommendationTypes.SUPPLIER_RECOMMENDATION: {
				return "supplier-recommendation";
			}
			default:
				return "ingredient-recommendation";
		}
	};

	return (
		<Modal isOpen={isOpen} className={"notification-modal recommendation-v2"}>
			<ModalHeader tag="div" className={"d-flex"}>
				<Row>
					<Col className={"card-header-label"} md="auto">
						<span className={setHeaderClass(activeRecommendation)}>{activeRecommendation}</span>
					</Col>
					{expendedCard !== recommendationStep.INGREDIENTS_INFO && (
						<Col className="ml-3">
							<span
								style={{
									fontStyle: "italic",
									textDecoration: "underline",
									cursor: "pointer",
									color: "#AAAAAA"
								}}
								onClick={() => {
									updateExpendedCards(recommendationStep.INGREDIENTS_INFO);
								}}
							>
								Back to overview
							</span>
						</Col>
					)}

					<Col className={"mr-4 d-flex justify-content-end"}>
						{recommendation?.rec_source === "SUGGESTED" ? (
							<div className="card-body-label suggested ml-2">Suggested</div>
						) : (
							<div className="card-body-label requested ml-2">Requested</div>
						)}

						{/* </div> */}
						<div onClick={toggle} style={{ cursor: "pointer" }} className={"mr-4"}>
							<PlusSquare size={20} color={"#17C9DC"} />
						</div>
						<div onClick={toggle} style={{ cursor: "pointer" }}>
							<X size={20} color={"#141C3F"} />
						</div>
					</Col>
				</Row>
			</ModalHeader>

			<ModalBody>
				<>
					<Row className={"d-flex align-items-center justify-content-between"}>
						<Col className={"d-flex align-items-center justify-content-end"}></Col>
					</Row>
					<Row>
						<span style={{ color: "black" }}>#{recommendationNumber}</span>
					</Row>
					<Row className={"mb-3 mt-2"}>
						<span className={"recommendation-text"}>
							<span
								style={{
									fontSize: "20px",
									letterSpacing: "-0.4px",
									fontFamily: "Biotif",
									color: "#041928"
								}}
							>
								<b>{recommendedIngredient?.jf_display_name}</b> <br />
								<span
									style={{
										fontSize: "14px",
										letterSpacing: "-0.4px",
										lineHeight: "17px",
										display: "flex",
										flexDirection: "column"
									}}
								>
									<span>Replace {oldIngredient?.jf_display_name} in</span>
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											props.history.push(routes.PRODUCTS + `/${recommendation?.product.id}`);
										}}
									>
										<u>
											{recommendation?.product?.name}
											{Boolean(recommendation?.product?.brand?.name) &&
												`in ${recommendation?.product?.brand?.name}`}
										</u>
									</span>
								</span>
							</span>
						</span>
					</Row>
					{expendedCard === recommendationStep.ANALYSIS && (
						<Row className={"mt-2"}>
							<Col className={"d-flex justify-content-end"}>
								<div className={"d-flex align-items-center mr-3"}>
									<div className="circle gray"></div>
									<span className="pl-1">{recommendation.ingredient.jf_display_name}</span>
								</div>
								<div className={"d-flex align-items-center"}>
									<div className="circle purple"></div>
									<span className="pl-1">
										{recommendation?.recommended_ingredients?.[0]?.jf_display_name}
									</span>
								</div>
							</Col>
						</Row>
					)}
					{expendedCard === recommendationStep.INGREDIENTS_INFO &&
						oldIngredient &&
						recommendedIngredient && (
							<RecommendationIngredientsInfo
								harmonizations={harmonizations}
								oldIngredient={oldIngredient}
								recommendedIngredient={recommendedIngredient}
								recommendation={recommendation}
								onChangeExpendedCard={updateExpendedCards}
							/>
						)}
					{expendedCard === recommendationStep.ANALYSIS &&
						oldIngredient &&
						recommendedIngredient && (
							<RecommendationAnalysis
								oldIngredient={oldIngredient}
								recommendedIngredient={recommendedIngredient}
								activeRecommendation={activeRecommendation}
								activeAnalysis={expendedAnalysis}
							/>
						)}
					{expendedCard === recommendationStep.SUPPLIERS && (
						<RecommendationSuppliers
							onSupplierDetail={() => {
								updateExpendedCards(recommendationStep.SUPPLIER_INFO);
							}}
						/>
					)}
					{expendedCard === recommendationStep.SUPPLIER_INFO && <RecommendationSupplierInfo />}
					{expendedCard === recommendationStep.PORTFOLIO && (
						<RecommendationPortfolios
							history={props.history}
							toggle={toggle}
							harmonizations={harmonizations}
						/>
					)}

					{expendedCard === recommendationStep.RATE && (
						<RecommendationRate
							rate={rate}
							onSubmit={(rateNumber: number) => {
								updateRate(rateNumber);
							}}
						/>
					)}
					{expendedCard === recommendationStep.SUBMITTED && (
						<RecommendationSubmitted
							onNavigateInfo={() => {
								updateExpendedCards(recommendationStep.INGREDIENTS_INFO);
							}}
						/>
					)}
					<Row className="recommendation-container">
						<Col style={{ display: "flex", alignItems: "center" }}>
							<img src={logo} alt="Logo" />
							<span style={{ marginLeft: 5 }}>
								{" "}
								Powered by <i>JourneyAI</i>
							</span>
						</Col>
						<Col className={"d-flex justify-content-end"}>
							<h4
								onClick={() => {
									updateExpendedCards(recommendationStep.RATE);
								}}
							>
								Rate this recommendation
							</h4>
						</Col>
					</Row>
				</>
			</ModalBody>
		</Modal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		similarIngredients: state.ingredient.selectedSimiliarIngredients
	};
};

const mapDispatchToProps = {
	getRecommendedIngredients: (oldIngredientId: string, recommendIngredientId: string) =>
		ingredientService.getCompareIngredients(oldIngredientId, recommendIngredientId),
	getHarmonized: (productId: string, firstIngredientId: string, secondIngredientId: string) =>
		productService.getHarmonized(productId, firstIngredientId, secondIngredientId),
	addRateRecommendation: (recommendationId: string, rate: number) =>
		ingredientService.addRateRecommendation(recommendationId, rate)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecommendationModal));
