import React, { useEffect, useState } from "react";
import { Box, CheckCircle, ChevronRight, X } from "react-feather";
import Button from "../../../components/common/button";
import HierarchySelect from "./components/hierarchySelect";
import SelectedProductPreferences from "./components/selectedProductPreferences";
import OriginalProduct from "./original-product";

interface IProps {
	user: any;
	getProduct: () => Promise<any>;
	getProductPreferences: (userId: string) => Promise<any>;
	updateProductPreferences: (userId: string, body: any) => Promise<any>;
}

interface IPreference {
	preference_type: string;
	title: string;
	id: string;
	category?: string;
	sub_category?: string;
}

const Ingredient: React.FC<IProps> = (props) => {
	const [list, setList] = useState<any>([]);
	const [prefs, setPrefs] = useState<any>({});
	const [saving, setSaving] = useState<boolean>(false);

	useEffect(() => {
		setSaving(true);
		props
			.getProduct()
			.then((data) => {
				setList(data);
			})
			.then(() => {
				props.getProductPreferences(props.user?.user_id).then((data) => {
					setPrefs(data || []);
				});
			})
			.finally(() => setSaving(false));
	}, []);

	const onSelectPref = (pref: any) => {
		pref = list.find((e: any) => e.id === pref.jf_id || pref.id === e.id); // Map id of selected value back to preference
		const index = (prefs.preferences || []).findIndex((o: IPreference) => o.id === pref.id);
		const temp = { ...prefs };
		if (index > -1) {
			if (temp && temp.preferences) temp.preferences.splice(index, 1);
		} else {
			temp.preferences = [...(temp.preferences || []), pref];
		}
		setPrefs(temp);
	};

	const onRemovePref = (pref: any) => {
		const index = (prefs.preferences || []).findIndex((o: IPreference) => o.id === pref.id);
		const temp = { ...prefs };
		if (index > -1) {
			if (temp && temp.preferences) temp.preferences.splice(index, 1);
		}
		setPrefs(temp);
	};

	const onSave = () => {
		setSaving(true);
		props.updateProductPreferences(props.user?.user_id, prefs).finally(() => setSaving(false));
	};

	const colorPreferred = (category: string, isPreferred: boolean) => {
		if (!isPreferred) {
			return "#fff";
		}
		if (category.toLowerCase() === "categories") {
			return "#3f65f1";
		} else if (category.toLowerCase() === "form") {
			return "#f4a850";
		} else if (category.toLowerCase() === "storage conditions") {
			return "#657280";
		} else {
			return "#3f65f1";
		}
	};

	const getIcon = (category: string, isPreferred: boolean) => {
		if (category.toLowerCase() === "categories") {
			return <Box color={colorPreferred(category, !isPreferred)} />;
		} else if (category.toLowerCase() === "form") {
			return <CheckCircle color={colorPreferred(category, !isPreferred)} />;
		} else if (category.toLowerCase() === "storage conditions") {
			return <ChevronRight color={colorPreferred(category, !isPreferred)} />;
		} else {
			return <Box color={colorPreferred(category, !isPreferred)} />;
		}
	};

	// Convert defined preferences to original preference structure for display in original format
	const reduceToOriginalPrefStructure = () => {
		return list
			.filter((obj: IPreference) =>
				["Finished Format", "Storage Conditions"].includes(obj.category || "")
			)
			.reduce(
				(formatted: any, pref: IPreference) => {
					pref.category == "Storage Conditions"
						? (formatted["Storage Conditions"] = [...formatted["Storage Conditions"], pref])
						: (formatted["Finished Format"] = [...formatted["Finished Format"], pref]);
					return formatted;
				},
				{ "Finished Format": [], "Storage Conditions": [] }
			);
	};
	return (
		<>
			<div className="product-pref-container">
				<HierarchySelect
					options={list}
					currentlySelected={prefs.preferences}
					onSelectChange={onSelectPref}
				/>

				<SelectedProductPreferences prefs={prefs} onRemove={onRemovePref} />
			</div>
			{/* Original Preference Display for Product Forms and Storage Condition Preferences */}
			<div>
				<OriginalProduct
					onSelectPref={onSelectPref}
					preferences={reduceToOriginalPrefStructure()}
					selectedPrefs={prefs}
				/>
			</div>
			<div style={{ paddingTop: 17 }}>
				{" "}
				<p>
					These preferences will help tailor our product suggestions and filter your <br />
					live searching whilst using the app.
				</p>
				<Button
					className="btn btn-primary color-white ls-5 font-weight-semibold mt-3"
					type="submit"
					disabled={saving}
					onClick={() => onSave()}
				>
					Save account settings
				</Button>
			</div>
		</>
	);
};

export default Ingredient;
