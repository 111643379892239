import React from "react";
import { Pagination as ReactPagination, PaginationItem, PaginationLink } from "reactstrap";
import "./pagination.scss";
import { IPagination } from "../../interfaces/pagination";
import { ITEMS_PER_PAGE } from "../../utils/constants";

interface IProps extends IPagination {
	range?: number;
	handlePageChange: (page: number) => void;
	className?: string;
	paginatorSize?: "sm" | "md" | "lg" | "xs";
	loading?: boolean;
}

export const Pagination: React.FC<IProps> = ({
	className,
	page,
	pages,
	size,
	range = 5,
	handlePageChange,
	total,
	paginatorSize = "md",
	loading = false
}) => {
	const paginateItems = [];
	let start = 1;
	let end = pages;

	if (end - start > range) {
		let lowerDiff = page - Math.floor(range / 2);
		let upperDiff = page + Math.floor(range / 2);

		if (lowerDiff > 0) {
			start = lowerDiff;

			let exceedRange = start + range - 1 - total;

			if (exceedRange > 0 && start - exceedRange > 0) {
				start = start - exceedRange;
			}
		}

		if (upperDiff <= total) {
			end = start + range - 1;
		}
	}

	for (let pageNumber = start; pageNumber <= end; pageNumber++) {
		paginateItems.push(
			<PaginationItem
				key={pageNumber}
				active={pageNumber === page}
				onClick={() => handlePageChange(pageNumber)}
				disabled={loading}
			>
				<PaginationLink>{pageNumber}</PaginationLink>
			</PaginationItem>
		);
	}

	return (
		<div className={`pagination-wrapper ${className ? className : ""}`}>
			<ReactPagination size={paginatorSize}>
				{/* <PaginationItem>
                    <PaginationLink first onClick={() => handlePageChange(1)} />
                </PaginationItem> */}
				<PaginationItem disabled={loading}>
					<PaginationLink
						disabled={page === start}
						previous
						onClick={() => handlePageChange(page - 1)}
					/>
				</PaginationItem>

				{/* {Array.from(Array(pages).keys()).map(pageNumber => {
                    pageNumber++;
                    return (
                    
                    )
                })} */}

				{paginateItems}

				<PaginationItem disabled={loading}>
					<PaginationLink
						disabled={page === pages}
						next
						onClick={() => handlePageChange(page + 1)}
					/>
				</PaginationItem>
				{/* <PaginationItem>
                    <PaginationLink last onClick={() => handlePageChange(pages)} />
                </PaginationItem> */}
			</ReactPagination>
		</div>
	);
};
