import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { ChevronRight } from "react-feather";
import { IProduct } from "../../../interfaces/products";
import { routes } from "../../routes";

interface ISupplierItemProps {
	id: string;
	title: string;
	cost: string;
	sustainability: string;
	nutrition: string;
	history: any;
	toggle: () => any;
}

const PortfolioItem: React.FC<ISupplierItemProps> = ({
	id,
	title,
	cost,
	sustainability,
	nutrition,
	history,
	toggle
}) => {
	return (
		<Row className={"portfolio-content mt-4"}>
			<Col className={"name large"}>{title}</Col>
			<Col className={"text-center"}>
				<span className={"portfolio-content-box"}>{cost}</span>
			</Col>
			<Col className={"text-center"}>
				<span className={"portfolio-content-box"}>{sustainability}</span>
			</Col>
			<Col className={"text-center"}>
				<span className={"portfolio-content-box"}>{nutrition}</span>
			</Col>
			<Col className={"d-flex justify-content-center"}>
				<Button
					className={"view-button"}
					size="sm"
					outline
					onClick={() => {
						history.push(routes.PRODUCTS + `/${id}`);
						toggle();
					}}
				>
					View <ChevronRight size={14} />
				</Button>
			</Col>
		</Row>
	);
};

interface ISuppliersProps {
	harmonizations: Array<IProduct>;
	history: any;
	toggle: () => any;
}

const RecommendationPortfolios: React.FC<ISuppliersProps> = ({
	harmonizations,
	history,
	toggle
}) => {
	return (
		<Card className={"mt-3 pt-3"} style={{ paddingBottom: "125px" }}>
			<div
				style={{
					padding: "22px 0 35px 45px",
					fontFamily: "Inter",
					fontSize: "18px",
					fontWeight: "bold",
					color: "#041928"
				}}
			>
				Portfolio Harmonization
			</div>
			<Row className={"portfolio-content"}>
				<Col className={"portfolio-content-header large"}>Product</Col>
				<Col className={"portfolio-content-header"}>Cost</Col>
				<Col className={"portfolio-content-header"}>Sustainability</Col>
				<Col className={"portfolio-content-header"}>Nutrition</Col>
				<Col></Col>
			</Row>
			{harmonizations.map((element: any) => {
				return (
					<PortfolioItem
						id={element.master_product_id}
						key={element.master_product_id}
						title={element.name}
						cost={`${element.calculated_amount} %`}
						sustainability="+26.9%"
						nutrition="+26.9%"
						history={history}
						toggle={toggle}
					></PortfolioItem>
				);
			})}
		</Card>
	);
};

export default RecommendationPortfolios;
