import React from "react";
import { IPreference } from "../../../interfaces/preference";
import { ShoppingBag, Triangle, Hexagon, Info } from "react-feather";

interface IProps {
  preference: IPreference;
}

const PreferenceIcon = (preference_type: string) => {
  switch (preference_type) {
    case "Cost": 
      return <ShoppingBag className="mr-2 color-bg-blue" size="18" />
    case "Allergens":
      return <Triangle className="mr-2 color-bg-yellow" size="18" />
    case "Dietary/Functional":
      return <Hexagon className="mr-2 color-bg-yellow" size="18" />
    default: 
      return <Info className="mr-2 color-bg-yellow" size="18" />
  }
}

const Preference: React.FC<IProps> = ({ preference: { id, preference_type, title } } ) => {
  return (
    <div className="portfolio-preference-item ml-0 mt-2 mr-2" key={ id }>
        { PreferenceIcon(preference_type) }
        <p>{ title }</p>
    </div> 
  )
}

export default Preference;