import React, { useState } from "react";
import {
	IProfileRequest,
	IProfileResponse,
	IProfileFormElements
} from "../../../../interfaces/user";
import { InjectedFormikProps, withFormik } from "formik";
import { Form, Col, Row, Spinner } from "reactstrap";
import Button from "../../../../components/common/button";
import { ProfileSchema } from "../../../../validations/profileValidation";
import ProfileFormElements from "./profileFormElements";
import userService from "../../../../services/userService";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../redux/reducers";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { Image } from "react-feather";
import TwoFactorSetup from "../twoFactor/twoFactorSetup";

interface IProfileFormProps {
	userId: any;
	formData: IProfileResponse;
	saveProfile: (
		userId: string,
		request: IProfileRequest,
		resolve?: Function,
		reject?: Function
	) => Promise<IProfileResponse>;
	updateProfileImage: (file: File) => Promise<any>;
	updateCompanyLogo: (file: File) => Promise<any>;
}

const ProfileForm: React.FC<InjectedFormikProps<IProfileFormProps, IProfileFormElements>> = (
	props
) => {
	const [uploadingProfileImage, setUploadingProfileImage] = useState<boolean>(false);
	const [uploadingCompanyLogo, setUploadingCompanyLogo] = useState<boolean>(false);

	async function updateProfileImage([{ file }]: ImageListType) {
		if (!file) return;
		setUploadingProfileImage(true);
		try {
			await props.updateProfileImage(file);
		} catch (error) {
		} finally {
			setUploadingProfileImage(false);
		}
	}

	async function updateCompanyLogo([{ file }]: ImageListType) {
		if (!file) {
			return;
		}
		setUploadingCompanyLogo(true);

		try {
			await props.updateCompanyLogo(file);
		} catch (error) {
		} finally {
			setUploadingCompanyLogo(false);
		}
	}

	return (
		<Form onSubmit={props.handleSubmit}>
			<hr style={{ marginBottom: 20, borderColor: "#cedbe9" }} />
			<div className="d-flex">
				<div style={{ flex: 1, marginRight: 100 }}>
					<ProfileFormElements {...props} />
				</div>
				<div style={{ flex: 1 }}>
					<div style={{ marginBottom: 14 }}>
						<ImageUploading onChange={updateProfileImage}>
							{({ errors, imageList, onImageUpload }) => (
								<div className="image-upload-wrapper">
									<p style={{ marginBottom: 14 }}>User Photo</p>
									<div onClick={onImageUpload}>
										<div>
											{imageList.length ? (
												<div className="image-upload square">
													{uploadingProfileImage && <Spinner type="grow" color="primary" />}
													<img src={imageList[0].dataURL} />
												</div>
											) : props.formData.user_photo !== "" ? (
												<div className="image-upload square">
													{uploadingProfileImage && <Spinner type="grow" color="primary" />}
													<img src={props.formData.user_photo} loading="lazy" />
												</div>
											) : (
												<div
													style={{
														padding: 16,
														alignItems: "center",
														borderRadius: 6,
														border: "1px solid #cedbe9",
														maxWidth: 340
													}}
													className="d-flex"
												>
													<div className="image-upload square" style={{ marginRight: 17 }}>
														<Image />
													</div>
													<div>
														<span style={{ fontSize: 16, color: "#3f65f1" }}>Choose File</span>
														<br />
														<span style={{ fontSize: 14, color: "#657280" }}>no file</span>
													</div>
												</div>
											)}
										</div>
									</div>
									{/* <div className="image-upload circle" onClick={onImageUpload}>
                                        {uploadingProfileImage && <Spinner type="grow" color="primary" />}
                                        {imageList.length ? <img src={imageList[0].dataURL} /> :
                                            props.formData.user_photo !== "" ?
                                                <img src={props.formData.user_photo} loading="lazy" />
                                                :
                                                <Image />
                                        }
                                    </div> */}
								</div>
							)}
						</ImageUploading>
					</div>
					<div>
						<ImageUploading onChange={updateCompanyLogo}>
							{({ imageList, onImageUpload }) => (
								<div className="image-upload-wrapper">
									<p style={{ marginBottom: 14 }}>Company Logo</p>
									<div onClick={onImageUpload}>
										<div>
											{imageList.length ? (
												<div className="image-upload square">
													{uploadingCompanyLogo && <Spinner type="grow" color="primary" />}
													<img src={imageList[0].dataURL} />
												</div>
											) : props.formData.company.logo !== "" ? (
												<div className="image-upload square">
													{uploadingCompanyLogo && <Spinner type="grow" color="primary" />}
													<img src={props.formData.company.logo} loading="lazy" />
												</div>
											) : (
												<div
													style={{
														padding: 16,
														alignItems: "center",
														borderRadius: 6,
														border: "1px solid #cedbe9",
														maxWidth: 340
													}}
													className="d-flex"
												>
													<div className="image-upload square" style={{ marginRight: 17 }}>
														<Image />
													</div>
													<div>
														<span style={{ fontSize: 16, color: "#3f65f1" }}>Choose File</span>
														<br />
														<span style={{ fontSize: 14, color: "#657280" }}>no file</span>
													</div>
												</div>
											)}
										</div>
									</div>
									{/* <div className="image-upload" onClick={onImageUpload}>
                                        {uploadingCompanyLogo && <Spinner type="grow" color="primary" />}
                                        {imageList.length ? <img src={imageList[0].dataURL} /> :
                                            props.formData.user_photo !== "" ?
                                                <img src={props.formData.company.logo} loading="lazy" />
                                                :
                                                <Image />
                                        }
                                    </div> */}
								</div>
							)}
						</ImageUploading>
					</div>
					<div>
						<TwoFactorSetup />
					</div>
				</div>
			</div>
			<hr style={{ marginTop: 70, marginBottom: 15, borderColor: "#cedbe9" }} />
			<Button
				className="btn btn-primary color-white ls-5 font-weight-semibold mt-3"
				type="submit"
				loading={props.isSubmitting}
				disabled={!props.isValid || props.isSubmitting}
			>
				Save account settings
			</Button>
		</Form>
	);
};

const WrappedForm = withFormik<IProfileFormProps, IProfileFormElements>({
	enableReinitialize: true,
	validationSchema: ProfileSchema,
	mapPropsToValues: (props) => {
		return {
			first_name: props.formData.first_name,
			last_name: props.formData.last_name,
			country: props.formData.country,
			city: props.formData.city,
			company_id: props.formData.company.id,
			company_goal: props.formData.company.company_goal,
			company_objective: props.formData.company.company_objective,
			company_name: props.formData.company.name,
			email: props.formData.email,
			state: props.formData.state,
			user_photo: props.formData.user_photo
		};
	},
	handleSubmit: async (
		{ email, company_id, company_name, company_objective, company_goal, ...rest },
		{ props: { saveProfile, userId } }
	) => {
		const updateProfileRequest: IProfileRequest = {
			company: {
				name: company_name,
				id: company_id,
				company_goal,
				company_objective
			},
			...rest
		};
		await saveProfile(userId, updateProfileRequest);
	}
});

const mapStateToProps = ({ user: { user_id } }: IApplicationState) => ({
	userId: user_id
});

const mapDispatchToProps = (dispatch: any) => ({
	saveProfile: (
		userId: string,
		profileData: IProfileRequest,
		resolve?: Function,
		reject?: Function
	) => dispatch(userService.updateProfile(userId, profileData)),
	updateProfileImage: (file: File) => dispatch(userService.updateProfileImage(file)),
	updateCompanyLogo: (file: File) => dispatch(userService.updateCompanyLogo(file))
});

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm(ProfileForm));
