import React from "react";

export const renderFeatureBox = (name: string, optimization?: number) => {
	if (optimization === undefined || optimization === null) {
		return <div className="box-secondary">{name}</div>;
	}
	switch (Math.sign(optimization)) {
		case 1:
			return <div className="box-outline-success">{name}</div>;
		case -1:
			return <div className="box-outline-danger">{name}</div>;
		case 0:
			return <div className="box-outline-warning">{name}</div>;
		default:
			return <div className="box-secondary">{name}</div>;
	}
};

export const renderNegativeFeatureBox = (name: string, optimization?: number) => {
	if (optimization === undefined || optimization === null) {
		return <div className="box-secondary">{name}</div>;
	}
	switch (Math.sign(optimization)) {
		case 1:
			return <div className="box-outline-danger">{name}</div>;
		case -1:
			return <div className="box-outline-success">{name}</div>;
		case 0:
			return <div className="box-outline-warning">{name}</div>;
		default:
			return <div className="box-secondary">{name}</div>;
	}
};
