import React from "react";
import { InjectedFormikProps } from "formik";

import { Row } from "reactstrap";
import Input from "../../../../../components/form/Input";

export interface IEmailFormElementsValues {
	email: string;
	name: string;
	companyName: string;
}

interface IEmailElementsProps {}

const EmailFormElements: React.FC<
	InjectedFormikProps<IEmailElementsProps, IEmailFormElementsValues>
> = (props) => {
	const {
		values: { email, name, companyName },
		handleChange,
		touched,
		handleBlur,
		errors
	} = props;

	return (
		<div className="recovery-form-content col-md-12" style={{ width: 300 }}>
			<Row className="pt-3">
				<Input
					label="Email"
					name="email"
					placeholder="****@thirstysprout.com"
					type="email"
					value={email}
					handleChange={handleChange}
					errors={errors}
					touched={touched}
					handleOnBlur={handleBlur}
				/>
			</Row>
			<Row className="pt-3">
				<Input
					label="Company Name"
					name="companyName"
					placeholder="JourneyFood"
					type="text"
					value={companyName}
					handleChange={handleChange}
					errors={errors}
					handleOnBlur={handleBlur}
					touched={touched}
				/>
			</Row>
			<Row className="pt-3">
				<Input
					label="Name"
					name="name"
					placeholder="John"
					type="text"
					value={name}
					handleChange={handleChange}
					errors={errors}
					handleOnBlur={handleBlur}
					touched={touched}
				/>
			</Row>
		</div>
	);
};

export default EmailFormElements;
