import React from "react";
import { FormikErrors, FormikValues, FormikTouched, ErrorMessage } from "formik";
import { Label, Input as InputField } from "reactstrap";

type IType =
	| "text"
	| "email"
	| "number"
	| "password"
	| "checkbox"
	| "select"
	| "textarea"
	| "hidden"
	| "date";
interface IInputFieldProps {
	label?: string;
	name: string;
	placeholder?: string;
	value: any;
	errors: FormikErrors<FormikValues>;
	touched: FormikTouched<FormikValues>;
	handleOnBlur?(e: React.FocusEvent<any>): void;
	handleChange?(e: React.ChangeEvent<HTMLInputElement>): void;
	disabled?: boolean;
	type?: IType;
	style?: React.CSSProperties;
	rows?: number;
	children?: any;
}

const Input: React.FC<IInputFieldProps> = (props) => {
	const {
		label,
		name,
		placeholder,
		value,
		errors,
		touched,
		handleChange,
		disabled,
		type,
		handleOnBlur,
		style
	} = props;

	return (
		<>
			{label && (
				<Label for={name} className="color-battleship-grey">
					{label}
				</Label>
			)}
			<InputField
				className="w-100 px-3 py-2 font-16"
				style={{
					borderRadius: 6,
					...style
				}}
				value={value}
				onChange={handleChange}
				disabled={disabled}
				type={type}
				name={name}
				onBlur={handleOnBlur}
				id={name}
				placeholder={placeholder}
			>
				{props.children}
			</InputField>

			<ErrorMessage
				name={name}
				render={(error) => <small className="text-danger">{error}</small>}
			/>
		</>
	);
};

export default Input;
