export const ITEMS_PER_PAGE = 20;

export const REPORTS_ALLOWED_FILE_EXTENSIONS = ["application/pdf", "image/*", ".csv", ".xlsx"];

export const PREFERENCE_TYPES = [
	"Ingredient Categories",
	"Sub Categories",
	"Form",
	"Storage Conditions",
	"Processes",
	"Dietary/Functional",
	"Allergens",
	"Harvest Type",
	"Season",
	"Regional",
	"Cost"
];

export enum availableSubscriptionPlans {
	supplier = "supplier",
	growth = "growth",
	fresh = "fresh",
	group = "group",
	enterprise = "enterprise"
}

export enum trialSubscriptionPlans {
	fresh = "fresh",
	growth = "growth"
}
export enum subscriptionInterval {
	month = "month",
	year = "year"
}

export interface ISubscriptionDetails {
	subscriptionName: availableSubscriptionPlans;
	subscriptionDetails: string[];
	subscriptionPrice: number;
	subscriptionInterval: string;
	subscriptionDescription: string;
	dividerColor?: string;
}

export const SUBSCRIPTION_OPTIONS: { [index: string]: ISubscriptionDetails } = {
	supplier: {
		subscriptionName: availableSubscriptionPlans.supplier,
		subscriptionDetails: [
			"Supplier dashboard",
			"Portfolio management",
			"Portfolio usage analytics"
		],
		subscriptionPrice: 60,
		subscriptionInterval: subscriptionInterval.year,
		subscriptionDescription: "Market your ingredients to CPG brands",
		dividerColor: "#3f65f1"
	},
	fresh: {
		subscriptionName: availableSubscriptionPlans.fresh,
		subscriptionDetails: [
			"Ingredient database",
			"Portfolio management",
			"Customized email insights"
		],
		subscriptionPrice: 199,
		subscriptionInterval: subscriptionInterval.month,
		subscriptionDescription: "Get started with 1 user and 1 brand",
		dividerColor: "#7c4aa9"
	},
	growth: {
		subscriptionName: availableSubscriptionPlans.growth,
		subscriptionDetails: ["Cost insights", "Regulatory insights", "Sustainability insights"],
		subscriptionPrice: 499,
		subscriptionInterval: subscriptionInterval.month,
		subscriptionDescription: "Support growth with 2 users and 3 brands",
		dividerColor: "#e85a73"
	},
	group: {
		subscriptionName: availableSubscriptionPlans.group,
		subscriptionDetails: ["Ingredient recommendations", "Live charts"],
		subscriptionPrice: 999,
		subscriptionInterval: subscriptionInterval.month,
		subscriptionDescription: "Drive performance with 10 users and 10 brands",
		dividerColor: "#0ed7ac"
	},
	enterprise: {
		subscriptionName: availableSubscriptionPlans.enterprise,
		subscriptionDetails: ["Custom reporting", "Advanced integrations"],
		subscriptionPrice: 1999,
		subscriptionInterval: subscriptionInterval.month,
		subscriptionDescription: "Automate innovation with 50 users and unlimited brands",
		dividerColor: "#f4a850"
	}
};
