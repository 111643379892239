export const routes = {
	LOGIN: "/auth/login",
	REGISTER: "/auth/register",
	FORGOT_PASSWORD: "/auth/forgot-password",
	RECOVERY_PASSWORD: "/auth/recovery-password",

	DEFAULT: "/app/dashboard",
	DASHBOARD: "/app/dashboard",
	SEARCH: "/app/search",
	SUBSCRIBE: "/app/subscribe",

	// feature upgrade prompts
	// UPGRADE: "/app/upgrade",
	LABELS_TEASER: "/feature-labels",
	PERSONAS_TEASER: "/feature-personas",
	INGREDIENTS_TEASER: "/feature-ingredients",
	PRODUCTS_TEASER: "/feature-products",
	PACKAGING_TEASER: "/feature-packaging",
	ANALYSIS_TEASER: "/feature-analysis",

	// ingredients
	INGREDIENTS: "/app/ingredients",
	INGREDIENT_DETAIL: "/app/ingredients/:id",
	INGREDIENT_SINGLE: "/design/ingredient-single",

	// integration
	INTEGRATION: "/design/integration",
	INTEGRATIONS: "/app/integrations",
	INTEGRATIONS_SOURCES: "/app/integrations/sources",
	INTEGRATIONS_MARKETS: "/app/integrations/markets",
	INTEGRATIONS_PERKS: "/app/integrations/perks",
	INTEGRATIONS_PARTNERS: "/app/integrations/partners",
	INTEGRATIONS_REQUESTS: "/app/integrations/requests",

	// integration pages
	CSV_INTEGRATION: "/app/integrations/csv",
	EXCEL_INTEGRATION: "/app/integrations/excel",
	GOOGLE_SHEETS: "/app/integrations/google-sheets",

	//knowledge hub
	KB: "/design/kh",

	//labels
	LABEL: "/design/labels",

	LABELS: "/app/labels",
	REPORTS: "/app/labels/reports",

	//products
	PRODUCTS: "/app/products",
	PRODUCT_DETAIL: "/app/products/:id",
	VERSION_HISTORY: "/design/product-single",

	//personas
	PERSONAS: "/app/personas",
	MARKET_PERSONAS: "/app/personas/integrations",
	JOURNEY_FOODS_PERSONAS: "/app/personas/journeyfoods",

	//packaging
	PACKAGING: "/app/packaging",
	PACKAGING_DETAIL: "/app/packaging/:id",

	//account
	ACCOUNT: "/app/account",
	PROFILE: "/app/account/profile",
	PREFERENCES: "/app/account/preferences",
	BILLING: "/app/account/billing",
	COMPANY: "/app/account/company",

	// billing
	INVOICES: "/app/account/billing/invoices",
	SUBSCRIPTION: "/app/account/billing/subscription",
	BILLING_INFO: "/app/account/billing/info",
	UPGRADE: "/app/account/billing/upgrade",

	//
	KNOWLEDGE_HUB: "/app/knowledge-hub",

	// analytics
	ANALYSIS: "/app/analysis",
	ANALYTICS: "/app/analysis/analytics",
	SCORES: "/app/analysis/scores",
	MARKETS: "/app/analysis/markets",

	//admin
	ADMIN: "/app/admin",
	PENDING: "/app/admin/pending",
	ADMIN_PRODUCTS: "/app/admin/products",
	ADMIN_REPORTS: "/app/admin/reports",
	ADMIN_INTEGRATIONS: "/app/admin/integrations",
	ADMIN_EMAIL_SCHEDULE: "/app/admin/email-schedule",
	ADMIN_EMAIL: "/app/admin/email",
	ADMIN_COMPANIES: "/app/admin/companies",
	ADMIN_UPLOADS: "/app/admin/uploads",
	ADMIN_BILLING: "/app/admin/billing",
	ADMIN_RECOMMENDATIONS: "/app/admin/recommendations",

	//notifications
	NOTIFICATIONS: "/app/notifications",
	NOTIFICATIONS_PRODUCTS: "/app/notifications/products",
	NOTIFICATIONS_INGREDIENTS: "/app/notifications/ingredients",
	NOTIFICATIONS_USERS: "/app/notifications/users"
};
