import { stat } from "fs";
import { IPackage } from "../../interfaces/package";
import { IPaginateList } from "../../interfaces/pagination";
import { ActionType } from "../actions/action";
import { packaging, search } from "../actions/actionContants";

export interface IPackagingState {
	all: IPackage[];
	packaging: IPaginateList<IPackage[]>;
	selected: IPackage | undefined;
	search: {
		term?: string;
		filter: any | undefined;
		paginated_results: IPaginateList<IPackage[]>;
		loading: boolean;
	};
}

const INIT_STATE: IPackagingState = {
	all: [],
	selected: undefined,
	packaging: {
		list: [],
		pagination: {
			page: 1,
			pages: 0,
			size: 20,
			total: 0
		}
	},
	search: {
		term: "",
		filter: undefined,
		paginated_results: {
			list: [],
			pagination: {
				page: 1,
				pages: 0,
				size: 10,
				total: 0
			}
		},
		loading: false
	}
};

export default function (state: IPackagingState = INIT_STATE, action: any) {
	switch (action.type) {
		case packaging.GET_ALL_PACKAGES_RESPONSE:
			return {
				...state,
				packaging: action.payload
			};
		case packaging.UPDATE_PAGINATION:
			return {
				...state,
				packaging: {
					...state.packaging,
					pagination: {
						...state.packaging.pagination,
						page: action.payload
					}
				}
			};
		case packaging.SELECT_PACKAGE:
			return {
				...state,
				selected: action.payload
			};
		case search.PACKAGING_SEARCH_RESPONSE:
			return {
				...state,
				search: {
					paginated_results: action.payload,
					filter: action.filter
				}
			};
		case search.UPDATE_PACKAGING_PAGINATION:
			return {
				...state,
				search: {
					paginated_results: {
						...state.search.paginated_results,
						pagination: {
							...state.search.paginated_results.pagination,
							page: action.payload
						}
					}
				}
			};
		case search.SET_PACKAGE_SEARCH_LOADING:
			return {
				...state,
				search: {
					...state.search,
					loading: action.loading
				}
			};
		case search.SET_PACKAGE_SEARCH_TERM:
			return {
				...state,
				search: {
					...state.search,
					term: action.term
				}
			};
	}

	return state;
}
