import {
	IGetAllReportsResponse,
	IGetAllReportRequestsResponse,
	IReportsPagination
} from "./reports.d";
import { report, admin } from "../actionContants";

export function getAllReportsResponseAction(
	payload: any
): IGetAllReportsResponse {
	return {
		type: report.GET_ALL_REPORTS_RESPONSE,
		payload
	};
}

export function paginateReports(page: number): IReportsPagination {
	return {
		type: report.PAGINATE_REPORTS,
		payload: page
	};
}

export function getAllReportRequestsResponseAction(
	payload: any
): IGetAllReportRequestsResponse {
	return {
		type: report.GET_ALL_REPORT_REQUESTS_RESPONSE,
		payload
	};
}
