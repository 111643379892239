import React from "react";

export const defaultStyles = (
	selected: boolean,
	width?: number,
	cssStyles?: React.CSSProperties
) => ({
	container: (provided: any) => ({
		...provided,
		width: width || "100%"
		// zIndex: 100
	}),
	control: (provided: any, state: any) => ({
		...provided,
		...cssStyles,
		border: cssStyles?.border ? cssStyles.border : provided.border,
		boxShadow: selected ? "0 0 0 1px rgb(38, 132, 255)" : provided.boxShadow
	}),
	menu: (provided: any, state: any) => ({
		...provided,
		zIndex: 105
	})
});
