import * as yup from "yup";

export const ProfileSchema = yup.object().shape({
	email: yup.string().email("Email must be a valid email"),
	name: yup.string(),
	city: yup.string(),
	state: yup.string(),
	company_name: yup.string(),
	company_objective: yup.string().max(60),
	company_goal: yup.string().max(60)
});
