import {
	IGetReportsResponseAction,
	IGetTimeSavedAction,
	IGetProductChangesAction,
	IFilterTimeSavedAction,
	IFilterProductChangesAction
} from "./analysis.d";
import { analytics } from "../actionContants";

export function getUserReportsResponseAction(
	payload: any
): IGetReportsResponseAction {
	return {
		payload,
		type: analytics.GET_REPORTS
	};
}

export function getTimeSavedAction(payload: any): IGetTimeSavedAction {
	return {
		payload,
		type: analytics.GET_TIME_SAVED
	};
}

export function getProductChangesAction(
	payload: any
): IGetProductChangesAction {
	return {
		payload,
		type: analytics.GET_PRODUCT_CHANGES
	};
}

export function filterTimeSavedAction(months: number): IFilterTimeSavedAction {
	return {
		payload: months,
		type: analytics.FILTER_TIME_SAVED
	};
}

export function filterProductChangesAction(
	months: number
): IFilterProductChangesAction {
	return {
		payload: months,
		type: analytics.FILTER_PRODUCT_CHANGES
	};
}
