import React, { useState } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import "../../../../../assets/scss/pages/integration/_csv-integration.scss";
import { AlertCircle, CheckCircle, Download, Info, Send, UploadCloud, X } from "react-feather";
import Button from "../../../../../components/common/button";
import { Loader as LoaderIcon } from "react-feather";
import integrationService from "../../../../../services/integrationService";
import { Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import Select from "react-select";
import firebase from "../../../../../utils/firebase";
import axios from "axios";
import fileDownload from "js-file-download";
import {
	FoodProductTemplateDescriptions,
	ProductRecipeTemplateDescriptions,
	IngredientTemplateDescriptions
} from "../../utils/templateDescriptions";
import UploadRequestCheckout, {
	REQUEST_FILE_TYPES
} from "../../../../../components/checkout/uploadRequestModal";
import { PaymentType } from "../../../../../services/paymentService";

const ACCEPTED_FILE_TYPES = [
	"application/vnd.ms-excel",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

interface IProps {
	uploadXLSXFile: (file: File, type: string) => Promise<any>;
}

const XLSXIntegration: React.FC<IProps> = ({ uploadXLSXFile }) => {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [fileSelectionError, setFileSelectionError] = useState<string>();
	const [uploadedFile, updateUploadedFile] = useState<File>();
	const [dataType, setDataType] = useState<string>();
	const [uploadErrorMessage, setUploadErrorMessage] = useState<string>();
	const [xlsxDownloads, setXLSXDownloads] = useState<
		{ label: string; value: firebase.storage.Reference }[]
	>();
	const [uploading, setUploading] = useState<boolean>();
	const [checkoutModal, setCheckoutModal] = useState<boolean>();

	// Handle manual upload state and message to Journey Foods
	const [manualUpload, setManualUpload] = useState<boolean>();
	const [message, setMessage] = useState<any>();

	const firebaseStorage = firebase.storage();
	const xlsxFileRef = {
		products: firebaseStorage.refFromURL(
			"gs://journeyfoods-io.appspot.com/Integration Templates/XLSX Templates/Food Product Template (XLSX).xlsx"
		),
		ingredients: firebaseStorage.refFromURL(
			"gs://journeyfoods-io.appspot.com/Integration Templates/XLSX Templates/Ingredient Manufacturer Template (XLSX).xlsx"
		),
		product_ingredients: firebaseStorage.refFromURL(
			"gs://journeyfoods-io.appspot.com/Integration Templates/XLSX Templates/Product Recipe Template (XLSX).xlsx"
		)
	};

	const downloadTemplateFiles = (
		storageRef: { label: string; value: firebase.storage.Reference }[]
	) => {
		if (storageRef != undefined) {
			for (let i = 0; i < storageRef.length; i++) {
				let ref = storageRef[i];
				ref.value
					.getDownloadURL()
					.then((url) => {
						axios
							.get(url, {
								responseType: "blob"
							})
							.then((res) => {
								fileDownload(res.data, ref.label);
							});
					})
					.catch((error) => {
						error("Error Downloading Files");
					});
			}
		}
	};

	const processXLSXFiles = async (paymentType?: PaymentType) => {
		setUploading(true);
		if (uploadedFile != undefined && dataType != undefined && manualUpload) {
			uploadXLSXFile(uploadedFile, dataType)
				.then((res: any) => {
					if (res?.response?.status == 500)
						setUploadErrorMessage("Please ensure you have uploaded a .xlsx file");
				})
				.finally(() => {
					setUploading(false);
				});
		} else {
			setUploadErrorMessage(
				"You must upload a file and select a file type before we can process your data"
			);
		}
	};

	const onSelectChange = (option: any) => {
		if (option) {
			setDataType(option.value);
		}
	};

	const onDownloadSelectChange = (options: any, type: string) => {
		setXLSXDownloads(options);
	};

	const isFileTypeValid = (file: File) => {
		return ACCEPTED_FILE_TYPES.includes(file.type);
	};

	const onFileSelect = (file: File) => {
		if (file && isFileTypeValid(file)) {
			setFileSelectionError(undefined);
			updateUploadedFile(file);
			return;
		}
		updateUploadedFile(undefined);
		setFileSelectionError("Please upload valid a .xlsx file");
		return;
	};

	const reset = () => {
		setUploading(undefined);
		setDataType(undefined);
		updateUploadedFile(undefined);
		setManualUpload(undefined);
		setMessage(undefined);
		setUploadErrorMessage(undefined);
	};

	return (
		<div>
			<h3 className={"pt-3 integration-header"}>Import Data from an Excel File</h3>
			<div className={"csv-integration-container"}>
				<div className="integration-actions">
					<div className="subsection-links">
						<h5>Table of Contents</h5>
						<ul className={"main-list"}>
							<li>
								<a href="#introduction">- Introduction</a>
							</li>
							<li>
								<a href="#correct-template-file">- Using the Correct Template</a>
							</li>
							<li>
								<a href="#proper-data-formatting">- Proper Data Formatting</a>
							</li>
							<li>
								<a href="#template-field-descriptions">- Template Field Descriptions</a>
							</li>
							<ul className={"sub-list"}>
								<li>
									<a href="#food-product-fields">- Food Product Fields</a>
								</li>
								<li>
									<a href="#product-recipe-fields">- Product Recipe Fields</a>
								</li>
								<li>
									<a href="#ingredient-manufacturer-fields">- Ingredient Manufacturer Fields</a>
								</li>
							</ul>
							<li>
								<a href="#exporting-as-csv">- Exporting Files in Excel Format</a>
							</li>
						</ul>
					</div>
					<div className="template-downloads">
						<h5>Journey Foods Template Downloads:</h5>
						<div className="download-selection">
							<h6>Excel Templates (.xlsx)</h6>
							<Select
								name="Choose XLSX File Templates"
								isClearable={true}
								isMulti={true}
								className={"download-select"}
								hideSelectedOptions={true}
								placeholder="Select Excel Templates"
								options={[
									{ label: "Food Product Template.xlsx", value: xlsxFileRef.products },
									{
										label: "Ingredient Manufacturer Template.xlsx",
										value: xlsxFileRef.ingredients
									},
									{ label: "Product Recipe Template.xlsx", value: xlsxFileRef.product_ingredients }
								]}
								onChange={(option) => onDownloadSelectChange(option, "xlsx")}
								isCreatable={false}
							/>
							<Button
								onClick={() =>
									xlsxDownloads != undefined ? downloadTemplateFiles(xlsxDownloads) : null
								}
								color={"secondary"}
								outline={false}
								className={`mt-3 download-btn`}
							>
								<div>
									<Download size={16} />
									<span className={"ml-3"}>Download XLSX Templates</span>
								</div>
							</Button>
						</div>
					</div>

					<Button onClick={() => setModalOpen(true)} outline={false} className={`mt-5 manage-btn`}>
						<div>
							<UploadCloud size={16} color={"#fff"} />
							<span className={"ml-3"}>Upload an Excel File</span>
						</div>
					</Button>
				</div>

				<div className={"instructions-container"}>
					<a id="introduction"></a>
					<h4>Introduction</h4>
					<p>
						Journey Foods allows users to import formulation data from a variety of file types
						including, Excel Files (XLS, XLSX, CSV, TXT). This guide/page/tutorial shows you how to
						import data from an Excel spreadsheet by following the instructions/prompts. By using an
						Excel file, you can import or export a large number of products and their details at one
						time. This can be helpful if you want to exchange product information between Journey
						Foods and another system.
					</p>

					<div className={"info-container"}>
						<Info className={"info-icon"} />
						<span>
							If you're importing a large number of products or ingredients into Journey Foods, you
							can split the file into smaller chunks, and then import these Excel files
							individually.
						</span>
					</div>
					<a id="correct-template-file"></a>
					<h4>Using the Correct Template File</h4>
					<p>
						Depending on the type of data you want to upload, it is imperative that you use the
						correct template for your data. Download the necessary XLSX template files from the drop
						down on the left.
					</p>
					<p>
						The following questions outline the template choice(s) that will be best suited for your
						data.
					</p>
					<ul>
						<li>
							<strong>Are you a food company?</strong> <br /> Use the Food Products template
						</li>
						<li>
							<strong>Are you an ingredients company?</strong> <br /> Use the Ingredients
							Manufacturer template
						</li>
						<li>
							<strong>
								Are you are producing ingredients and/or have your own collection of ingredient
								infromation and are using them in food products?
							</strong>{" "}
							<br />
							Use the Ingredients and Product Recipe template in conjunction
						</li>
					</ul>
					<p>
						* Template file(s) can be downloaded using the drop down on the left hand side of the
						page
					</p>

					<a id="proper-data-formatting"></a>
					<h4>Properly Formatting Your Data</h4>
					<p>
						The Excel file import wizard uses the template Excel file's header row to determine how
						to map data from the file's 2nd row and beyond to fields in Journey Foods. The header
						row of the template you are using should <strong>NOT</strong> be edited when
						transferring your data to the template as it will result in your data not being properly
						imported into Journey Foods.
					</p>
					<p>
						Please refer to the <b>template field descriptions</b> for fine grained formatting info
						for each field in your dataset{" "}
					</p>
					<a id="template-field-descriptions"></a>
					<a id="food-product-fields"></a>
					<h4>Food Product Template Field Descriptions</h4>
					<div className={"template-description"}>
						<Table striped>
							<thead>
								<tr>
									<th>Column Header</th>
									<th>Description</th>
									<th>Data Requirements</th>
								</tr>
							</thead>
							<tbody>
								{FoodProductTemplateDescriptions.map((field) => {
									return (
										<tr>
											<td>{field.column_header}</td>
											<td>{field.description}</td>
											<td>{field.data_requirements}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<a id="product-recipe-fields"></a>
					<h4>Product Recipe Template Field Descriptions</h4>
					<div className={"template-description"}>
						<Table striped>
							<thead>
								<tr>
									<th>Column Header</th>
									<th>Description</th>
									<th>Data Requirements</th>
								</tr>
							</thead>
							<tbody>
								{ProductRecipeTemplateDescriptions.map((field) => {
									return (
										<tr>
											<td>{field.column_header}</td>
											<td>{field.description}</td>
											<td>{field.data_requirements}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<a id="ingredient-manufacturer-fields"></a>
					<h4>Ingredient Manufacturer Template Field Descriptions</h4>
					<p>
						* Some template fields are not described here as they are self-explanatory. Please
						contact <a>help@journeyfoods.com</a> with any issues.
					</p>
					<div className={"template-description"}>
						<Table striped>
							<thead>
								<tr>
									<th>Column Header</th>
									<th>Description</th>
									<th>Data Requirements</th>
								</tr>
							</thead>
							<tbody>
								{IngredientTemplateDescriptions.map((field) => {
									return (
										<tr>
											<td>{field.column_header}</td>
											<td>{field.description}</td>
											<td>{field.data_requirements}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<a id="exporting-as-csv"></a>
					<h4>Uploading your completed template</h4>
					<p>
						Once you have exported you have filled out your template file, it's time to upload it!
						Click on the 'Upload an Excel File' button to upload your file. In the popup, it is
						imperative that you select the correct data type from the drop down corresponding to the
						template file that you filled out. The data types corresponding to each template type
						are listed below:
					</p>
					<ul>
						<li>
							<b>Food Product Template:</b> Select 'Food Products' data type{" "}
						</li>
						<li>
							<b>Ingredient Manufacturer Template:</b> Select 'Ingredient Manufacturer' data type{" "}
						</li>
						<li>
							<b>Product Recipe Template:</b> Select 'Product Recipe Template' data type{" "}
						</li>
					</ul>
				</div>

				<Modal isOpen={modalOpen} size="md">
					<ModalHeader>
						Upload Excel Data
						<X className={"close-modal"} size={24} onClick={() => setModalOpen(false)} />
					</ModalHeader>
					<ModalBody>
						<div className={"upload-body"}>
							<Dropzone
								accept={[".xlsx"]}
								onDrop={(acceptedFiles: File[]) => onFileSelect(acceptedFiles[0])}
							>
								{({ getRootProps, getInputProps }) => (
									<section>
										<div className="drop-zone" {...getRootProps()}>
											<input {...getInputProps()} />
											<p>
												Drag and drop a file <a>here</a>, or click to select files
											</p>
											{fileSelectionError && (
												<div className={"uploaded-files"}>
													<strong style={{ color: "#e85a73" }}>{fileSelectionError}</strong>
												</div>
											)}
											{uploadedFile != undefined ? (
												<div className={"uploaded-files"}>
													<strong>Uploaded File</strong>
													<p>{uploadedFile.name}</p>
												</div>
											) : (
												<></>
											)}
										</div>
									</section>
								)}
							</Dropzone>
							{/* Display option for  */}
							{uploadedFile != undefined ? (
								<Form disabled={uploading} className={"upload-type"}>
									<FormGroup>
										<FormGroup check>
											<Label check>
												<Input
													disabled={uploading}
													type="radio"
													name="radio1"
													onClick={() => setManualUpload(true)}
												/>{" "}
												I have filled out a template file
											</Label>
										</FormGroup>
										<FormGroup check>
											<Label check>
												<Input
													disabled={uploading}
													type="radio"
													name="radio1"
													onClick={() => setManualUpload(false)}
												/>{" "}
												I would like Journey Foods to clean and upload my data
											</Label>
										</FormGroup>
									</FormGroup>
								</Form>
							) : (
								<></>
							)}
							{manualUpload != undefined ? (
								// Client manual template formatting flow
								<>
									{manualUpload ? (
										<>
											<div className={"info-container"}>
												<Info className={"info-icon"} />
												<div>
													<div>
														<h5>
															Be sure to select the correct file type according to the template you
															filled out:
														</h5>
													</div>
													<p>Food Product Template - Food Products</p>
													<p>Ingredient Manufacturer Template - Ingredient Manufacturer</p>
													<p>Product Recipe Template - Product Recipes</p>
												</div>
											</div>
											<div className={"match-file-container"}>
												<p>Select File Type</p>
												<Select
													isDisabled={uploading}
													name="Choose Data Type"
													isClearable={true}
													placeholder="Select Dataset Type"
													options={[
														{ label: "Food Products", value: "products" },
														{ label: "Ingredient Manufacturer", value: "ingredients" },
														{ label: "Product Recipes", value: "product_ingredients" }
													]}
													onChange={(option) => onSelectChange(option)}
													isCreatable={false}
												/>
											</div>
										</>
									) : (
										/* Journey Foods manual data cleaning flow */
										<div className={"mt-3 submit-request"}>
											<Form disabled={uploading}>
												<FormGroup>
													<Label className={"message-info"} for="exampleText">
														Send a message with your request
													</Label>
													<Input
														onBlur={(e) => setMessage(e.target.value)}
														className={"message"}
														type="textarea"
														name="message"
														id="requestMessage"
														placeholder="Is there anything we should know?"
													/>
												</FormGroup>
											</Form>
										</div>
									)}
								</>
							) : (
								<></>
							)}

							{manualUpload != undefined ? (
								!uploading && uploading != undefined ? (
									<>
										{uploadErrorMessage != undefined ? (
											<div className={"upload-error-message mt-2"}>
												<AlertCircle
													className={"alert-icon"}
													color={"#e85a73"}
													size={21}
													style={{ marginRight: 10 }}
												/>
												<span>{uploadErrorMessage}</span>
											</div>
										) : (
											<p className={"upload-success"}>
												{" "}
												<Info size={16} color={"#0ed7ac"} /> Upload Successful!
											</p>
										)}
										<Button className={"reset-btn"} onClick={() => reset()}>
											<UploadCloud size={16} color={"#fff"} />
											<span>Upload Another File</span>
										</Button>
									</>
								) : (
									<Button
										disabled={
											uploading ||
											(manualUpload && dataType == undefined) ||
											fileSelectionError != undefined
										}
										className={uploading ? "upload-btn loading" : "upload-btn"}
										onClick={() => {
											if (manualUpload) {
												processXLSXFiles();
											} else {
												setCheckoutModal(true);
												setModalOpen(false);
											}
										}}
									>
										{uploading ? (
											<LoaderIcon className={"fa-spin"} color={"#fff"} size={16} />
										) : manualUpload ? (
											<CheckCircle color={"#fff"} size={16}></CheckCircle>
										) : (
											<Send color={"#fff"} size={16}></Send>
										)}
										<span>
											{manualUpload
												? uploading
													? "Uploading..."
													: "Upload Data"
												: uploading
												? "Submitting Request..."
												: "Send Request"}
										</span>
									</Button>
								)
							) : (
								<></>
							)}
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={checkoutModal}>
					<ModalHeader>
						<div className={"modal-header-content"}>
							<span>Payment for Excel Upload Request</span>
							<X
								onClick={() => {
									setCheckoutModal(false);
									setModalOpen(true);
								}}
								className={"close-modal"}
								size={24}
							></X>
						</div>
					</ModalHeader>
					<ModalBody>
						<UploadRequestCheckout
							dataType={REQUEST_FILE_TYPES.XLSX}
							file={uploadedFile}
							message={message || ""}
						/>
					</ModalBody>
				</Modal>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	uploadXLSXFile: (file: File, type: string) => integrationService.uploadTabularDataFile(file, type)
};

export default connect(null, mapDispatchToProps)(XLSXIntegration);
