import Noty from "noty";
import { AlertCircle, AlertTriangle, Box, Compass, DollarSign, Send } from "react-feather";
import ingredientIcon from "../assets/images/icon-nav-ingredients.svg";
import "noty/lib/noty.css";
import "noty/lib/themes/sunset.css";
import React from "react";

interface INotification {
	title: string;
	description?: string;
}

function getIconForNotification(type: string) {
	if (type === "error") {
		return "fa fa-exclamation-triangle";
	}

	if (type === "product") {
		return "fa fa-cube";
	}

	if (type === "ingredient") {
		return "far fa-lemon";
	}

	return "fa fa-exclamation-circle";
}

export const getNotificationIcon = (type: string | undefined) => {
	switch (type) {
		case "Product":
			return <Box size={23} className="color-bg-blue" />;
		case "Ingredient":
			return <img src={ingredientIcon} />;
		case "You can start using Journey Foods":
			return <AlertCircle size={23} className="text-success" />;
		case "Payment Successfully Processed":
			return <DollarSign size={23} className="text-success" />;
		case "Request Submitted":
			return <Send size={23} className="text-success" />;
		case "Payment Later":
			return <DollarSign size={23} className="text-danger" />;
		case "Recommendation":
			return <Compass size={23} color={"#7c4aa9"} />;
		case "Data Successfully Uploaded":
			return <AlertCircle size={23} className="text-success" />;
		case "Regulation" || "regulation":
			return <AlertTriangle size={23} className="text-danger" />;
		default:
			return <AlertCircle size={23} className="text-success" />;
	}
};

function show(type: string, notification: INotification | string, options?: Noty.Options) {
	let title = "";
	let description = "";
	if (typeof notification === "string") {
		title = notification;
	} else {
		title = notification.title;
		description = notification.description || "";
	}
	new Noty({
		callbacks: {
			onTemplate: function () {
				let that = this as any;
				that.barDom.innerHTML = `
                    <div class="notification ${that.options.type}">
                        <i class="${getIconForNotification(that.options.type)}"></i>
                        <div class="details">
                            <p class="title">${title}</p>
                            <p class="description">${description}</p>
                        </div>
                    </div>
                `;
			}
		},
		theme: "sunset",
		type,
		timeout: 2000,
		text: typeof notification === "string" ? notification : notification.title,
		progressBar: true,
		...options
	} as any).show();
}

export function error(notification: INotification | string, options?: Noty.Options) {
	show("error", notification, {
		...options
	});
}

export function ingredient(notification: INotification | string, options?: Noty.Options) {
	show("ingredient", notification, {
		...options
	});
}

export function info(notification: INotification | string, options?: Noty.Options) {
	show("info", notification, {
		...options
	});
}

export function product(notification: INotification | string, options?: Noty.Options) {
	show("product", notification, {
		...options
	});
}

export default {
	error,
	ingredient,
	info,
	product
};
