import React from "react";
import { InjectedFormikProps } from "formik";
import Input from "../../../../components/form/Input";
import { IProfileFormElements } from "../../../../interfaces/user";
import { Row, Col } from "reactstrap";

const ProfileFormElements: React.FC<InjectedFormikProps<{}, IProfileFormElements>> = (props) => {

    const {
        values: { country, state, email, company_goal, company_name, company_objective, company_id, city, first_name, last_name },
        handleChange,
        touched,
        handleBlur,
        errors,
    } = props;

    return (
        <>
            <div>
                <Input
                    label="First Name"
                    name="first_name"
                    placeholder="John"
                    value={first_name}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleBlur}
                    style={{marginBottom: 14}}
                />
                <Input
                    label="Last Name"
                    name="last_name"
                    placeholder="Doe"
                    value={last_name}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleBlur}
                    style={{marginBottom: 14}}
                />
                <Input
                    label="Country"
                    name="country"
                    placeholder="US"
                    disabled={true}
                    value={country}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleBlur}
                    style={{marginBottom: 14}}
                />
                <Input
                    label="City"
                    name="city"
                    placeholder="Chicago"
                    value={city}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleBlur}
                    style={{marginBottom: 14}}
                />
                <Input
                    label="State"
                    name="state"
                    placeholder="IL"
                    value={state}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleBlur}
                    style={{marginBottom: 14}}
                />
                <Input
                    label="Company"
                    name="company"
                    disabled={true}
                    placeholder="Journey Foods"
                    value={company_name}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleBlur}
                    style={{marginBottom: 14}}
                />
                <Input
                    label="Email"
                    name="email"
                    disabled={true}
                    placeholder="johndoe@mail.com"
                    value={email}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleBlur}
                    style={{marginBottom: 14}}
                />
                <Input
                    rows={10}
                    label="Company Goals"
                    name="company_goal"
                    type="textarea"
                    value={company_goal}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleBlur}
                    style={{marginBottom: 14}}
                />
                <Input
                    rows={10}
                    label="Company Objectives"
                    type="textarea"
                    name="company_objective"
                    value={company_objective}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleOnBlur={handleBlur}
                />


                <Input
                    style={{ marginBottom: 0 }}
                    type="hidden"
                    name="company_id"
                    value={company_id}
                    errors={errors}
                    touched={touched}
                />
            </div>
        </>

    )

}

export default ProfileFormElements;