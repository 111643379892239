import { Api } from "./api";
import { Dispatch } from "react";
import { IApplicationState } from "../redux/reducers";
import { getAllProductTypesAction } from "../redux/actions/product_type/product_types";
import config from "../utils/config";

class ProductTypeService extends Api {
	getProductTypes() {
		return async (
			dispatch: Dispatch<any>,
			getState: () => IApplicationState
		) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.product_types.all
				);
				// dispatch(getAllProductTypesAction(response.data));
				return response.data;
			} catch (error) {
				this.handleError(error, "Product Types - get product types");
				return [];
			}
		};
	}
}

export default new ProductTypeService();
