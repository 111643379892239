import { SnakeCaseToNormal } from "./string";
import moment from "moment";
import { IProductFormElements } from "../routes/products/productDetail/form/productFormElements";
import brand from "../routes/account/company/components/brand/brand";
import { IProduct } from "../interfaces/products";
import { IOption } from "../components/form/Select";

const formatIngredient = (ingredient: any) => ({
	base: ingredient.base,
	unit: ingredient.unit.value,
	ingredient:
		typeof ingredient.ingredient === "string"
			? ingredient.ingredient
			: {
					id: ingredient.ingredient.id
			  },
	weight: ingredient.weight
});
//Optional argument 'prev' for comparing old product to new product
// - implemented specifically to check for newly added ingredients
export const formatProductFormData = (data: IProductFormElements, prev?: IProduct | null): any => {
	// Check for newly added ingredients against previous product
	let newlyAddedIngredients: any[] = [];
	if (prev !== undefined && prev !== null) {
		const formIngredients = data.ingredients
			.filter((item) => typeof item.ingredient === "object")
			.map(formatIngredient);
		const prevIngredients = prev?.ingredients;
		newlyAddedIngredients = formIngredients?.filter((formItem) => {
			const isOld = prevIngredients?.find((prevItem) => {
				return formItem.ingredient?.id === prevItem.ingredient?.id;
			});
			if (isOld === undefined) {
				return true;
			}
			return false;
		});
	}

	const formattedData = {
		brand: data.brand ? { id: data.brand.value } : null,
		code: data.code,
		name: data.name,
		company: data.company?.value,
		cost_margin: getCostMarkup(data.cost, data.markup),
		cost: Number(data.cost),
		markup: Number(data.markup),
		...(data.country?.value && { country: { id: data.country?.value } }),
		...(data.currency?.value && { currency: { id: data.currency?.value } }),
		serving_size: Number(data.serving_size),
		unit_serving_size: data.unit_serving_size,
		...(!data.manufacturer
			? {}
			: {
					manufacturer: !data.manufacturer.value
						? data.manufacturer.label
						: { id: data.manufacturer.value }
			  }),
		// ...(!data.product_type ? {} : { product_type: !data.product_type.value ? data.product_type.label : { id: data.product_type.value } }), // OLD FORMAT
		...(!data.product_type
			? {}
			: {
					product_type: !data.product_type.value ? data.product_type.label : data.product_type.value
			  }),
		flavor: data.flavor,
		created_date: moment(data.created_date).format(),
		fulfillment_date: moment(data.fulfillment_date).format(),
		product_status: data.product_status.value,
		version: 1,
		product_class: "",
		ingredients: data.ingredients
			.filter((item) => typeof item.ingredient === "object")
			.map(formatIngredient),
		new_ingredients: data.ingredients
			.filter((item) => typeof item.ingredient === "string")
			.map(formatIngredient),
		packaging: data.packaging,
		newly_added_product_ingredient: newlyAddedIngredients.length > 0 ? newlyAddedIngredients : null,
		notes: data.notes,
		objective: data.objective?.map((o: IOption) => o.value)
	};
	return formattedData;
};

export const formatProductData = (data: any) => {
	const product = Object.assign({}, data);
	product.cost_margin = parseInt(product.cost_margin, 10);
	product.serving_size = parseInt(product.serving_size, 10);

	const flavorArray = [];
	product.product_status = product.product_status === "active";

	for (let key in product.ingredients) {
		flavorArray.push(SnakeCaseToNormal(key));
		const flavor = product.ingredients[key];

		const formatted = flavor.map((item: any) => {
			const ingredientData = Object.assign({}, item);
			return {
				base: 2,
				ingredient: {
					jf_display_name: ingredientData.jf_display_name,
					id: ingredientData.id
				},
				unit: ingredientData.unit,
				weight: parseInt(ingredientData.weight, 10)
			};
		});
		product.ingredients[key] = formatted;
	}

	product.flavor = flavorArray;
	product.created_date = moment(product.created_date).toISOString();
	product.fulfillment_date = moment(product.fulfillment_date).toISOString();

	return product;
};

export function getCostMarkup(cost: number, markup: number): any {
	if (cost === 0) {
		return 0;
	}

	if (isNaN(cost) || isNaN(markup)) {
		return 0;
	}

	const costMarkup = ((markup - cost) / markup) * 100;
	return isNaN(costMarkup) ||
		costMarkup === Number.POSITIVE_INFINITY ||
		costMarkup === Number.NEGATIVE_INFINITY
		? 0
		: parseFloat(costMarkup.toFixed(2));
}
