import React, { useEffect, useState } from "react";
import { CheckCircle, ExternalLink } from "react-feather";
import { Button, Col, Progress, Row } from "reactstrap";
import { Loader } from "../../../components/loader/loader";
import { IPackage, IPackagePreference } from "../../../interfaces/package";
import packageImagePlaceholder from "../../../assets/images/product-packaging-placeholder.png";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import RecentlyViewed from "../../../components/common/recentlyViewed";
import costScaleIcon from "../../../assets/images/cost-scale-icon.svg";
import packagingIcon from "../../../assets/images/icon-nav-packaging.svg";
import sustainabilityScaleIcon from "../../../assets/images/sustainability-scale-icon.svg";
import packagingService from "../../../services/packagingService";

interface IProps extends RouteComponentProps<{ id: string }> {
	selected: IPackage | undefined;
	getPackageById: (id: string) => Promise<any>;
}

const PackagingDetail: React.FC<IProps> = ({
	selected,
	match: {
		params: { id }
	},
	getPackageById
}) => {
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		getPackageById(id).finally(() => {
			setLoading(false);
		});
	}, [id]);

	return (
		<Loader isLoading={loading}>
			{selected && (
				<div className="package-single">
					<section className="ingredient-single__details">
						<Row>
							<Col md="7" className="pt-2">
								<div className="ingredient-single__name">
									<div className="d-flex" style={{ alignItems: "center" }}>
										<h1 className="mr-3 mb-0 font-weight-semibold">{selected.name}</h1>
										{selected.status && (
											<div className="color-bg-green mr-2 d-flex">
												<CheckCircle size={22} className="mr-2" />
												<span style={{ fontSize: "14px", fontWeight: "bold" }}>Verified</span>
											</div>
										)}
									</div>
									<h6 className="font-weight-normal mt-2 color-battleship-grey">
										Manufactured By: {"General"}{" "}
										{/* TODO: Implement package manufacturer if possible*/}
									</h6>
								</div>
							</Col>
						</Row>
						<Row>
							<Col className={"mt-2 float-left"} style={{ width: "max-content", flexGrow: 0 }}>
								<img
									src={packageImagePlaceholder}
									alt="Ingredient Image"
									className="package-single-image"
								/>
							</Col>
							<Col className={"description mt-2 float-left"}>
								<h6>Materials:</h6>
								<div className={"materials"}>
									{selected?.material?.map((m: string, index: number) => {
										return (
											<div className={"material-tag"} key={index}>
												{m}
											</div>
										);
									})}
								</div>
								<h6 className="mt-2">Tags:</h6>
								<div className={"tags"}>
									{selected?.sustainability?.map((s: IPackagePreference) => {
										return (
											<span className="tag">
												<CheckCircle size={17} className="mr-1" />
												{s.title}
											</span>
										);
									})}
									{selected?.filling_process?.map((f: IPackagePreference) => {
										return (
											<span className="tag">
												<CheckCircle size={17} className="mr-1" />
												{f.title}
											</span>
										);
									})}
								</div>
							</Col>
							<Col className={"mt-2 float-right"}>
								<Row className="scores">
									<Col md="4" className={"d-flex align-items-center"}>
										<div className="d-flex">
											{/* change icon/bar to purple  */}
											<img src={packagingIcon} style={{ stroke: "#7c4aa9" }} />
											<div className="ml-3" style={{ flex: 1 }}>
												<h5 className="mb-3">Packaging Score:</h5>
												<div
													data-tooltip={
														selected.packaging_score != 0 && selected.packaging_score != undefined
															? selected.packaging_score
															: 70
													}
												>
													<Progress
														color="packaging"
														value={
															selected.packaging_score != 0 && selected.packaging_score != undefined
																? selected.packaging_score
																: 70
														}
														className="h-12"
													/>
												</div>
											</div>
										</div>
									</Col>
									<Col md="4" className={"d-flex align-items-center"}>
										<div className="d-flex">
											<img src={sustainabilityScaleIcon} />
											<div className="ml-3" style={{ flex: 1 }}>
												<h5 className="mb-3">Sustainability Score:</h5>
												<div data-tooltip={75}>
													<Progress color="success" value="75" className="h-12" />
												</div>
											</div>
										</div>
									</Col>
									<Col md="4" className={"d-flex align-items-center"}>
										<div className="d-flex">
											<img src={costScaleIcon} />
											<div className="ml-3" style={{ flex: 1 }}>
												<h5 className="mb-3">Cost Score:</h5>
												<div className="d-flex">
													<h5 className="mr-2 color-bg-green">$</h5>
													<h5 className="mr-2 color-bg-green">$</h5>
													<h5 className="mr-2 color-battleship-grey">$</h5>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className={"mt-3"} style={{ paddingLeft: 15 }}>
							<Button style={{ color: "#fff", backgroundColor: "#3f65f1", borderColor: "#3f65f1" }}>
								<span className={"d-flex align-items-center"} style={{ width: "max-content" }}>
									Suppliers <ExternalLink className="ml-1" color="#fff" size={18} />
								</span>
							</Button>
						</Row>
						<Row style={{ padding: 15 }}>
							<RecentlyViewed title="Active Usage" type={"products"} />
						</Row>
					</section>
				</div>
			)}
		</Loader>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	selected: state.packaging.selected
});

const mapDispatchToProps = {
	getPackageById: (id: string) => packagingService.getPackageById(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackagingDetail));
