import React, { useState } from "react";
import Select, { InputProps } from "react-select";
import { SelectComponentsProps } from "react-select/src/Select";
import { JsxAttributes } from "typescript";
import { defaultStyles } from "./customStyles";

interface ISelectFieldProps extends SelectComponentsProps {
	options: any;
	placeholder: string;
	width?: number;
	className?: string;
	onChange: (option: any) => void;
	title?: string;
	cssStyles?: React.CSSProperties;
	currentValue?: { label: string; value: any };
	isClearable?: boolean;
	isDisabled?: boolean;
	id?: string;
	name?: string;
}

const CustomSelect: React.FC<ISelectFieldProps> = (
	{
		options,
		placeholder,
		width,
		className,
		title,
		cssStyles,
		currentValue,
		isClearable,
		isDisabled,
		tag,
		id,
		name,
		onChange
	},
	props
) => {
	const [selected, setSelected] = useState<boolean>(false);

	return (
		<>
			{title && <h5>{title}</h5>}
			<Select
				tag={tag}
				isSearchable
				value={currentValue}
				styles={defaultStyles(selected, width, cssStyles)}
				className={className || ""}
				options={options}
				placeholder={placeholder}
				isDisabled={isDisabled}
				isClearable={isClearable == undefined ? true : isClearable}
				hideSelectedOptions={false}
				onChange={(option: any) => onChange(option)}
				{...props}
			/>
		</>
	);
};

export default CustomSelect;
