export const SnakeCaseToNormal = function (str: string): string {
	return str.split("_").join(" ");
	let words = str.split("_");
	words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

	return words.join(" ");
};

export const NormalToSnakeCase = function (str: string): string {
	return str.toLowerCase().split(" ").join("_");
};

export const validateGoogleSheetsLink = function (url: string) {
	return url.includes("docs.google.com/spreadsheets") && url.includes("usp=sharing");
};

export const sanitizeNutrient = function (str: string): string {
	let words = str.split("_").slice(1);
	words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
	return words.join(" ");
};

export const toCamelCase = (str: string) => {
	const words = str?.split(" ");

	for (let i = 0; i < words?.length; i++) {
		if (words[i][0] !== undefined) {
			words[i] = words[i][0].toUpperCase() + words[i].substr(1);
		}
	}

	return words?.join(" ");
};

export const truncate = (str: string, n: number) => {
	return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const formatNotificationDate = (date: Date) => {
	let dd = String(date.getDate()).padStart(2, "0");
	let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
	let yyyy = date.getFullYear();
	let hour = date.getHours();
	let min = date.getMinutes() > 10 ? date.getMinutes() : 0 + date.getMinutes();
	let ampm = hour >= 12 ? "pm" : "am";

	return mm + "/" + dd + "/" + yyyy;
};
