import { IProductType } from "../../interfaces/products";
import { ActionType } from "../actions/action";
import { product_types } from "../actions/actionContants";

export interface IProductTypeState {
	product_types: IProductType[];
}

const INIT_STATE: IProductTypeState = {
	product_types: []
};

export default function (
	state: IProductTypeState = INIT_STATE,
	action: ActionType
) {
	switch (action.type) {
		case product_types.GET_ALL_PRODUCT_TYPES: {
			return {
				...state,
				product_types: action.payload
			};
		}
	}

	return state;
}
