import moment from "moment";
import React, { useEffect, useState } from "react";
import { ArrowDown, ArrowUp, FileText, Loader } from "react-feather";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { IUploadRequest } from "../../../../../interfaces/integration";
import { IApplicationState } from "../../../../../redux/reducers";
import integrationService from "../../../../../services/integrationService";
import { toCamelCase } from "../../../../../utils/string";

interface IProps {
	integrationRequests: any[];
	getIntegrationRequests: () => Promise<any>;
}

const requestStyles = {
	requested: {
		border: "1px solid #f4a850",
		color: "#f4a850",
		borderRadius: 6,
		padding: "6px 12px"
	},
	complete: {
		border: "1px solid #0ed7ac",
		borderRadius: 6,
		color: "#0ed7ac",
		padding: "6px 12px"
	}
};
const UserRequests: React.FC<IProps> = ({ integrationRequests, getIntegrationRequests }) => {
	const [loading, setLoading] = useState<boolean>();
	const [dateSort, setDateSort] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		getIntegrationRequests().finally(() => {
			setLoading(false);
		});
	}, []);

	function calculateTargetEnd(request: any) {
		return request.invoice?.invoice_status == "paid"
			? moment(request.invoice?.updated_at).add(2, "days").format("MMM DD YYYY HH:mm a")
			: "Awaiting Payment";
	}

	const sort = () => {
		return integrationRequests.sort(function (a: any, b: any) {
			// Turn your strings into dates, and then subtract them
			// to get a value that is either negative, positive, or zero.
			if (!dateSort) return new Date(b.request_date).getTime() - new Date(a.request_date).getTime();
			return new Date(a.request_date).getTime() - new Date(b.request_date).getTime();
		});
	};

	const formatRequestType = (request: IUploadRequest) => {
		switch (request.request_type) {
			case "XLSX_UPLOAD":
				return "Excel Upload";
			case "CSV_UPLOAD":
				return "CSV Upload";
			default:
				return `Unknown data type ${
					request.data_type ? ", " + toCamelCase(request.data_type) : ""
				}`;
		}
	};

	return (
		<div style={{ padding: 20 }}>
			{loading ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: "100%", paddingTop: 50 }}
				>
					<Loader className="fa-spin" />{" "}
				</div>
			) : (
				<Table className={"clean-table"}>
					<thead>
						<tr>
							<th>Request Type</th>
							<th onClick={() => setDateSort(!dateSort)}>
								Request Date
								{!dateSort ? (
									<ArrowUp size={16} className={"ml-2"} />
								) : (
									<ArrowDown size={16} className={"ml-2"} />
								)}
							</th>
							<th>Expected Live Date</th>
							<th>Payment</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{sort()?.map((request) => {
							return (
								<tr key={request?.id}>
									<td>{formatRequestType(request)}</td>
									<td>{moment(request.request_date).format("MMM DD YYYY HH:mm a")}</td>
									<td>{calculateTargetEnd(request)} </td>
									<td>{request.invoice?.invoice_status == "open" ? "Unpaid" : "Paid"}</td>
									<td>
										<span
											style={
												request.status.toLowerCase() == "requested"
													? requestStyles.requested
													: requestStyles.complete
											}
										>
											<FileText size={17} className="mr-2" />
											{request.status.toLowerCase()}
										</span>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			)}
		</div>
	);
};

const mapDispatchToProps = {
	getIntegrationRequests: () => integrationService.getAllUploadRequests()
};

const mapStateToProps = (state: IApplicationState) => ({
	integrationRequests: state.integration.requests
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRequests);
