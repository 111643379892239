import { IGetAllPersonasResponse } from "./personas.d";
import { persona } from "../actionContants";

export function getAllPersonasResponseAction(
	payload: any
): IGetAllPersonasResponse {
	return {
		type: persona.GET_ALL_PERSONAS_RESPONSE,
		payload
	};
}
