import React, { useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../../redux/reducers";
import { Row, Button } from "reactstrap";
import { Share2 } from "react-feather";
import { INTEGRATIONS } from "../../../integrations";
import { IIntegration } from "../../../../../interfaces/integration";
import integrationService from "../../../../../services/integrationService";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { routes } from "../../../../routes";
import { getIntegrationImage } from "../../../../../utils/integration";

interface IProps extends RouteComponentProps {
	integrations: IIntegration[];
	profile: any;
	getIntegrations: () => Promise<any>;
}

const DataSources: React.FC<IProps> = (props) => {
	const CSV_INTEGRATION = INTEGRATIONS.find((integration) => integration.name === "CSV");
	const EXCEL_INTEGRATION = INTEGRATIONS.find((integration) => integration.name === "Excel");
	const DEFAULT_ROUTES = [EXCEL_INTEGRATION, CSV_INTEGRATION];

	useEffect(() => {
		props.getIntegrations();
	}, []);

	const isIntegrated = (integration: IIntegration) => {
		return (props.profile?.company.integrations || []).find(
			(i: IIntegration) => i.id === integration.id
		)
			? true
			: false;
	};

	const sortIntegrations = () => {
		if (props.integrations) {
			var arr = props.integrations;
			var result1 = [],
				result2 = [];
			for (var i = 0; i < arr.length; i++) {
				if (arr[i].name === "SAP.io Marketplace") {
					arr[i].name = "SAP";
					result1.push(arr[i]);
				} else {
					result2.push(arr[i]);
				}
			}
			var result = result1.concat(result2);
			return result;
		}
		return [];
	};

	const handleNav = (name: string) => {
		switch (name) {
			case "Google Sheets":
				props.history.push(routes.GOOGLE_SHEETS);
				return;
			default:
				return;
		}
	};

	return (
		<>
			<div className="integration pt-4">
				<h5 className="mb-5 mt-2">
					Journey Foods provides a comprehensive list of data sources to collect and combine data
					from your marketing automation platforms, CRMs, A/B testing tools, and more. No code
					required.
					<br /> <br />
					Select a source from the list below to learn more!
				</h5>
				<Row>
					{/* Test CSV Integration card */}
					{DEFAULT_ROUTES.map((integration: any) => {
						return (
							<NavLink to={integration?.route as string}>
								<div className={"integration-connection"}>
									<img src={integration.logo} height={100}></img>
									<Button
										id={"csv-joyride"}
										color="primary"
										style={{ backgroundColor: "#3f65f1", color: "#fff" }}
										outline
										className="mt-4"
									>
										<Share2 className="mr-2" size={17} />
										Connect
									</Button>
								</div>
							</NavLink>
						);
					})}

					{sortIntegrations().map((integration: IIntegration) => {
						return (
							<div data-tooltip={integration.name} className={"integration-connection"}>
								<img style={{ maxWidth: 125 }} src={getIntegrationImage(integration.name)}></img>

								{isIntegrated(integration) ? (
									<Button
										color="primary"
										style={{ backgroundColor: "#3f65f1", color: "#fff" }}
										outline
										onClick={() => handleNav(integration.name)}
									>
										<Share2 className="mr-2" size={17} />
										Connect
									</Button>
								) : (
									<Button
										disabled={true}
										color="secondary"
										outline
										className="color-battleship-grey"
										onClick={
											integration.route
												? () => props.history.push(`${integration.route}`)
												: () => {}
										}
									>
										<Share2 className="mr-2" size={17} />
										Activate
									</Button>
								)}
							</div>
						);
					})}
				</Row>
			</div>
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => {
	return {
		integrations: state.integration.integrations,
		profile: state.user.profile
	};
};

const mapDispatchToProps = {
	getIntegrations: () => integrationService.getIntegrations()
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSources);
