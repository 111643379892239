import React, { useState } from "react";
import { ArrowLeft, Check, Info } from "react-feather";
import { Button, Card, CardBody } from "reactstrap";
import { availableSubscriptionPlans } from "../../../utils/constants";
import { toCamelCase } from "../../../utils/string";

interface IProps {
	subscriptionName: availableSubscriptionPlans;
	subscriptionPrice?: number;
	subscriptionDescription: string;
	subscriptionInterval: string;
	subscriptionDetails: string[];
	dividerColor?: string;
	handleSelectPlan?: (plan: availableSubscriptionPlans) => void;
}

const SubscriptionCard: React.FC<IProps> = ({
	subscriptionName,
	subscriptionPrice,
	subscriptionInterval,
	subscriptionDescription,
	subscriptionDetails,
	dividerColor,
	handleSelectPlan,
	...props
}) => {
	const [viewDetails, setViewDetails] = useState<boolean>();

	return (
		<Card className={"subscription-card"}>
			<CardBody>
				<div style={{ width: "90%", height: 400 }} className={"option"}>
					<h4>{toCamelCase(subscriptionName)}</h4>
					{!viewDetails ? (
						<>
							<p className="price">
								{subscriptionPrice && subscriptionInterval ? (
									<>
										{`$${subscriptionPrice}`} <br />
										{`/${subscriptionInterval}`}
									</>
								) : (
									"Custom"
								)}
							</p>
							<hr style={{ borderTop: `3px solid ${dividerColor || "#3f65f1"}`, width: "100%" }} />
							<p>
								{subscriptionDescription}
								<br />
								<br />
							</p>
						</>
					) : (
						<>
							<ul>
								{subscriptionDetails.map((detail: string, index: number) => {
									return (
										<li key={index}>
											<Check size={16} />
											<span>{detail}</span>
										</li>
									);
								})}
							</ul>
						</>
					)}
					<a
						href={`${subscriptionName == "supplier" ? "#supplier-features" : "#features"}`}
						style={{ textDecoration: "none", color: "inherit" }}
					>
						<span className="info">
							<Info size={18} />
							View Details
						</span>
					</a>
					{handleSelectPlan ? (
						<Button
							className="btn-outline-primary"
							style={{ marginTop: "auto", marginBottom: 15 }}
							onClick={() => handleSelectPlan(subscriptionName)}
						>
							Choose plan
						</Button>
					) : (
						<a
							href={`mailto:sales@journeyfoods.com?subject=${toCamelCase(
								subscriptionName
							)} Tier Subscription Inquiry`}
							style={{ marginTop: "auto", marginBottom: 15 }}
						>
							<Button className="btn-outline-primary">Contact us</Button>
						</a>
					)}
				</div>
			</CardBody>
		</Card>
	);
};

export default SubscriptionCard;
