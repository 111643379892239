import React, { MouseEvent, useEffect, useState } from "react";
import Button from "../../../components/common/button";
import { connect } from "react-redux";
import ProductItem from "./productItem";
import { PRODUCT_TYPE, IProduct } from "../../../interfaces/products";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { routes } from "../../routes";
import { Row, Col } from "reactstrap";
import { Box } from "react-feather";
import PerfectScroll from "react-perfect-scrollbar";
import productService from "../../../services/productService";
import { IApplicationState } from "../../../redux/reducers";
import { IPaginateList } from "../../../interfaces/pagination";
import { Dispatch } from "redux";
import { paginateAction } from "../../../redux/actions/pagination/pagination";
import { product } from "../../../redux/actions/actionContants";
import Pagination from "../../../components/pagination";
import Loader from "../../../components/loader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { IUser } from "../../../interfaces/user";
import Upgrade from "../../../components/common/upgrade";
import { IBrand } from "../../../interfaces/company";

interface IProps extends RouteComponentProps {
	history: History;
	getConceptProducts: () => Promise<any>;
	paginate: (page: number) => void;
	concept: IPaginateList<IProduct[]>;
	removeProduct: (productId: string) => Promise<any>;
	profile?: IUser | undefined;
	selected_brand: IBrand;
}

const ConceptProducts: React.FC<IProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [removing, setRemoving] = useState<boolean>(false);

	const {
		concept: { list, pagination }
	} = props;

	useEffect(() => {
		setLoading(true);
		props.getConceptProducts().finally(() => {
			setLoading(false);
		});
	}, [pagination.page]);

	useEffect(() => {
		setLoading(true);
		props.getConceptProducts().finally(() => setLoading(false));
	}, [props.selected_brand]);

	function onRemoveHandler(productId: string) {
		setRemoving(true);

		confirmAlert({
			title: "Remove Product",
			message: "Are you sure you want to remove this product?",
			buttons: [
				{
					label: "Remove",
					onClick: () => {
						props.removeProduct(productId).finally(() => {
							setLoading(true);
							props.getConceptProducts().finally(() => {
								setLoading(false);
							});
						});
					}
				},
				{
					label: "Cancel",
					onClick: () => {}
				}
			]
		});
	}

	return (
		<div className="concept-products">
			{props.profile?.company?.subscription_type == "supplier" ? (
				<></>
			) : (
				<Pagination
					page={pagination.page}
					total={pagination.total}
					paginatorSize="md"
					pages={pagination.pages}
					size={pagination.size}
					handlePageChange={props.paginate}
				/>
			)}

			<span className="title mb-3">
				<h4 className="mr-2 font-weight-semibold">Concept Products</h4>
				<h4 className="color-bg-blue mr-3 font-weight-normal">
					{props.profile?.company?.subscription_type == "supplier" ? "-" : pagination.total}
				</h4>
				<Button color="warning" className="color-white">
					<Box size={18} className="mr-2" />
					Concept
				</Button>
			</span>
			{props.profile?.company?.subscription_type == "supplier" ? (
				<Upgrade />
			) : (
				<Loader type="spin" isLoading={loading}>
					<Row>
						<Col xl="11" className="concept-products-list col-md-12 scroll-y">
							{props.concept.list.map((product) => (
								<ProductItem
									product={product}
									productType={PRODUCT_TYPE.CONCEPT}
									onRemoveHandler={onRemoveHandler}
								/>
							))}
						</Col>
					</Row>
				</Loader>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	concept: state.product.concept,
	profile: state.user.profile,
	selected_brand: state.user.selected_brand
});

const mapDispatchToProps = {
	getConceptProducts: () => productService.getConceptProducts(),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(product.PAGINATE_CONCEPT_PRODUCT, page));
	},
	removeProduct: (productId: string) => productService.removeProduct(productId)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConceptProducts));
