import React from "react";
import { InjectedFormikProps } from "formik";
import Input from "../../../../components/form/Input";
import { Row, Col } from "reactstrap";

export interface IRegisterFormElementsValues {
	firstName: string;
	lastName: string;
	company: string;
	jobtitle: string;
	city: string;
	state: string;
	country: string;
	email: string;
	password: string;
	confirmPassword: string;
}

interface ILoginFormElementsProps {}

const RegisterFormFields: React.FC<
	InjectedFormikProps<ILoginFormElementsProps, IRegisterFormElementsValues>
> = (props) => {
	const {
		values: {
			city,
			company,
			confirmPassword,
			country,
			email,
			firstName,
			lastName,
			password,
			state,
			jobtitle
		},
		handleChange,
		touched,
		handleBlur,
		errors
	} = props;

	return (
		<>
			<Row>
				<Col>
					<Input
						name="firstName"
						placeholder="First Name"
						value={firstName}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
				<Col>
					<Input
						name="lastName"
						placeholder="Last name"
						value={lastName}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						name="company"
						placeholder="Company"
						value={company}
						handleChange={handleChange}
						errors={errors}
						handleOnBlur={handleBlur}
						touched={touched}
					/>
				</Col>
				<Col>
					<Input
						label=""
						name="jobtitle"
						placeholder="Job Title"
						value={jobtitle}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						name="city"
						placeholder="City"
						value={city}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
				<Col>
					<Input
						name="state"
						placeholder="State"
						value={state}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						name="country"
						placeholder="Country"
						value={country}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
				<Col>
					<Input
						name="email"
						placeholder="Email"
						value={email}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						name="password"
						placeholder="Password"
						value={password}
						handleChange={handleChange}
						errors={errors}
						handleOnBlur={handleBlur}
						touched={touched}
						type="password"
					/>
				</Col>
				<Col>
					<Input
						name="confirmPassword"
						placeholder="Confirm Password"
						value={confirmPassword}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
						type="password"
					/>
				</Col>
			</Row>
		</>
	);
};

export default RegisterFormFields;
