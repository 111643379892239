import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Tabs from "../../components/tab";
import { routes } from "../routes";
import Personas from "./personas";
import MarketPersonas from "./marketPersonas";


const PersonasRouter: React.FC = () => (

    <Row className="analysis">
        <Col xl="11" md="12">
            <Tabs tabs={[
                {
                    name: "Personas",
                    route: routes.JOURNEY_FOODS_PERSONAS,
                },
                {
                    name: "Market Integrations",
                    route: routes.MARKET_PERSONAS,
                },
            ]} />

            <div className="py-3">
                <Switch>
                    <Route exact path={routes.JOURNEY_FOODS_PERSONAS} component={Personas} />
                    <Route exact path={routes.MARKET_PERSONAS} component={MarketPersonas}/>
                    <Redirect from={routes.PERSONAS} to={routes.JOURNEY_FOODS_PERSONAS}/>
                </Switch>
            </div>
        </Col>
    </Row>
)


export default PersonasRouter;