import { Api } from "./api";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import config from "../utils/config";
import { info } from "../utils/notification";
import { IApplicationState } from "../redux/reducers";
import {
	GetIntegrationsAction,
	getAllUploadRequestsAction
} from "../redux/actions/integrations/integrations";

const ACCEPTED_FILE_TYPES = [
	"text/csv",
	"application/csv",
	"application/x-csv",
	"text/comma-separated-values",
	"application/vnd.ms-excel",
	"text/x-comma-separated-values",
	"text/tab-separated-values",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

class IntegrationService extends Api {
	getIntegrations() {
		return async (dispatch: Dispatch) => {
			try {
				const response: AxiosResponse = await this.http.get(config.api.endpoints.integrations.all);
				dispatch(GetIntegrationsAction(response.data));
			} catch (error) {
				this.handleError(error);
			}
		};
	}

	getAllUploadRequests() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.integrations.upload_request);
				dispatch(getAllUploadRequestsAction(response.data));
			} catch (error) {
				this.handleError(error, "Report Requests");
			}
		};
	}

	uploadTabularDataFile(file: File, type: string) {
		return async (dispatch: Dispatch) => {
			const formData = new FormData();
			formData.append(type, file);
			try {
				const response: AxiosResponse = await this.http.post(
					config.api.endpoints.integrations.upload_tabular_data,
					formData
				);
				info("Data File Uploaded Successfully.");
				// TODO: Check for errors in response'; 200 status code does not necessarily mean all data was uploaded correctly
				return response;
			} catch (error) {
				this.handleError(error, "Upload Data File");
				return error;
			}
		};
	}

	requestDataUpload(file: File, description: string) {
		return async () => {
			const formData = new FormData();
			formData.append("file", file);
			formData.append("description", description);
			try {
				if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
					throw Error("Invalid file type");
				} else {
					const response = await this.http.post(
						config.api.endpoints.integrations.upload_request,
						formData
					);
					info("Data Upload Request Successful");
					return response;
				}
			} catch (error) {
				this.handleError(error, "Data Upload Request");
				return error;
			}
		};
	}

	// Fulfill a user's csv data upload request
	uploadRequestFile(request_id: string, request_file: File, data_type: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			const formData = new FormData();
			formData.append("file", request_file);
			formData.append("request_file_id", request_id);
			formData.append("data_type", data_type);

			try {
				const response = await this.http.put(
					config.api.endpoints.integrations.upload_request,
					formData
				);
				info("Upload Request Fulfilled Successfully");
			} catch (error) {
				this.handleError(error, "Upload Request Fulfillment");
			}
		};
	}

	async uploadGoogleSheet(url: string, name: string, type: string) {
		try {
			const body = {
				gsheet_url: url,
				sheet_name: name,
				sheet_type: type
			};
			const response = await this.http.post(config.api.endpoints.integrations.upload_gsheet, body);
			info("Google Sheets Upload Successful");
			return response;
		} catch (error) {
			this.handleError(error, "Google Sheet Upload");
			return error;
		}
	}
}

export default new IntegrationService();
