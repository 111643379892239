import { Api } from "./api";
import { Dispatch } from "react";
import { IApplicationState } from "../redux/reducers";
import config from "../utils/config";
import { getAllPersonasResponseAction } from "../redux/actions/personas/personas";

class PersonaService extends Api {
	getAllPersonas(userId: string) {
		return async (
			dispatch: Dispatch<any>,
			getState: () => IApplicationState
		) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.personas.all(userId)
				);
				dispatch(getAllPersonasResponseAction(response.data));
			} catch (error) {
				this.handleError(error, "All Personas");
			}
		};
	}
}

export default new PersonaService();
