import React from "react";
import { Switch, Route, Redirect, RouteComponentProps} from "react-router-dom";
import { routes } from "../routes";
import Tabs from "../../components/tab";
import Profile from "./profile";
import Company from "./company";
import Preferences from "./preferences/preferences";
import Subscription from "./billing";
import { Row, Col } from "reactstrap";
import PermissionRoute from "../../components/hoc/PermissionRoute";
import { USER_ROLES } from "../../interfaces/roles";
import Billing from "./billing";


const AccountRouter: React.FC = () => (

    <Row className="account">
        <Col md="12" xl="11">
            <div className="pt-4">

                <Switch>
                    <Route exact path={routes.PROFILE} component={Profile} />
                    <PermissionRoute exact path={routes.PREFERENCES} component={Preferences}  componentRoles={[
                        USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_USER
                    ]}/>
                    <PermissionRoute path={routes.BILLING} component={Billing}
                        componentRoles={[USER_ROLES.COMPANY_ADMIN]} accessingAccount={true} />
                    <PermissionRoute exact path={routes.COMPANY} componentRoles={[
                        USER_ROLES.COMPANY_ADMIN
                    ]} accessingAccount={true} component={Company} />
                    <Redirect exact to={routes.PROFILE} from={routes.ACCOUNT} />
                </Switch>
            </div>
        </Col>
    </Row>

)

export default AccountRouter;