import { billing } from "../actionContants";
import {
	IGetAllInvoicesAction,
	IGetCompanyInvoicesAction,
	IGetInvoiceAction
} from "./billing.d";

export function getAllInvoicesAction(payload: any): IGetAllInvoicesAction {
	return {
		type: billing.GET_ALL_INVOICES,
		payload
	};
}

export function getCompanyInvoicesAction(
	payload: any
): IGetCompanyInvoicesAction {
	return {
		type: billing.GET_COMPANY_INVOICES,
		payload
	};
}

export function getInvoiceAction(payload: any): IGetInvoiceAction {
	return {
		type: billing.GET_INVOICE,
		payload
	};
}
