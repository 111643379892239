import React from "react";
import { Check, Info } from "react-feather";
import { Table } from "reactstrap";
import InfoTooltip from "../../../../components/common/infoTooltip";

interface IProps {
	supplier?: boolean;
}
const FeatureTable: React.FC<IProps> = (props) => {
	return (
		<>
			{props.supplier ? (
				<Table className="feature-table">
					<thead>
						<tr>
							<th>
								Supplier <br /> $60/year
							</th>
						</tr>
					</thead>
					<tbody>
						<tr className="striped">
							<td className={"single"}>1 user</td>
						</tr>
						<tr>
							<td className={"single"}>Supplier profile management</td>
						</tr>
						<tr className="striped">
							<td className={"single"}>Ingredient portfolio management</td>
						</tr>
						<tr>
							<td className={"single"}>Get recommended to buyers</td>
						</tr>
						<tr className="striped">
							<td className={"single"}>Weekly email insights on top 10 ingredients</td>
						</tr>
					</tbody>
				</Table>
			) : (
				<Table className="feature-table">
					<thead>
						<tr>
							<th></th>
							<th>
								Fresh <br /> <span style={{ fontWeight: 400 }}>$199/month</span>
							</th>
							<th>
								Growth <br /> <span style={{ fontWeight: 400 }}>$499/month</span>
							</th>
							<th>
								Group <br /> <span style={{ fontWeight: 400 }}>$999/month</span>
							</th>
							<th>
								Enterprise <br /> <span style={{ fontWeight: 400 }}>$1999/month</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr className="sub-header">
							<td>Usage</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr className="striped">
							<td>Users</td>
							<td>1 users</td>
							<td>2 users</td>
							<td>10 users</td>
							<td>50 users</td>
						</tr>
						<tr className="sub-header">
							<td>Portfolio Management</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr className="striped">
							<td>Company goal setting</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
						</tr>
						<tr>
							<td>Analytics</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
						</tr>
						<tr className={"striped"}>
							<td>Resource management</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
						</tr>
						<tr className="sub-header">
							<td>Product Management</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr className="striped">
							<td>Product objectives</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
						</tr>
						<tr>
							<td>Version control</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
						</tr>
						<tr className="sub-header">
							<td>Data Access</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr className="striped">
							<td>Weekly email insights on top 10 ingredients</td>
							<td>
								<span>
									1 <br />
									(1/user)
								</span>
							</td>
							<td>
								<span>
									2 <br />
									(1/user)
								</span>
							</td>
							<td>
								<span>
									10 <br />
									(1/user)
								</span>
							</td>
							<td>
								<span>
									50 <br />
									(1/user)
								</span>
							</td>
						</tr>
						<tr>
							<td>Nutrition insights and recommendations</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
						</tr>
						<tr className="striped">
							<td>
								{" "}
								<span>{"Cost insights & ingredient recommendations"}</span>
								<InfoTooltip id="cost-info">
									<ul className={"tooltip-list"}>
										<li>
											<span>{"Cost range for ingredients"}</span>
										</li>
										<li>
											<span>{"Cost savings over time"}</span>
										</li>
									</ul>
								</InfoTooltip>
							</td>
							<td>+ $100/month</td>
							<td>+ $100/month</td>
							<td>+ $100/month</td>
							<td>+ $100/month</td>
						</tr>
						<tr>
							<td>
								<span>{"Sustainability insights & ingredient recommendations"}</span>
								<InfoTooltip id="sustainability-info">
									<ul className={"tooltip-list"}>
										<li>
											<span>{"GHG & Water usage per ingredient"}</span>
										</li>
										<li>
											<span>{"Upcycled ingredient"}</span>
										</li>
									</ul>
								</InfoTooltip>
							</td>
							<td>+ $100/month</td>
							<td>+ $100/month</td>
							<td>+ $100/month</td>
							<td>+ $100/month</td>
						</tr>
						<tr className={"striped"}>
							<td>
								<span>{"Regulatory insights & ingredient recommendations"}</span>
								<InfoTooltip id="regulatory-info">
									<ul className={"tooltip-list"}>
										<li>
											<span>{"Recieve notifications for ingredient regulations"}</span>
										</li>
										<li>
											<span>
												{
													"Data on additives for US, Canada, South Korea, Japan, Thailand, CODEX, Europe, UK"
												}
											</span>
										</li>
									</ul>
								</InfoTooltip>
							</td>
							<td>+ $100/month</td>
							<td>+ $100/month</td>
							<td>+ $100/month</td>
							<td>+ $100/month</td>
						</tr>
						<tr className="sub-header">
							<td>Integrations</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr className="striped">
							<td>Free integrations</td>
							<td>CSV, Excel, Google Sheets</td>
							<td>CSV, Excel, Google Sheets</td>
							<td>CSV, Excel, Google Sheets</td>
							<td>CSV, Excel, Google Sheets</td>
						</tr>
						<tr>
							<td>
								<span>Paid integrations available</span>
								<InfoTooltip id="integrations-info">
									<ul className={"tooltip-list"}>
										<li>
											<span>{"Airtable"}</span>
										</li>
										<li>
											<span>{"Google Cloud Services"}</span>
										</li>
										<li>
											<span>{"Microsoft Dynamics"}</span>
										</li>
										<li>
											<span>{"Oodo"}</span>
										</li>
										<li>
											<span>{"Oracle"}</span>
										</li>
										<li>
											<span>{"SAP"}</span>
										</li>
										<li>
											<span>{"Shopfiy"}</span>
										</li>
									</ul>
								</InfoTooltip>
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
						</tr>
						<tr className="sub-header">
							<td>Support</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr className="striped">
							<td>Email communication within 1-2 days</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
							<td>
								<Check />
							</td>
						</tr>
						<tr>
							<td>Slack channel for customer support</td>
							<td>+ $50/month</td>
							<td>+ $100/month</td>
							<td>+ $500/month</td>
							<td>+ $2500/month</td>
						</tr>
						<tr className="sub-header">
							<td>Additional usage</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr className={"striped"}>
							<td>Extra users</td>
							<td>$9/user</td>
							<td>$19/user</td>
							<td>$39/user</td>
							<td>$49/user</td>
						</tr>
						<tr>
							<td>Extra brands</td>
							<td>$39/user</td>
							<td>$99/user</td>
							<td>$199/user</td>
							<td>$399/user</td>
						</tr>
					</tbody>
				</Table>
			)}
		</>
	);
};

export default FeatureTable;
