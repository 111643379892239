import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { IApplicationState } from "../../../../redux/reducers";
import Stripe from "@stripe/stripe-js";
import paymentService from "../../../../services/paymentService";
import PaymentMethod from "../components/paymentMethod";

interface IProps {}

const BillingInfo: React.FC<IProps> = () => {
	const [paymentMethods, setPaymentMethods] = useState<Stripe.PaymentMethod[]>([]);

	useEffect(() => {
		paymentService.getCompanyBillingInfo().then((response: Stripe.PaymentMethod[]) => {
			setPaymentMethods(response);
		});
	}, []);

	const getPaymentMethodHeader = (index: number) => {
		switch (index) {
			case 0:
				return (
					<Row noGutters>
						<h3>Default Payment Method</h3>
					</Row>
				);
			case 1:
				return (
					<Row noGutters className="mt-3">
						<h3>Other Payment Methods</h3>
					</Row>
				);
			default:
				return;
		}
	};

	return (
		<Container fluid style={{ padding: 0 }}>
			<Row className={"mt-2"}>
				<Col>
					<h3>Payment Method</h3>
					<p>Update your billing details and address</p>
					<hr
						style={{
							width: "100%",
							borderTop: "dashed 2px #e1ecf3"
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					{paymentMethods && paymentMethods.length > 0 ? (
						paymentMethods.map((pm: Stripe.PaymentMethod, index: number) => {
							return (
								<>
									{getPaymentMethodHeader(index)}
									<PaymentMethod payment_method={pm} details={index == 0} />
								</>
							);
						})
					) : (
						<div>
							No billing information found. Please contact{" "}
							<a href="mailto:sales@journeyfoods.com?subject=Missing Subscription Information">
								sales@journeyfoods.com
							</a>{" "}
							if you believe this is a mistake
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state: IApplicationState) => {};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
