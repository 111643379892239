import { IPreference } from "../interfaces/preference";

export function structurePreferences(preferences: any[]) {
	let structured: any = [];

	preferences.forEach((pref: any) => {
		const cat = structured.findIndex((o: any) => o.name === pref.category);

		// Skip over defined categories as they are displayed in original preference grid format
		if (pref.category && ["Finished Format", "Storage Conditions"].includes(pref.category)) return;

		if (cat > -1) {
			if (pref.sub_category) {
				const sub_cat = structured[cat].subOptions?.findIndex(
					(i: any) => i.name === pref.sub_category
				);

				if (sub_cat > -1) {
					structured[cat].subOptions[sub_cat].subOptions.push({
						name: pref.title,
						id: pref.title + "-id",
						subOptions: [],
						jf_id: pref.id
					});
				} else {
					structured[cat].subOptions.push({
						name: pref.sub_category,
						id: pref.sub_category + "-id",
						subOptions: [
							{
								name: pref.title,
								id: pref.title + "-id",
								subOptions: [],
								jf_id: pref.id
							}
						]
					});
				}
			} else {
				structured[cat].subOptions.push({
					name: pref.title,
					id: pref.title + "-id",
					subOptions: [],
					jf_id: pref.id
				});
			}
		} else {
			structured.push({
				name: pref.category,
				id: pref.category + "-id",
				subOptions: pref.sub_category
					? [
							{
								name: pref.sub_category,
								id: pref.sub_category + "-id",
								subOptions: [
									{
										name: pref.title,
										id: pref.title + "-id",
										subOptions: [],
										jf_id: pref.id
									}
								]
							}
					  ]
					: []
			});
		}
	});

	// let structured:any = {};

	// preferences.forEach((pref: any) => {
	//     if(pref.category != '') {
	//         if(!structured[pref.category]) structured[pref.category] = {};
	//         if(pref.sub_category != '') {
	//             if(!structured[pref.category][pref.sub_category]) structured[pref.category][pref.sub_category] = [];
	//             structured[pref.category][pref.sub_category].push(pref);
	//         } else {
	//             structured[pref.category] = pref;
	//         }
	//     }
	// })

	return structured;
}
