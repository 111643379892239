import React, { useEffect, useState } from "react";
import ProfileForm from "./form/profileForm";
import { connect } from "react-redux";
import { IApplicationState } from "../../../redux/reducers";
import { IUser } from "../../../interfaces/user";
import userService from "../../../services/userService";
import Loader from "../../../components/loader";

interface IProps {
    profile?: IUser;
    userId: string,
    getProfile: (id: string, resolve?: Function, reject?: Function) => Promise<any>;
}


const Profile: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);

    async function getProfile() {
        setLoading(true)
        await props.getProfile(props.userId)
        setLoading(false)
    }

    useEffect(() => {
        getProfile()
    }, []);

    return (
        <div className="profile">
            <Loader isLoading={loading}>
                <ProfileForm formData={{
                    user_photo: props.profile?.user_photo || "",
                    city: props.profile?.city || "",
                    country: props.profile?.country || "",
                    company: {
                        company_objective: props.profile?.company?.company_objective || "",
                        company_goal: props.profile?.company?.company_goal || "",
                        id: props.profile?.company?.id || "",
                        name: props.profile?.company?.name || "",
                        logo: props.profile?.company.logo || ""
                    },
                    email: props.profile?.email || "",
                    state: props.profile?.state || "",
                    first_name: props.profile?.first_name || "",
                    last_name: props.profile?.last_name || "",
                }} />
            </Loader>
        </div>
    )
};


const mapStateToProps = ({ user: { profile, user_id } }: IApplicationState) => ({
    profile: profile,
    userId: user_id || ""
})


const mapDispatchToProps = {
    getProfile: (userId: string, resolve?: Function, reject?: Function) => userService.getUserProfile(userId, resolve, reject)
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);