import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../components/common/button";
import { Table } from "reactstrap";
import { IApplicationState } from "../../redux/reducers";
import adminService from "../../services/adminService";
import { IUser } from "../../interfaces/user";
import { USER_ROLES } from "../../interfaces/roles";
import {
	Input,
	Modal,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Form,
	FormGroup,
	Label,
	InputGroup,
	Button as ReactstrapButton,
	ButtonGroup,
	Row,
	Col
} from "reactstrap";

import { Loader, User, X } from "react-feather";
import moment from "moment";

interface IProps {
	getPendingRequests: (resolve?: Function, reject?: Function) => Promise<any>;
	approveOrReject: (userId: string, isApproved: boolean) => Promise<any>;
	pendingss: IUser[];
	editUser: (userId: string, userData: any) => Promise<any>;
	updateUserStatus: (userId: string, status: boolean) => Promise<any>;
	modifyUser: (userId: string, data: any) => Promise<any>;
}

const UserRequest: React.FC<IProps> = (props) => {
	const [loading, setLoading] = useState<
		"refresh" | { type: "approve" | "reject"; id: string } | null
	>(null);
	const [modal, setModal] = useState<boolean>(false);
	const [user, setUser] = useState<IUser>();

	const [updating, setUpdating] = useState<boolean>(false);
	const [users, setUsers] = useState<IUser[]>();
	const [sortByNewest, setSortByNewest] = useState<boolean>(false);

	async function getPendingRequests() {
		setLoading("refresh");
		try {
			props.getPendingRequests();
		} finally {
			setLoading(null);
		}
	}

	useEffect(() => {
		getPendingRequests();
	}, []);

	useEffect(() => {
		setUsers(props.pendingss);
	}, [props.pendingss]);

	const selectUser = (user: IUser) => {
		setUser(user);
		setModal(true);
	};

	const toggleModal = () => {
		setModal(!modal);
	};

	const onModifyUser = (user: IUser, data: any) => {
		setUpdating(true);
		setUser({ ...user, ...data });
		props.modifyUser(user.id, data).finally(() => {
			setUpdating(false);
			getPendingRequests();
		});
	};

	const handleSortByDate = () => {
		setSortByNewest(!sortByNewest);
	};

	const sortByRegisteredDate = useCallback(() => {
		const sorted = users?.sort((a: IUser, b: IUser) => {
			return new Date(a.created_at) > new Date(b.created_at)
				? sortByNewest
					? 1
					: -1
				: sortByNewest
				? -1
				: 1;
		});
		setUsers(sorted);
	}, [users, sortByNewest]);

	useEffect(() => {
		sortByRegisteredDate();
	}, [sortByNewest, sortByRegisteredDate]);

	function handleRoleChange(e: any, id: string) {
		const role = e.target.value;
		if (user) {
			onModifyUser(user, { role });
		}
	}

	return (
		<div className="user-requests">
			<Button
				className="refresh color-white"
				onClick={getPendingRequests}
				loading={loading === "refresh"}
			>
				Refresh
			</Button>

			<Table responsive hover>
				<thead>
					<tr>
						<th>Full Name</th>
						<th>Company</th>
						<th>Email</th>
						<th>Country</th>
						{/* <th>State</th> */}
						{/* <th>City</th> */}
						{/* <th>Job Title</th> */}
						<th>Role</th>
						<th onClick={handleSortByDate}>Registered Date</th>
						<th>Approval</th>
						{/* <th>Actions</th> */}
						<th>Status</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{users?.map((user, index) => (
						<tr key={user.id} className={user.disable ? "text-muted" : ""}>
							<td>{`${user.first_name || ""} ${user.last_name || ""}`}</td>
							<td>{user.company?.name}</td>
							<td>{user.email}</td>
							<td>{user.country}</td>
							<td>{user.role}</td>
							<td>{moment(user.created_at).format("MM/DD/YY")}</td>
							<td>{user.is_active ? "active" : "pending"}</td>
							<td>{user.disable ? "disabled" : "enabled"}</td>
							<td>
								<div className="actions">
									<Button
										className="color-white approve"
										icon="fa fa-edit"
										onClick={() => selectUser(user)}
									/>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</Table>

			{user && (
				<Modal isOpen={modal}>
					<ModalHeader>
						<User className="color-bg-blue mr-2" size={24} />
						{`${user?.email}`}
						<X className="float-right" onClick={toggleModal} />
					</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup className="mb-3">
								<Label>Company</Label>
								<Input
									value={user.company.name}
									disabled={true}
									style={{ width: "100%", padding: "8px" }}
								/>
							</FormGroup>

							<FormGroup className="mb-3">
								<Label>Role</Label>
								<Input
									type="select"
									name="select"
									onChange={(e) => handleRoleChange(e, user.id)}
									defaultValue={user.role}
									style={{ width: "100%" }}
									disabled={updating}
								>
									{Object.values(USER_ROLES).map((role) => (
										<option key={role}>{role}</option>
									))}
								</Input>
							</FormGroup>

							<Row>
								<Col md={6}>
									<FormGroup className="mb-3">
										<Label>Approval</Label>
										<ButtonGroup className="d-flex" disabled={updating}>
											<ReactstrapButton
												className={"btn-permission-toggle"}
												color="primary"
												onClick={() => onModifyUser(user, { is_active: false })}
												active={!user.is_active}
											>
												pending
											</ReactstrapButton>
											<ReactstrapButton
												className={"btn-permission-toggle"}
												color="primary"
												onClick={() => onModifyUser(user, { is_active: true })}
												active={user.is_active}
											>
												active
											</ReactstrapButton>
										</ButtonGroup>
									</FormGroup>
								</Col>

								<Col md={6}>
									<FormGroup className="mb-3">
										<Label>Status</Label>
										<ButtonGroup className="d-flex" disabled={updating}>
											<ReactstrapButton
												className={"btn-permission-toggle"}
												color="primary"
												onClick={() => onModifyUser(user, { disable: true })}
												active={user.disable}
											>
												disabled
											</ReactstrapButton>
											<ReactstrapButton
												className={"btn-permission-toggle"}
												color="primary"
												onClick={() => onModifyUser(user, { disable: false })}
												active={!user.disable}
											>
												enabled
											</ReactstrapButton>
										</ButtonGroup>
									</FormGroup>
								</Col>
							</Row>
						</Form>
					</ModalBody>
					<ModalFooter></ModalFooter>
				</Modal>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => {
	return {
		pendingss: state.admin.pending
	};
};

const mapDispatchToProps = {
	getPendingRequests: (resolve?: Function, reject?: Function) =>
		adminService.getPendingUsers(resolve, reject),
	approveOrReject: (userId: string, isApproved: boolean) =>
		adminService.approveOrRejectUser(userId, isApproved),
	editUser: (userId: string, userData: any) => adminService.updateUserById(userId, userData),
	updateUserStatus: (userId: string, status: boolean) =>
		adminService.updateUserStatus(userId, status),
	modifyUser: (userId: string, data: any) => adminService.modifyUser(userId, data)
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRequest);
