import { IPersona } from "../../interfaces/persona";
import { ActionType } from "../actions/action";
import { persona } from "../actions/actionContants";

export interface IPersonaState {
	all: IPersona[];
}

const INIT_STATE: IPersonaState = {
	all: []
};

export default function (
	state: IPersonaState = INIT_STATE,
	action: ActionType
) {
	switch (action.type) {
		case persona.GET_ALL_PERSONAS_RESPONSE:
			return {
				...state,
				all: action.payload
			};
	}

	return state;
}
