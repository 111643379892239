import React, { useEffect, useState } from "react";
import "../../../assets/scss/pages/_upgrade-prompt.scss"
import { Check, X } from 'react-feather';
import { Button, Modal, ModalBody } from 'reactstrap';
import SubscriptionItem from "../../account/components/subscriptionItem";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";
import { routes } from "../../routes";
import { Link, RouteComponentProps } from "react-router-dom";

interface IProps {
    location: any;
}

const FeatureTeaser: React.FC<IProps> = (props) => {
    const [modal, setModal] = useState<boolean>(true);

    // Extract path name from location for teaser screenshots
    const path = 'Feature: ' + props.location.pathname.split('/')[2].replace('-', '_');
    useEffect(() => {
        setModal(true);
    }, [props.location])
 
    return (
        <div className='page-content container-fluid'>
            <h3 style={{textTransform: 'capitalize'}} className={'mt-3'}>{path.replace('_', ': ')}</h3>
            <div className={'teaser-screenshot-grid'}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <Modal className={'upgrade-modal-container'} isOpen={modal}>
                <ModalBody className={'upgrade-modal-body'}>
                    <div className='upgrade-modal-content'>
                        <X id='close-prompt' height={24} width={24} onClick={() => setModal(false)}></X>
                        <h3>You need to <a>upgrade</a> to access this area</h3>
                        <div className="subscription">
                            <div className="subscription-list">

                                <SubscriptionItem active={true} type="Fresh" plans={[
                                    {
                                        details: ["Ingredient Insights", "Product Management", "1 User"],
                                        price: 199
                                    }
                                ]} />


                                <SubscriptionItem type="Growth" plans={[
                                    {
                                        details: ["Ingredient Insights", "Product Management", "2 Users"],
                                        price: 499
                                    }
                                ]} />


                                <SubscriptionItem type="Enterprise" plans={[
                                    {
                                        details: ["Ingredient insight", "Product Management", "10 Users"],
                                        price: 1999
                                    }
                                ]} />


                            </div>
                        </div>
                        <p className={'details'}>
                            Upgrade or downgrade your active subscription at any time. <br/>
                            For advice on the best subscription for your needs, contact a <br/>
                            member of our team sales@journeyfoods.com
                        </p>
                        <Link to={routes.BILLING}>
                            <Button className={'upgrade-modal-button'}>
                                <Check width={15} height={15}></Check>
                                <span>View Subscriptions</span>
                            </Button>
                        </Link>

                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: IApplicationState) => ({
    location: state.router.location,
})

export default connect(mapStateToProps)(FeatureTeaser);