import React from "react";
import { currencyFormatCentsToDollars } from "../../../../utils/currency";

interface IProps {
	subTotal: number;
	total?: number;
	tax?: number;
	discount?: number;
}

// TODO: Convert cents value for invoice subtotal to dollars
const InvoiceTotal: React.FC<IProps> = ({ subTotal = 0, total, tax, discount, ...props }) => {
	const calculateDiscountAmount = () => {
		if (!discount) return 0;
		return (discount / 100) * subTotal;
	};

	return (
		<div className="invoice-total">
			<p>
				<span> Subtotal </span>
				<span> {currencyFormatCentsToDollars(subTotal, "USD")}</span>
			</p>
			{tax && (
				<p>
					<span> Tax </span>
					<span> {currencyFormatCentsToDollars(tax, "USD")} </span>
				</p>
			)}
			{discount && (
				<p>
					<span> Discount </span>
					<span> {`- ${currencyFormatCentsToDollars(calculateDiscountAmount(), "USD")}`} </span>
				</p>
			)}
			{total != null && total != undefined && (
				<p>
					<span> Total </span>
					<span> {currencyFormatCentsToDollars(total, "USD")} </span>
				</p>
			)}
		</div>
	);
};

export default InvoiceTotal;
