import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase";
import firebaseApp from "../../../../utils/firebase";
import { X } from "react-feather";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../../../components/common/button";
import VerificationCodeForm from "../../../auth/login/verificationCode/verificationCodeForm";
import PasswordVerification from "./passwordVerification";
import SetUserPhoneNumber from "./phoneNumber";

interface IProps {
	isOpen: boolean;
	toggle: () => void;
}

const TwoFactorSetupModal: React.FC<IProps> = (props) => {
	const [passwordVerified, setPasswordVerified] = useState<boolean>(false);
	const [phoneNumberVerified, setPhoneNumberVerified] = useState<boolean>(false);
	const [codeVerified, setCodeVerified] = useState<boolean>(false);
	const [phoneNumberVerificationId, setPhoneNumberVerificationId] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [requiresMFASignIn, setRequiresMFASignIn] = useState<boolean>(false);
	const [mfaResolver, setMfaResolver] = useState<any>(null);
	const [phoneVerificationId, setPhoneVerificationId] = useState<string>("");
	const [reCaptchaVerifier, setReCaptchaVerifier] = useState<any>(null);
	const reCaptchaRef = useRef<HTMLDivElement | null>();

	const handleSubmitValidationCode = async (value: string) => {
		const cred = firebase.auth.PhoneAuthProvider.credential(phoneNumberVerificationId, value);
		const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
		const user = firebaseApp.auth().currentUser;
		if (!user) return;
		await user.multiFactor.enroll(multiFactorAssertion).then(() => {
			setCodeVerified(true);
		});
	};

	const handleClose = () => {
		setPasswordVerified(false);
		setPhoneNumberVerified(false);
		setCodeVerified(false);
		props.toggle();
	};

	const phoneNumberVerifiedCallback = (phoneVerificationId: string) => {
		setPhoneNumberVerified(true);
		setPhoneNumberVerificationId(phoneVerificationId);
	};

	const onPasswordVerifiedCallback = (requiresMFASignIn: boolean, mfaResolver?: any) => {
		if (!requiresMFASignIn) {
			setPasswordVerified(true);
			return;
		}
		if (requiresMFASignIn) {
			setPasswordVerified(true);
			setRequiresMFASignIn(true);
			if (mfaResolver) {
				setMfaResolver(mfaResolver);
				const phoneInfoOptions = {
					multiFactorHint: mfaResolver.hints[0],
					session: mfaResolver.session
				};
				const reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(reCaptchaRef.current, {
					size: "invisible"
				});
				setReCaptchaVerifier(reCaptchaVerifier);
				var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
				// Send SMS verification code.
				phoneAuthProvider
					.verifyPhoneNumber(phoneInfoOptions, reCaptchaVerifier)
					.then(function (verificationId) {
						setPhoneVerificationId(verificationId);
					});
			}
		}
	};

	const mfaSignInCallback = async (value: string) => {
		setRequiresMFASignIn(false);
	};

	return (
		<Modal isOpen={props.isOpen}>
			<ModalHeader>
				<div className={"modal-header-content"}>
					<span>Set Up 2 Factor Authentication</span>
					<X onClick={handleClose} className={"close-modal"} size={24}></X>
				</div>
			</ModalHeader>
			<ModalBody>
				{!codeVerified ? (
					<div>
						{!passwordVerified ? (
							<PasswordVerification
								updatePhoneNumber={(number: string) => setPhoneNumber(number)}
								passwordVerifiedCallback={onPasswordVerifiedCallback}
							/>
						) : !phoneNumberVerified ? (
							requiresMFASignIn ? (
								<VerificationCodeForm phoneNumber={phoneNumber} onSubmit={mfaSignInCallback} />
							) : (
								<SetUserPhoneNumber phoneNumberVerifiedCallback={phoneNumberVerifiedCallback} />
							)
						) : (
							<VerificationCodeForm
								phoneNumber={phoneNumber}
								onSubmit={handleSubmitValidationCode}
							/>
						)}
					</div>
				) : (
					<div>
						<h4 className={"text-center mb-4"}>Two factor authentication setup complete!</h4>
						<div className={"w-100 d-flex flex-row justify-content-center"}>
							<Button
								type="button"
								className="btn-primary color-white"
								color={"primary"}
								style={{ color: "white" }}
								onClick={handleClose}
							>
								Close
							</Button>
						</div>
					</div>
				)}
			</ModalBody>
			<div
				style={{ display: "hidden", opacity: 0 }}
				ref={(ref) => (reCaptchaRef.current = ref)}
			></div>
		</Modal>
	);
};

export default TwoFactorSetupModal;
