import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Tabs from "../../components/tab";
import { USER_ROLES } from "../../interfaces/roles";
import { routes } from "../routes";
import Labels from "./labels";
import Reports from "./reports";

const LabelRouter: React.FC = () => (
	<Row className="analysis">
		<Col xl="11" md="12">
			<Tabs
				tabs={[
					{
						name: "Your Documents",
						route: routes.LABELS
					},
					{
						name: "Reports",
						route: routes.REPORTS,
						tabRoles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_USER]
					}
				]}
			/>

			<div className="py-3">
				<Switch>
					<Route exact path={routes.LABELS} component={Labels} />
					<Route exact path={routes.REPORTS} component={Reports} />
					<Redirect from={routes.ANALYSIS} to={routes.LABELS} />
				</Switch>
			</div>
		</Col>
	</Row>
);

export default LabelRouter;
